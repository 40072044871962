import App from './App.vue';

export default {
  path: '/CDS_VF_NoticeDetail',
  name: 'CDS_VF_NoticeDetail',
  component: App,
  meta: {
    pageProperty: {
      title: 'お知らせ詳細',
      controller: 'CDS_CTR_NoticeDetail',
    },
  },
};
