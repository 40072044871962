import App from './App.vue';
import { user } from '@/beforeGuard/defaultRules';

export default {
  path: '/CDS_VF_LinkList',
  name: 'CDS_VF_LinkList',
  component: App,
  meta: {
    pageProperty: {
      title: 'リンク集',
      controller: 'CDS_CTR_LinkList',
    },
    guardRules: [user],
  },
};
