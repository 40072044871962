import App from './App.vue';

export default {
  path: '/login',
  name: 'login',
  component: App,
  meta: {
    pageProperty: {
      title: 'ログイン',
    },
    guardRules: [],
  },
};
