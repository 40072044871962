import App from './App.vue';

export default {
  path: '/CDS_VF_RefugeList',
  name: 'CDS_VF_RefugeList',
  component: App,
  meta: {
    pageProperty: {
      title: '避難所一覧',
      controller: 'CDS_CTR_RefugeList',
    },
  },
};
