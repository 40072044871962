<template>
  <v-dialog v-model="passwordDialog" scrollable max-width="600">
    <template #activator="{ on }">
      <ActionButton btn-value="パスワード変更" icon="mdi-key" v-on="on" />
    </template>
    <v-card class="pa-2">
      <v-card-title class="mb-2">
        <v-icon>mdi-key</v-icon>
        パスワード変更
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation @submit="validate">
        <v-card-text class="py-0 px-2">
          <v-text-field
            v-model="value.newPassword"
            label="新パスワード"
            :rules="[rules.required]"
            required
            outlined
            dense
            type="password"
          />

          <v-text-field
            v-model="value.newPasswordConfirm"
            label="新パスワード(再入力)"
            :rules="[rules.required]"
            required
            outlined
            dense
            type="password"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="error"
            class="mr-4"
            depressed
            @click="validate"
          >
            <v-icon left>
              mdi-key-change
            </v-icon>
            パスワード変更
          </v-btn>
          <v-btn
            :disabled="!valid"
            class="mr-4"
            depressed
            @click="() => (passwordDialog = false)"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ActionButton from '@/components/detail/ActionButton.vue';

export default {
  components: { ActionButton },

  props: {
    username: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    valid: true,
    value: {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
    },
    passwordDialog: false,
    rules: {
      required: (v) => !!v || '必須項目です。',
    },
    success: false,
  }),

  methods: {
    /**
     * フォームのバリデート
     */
    async validate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.value.newPassword !== this.value.newPasswordConfirm) {
        this.openSnackBar({
          message: `新パスワードと新パスワード(再入力)が一致しません。`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 5000,
          },
          closable: true,
        });
        return;
      }
      await this.$store.dispatch('loading/register', this.changePassword());
    },

    /**
     * パスワード変更
     */
    async changePassword() {
      try {
        const result = await this.$con.invoke({
          controller: 'CDS_CTR_UserDetail',
          method: 'changePassword',
          params: {
            object: JSON.stringify({
              Username: this.username,
              Password: this.value.newPasswordConfirm,
            }),
          },
        });
        if (result.error) throw new Error(result.error);
        console.log('changepassword result:', result);
        this.passwordDialog = false;
        this.success = true;
        this.openSnackBar({
          message: `パスワードを変更しました。`,
          props: {
            color: 'green',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
      } catch (error) {
        console.error(error);
        console.log(this.$t(error.message));
        this.openSnackBar({
          message: `パスワード変更に失敗しました。${this.$t(error.message)}`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        this.$refs.form.resetValidation();
      }
    },

    async logout() {
      await this.$cognito.logout();
    },

    async getLoggedIn() {
      try {
        const user = await this.$cognito.getLoggedInUser();
        console.log(user);
      } catch (error) {
        console.error(error);
      }
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
