/***** 内部データ変換処理 *****/

/**
 * 発令or解除
 */
export const orderStatuses = [
  {
    label: '発令',
    id: 'd',
  },
  {
    label: '解除',
    id: 'l',
  },
];

/**
 * 内部データラッパー変換の定義
 */
export const internalDataWrapperMinifyDef = [
  // 発令日時
  {
    // 元のキー
    originalKey: 'announceDatetime',
    // minifyのキー
    minifyKey: 'ad',
  },
  // レコードデータ
  {
    originalKey: 'records',
    // minifyデータから元のデータに変換する
    minifyKey: 'r',
    toOriginal: (v) => internalDataRecordEnlarge(v),
    // 元のデータからminifyに変換する
    toMinify: (v) => internalDataRecordMinify(v),
  },
];

/**
 * 内部データ変換の定義
 */
export const internalDataMinifyDef = [
  // Id
  {
    originalKey: 'Id',
    minifyKey: 'id',
  },
  // 区域名(臨時区域用)
  {
    originalKey: 'Name',
    minifyKey: 'nm',
  },
  // ふりがな(臨時区域用)
  {
    originalKey: 'Phonetic__c',
    minifyKey: 'pt',
  },
  // 発令種別
  {
    // 元のキー
    originalKey: 'announceTypeName',
    // minifyのキー
    minifyKey: 'at',
  },
  // 発令or解除
  {
    originalKey: 'orderStatus',
    // minifyデータから元のデータに変換する
    minifyKey: 'os',
    toOriginal: (v) => orderStatuses.find(({ id }) => id === v).label,
    // 元のデータからminifyに変換する
    toMinify: (v) => orderStatuses.find(({ label }) => label === v).id,
  },
  // 原因種別
  {
    originalKey: 'reasonType',
    minifyKey: 'rt',
  },
  // 発令解除日時
  {
    originalKey: 'announceDatetime',
    minifyKey: 'ad',
  },
  // 世帯数
  {
    originalKey: 'Household__c',
    minifyKey: 'hh',
  },
  // 人数
  {
    originalKey: 'People__c',
    minifyKey: 'pp',
  },
  // 臨時か
  {
    originalKey: 'isExtra',
    minifyKey: 'ex',
  },
];

/**
 * 内部データのラッパーをminifyする
 * @param {Object} wrapperData 内部データ
 * @returns {String} minifyしたjson文字列
 */
export const internalDataMinify = (wrapperData) => {
  if (_.isEmpty(wrapperData)) {
    return JSON.stringify({});
  }
  const res = {};
  internalDataWrapperMinifyDef.map((def) => {
    res[def.minifyKey] =
      (def.toMinify && def.toMinify(wrapperData[def.originalKey])) ||
      wrapperData[def.originalKey];
  });
  // console.log('%cminified data ->', 'color: deepskyblue;', res);
  return JSON.stringify(res);
};

/**
 * 内部データをminifyする
 * @param {Array} internalData 内部データ
 * @returns {String} minifyしたjson文字列
 */
const internalDataRecordMinify = (internalData) => {
  if (_.isEmpty(internalData) || !Array.isArray(internalData))
    return internalData || [];
  const minified = internalData.map(minifyData);
  // console.log('%cminified data::: records ->', 'color: deepskyblue;', minified);
  return minified;
};

/**
 * 各データ単位のminify
 * @param {Object} d データ
 * @returns {Object} minifyされたオブジェクト
 */
const minifyData = (d) => {
  const res = {};
  internalDataMinifyDef.map((def) => {
    res[def.minifyKey] =
      (def.toMinify && def.toMinify(d[def.originalKey])) || d[def.originalKey];
  });
  return res;
};

/**
 * 内部データを復元する
 * @param {String} minifyData minifyされた内部データ
 * @returns {Array} 復元されたデータの配列
 */
export const internalDataEnlarge = (minifyData) => {
  const parsed = JSON.parse(minifyData || '{}');
  if (_.isEmpty(parsed)) return parsed;
  const res = {};
  internalDataWrapperMinifyDef.map((def) => {
    res[def.originalKey] =
      (def.toOriginal && def.toOriginal(parsed[def.minifyKey])) ||
      parsed[def.minifyKey];
  });
  // console.log('%cenlarged data ->', 'color: deepskyblue;', res);
  return res;
};

/**
 * 内部データを復元する
 * @param {Array} minifyData minifyされた内部データ
 * @returns {Array} 復元されたデータの配列
 */
const internalDataRecordEnlarge = (minifyData) => {
  if (_.isEmpty(minifyData)) return minifyData;
  const enlarged = minifyData.map(enlargeData);
  // console.log('%cenlarged data::: records ->', 'color: deepskyblue;', enlarged);
  return enlarged;
};

/**
 * 各データ単位の復元
 * @param {Object} d データ
 * @returns {Object} 復元されたデータ
 */
const enlargeData = (d) => {
  const res = {};
  internalDataMinifyDef.map((def) => {
    res[def.originalKey] =
      (def.toOriginal && def.toOriginal(d[def.minifyKey])) || d[def.minifyKey];
  });
  return res;
};

/***** レコードデータ変換処理 *****/

export const recordDataConvertDef = (announceTypes) => {
  return [
    // 区域名
    {
      // レコードデータのキー
      recordDataKey: 'Name',
      // Objectのキー
      objectKey: 'AreaName__c',
    },
    // ふりがな
    {
      recordDataKey: 'Phonetic__c',
      objectKey: 'AreaNameKana__c',
    },
    // 区名
    {
      recordDataKey: 'Ward__c',
      objectKey: 'Ward__c',
    },
    // 発令種別
    {
      recordDataKey: 'announceTypeName',
      objectKey: 'AnnounceType__c',
      toObject: (v) =>
        (announceTypes.find(({ name }) => name === v) || {}).label,
    },
    // 発令or解除
    {
      recordDataKey: 'orderStatus',
      objectKey: 'OrderStatus__c',
    },
    // 発令日時
    {
      recordDataKey: 'announceDatetime',
      objectKey: 'AnnounceDateTime__c',
    },
    // 警戒レベル
    {
      recordDataKey: 'alertLevel',
      objectKey: 'AlertLevel__c',
    },
    // 原因種別
    {
      recordDataKey: 'reasonType',
      objectKey: 'ReasonType__c',
    },
    // 世帯数
    {
      recordDataKey: 'Household__c',
      objectKey: 'Household__c',
    },
    // 人数
    {
      recordDataKey: 'People__c',
      objectKey: 'Population__c',
    },
    // 図形
    {
      recordDataKey: 'Polygon__c',
      objectKey: 'Polygon__c',
    },
  ];
};

/**
 * 区域オブジェクトの配列とpolygonのデータを作成
 * @param {Object} recordData レコードデータ
 * @param {Array} announceTypes 発令種別
 * @returns {Array} CDS_T_EvacuationAdvisory__c: Array
 */
export const recordDataToObject = (recordData, announceTypes) => {
  // 変換の定義
  const convertDef = recordDataConvertDef(announceTypes);
  // まずrecordDataがobjectなので配列にする
  const recordDataArray = _.flatten(
    _.map(recordData, (typeValue) =>
      _.flatten(_.map(typeValue, (recordValue) => recordValue)),
    ),
  );
  // 区域オブジェクトの配列
  const CDS_T_EvacuationAdvisory__c = recordDataArray.map((record, i) => ({
    ...recordDataConvert(record, convertDef),
    // 行数をここで追加
    LineNo__c: i + 1,
  }));
  return CDS_T_EvacuationAdvisory__c;
};

/**
 * 個別データの変換
 * @param {Object} record データ
 * @param {Array} defArray 変換の定義
 * @returns {Object} 変換後データ
 */
const recordDataConvert = (record, defArray) => {
  const res = {
    attributes: {
      type: 'CDS_T_EvacuationAdvisory__c',
    },
  };
  defArray.map((def) => {
    res[def.objectKey] =
      (def.toObject && def.toObject(record[def.recordDataKey])) ||
      record[def.recordDataKey];
  });
  return res;
};
