<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <!-- メニュー表示ボタン -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </template>
    <v-card class="dialog">
      <!--ツールバー-->
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>印刷設定</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="print">
            <v-icon>mdi-printer</v-icon>
            印刷
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!--設定内容-->
      <v-list three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>レイアウト</v-list-item-title>
            <v-list-item-subtitle
              ><v-select dense :items="layouts" v-model="layout"
            /></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>用紙サイズ</v-list-item-title>
            <v-list-item-subtitle
              ><v-select
                dense
                :items="paperSizeSetting"
                v-model="selectedPaper"
            /></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    selectedPaper: [210, 297],
    layout: 'landscape',
    layouts: [
      { text: '横', value: 'landscape' },
      { text: '縦', value: 'portrait' },
    ],
    paperSizeSetting: [
      { text: 'A0', value: [841, 1189] },
      { text: 'A1', value: [594, 841] },
      { text: 'A2', value: [420, 594] },
      { text: 'A3', value: [297, 420] },
      { text: 'A4', value: [210, 297] },
      { text: 'A5', value: [148, 210] },
      { text: 'A6', value: [105, 148] },
      { text: 'B0', value: [1030, 1456] },
      { text: 'B1', value: [728, 1030] },
      { text: 'B2', value: [515, 728] },
      { text: 'B3', value: [364, 515] },
      { text: 'B4', value: [257, 364] },
      { text: 'B5', value: [182, 257] },
      { text: 'B6', value: [128, 182] },
    ],
  }),
  mounted() {},
  methods: {
    async print() {
      this.dialog = false;
      const { map } = this.$store.state.GIS;
      const mapDiv = map.getDiv();
      // 表示している中心点を保存
      const printCenter = map.getCenter();
      // 必ずタイルを再読み込みさせるために表示領域を変更
      mapDiv.style.width = '0mm';
      mapDiv.style.height = '0mm';
      // 表示領域を印刷サイズに変更
      const isLandscape = this.layout === 'landscape';
      mapDiv.style.width = `${this.selectedPaper[isLandscape ? 1 : 0]}mm`;
      mapDiv.style.height = `${this.selectedPaper[isLandscape ? 0 : 1]}mm`;
      // 地図の中心点を復元
      map.setCenter(printCenter);
      // 読み込み完了を待ってから印刷ダイアログを表示する
      await new Promise((resolve) =>
        google.maps.event.addListenerOnce(map, 'idle', resolve),
      );
      // 印刷モーダル表示
      print();
      // 表示サイズを復帰
      mapDiv.style.width = '';
      mapDiv.style.height = '';
    },
  },
};
</script>

<style scoped>
@media print {
  .dialog {
    display: none;
  }
}
</style>
