import App from './App.vue';

export default {
  path: '/CDS_VF_HeadquarterList',
  name: 'CDS_VF_HeadquarterList',
  component: App,
  meta: {
    pageProperty: {
      title: '本部設置状況一覧',
      controller: 'CDS_CTR_HeadquarterList',
    },
  },
};
