<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab title="ライフライン状況" icon="mdi-transmission-tower" />
    </template>
    <div>
      <v-row no-gutters>
        <v-col>
          <v-tabs
            v-model="tab"
            background-color="rgba(255,255,255,0)"
            @change="handleOnTabChanged"
          >
            <v-tab
              v-for="{ objectName, icon, label } in objectSettings"
              :key="objectName"
            >
              <v-icon left>
                {{ icon }}
              </v-icon>
              {{ label }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              v-for="objset in objectSettings"
              :key="objset.objectName"
            >
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :items-per-page="10"
                disable-sort
                hide-default-footer
                dense
                class="cdsTable"
                :mobile-breakpoint="0"
              >
                <template
                  v-for="header of table.headers"
                  #[`item.${header.value}`]="props"
                >
                  <Display
                    :key="header.value"
                    v-model="props.value"
                    :item-props="props"
                    :field-info="props.header.fieldInfo"
                  />
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard.vue';
import BarCardTab from '@/components/common/BarCardTab';
import Display from '@/components/list/Display.vue';

import { objectSettings } from '@/assets/js/objects/CDS_T_Lifeline__c';

export default {
  name: 'OverviewLifeline',

  components: {
    BarCard,
    BarCardTab,
    Display,
  },

  data: () => ({
    // ローディング
    loading: false,
    // タブ
    tab: null,

    // オブジェクト設定
    objectSettings,

    // 選択中のオブジェクト
    selectedObject: null,

    // テーブル
    table: {
      headers: [],
      items: [],
    },
  }),

  computed: {},

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await Promise.all([this.getStat()]);
      await this.$nextTick();
      this.loading = false;
    },

    async getStat() {
      this.table.headers = [];
      this.table.items = [];
      if (!this.selectedObject) return;
      await Promise.all([this.getRecords(), this.getHeader()]);
    },

    async getRecords() {
      const { objectName } = this.selectedObject;

      const { objList = [] } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getLifelineStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            objectName,
          },
        })) || {};

      this.table.items = objList || [];
    },

    async getHeader() {
      const { objectName } = this.selectedObject;

      const { fieldSet, objectInfo } = await this.$util.getFieldSet(
        objectName,
        'PortalFieldSet',
      );

      this.table.headers =
        fieldSet?.fields.map((f) => {
          const { fieldPath } = f;
          const fieldInfo = objectInfo.properties[fieldPath];

          return {
            value: fieldPath,
            sortable: false,
            text: fieldInfo?.label,
            fieldInfo,
          };
        }) || [];
    },

    /**
     * タブが変更された時
     */
    async handleOnTabChanged(to) {
      this.$set(this, 'selectedObject', this.objectSettings[to]);
      await this.init();
    },

    handleClickBar() {
      this.$transition.to('CDS_VF_LifelineList', null, {
        notApplyRetUrl: true,
      });
    },
  },
};
</script>
