var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mx-2",attrs:{"justify":"end"}},[_c('span',{staticClass:"mr-2 red--text"},[_vm._v("*")]),_vm._v(" 必須項目 ")]),_c('FormulateForm',{attrs:{"name":"input_form","formulate-value":_vm.slotProps.value},on:{"input":(v) => _vm.slotProps.change(v),"submit-raw":_vm.formSubmit}},[_c('v-row',{staticClass:"pa-3"},_vm._l((_vm.customFormConfig),function(formItem,index){return _c('field',{key:`${formItem.name}---${index}`,attrs:{"field-path":formItem.name,"field-info":formItem,"column":'1',"required":formItem.required,"edit-mode":true,"custom-validation":_vm.customValidation(formItem.name)}})}),1),_vm._l((_vm.layout.sections),function(section){return _c('CustomSection',_vm._b({key:section.id,scopedSlots:_vm._u([{key:"bottom_title",fn:function(){return [(section.label == '全体情報')?[_c('div',{staticClass:"px-5 pt-2"},[_c('span',{staticClass:"mx-2"},[_vm._v("サンプル文表示")]),_vm._l((Object.keys(
                    _vm.sampleText.content || {},
                  )),function(target,targetIndex){return _c('v-btn',{key:targetIndex,staticClass:"mx-2",attrs:{"depressed":""},on:{"click":function($event){return _vm.openSampleTextModal(target)}}},[_vm._v(" "+_vm._s(_vm._.get(_vm.objectInfo, `properties[${target}].label`, null))+" ")])})],2)]:_vm._e()]},proxy:true}],null,true)},'CustomSection',{
            title: section.label,
            editMode: true,
            ...section,
          },false),_vm._l((section.items),function(item){return _c('field',{key:item.fieldPath,attrs:{"field-path":item.fieldPath,"field-info":_vm.objectInfo.properties[item.fieldPath],"column":section.column,"required":item.required,"edit-mode":true,"custom-validation":_vm.customValidation(item.fieldPath)}})}),1)})],2)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.sampleText.show),callback:function ($$v) {_vm.$set(_vm.sampleText, "show", $$v)},expression:"sampleText.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-headline"},[_vm._v(" サンプル文 ("+_vm._s(_vm._.get(_vm.objectInfo, `properties[${_vm.sampleText.target}].label`, null))+") ")]),_c('v-card-text',[_c('span',{staticClass:"black--text",staticStyle:{"white-space":"pre"},domProps:{"textContent":_vm._s(_vm._.get(_vm.sampleText.content, _vm.sampleText.target, null))}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.sampleText.show = false}}},[_vm._v(" 閉じる ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }