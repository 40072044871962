import Vue from 'vue';
import VueApollo from 'vue-apollo';

import AWSAppSyncClient from 'aws-appsync';
import { AUTH_TYPE } from 'aws-appsync/lib/client';

const { appConfig } = Vue;

export async function load() {
  const client = new AWSAppSyncClient(
    {
      url: appConfig.appsync.endpoint,
      region: appConfig.region,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        apiKey: appConfig.appsync.apiKey,
        jwtToken: async () => {
          const userInfo = await Vue.cognito.getAuthenticatedUser();
          if (!userInfo) return null;
          return userInfo.session.getAccessToken().getJwtToken();
        },
      },
      fetchPolicy: 'cache-and-network',
      disableOffline: true,
    },
    {
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
        },
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
      },
    },
  );

  const appsyncProvider = new VueApollo({
    defaultClient: client,
  });

  await client.hydrated();

  Vue.use(VueApollo);

  return appsyncProvider;
}
