import Vue from 'vue';
import beforeGuard from '@/beforeGuard/index';

/**
 * ルーターに対して初期設定を行う
 * @param {*} param0.router ルーター
 * @param {*} param0.routes ルート定義
 */
export function setupHandler({ router, routes }) {
  // ページ遷移が行われた後に実行させる
  router.afterEach(async (to) => {
    // タイトルを設定
    const { title, forceTitle } = to.meta.pageProperty || {};
    document.title = forceTitle
      ? forceTitle
      : `防災システム${title ? ` | ${title}` : ''}`;
    // ページプロパティに値を設定
    setPageProperty(to);
  });

  // ナビゲーションガードのハンドラー
  async function handleBeforeEach(to) {
    await beforeGuard(routes.find((r) => r.path === to.path)?.meta?.guardRules);
  }

  // ナビゲーションガード
  router.beforeEach((to, from, next) => {
    // 同じページへの遷移の場合は、一旦別ページにリダイレクトさせてコンポーネントの初期化を狙う
    if (to.path === from.path) {
      // リダイレクトが繰り返し行われる可能性の回避
      if ((to.params?.redirectCount || 0) <= 2) {
        return next({
          name: 'redirect',
          params: {
            nextRoute: { ...to },
          },
        });
      }
    }
    if (to.path === '/redirect') {
      // 行き先がリダイレクトの場合は何もしない
      return next();
    }
    // ハンドラにまわす
    handleBeforeEach(to, from)
      .then(() => next())
      .catch((e) => next(e));
  });
}

/**
 * ページプロパティに値を設定
 * @param {*} location
 */
function setPageProperty(location) {
  // ページプロパティに値を設定
  Vue.prototype.$pageProperty = location.meta.pageProperty;
}
