<template>
  <v-app>
    <!-- ローディング -->
    <Loading overlay />
    <!--スナックバー-->
    <Snackbar />
    <!--ヘッダーバー-->
    <AppBar />
    <v-divider></v-divider>
    <template v-if="showDisaster">
      <DisasterBar />
      <v-divider></v-divider>
    </template>
    <!--GISMenu-->
    <GISMenu v-if="!mapLoading" id="Menu" />
    <!--地図-->
    <v-main id="Main">
      <v-overlay :value="overlay" absolute z-index="4"></v-overlay>
      <v-progress-linear
        v-if="dataLoading"
        indeterminate
        absolute
        color="green"
        height="10"
      />
      <v-icon class="centerIcon">
        mdi-image-filter-center-focus-weak
      </v-icon>
      <v-container ref="map" class="map" fluid fill-height />
      <Control v-if="!mapLoading" />
    </v-main>
    <Chat />
    <!-- ホットリロード -->
    <HotReloadMonitor />
  </v-app>
</template>

<script>
import AppBar from '@/components/core/AppBar';
import DisasterBar from '@/components/core/DisasterBar';
import Loading from '@/components/core/Loading';
import HotReloadMonitor from '@/components/core/HotReloadMonitor';
import Snackbar from '@/components/app/Snackbar';
import GISMenu from './parts/menu';
import Control from './parts/Control';
import Chat from '@/components/Chat';
import vuexModule from './vuex';

export default {
  components: {
    AppBar,
    DisasterBar,
    Loading,
    HotReloadMonitor,
    Snackbar,
    GISMenu,
    Control,
    Chat,
  },
  data: () => ({ map: {} }),
  computed: {
    mapLoading() {
      const { GIS = { mapLoading: true } } = this.$store.state;
      return GIS.mapLoading;
    },
    dataLoading() {
      const { GIS = { dataLoading: true } } = this.$store.state;
      return GIS.dataLoading;
    },
    // 災害と組織が確定していない場合表示しない
    showDisaster() {
      return (
        this.$store.state.disaster.disaster.Id &&
        this.$store.state.user.organization.Id
      );
    },
    overlay() {
      // 開いているメニューがひとつでもあればoverlayを表示する
      return this.$store.state.menu.activeMenues.length !== 0;
    },
  },
  mounted() {
    // オリジナルのvuexモジュールを追加
    this.$store.registerModule('GIS', vuexModule);
    const query = this.$query.current();
    this.$store.dispatch('GIS/loadMap', { dom: this.$refs.map, query });
  },
};
</script>

<style scoped>
@media print {
  #app-bar,
  #dis-app-bar,
  #Menu,
  #chat-component-wrapper,
  hr {
    display: none !important;
  }
  #Main {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0 !important;
  }
  .map {
    max-width: 100% !important;
    max-height: 100% !important;
    padding: 0 !important;
  }
}
.centerIcon {
  position: absolute;
  z-index: 2;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  color: #f44336;
}
</style>
<style>
/** 印刷ズレ対策 */
@media print {
  .gm-style div > img {
    position: absolute;
  }
}
</style>
