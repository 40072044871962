var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"table-props":{
      canEdit: false,
      canCreate: false,
      onClickFunc: null,
      bulkFieldSetName: 'BulkFieldSet',
    }}},'ListView',{
      objectName: 'CDS_T_Link__c',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }