import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';

import masterLoading from '@/MasterLoading';
import App from './App.vue';
import router from './router';
import { routes } from './router/routes';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/config';
import '@/plugins/cache';
import '@/plugins/globalConstants';
import '@/plugins/lodash';
import '@/plugins/cognito';
import '@/plugins/filter';
import '@/plugins/query';
import '@/plugins/transition';
import '@/plugins/datefns';
import '@/plugins/connection';
import '@/plugins/vue-formulate';
import '@/plugins/utility';
import '@/plugins/async-computed';
import i18n from '@/plugins/vueI18n';
import '@/assets/css/global.scss';
import { load as appsyncLoader } from '@/plugins/appsync';
import beforeGuard from '@/beforeGuard';
import monitor from '@/plugins/hotModuleMonitor';
import '@/plugins/echarts';
import '@/plugins/attachmentUploader';
import '@/plugins/clipboard';
import '@/plugins/notification';

monitor();

Vue.config.productionTip = false;
document.title = 'Loading...';

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

async function start() {
  // 最初にローディングを表示
  new Vue({
    vuetify,
    render: (h) => h(masterLoading),
  }).$mount('#app');

  // beforeGuard実施
  // その返り値で、storeの初期値を設定
  const initState = await beforeGuard(
    routes.find((r) => r.path === location.pathname)?.meta?.guardRules,
  );
  // 返り値がfalseならvueのレンダリングは行わない
  if (initState._error) return null;
  // Appsyncの準備を行い、plugin登録をする
  const appsyncProvider = await appsyncLoader();

  new Vue({
    router,
    i18n,
    store: store({
      initState,
    }),
    vuetify,
    apolloProvider: appsyncProvider,
    render: (h) => h(App),
  }).$mount('#app');
}

start();
