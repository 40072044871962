<template>
  <bar-card
    :loading="loading"
    :bar-click="latestObject.Id ? handleClickBar : null"
    @reload="init()"
  >
    <template #bar_tab>
      <BarCardTab title="発令中の避難情報 ※重複含む" icon="mdi-exit-run" />
    </template>
    <div class="mb-2 pt-2 d-flex justify-center align-center">
      <div class="text-sm-body-1 text-body-2">対象数</div>
      <div class="text-subtitle-1">
        <span class="mx-1 text-sm-h5 text-h6">
          {{ latestObject.CityHousehold__c || '-' | numberFormat }}
        </span>
        <span class="text-sm-body-1 text-body-2">世帯</span>
        <span class="text-sm-body-1 text-body-2">/</span>
        <span class="mx-1 text-sm-h5 text-h6">
          {{ latestObject.CityPeople__c || '-' | numberFormat }}
        </span>
        <span class="text-sm-body-1 text-body-2">人</span>
      </div>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="10"
        disable-sort
        hide-default-footer
        dense
        class="cdsTable"
        :mobile-breakpoint="0"
      >
        <template #item.AnnounceType__c="props">
          <AnnounceTypeLabel
            :value="props.value"
            :label-class="
              Number(props.item.count) > 0 ? null : 'blue-grey lighten-3'
            "
            class="mr-2 float-left rounded-0"
          />
        </template>
        <template #item.count="props">
          {{ props.value || '-' | numberFormat }} 区域
        </template>
        <template #item.Household__c="props">
          {{ props.value || '-' | numberFormat }} 世帯
        </template>
        <template #item.Population__c="props">
          {{ props.value || '-' | numberFormat }} 人
        </template>
      </v-data-table>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';
import AnnounceTypeLabel from '@/components/app/AnnounceTypeLabel';

export default {
  components: {
    BarCard,
    BarCardTab,
    AnnounceTypeLabel,
  },
  data: () => ({
    loading: false,
    // オブジェクト情報
    objectInfo: {},
    // 最新オブジェクト
    latestObject: {},
    // データ本体
    dataMap: {},

    // 表ヘッダー
    headers: [
      { text: '発令種別', value: 'AnnounceType__c' },
      { text: '区域数', value: 'count', align: 'end' },
      { text: '対象世帯数', value: 'Household__c', align: 'end' },
      { text: '対象人数', value: 'Population__c', align: 'end' },
    ],
  }),

  computed: {
    // 発令種別
    announceTypes() {
      return _.get(
        this.objectInfo,
        'properties.AnnounceType__c.picklistValues',
        [],
      ).map((v) => v.value);
    },
    // 表のレコード
    records() {
      return this.announceTypes.map((t) => ({
        AnnounceType__c: t,
        ...(this.dataMap[t] || {}),
      }));
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await Promise.all([this.getObjectInfo(), this.getStat()]);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // オブジェクト情報の取得
    async getObjectInfo() {
      const objectInfo = await this.$util.getObjectInfo(
        'CDS_T_EvacuationAdvisory__c',
      );
      this.$set(this, 'objectInfo', objectInfo);
    },
    // 情報の取得
    async getStat() {
      const { latestObject = null, announceMap = null } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getEvacuationStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};

      if (!_.isEmpty(latestObject)) {
        this.$set(this, 'latestObject', latestObject);
      }
      if (!_.isEmpty(announceMap)) {
        this.$set(this, 'dataMap', announceMap);
      }
    },
    handleClickBar() {
      this.$transition.to(
        'CDS_VF_EvacuationAdvisoryDetail',
        {
          id: this.latestObject.Id,
          ro: true,
        },
        {
          notApplyRetUrl: true,
        },
      );
    },
  },
};
</script>
