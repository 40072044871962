// ローディング
export default {
  namespaced: true,

  state: {
    // ローディング表示
    show: false,
    // 強制表示
    force: false,
    // ページ遷移中
    transition: true,
    // タスク配列
    tasks: [],
    // 現在の有効な非表示プロミス
    hidePromise: null,
  },

  mutations: {
    // 登録
    register(state, v) {
      // タスクに追加
      state.tasks.push(v);
      // loadingを表示
      state.show = true;
      // 全部が完了したらhideするプロミスを用意
      const completedPromise = Promise.allSettled(state.tasks);
      // 有効な非表示プロミスとして設定する
      state.hidePromise = completedPromise;
      // 非表示プロミスが完了した時の処理
      completedPromise.then(() => {
        if (state.hidePromise === completedPromise) {
          // loading非表示にする
          state.show = false;
          // 今あるタスクを空にする
          state.tasks.length = 0;
          // 非表示プロミスを削除
          state.hidePromise = null;
        }
      });
    },
    // 強制表示
    setForce(state, v) {
      state.force = !!v;
    },
    // ページ遷移中
    setTransition(state, v) {
      state.transition = !!v;
    },
  },

  actions: {
    async register({ commit }, v) {
      commit('register', v);
      return v;
    },
    async setForce({ commit }, v) {
      commit('setForce', !!v);
    },
    async setTransition({ commit }, v) {
      commit('setTransition', !!v);
    },
  },
};
