<template functional>
  <v-app dark>
    <CircularLoading />
  </v-app>
</template>

<script>
import Vue from 'vue';
import { VApp } from 'vuetify/lib';
import CircularLoading from '@/components/CircularLoading.vue';
Vue.component('VApp', VApp);
Vue.component('CircularLoading', CircularLoading);

export default {
  name: 'MasterLoading',
};
</script>
