<template>
  <Layout
    v-model="_value"
    name="input_form"
    :layout="layout"
    :object-info="objectInfo"
    :edit-mode="slotProps.editMode"
    :hidden-section-names="finalHiddenSectionNames"
    :readonly-field-names="finalReadonlyFieldNames"
    :skeleton-loading="isLoading"
    @submit-raw="slotProps.validate"
  />
</template>

<script>
import Layout from '@/components/detail/Layout';

export default {
  name: 'RequestDetailLayout',

  components: {
    Layout,
  },

  props: {
    slotProps: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    // 表示レイアウト情報
    layout: {},
    // 表示オブジェクト情報
    objectInfo: {},
    // レイアウトロード完了
    layoutLoaded: false,
  }),

  computed: {
    _value: {
      get() {
        return this.slotProps.value;
      },
      set(v) {
        this.slotProps.change(v);
      },
    },
    originalData() {
      return this.slotProps.originalData;
    },
    isLoading() {
      return this.slotProps.skeletonLoading || !this.layoutLoaded;
    },
    // 現在の組織
    currentOrg() {
      return this.$store.state.user.organization || {};
    },
    // 現在の組織が要請元か
    isRequestFromOrg() {
      return this.originalData.CDS_M_Organization__c === this.currentOrg.Id;
    },
    // 現在の組織が要請先か
    isRequestToOrg() {
      return (
        this.originalData.DestinationOrganization__c === this.currentOrg.Id
      );
    },
    // 非表示セクション
    finalHiddenSectionNames() {
      return [...(this._value.Id ? [] : ['訂正'])];
    },
    // 読み取り項目
    finalReadonlyFieldNames() {
      return [
        ...(!this._value.Id || !this.isRequestToOrg
          ? [
              'Answerer__c',
              'AnswererTel__c',
              'RequestStatus__c',
              'AnswerDetail__c',
            ]
          : []),
      ];
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadLayout()]),
      );
    },

    async loadLayout() {
      const { layoutName, objectName } = this.slotProps;
      const { layout, objectInfo } = await this.$util.getLayout(
        objectName,
        layoutName,
      );

      // 緯度経度登録項目の入力タイプを図形入力に変更
      objectInfo.properties.Location__c.type = 'features';
      // 画面の中心点を住所に設定ボタンクリック時の動作を指定
      objectInfo.properties.Location__c.reverseGeocodingEventhandler = ({
        address_components,
        formatted_address,
      }) => {
        // 発生場所に設定
        this.$set(this._value, 'Address__c', formatted_address);
        // 事案発生区名に設定
        const ward = address_components.find(({ types }) =>
          types.includes('sublocality_level_1'),
        );
        if (ward && ward.long_name) {
          this.$set(this._value, 'Ward__c', ward.long_name);
        }
      };

      // 要請先の選択肢を組織マスタから設定する
      try {
        // 組織マスタの取得
        const res = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getDestinationOrganizationList',
        });
        if (res) {
          // 選択肢に設定
          objectInfo.properties.DestinationOrganization__c.picklistValues = res.map(
            ({ Id, Name }) => ({
              active: true,
              defaultValue: false,
              label: Name,
              validFor: null,
              value: Id,
            }),
          );
        }
      } catch (error) {
        this.openSnackBar({
          message: '組織マスタの呼び出しに失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }

      this.$set(this, 'layout', layout);
      this.$set(this, 'objectInfo', objectInfo);

      this.layoutLoaded = true;
    },
  },
};
</script>
