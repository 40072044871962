import App from './App.vue';

export default {
  path: '/CDS_VF_JMAInfoDetail',
  name: 'CDS_VF_JMAInfoDetail',
  component: App,
  meta: {
    pageProperty: {
      title: '気象庁発表情報詳細',
      controller: 'CDS_CTR_JMAInfoDetail',
    },
  },
};
