<template>
  <v-treeview
    v-if="!loading.item"
    v-model="selected"
    selectable
    dense
    :items="items"
    class="text-caption"
  >
    <template #label="{item}">
      <v-icon v-if="item.icon" :color="item.color">
        {{ item.icon }}
      </v-icon>
      {{ item.name }}
    </template>
  </v-treeview>
  <v-skeleton-loader v-else :type="Array(5).fill('list-item').join(',')" />
</template>

<script>
import mdi from '@/assets/mdiIcons';
import * as dataDrawUtil from '@/googlemap/dataDrawUtil';
import { getUrl } from '@/assets/js/s3.js';

export default {
  components: {},
  props: {},
  data: (vm) => ({
    metadata: [],
    selected: [],
    data: {},
    conditionMetadatas: [],
    polygons: [],

    loading: {
      item: true,
      data: false,
    },
  }),
  computed: {
    // 選択チェックボックス表示用表示設定一覧
    items() {
      return _.orderBy(this.metadata, ['Sort__c'], ['asc']).map(
        ({ Label, Id, Icon__c, Color__c }) => ({
          name: Label,
          id: Id,
          icon: mdi[Icon__c],
          color: Color__c,
          // ロード中は選択リストを無効化
          disabled: this.loading.data,
        }),
      );
    },
  },
  watch: {
    async selected(ids) {
      this.loading.data = true;
      this.$store.commit('GIS/setLoading', true);
      // データ読み出し
      await this.loadData();
      // データ描画
      const { map } = this.$store.state.GIS;
      this.polygons.forEach(({ overlay, type }) => {
        overlay.setMap(null);
        if (type === 'culuster') {
          overlay.clearMarkers();
        }
      });
      this.polygons = [];
      this.selected.forEach((rootId) => {
        this.drawData(
          this.data[rootId],
          this.metadata.find((d) => d.Id === rootId),
        );
      });
      this.$store.commit('GIS/setLoading', false);
      this.loading.data = false;
    },
  },
  async mounted() {
    await this.loadMetadata();
  },
  methods: {
    /**
     * 表示設定メタデータを読み出す
     */
    async loadMetadata() {
      const { controller } = this.$pageProperty;
      this.loading.item = true;
      try {
        this.metadata =
          (await this.$con.invoke({
            controller,
            method: 'getStaticMetaDataList',
          })) || [];
        await Promise.all(
          this.metadata.map(async (rootMetadata) => {
            const { TargetObject__c, ItemSetName__c } = rootMetadata;
            // 項目セット取得
            const { fieldSet } = await this.$util.getFieldSet(
              TargetObject__c,
              ItemSetName__c,
            );
            rootMetadata.fieldSet = fieldSet;
          }),
        );
      } catch (error) {
        console.error(error);
      }

      this.loading.item = false;
    },
    /**
     * 表示データを読み出す
     */
    async loadData() {
      const { controller } = this.$pageProperty;
      await Promise.all(
        this.selected.map(async (rootId) => {
          // オブジェクト表示条件
          const settingMetaData =
            this.metadata.find(({ Id }) => rootId === Id) || {};
          // 対象のSObjectを読み出す
          let datas = await this.$con.invoke({
            controller,
            method: 'getStaticDataList',
            params: rootId,
          });
          if (!datas || datas.length === 0) return [];
          // 対象データが存在していればその添付ファイルを読み出す
          const ids = JSON.stringify(datas.map((d) => d.Id));
          datas = await this.$con.invoke({
            controller: 'CDS_CTR_Common',
            method: 'getAttachment',
            params: {
              objectName: settingMetaData.TargetObject__c,
              ids,
            },
          });
          if (settingMetaData.PlaceField__c) {
            // 位置情報からマーカを設定
            datas.forEach((d) => {
              if (!d.object[settingMetaData.PlaceField__c]) return;
              const latlng = JSON.parse(
                d.object[settingMetaData.PlaceField__c],
              );
              const lat_s = latlng.latitude;
              const lng_s = latlng.longitude;
              if (!lat_s || !lng_s) return;
              const lat = Number(lat_s);
              const lng = Number(lng_s);
              const marker = new google.maps.Marker({
                position: { lat, lng },
              });
              d.json = JSON.stringify({ features: [marker.getJSON()] });
            });
          } else {
            // GIS用GEOJSONを読み出す
            await Promise.all(
              datas.map(async (d) => {
                const { attachments } = d;
                if (!attachments) return;
                const { FileId } =
                  attachments.find(({ Name }) =>
                    Name.match(/gis_(.*)_data.json/),
                  ) || {};
                if (!FileId) return;
                const url = await getUrl(FileId);
                d.json = await fetch(url).then((res) => res.text());
              }),
            );
          }
          this.data[rootId] = datas;
        }),
      );
    },
    /**
     * 描画処理
     */
    drawData(datas, rootMetadata) {
      const { map } = this.$store.state.GIS;
      const color = rootMetadata.Color__c;
      if (!datas) return;
      datas.forEach((d) => {
        // 図形描画
        const polygons = dataDrawUtil.createPolygons({
          map,
          color,
          json: d.json,
          path: mdi[rootMetadata.Icon__c],
        });
        // ポップアップウィンドウ表示
        const url = '';
        dataDrawUtil.createPopupWindow(polygons, {
          title: rootMetadata.Label,
          icon: mdi[rootMetadata.Icon__c],
          fieldSet: rootMetadata.fieldSet,
          color,
          url,
          object: d.object,
        });
        this.polygons = [...this.polygons, ...polygons];
      });
      // マーカークラスタリング
      const culuster = dataDrawUtil.markerClustering(map, this.polygons);
      this.polygons.push({ type: 'culuster', overlay: culuster });
    },
  },
};
</script>
<style></style>
