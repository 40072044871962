<template>
  <detail
    object-name="CDS_T_Disaster__c"
    layout-name="DisasterLayout"
    default-title="新規災害作成"
    saved-page="CDS_VF_DisasterDetail"
    detail-page-name="CDS_VF_DisasterDetail"
    list-page="CDS_VF_DisasterList"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :override="override"
  >
    <template #layout="slotProps">
      <DetailLayout :slot-props="slotProps" />
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import DetailLayout from './DetailLayout';
import { disaster } from '@/beforeGuard/defaultRules';
import { validateDisaster } from '@/validation';

export default {
  components: {
    detail,
    DetailLayout,
  },
  data: () => ({
    override: {
      // 入力チェック
      async validate({ value }) {
        // チェック実施
        const result = validateDisaster(value);
        // nullであれば問題なし、文字列ならば問題あり
        if (result) throw new Error(result.message);
      },
    },
  }),
  computed: {
    // 編集時に上書きする値
    everyOverwriteValue() {
      const {
        user: { user },
      } = this.$store.state;
      const init = {
        Reporter__c: user.DefaultReporter__c,
        ReporterAffiliation__c: user.Department__c,
        ReporterTel__c: user.Phone,
      };
      return init;
    },
  },
  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },
};
</script>
