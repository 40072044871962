<template>
  <detail
    use-version
    object-name="CDS_T_Headquarter__c"
    layout-name="HeadquarterLayout"
    default-title="新規本部体制作成"
    subtitle="本部体制詳細"
    saved-page="CDS_VF_HeadquarterDetail"
    detail-page-name="CDS_VF_HeadquarterDetail"
    detail-layout-name="CDS_T_Headquarter__c-検索レイアウト"
    search-layout-name="CDS_T_Headquarter__c-一覧表レイアウト"
    list-page="CDS_VF_HeadquarterList"
    :diff-page="false"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    :load-master="loadMaster"
    :override="override"
    use-correction
    :fix-message="
      useFujisan
        ? '確定します。この操作でFUJISANに連携されます。よろしいですか？（市本部体制のみFUJISANに連携します。）'
        : undefined
    "
    :fix-text="useFujisan ? '確定/FUJISAN連携' : undefined"
  >
    <template #underHeader="{ editMode, isFixed, value }">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="editMode ? 1 : isFixed ? 3 : 2"
      >
        <v-stepper-header>
          <v-stepper-step step="1"> 入力 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2"> 確定 </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3"> 完了 </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import { validateHeadquarter } from '@/validation';

export default {
  components: {
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        /**
         * レイアウト読み出しをオーバラライド
         */
        _loadLayout: detail.methods.loadLayout,
        loadLayout: async function () {
          // 所属組織によって体制名が変えられるようにデフォルトのレイアウトをオーバライドする
          await this._loadLayout();
          // 画面の中心点を住所に設定ボタンクリック時の動作を指定
          this.objectInfo.properties.Location__c.reverseGeocodingEventhandler =
            ({ formatted_address, utmPromise }) => {
              // 設置場所に設定
              this.$set(this.value, 'Address__c', formatted_address);
              // UTM座標に設定
              utmPromise.then((v) => {
                this.$set(this.value, 'UtmPoint__c', v);
              });
            };
          //マスタデータ読み出し
          const controller = 'CDS_CTR_HeadquarterDetail';
          const masterList = await this.$con.invoke({
            controller,
            method: 'getMasterData',
            params: {},
          });
          // 配備体制選択リストを生成する関数を設定
          const picklistFunc = () => {
            // フォームの現在値を取得
            const { formulateValue } =
              this.$formulate.registry.get('input_form');
            //マスタデータ読み出し
            const master =
              _.find(masterList, {
                Id: formulateValue.CDS_M_Organization__c,
              }) || {};
            if (master.SystemValues__c) {
              // 災害種類に応じて選択できる配備体制が異なるため判定処理を行う
              // 警戒本部体制は静岡県では東海地震関連災害時のみ体制として利用するため
              const disaster = this.$store.state.disaster.disaster;
              const excludeSystem = {
                '一般災害（大雨、洪水、暴風、大雪等）': ['警戒本部体制'],
                地震災害: ['警戒本部体制'],
                '津波 災害（津波注意報・警報）': ['警戒本部体制'],
                '大規模事故災害（道路事故、鉄道事故、海上事故、火災事故等）': [
                  '警戒本部体制',
                ],
                その他: ['応援体制（外部組織）'],
              };
              return master.SystemValues__c.split(';')
                .filter((v) => {
                  const excludeTarget =
                    excludeSystem[disaster.DisasterKind__c] || [];
                  return !excludeTarget.includes(v);
                })
                .map((d) => ({
                  value: d,
                  label: d,
                }));
            }
          };
          this.objectInfo.properties.System__c.picklistController =
            picklistFunc;
          this.objectInfo.properties.NorthSystem__c.picklistController =
            picklistFunc;
          this.objectInfo.properties.SouthSystem__c.picklistController =
            picklistFunc;
        },

        /**
         * データ読み出しをオーバラライド
         */
        _loadData: detail.methods.loadData,
        loadData: async function () {
          const controller = 'CDS_CTR_HeadquarterDetail';
          const {
            user: { organization },
            disaster: { disaster },
          } = this.$store.state;
          const { id, mid, mode } = this.$query.current() || {};
          this.canEdit = false;
          if (!id && !mid && mode !== 'n') {
            // 登録済み本部体制がある場合はそちらへリダイレクト
            const registeredId = await this.$con.invoke({
              controller,
              method: 'checkRegisteredData',
              params: {
                organizationId: organization.Id,
                disasterId: disaster.Id,
              },
            });
            if (registeredId) {
              this.$transition.to(
                `CDS_VF_HeadquarterDetail?id=${registeredId}`,
                null,
                {
                  keepRetUrl: true,
                },
              );
              return;
            } else {
              // 登録済み本部がなければ、midを付与してリダイレクト
              this.$transition.to(
                `CDS_VF_HeadquarterDetail?mid=${organization.Id}`,
                null,
                {
                  keepRetUrl: true,
                },
              );
              return;
            }
          } else {
            // idが指定されている場合
            await this._loadData();
            // 編集可否判定
            // 組織が一致している場合のみ編集可能
            this.canEdit =
              mode === 'n' ||
              this.value.CDS_M_Organization__c === organization.Id;
          }
          // 初報作成時は訂正セクションを非表示にする
          if (!this.value.Id) {
            this.hiddenSectionNames.push('訂正');
          }
          // 災害対策本部事務局以外は北部南部を非表示にする
          if (
            !this.$gc.operationMaster.includes(
              this.value?.CDS_M_Organization__r?.Name,
            )
          ) {
            this.hiddenSectionNames.push('北部・南部');
          }
        },
      },
    },
  },
  data: () => ({
    override: {
      // 入力チェック
      async validate({ value }) {
        // チェック実施
        const result = validateHeadquarter(value);
        // nullであれば問題なし、文字列ならば問題あり
        if (result) throw new Error(result.message);
      },
    },
  }),
  computed: {
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      // マスタが組織マスタなので設定する項目を制限
      const { Reporter__c, ReporterAffiliation__c, ReporterTel__c } =
        this.$util.getDefaultEveryOverwriteValue(this.$store.state);
      return { Reporter__c, ReporterAffiliation__c, ReporterTel__c };
    },
    useFujisan() {
      const { Class1__c, Class2__c } = this.$store.state.user.organization;
      return (
        Class1__c == '本部' &&
        !Class2__c &&
        this.$store.getters['disaster/isFujisanConnected']
      );
    },
  },
  methods: {
    /**
     * マスタデータを読みだす
     */
    async loadMaster() {
      const { controller } = this.$pageProperty;
      const { id, mid, mode } = this.$query.current() || {};
      if (!id) {
        if (mid) {
          //マスタデータ読み出し
          const { object: master } = await this.$con.invoke({
            controller,
            method: 'getData',
            params: {
              id: mid,
              objectName: 'CDS_M_Organization__c',
            },
            mock: () => ({}),
          });
          if (master) {
            return {
              ...master,
              CDS_M_Organization__r: master,
              CDS_M_Organization__c: master.Id,
              IsExtra__c: false,
            };
          }
        }
        if (mode === 'n') {
          return {
            IsExtra__c: true,
          };
        }
      }
      return {};
    },
  },
};
</script>
