<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>

    <ListView
      v-bind="{
        objectName: 'CDS_T_Disaster__c',
        detailLayoutName: 'DetailPreviewLayout',
        detailPageName: 'CDS_VF_DisasterDetail',
      }"
      :table-props="{
        defaultListOptions,
        csvFileNameFormat: '災害一覧-%date%.csv',
        csvFieldSetName: 'CSVFieldSet',
        listSaveValidate: validate,
        bulkFieldSetName: 'BulkFieldSet',
      }"
      :search-props="{
        syncConditionWithUrl: true,
        defaultSearchCondition: defaultSearchCondition,
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import { disaster } from '@/beforeGuard/defaultRules';
import { validateDisaster } from '@/validation';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },
  data: () => ({
    defaultListOptions: {
      sortBy: ['HappenDate__c'],
      sortDesc: [true],
    },
    DisasterType__c: null,
  }),

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },

  methods: {
    validate(listData) {
      if (!listData || listData.length === 0) {
        return true;
      }

      // 共通のバリデート
      const result = new Set(
        _.flatten(
          listData
            .map(validateDisaster)
            .filter((v) => !!v)
            .map((v) => v.result),
        ),
      );
      if (result.size > 0) return [...result].join('\n');

      return true;
    },
    // デフォルト検索条件
    defaultSearchCondition() {
      let result = {};
      if (this.$store.state.disaster?.disaster?.DisasterType__c) {
        result.DisasterType__c = [
          this.$store.state.disaster.disaster.DisasterType__c,
        ];
      }
      return result;
    },
  },
};
</script>
