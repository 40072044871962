import Vue from 'vue';
import { CacheManager } from '../modules/cache';

const plugin = {
  install: function (Vue) {
    const cacheInstance = new CacheManager();
    Vue.prototype.$cache = cacheInstance;
    Vue.$cache = cacheInstance;
  },
};

Vue.use(plugin);
