// メニューを画面遷移のたびにDBから取得しないためにストアに保持する
export default {
  namespaced: true,

  state: {
    menuitem: null,
    menusection: null,
    linkCollection: null,
    activeMenues: [],
    showAll: false,
    showHelp: false,
  },
  getters: {},

  mutations: {
    setMenuData(state, { menuitem, menusection }) {
      state.menuitem = menuitem;
      state.menusection = menusection;
    },
    addActiveMenues(state, value) {
      state.activeMenues = [...new Set([...state.activeMenues, value])];
    },
    clearActiveMenues(state) {
      state.activeMenues = [];
    },
    setLinkCollection(state, value) {
      state.linkCollection = value;
    },
    removeActiveMenues(state, value) {
      state.activeMenues = state.activeMenues.filter((v) => v !== value);
    },
    setShowAll(state, value) {
      state.showAll = value;
    },
    setShowHelp(state, value) {
      state.showHelp = value;
    },
  },
  actions: {
    setMenuData({ commit }, { menuitem, menusection } = {}) {
      commit('setMenuData', { menuitem, menusection });
    },
    // メガメニューのoverlayを制御する
    setActiveMenues({ commit }, { name, value }) {
      if (value) {
        commit('addActiveMenues', name);
      } else {
        commit('removeActiveMenues', name);
      }
    },
    clearActiveMenues({ commit }) {
      commit('clearActiveMenues');
    },
    setLinkCollection({ commit }, value) {
      commit('setLinkCollection', value);
    },
    setShowAll({ commit }, value) {
      commit('setShowAll', !!value);
    },
    setShowHelp({ commit }, value) {
      commit('setShowHelp', !!value);
    },
  },
};
