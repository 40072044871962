import App from './App.vue';

export default {
  path: '/CDS_VF_LifelineGasDetail',
  name: 'CDS_VF_LifelineGasDetail',
  component: App,
  meta: {
    pageProperty: {
      title: 'ライフライン(都市ガス)',
      controller: 'CDS_CTR_LifelineGasDetail',
    },
  },
};
