import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
import cognito from '../assets/js/cognito/i18n';

export default new VueI18n({
  locale: 'ja',
  messages: { ja: { ...cognito } },
});
