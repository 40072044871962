<template>
  <div>
    <Layout
      v-model="_value"
      name="input_form"
      :layout="layout"
      :object-info="objectInfo"
      :edit-mode="slotProps.editMode"
      @submit-raw="slotProps.validate"
    />

    <!-- FUJISAN災害選択ダイアログ -->
    <v-dialog v-model="dialog" scrollable max-width="1200">
      <v-card>
        <v-card-title class="text-headline">
          FUJISAN災害選択
        </v-card-title>

        <v-card-text class="py-0 px-2" style="height: 500px;">
          <v-container class="py-0">
            <v-row no-gutters>
              <v-col>
                <v-card-title class="black--text">
                  <span>災害選択</span>
                  <v-btn
                    color="red"
                    outlined
                    depressed
                    class="ml-5"
                    @click="
                      onClickDisasterList({
                        Id: null,
                        Name: null,
                      })
                    "
                  >
                    選択を解除する
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="table.search"
                    append-icon="mdi-magnify"
                    label="検索"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  v-model="table.selected"
                  :headers="table.headers"
                  :items="table.items"
                  :search="table.search"
                  dense
                  class="cdsTable"
                  @click:row="onClickDisasterList"
                >
                  <template #item.SaigaiHasseiDatetime__c="props">
                    {{ props.value | datetime }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#2c3e50" text @click="dialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Layout from '@/components/detail/Layout';

export default {
  components: {
    Layout,
  },
  props: {
    slotProps: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    // レイアウト
    layout: {},
    // オブジェクト情報
    objectInfo: {},

    // FUJISAN災害ダイアログ
    dialog: false,
    // FUJISAN災害リスト
    table: {
      // テーブルヘッダー
      headers: [
        {
          text: '災害名',
          value: 'Name',
        },
        {
          text: '災害区分',
          value: 'Type__c',
        },
        {
          text: '災害状況',
          value: 'SaigaiStatus__c',
        },
        {
          text: '災害発生場所',
          value: 'SaigaiHasseiBasho__c',
        },
        {
          text: '災害発生時間',
          value: 'SaigaiHasseiDatetime__c',
        },
      ],
      // アイテム
      items: [],
      // 検索文字列
      search: null,
    },
    // FUJISAN災害
    fujisanDisaster: [],
  }),

  computed: {
    _value: {
      get() {
        return this.slotProps.value;
      },
      set(value) {
        this.slotProps.change(value);
      },
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadLayout()]),
      );
    },

    // レイアウトの読み込み
    async loadLayout() {
      const { layoutName, objectName } = this.slotProps;
      const { layout, objectInfo } = await this.$util.getLayout(
        objectName,
        layoutName,
      );

      // FUJISAN災害名をクリックできるようにする
      objectInfo.properties.FujisanName__c.handler = {
        click: () => {
          this.onClickFujisanName();
        },
      };

      // 編集不可にする
      objectInfo.properties.FujisanName__c.readonly = true;

      this.$set(this, 'layout', layout);
      this.$set(this, 'objectInfo', objectInfo);
    },

    // FUJISAN災害の取得
    async loadFujisanDisaster(disasterType) {
      const res = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getFujisanDisaster',
          params: {
            disasterType,
          },
        }),
      );
      this.table.items = res || [];
    },

    // 災害名をクリックした時
    async onClickFujisanName() {
      // 編集モード以外は動作しない
      if (!this.slotProps.editMode) return;
      // 災害区分が入力されていない場合も動作しない
      if (!this._value.DisasterType__c) {
        return alert('先に災害区分を選択してください。');
      }
      // すでにダイアログが開いていても動作しない
      if (this.dialog) return;

      // FUJISAN災害一覧の取得
      await this.loadFujisanDisaster(this._value.DisasterType__c);
      // モーダルを開く
      this.dialog = true;
    },

    // ダイアログ内リストをクリックした時
    async onClickDisasterList(item) {
      this.$set(this._value, 'ExternalId__c', item.Id);
      this.$set(this._value, 'FujisanName__c', item.Name);
      this.dialog = false;
    },
  },
};
</script>
