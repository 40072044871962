<template>
  <v-row no-gutters>
    <v-col>
      <v-slide-group
        v-model="tab"
        mandatory
        active-class="primary white--text"
        class="pa-2"
        @change="onTabChanged"
      >
        <v-slide-item
          v-for="area in areas"
          :key="area.name"
          v-slot="{ active, toggle }"
        >
          <v-btn
            class="mx-1"
            :input-value="active"
            depressed
            small
            @click="toggle"
          >
            {{ area.name }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="area in areas" :key="area.name" transition="false">
          <v-row no-gutters justify="space-around">
            <v-col
              v-for="(status, statusIndex) in damageReport.info.statuses"
              :key="statusIndex"
              cols="auto"
              class="py-1 px-2"
            >
              <div :class="`${status.textClass} text-center`">
                <div class="text-sm-body-1 text-body-2">
                  {{ status.name }}
                </div>
                <div class="text-sm-h5 text-h6">
                  {{ _.get(countMap, status.name, 0) }}
                </div>
              </div>
            </v-col>
          </v-row>
          <e-chart
            :option="damageReport.chart"
            autoresize
            style="height: 320px"
            @mouseover="mouseHoverHandler"
            @mouseout="mouseHoverHandler"
          />
          <div
            v-if="!!currentDescription"
            class="px-7"
            style="position: absolute; z-index: 2"
          >
            <div
              class="pa-2 text-caption"
              style="background-color: rgba(238, 238, 238, 0.8)"
            >
              <span class="red--text pb-2">{{ currentDescription }}</span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { ActivityStatus__c } from '@/assets/js/objects/CDS_T_DamageReport__c';

export default {
  props: {
    updateEvent: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    tab: null,
    selectedAreaValue: null,
    areas: [
      {
        name: '市全体',
        value: null,
      },
      {
        name: '中央区',
        value: '中央区',
      },
      {
        name: '浜名区',
        value: '浜名区',
      },
      {
        name: '天竜区',
        value: '天竜区',
      },
    ],

    // オブジェクト情報
    objectInfo: {},
    // データ本体
    dataSource: [],
    // 件数のmap
    countMap: {},

    // 各種類の説明
    damageTypeDescription: {
      住家破損:
        '「住家」とは、現実に居住のために使用している建物をいい、社会通念上の住家であるかは問わない。',
      非住家破損:
        '「非住家」とは、住家以外の建物で、他の被害箇所項目に属さないものとし、全壊又は半壊の被害を受けたもののみを計上する。',
      床上浸水:
        '住家の床より上に浸水したもの及び全壊・半壊には該当しないが、土砂・竹木のたい積により一時的に居住することができないものとする。',
      床下浸水: '床上浸水に至らない程度に浸水したものとする。',
      火災: '火災発生件数については、地震又は火山噴火の場合のみ報告するものとする。',
      道路被害:
        '道路法第２条第１項に規定する道路のうち、橋梁を除いたものとする。',
      橋梁被害: '道路を連結するために河川、運河等の上に架設された橋とする。',
      陥没: '道路陥没等の陥没被害に分類されるものとする。',
      土砂崩れ:
        '自然がけ及び宅地造成に伴う人造がけの崩落など、斜面表層の土砂や岩石が地中のある面を境にして滑り落ちて人若しくは建物に被害を及ぼし、また、道路・交通等に支障を及ぼしたものとする。',
      交通被害: '汽車、電車等の運行が不能となった程度の被害とする。',
      その他: '上記種類に分類できない被害。',
    },
    // 表示中のメッセージ
    currentDescription: null,
  }),
  computed: {
    // 凡例 (未対応, 対応中, ...)
    legend() {
      return _.get(
        this.objectInfo,
        'properties.ActivityStatus__c.picklistValues',
        [],
      ).map((v) => v.value);
    },
    // 種類 (住家被害, ...)
    damageType() {
      return _.get(
        this.objectInfo,
        'properties.DamageType__c.picklistValues',
        [],
      ).map((v) => v.value);
    },
    // 被害状況
    damageReport() {
      return {
        // 数字
        info: {
          // 対応状況
          statuses: [...this.legend, '計'].map((l) => ({
            name: l,
            textClass: '',
            ...ActivityStatus__c[l],
          })),
        },
        // グラフ
        chart: {
          color: this.legend.map((l) => ActivityStatus__c[l].chartColor),
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            top: '1%',
            data: this.legend,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '48',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: this.damageType,
            triggerEvent: true,
          },
          dataset: {
            dimensions: ['DamageType__c', ...this.legend],
            source: this.dataSource,
          },
          series: [...Array(this.legend.length)].map(() => ({
            type: 'bar',
            stack: 'stackname',
            label: {
              show: true,
              position: 'insideRight',
            },
            cursor: 'default',
          })),
          textStyle: {
            fontFamily: [
              'Hiragino Kaku Gothic ProN',
              'Hiragino Sans',
              'Roboto',
              'sans-serif',
            ],
          },
        },
      };
    },
  },
  watch: {
    updateEvent: {
      handler() {
        this.init();
      },
      deep: true,
    },
    loading(to) {
      this.$emit('loading', to);
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await Promise.all([this.getObjectInfo(), this.getStat()]);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // オブジェクト情報の取得
    async getObjectInfo() {
      if (!this.objectInfo || Object.keys(this.objectInfo).length === 0) {
        const objectInfo = await this.$util.getObjectInfo(
          'CDS_T_DamageReport__c',
        );
        this.$set(this, 'objectInfo', objectInfo);
      }
    },
    // 情報の取得
    async getStat() {
      const ward = this.selectedAreaValue || null;
      const { resultList = [], count = {} } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getDamageInfoStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            ward,
          },
        })) || {};
      if (resultList && Array.isArray(resultList)) {
        this.dataSource = resultList;
      }
      this.$set(this, 'countMap', count);
    },
    async onTabChanged(to) {
      this.selectedAreaValue = this.areas[to].value;
      await this.init();
    },

    // 軸のマウスホバーを検知
    mouseHoverHandler(e) {
      if (e.componentType != 'yAxis') return;
      // マウスを乗せた時の動作
      if (e.type == 'mouseover') {
        // 説明文
        const description = this.damageTypeDescription[e.value];
        if (!description) return;
        this.currentDescription = description;
      }
      // マウスが外れた時の動作
      else if (e.type == 'mouseout') {
        this.currentDescription = null;
      }
    },
  },
};
</script>
