var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header',{attrs:{"title":"ユーザ選択","subtitle":"ユーザを選択してください。","icon":"fa-synagogue"}})]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"search-props":{
      layoutName: 'SelectSearchLayout',
      forceCondition: {
        IsValid__c: true,
      },
    },"table-props":{
      listFieldSetName: 'SelectListFieldSet',
      defaultListOptions: _vm.defaultListOptions,
      canEdit: false,
      canCreate: false,
      canCsvDownload: false,
      onClickFunc: _vm.onClick,
    }}},'ListView',{
      objectName: 'User',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }