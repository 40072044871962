<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header
        title="ファイルダウンロード"
        subtitle="ファイルのダウンロードを行います。"
        icon="mdi-folder-star"
        :showbackarrow="false"
      ></page-header>
    </template>

    <v-row v-if="!!url" class="mt-2">
      <v-col v-if="isImage" cols="12">
        <v-img max-width="100%" :src="url"></v-img>
      </v-col>
      <v-col cols="12">
        <v-card max-width="500">
          <v-card-title>
            {{ fileName }}
          </v-card-title>
          <v-card-subtitle>
            {{ fileSize | filesize }}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              color="primary"
              :loading="downloading"
              @click="onClickDownload"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              ダウンロード
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';

import { mapActions } from 'vuex';
import { getUrl } from '@/assets/js/s3';
import { disaster } from '@/beforeGuard/defaultRules';

export default {
  components: {
    defaultLayout,
    PageHeader,
  },

  data: () => ({
    isImage: false,
    fileId: null,
    url: null,
    fileName: null,
    fileSize: null,
    downloading: false,
  }),

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch('loading/register', this.loadAttachment());
    },

    async loadAttachment() {
      const { id } = this.$query.current();
      if (!id) {
        this.openErrorSnackBar({ message: 'IDがありません。' });
        return;
      }
      const { attachment } = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getAttachmentObject',
        params: {
          id,
        },
      });

      if (!attachment) {
        this.openErrorSnackBar({
          message: '添付ファイルが見つかりませんでした。',
        });
        return;
      }
      console.log(attachment);

      const { FileId, Type, Name, Size } = attachment;

      this.fileId = FileId;
      this.isImage = !!(Type.startsWith('img/') || Type.startsWith('image/'));
      this.fileName = Name;
      this.fileSize = Size;

      this.url = await this.getAttachmentUrl(this.fileId);
      // if (!this.isImage) {
      //   this.onClickDownload();
      // }
    },

    async getAttachmentUrl(fileId) {
      return await getUrl(fileId);
    },

    async onClickDownload() {
      if (!this.url) return;
      this.downloading = true;

      try {
        const blob = await fetch(this.url).then((r) => r.blob());
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.fileName || 'ファイル.unknown';
        link.click();
      } catch (error) {
        this.openErrorSnackBar({
          message: 'ダウンロードに失敗しました。' + error.message,
        });
      }
      this.downloading = false;
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>
