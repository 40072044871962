<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'ChatRoom',
        detailPageName: 'CDS_VF_ChatDetail',
        isDisasterRelated: true,
      }"
      :search-props="{
        syncConditionWithUrl: true,
      }"
      :table-props="{
        defaultListOptions,
        canEdit: false,
        canCreate: false,
        viewAttachment: false,
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['CreatedDate'],
      sortDesc: [true],
    },
  }),
};
</script>
