export const AnnounceType__c = {
  // nameには固有のidのような使い方をするため、ユニークな文字列を指定すること
  高齢者等避難: {
    name: 'at_1',
    labelStyle: 'background-color: #FF2800; color: #FFFFFF;',
    short: '高齢者等避難',
    alertLevel: '警戒レベル３',
    map: {
      declare: {
        color: '#FF2800',
      },
      lift: {
        color: '#607D8B',
      },
    },
  },
  避難指示: {
    name: 'at_2',
    labelStyle: 'background-color: #AA00AA; color: #FFFFFF;',
    short: '避難指示',
    alertLevel: '警戒レベル４',
    map: {
      declare: {
        color: '#AA00AA',
      },
      lift: {
        color: '#607D8B',
      },
    },
  },
  緊急安全確保: {
    name: 'at_3',
    labelStyle: 'background-color: #0C000C; color: #FFFFFF;',
    short: '緊急安全確保',
    alertLevel: '警戒レベル５',
    map: {
      declare: {
        color: '#FF2800',
      },
      lift: {
        color: '#607D8B',
      },
    },
  },
  警戒区域設定: {
    name: 'at_4',
    labelStyle: 'background-color: #4527A0; color: #FFFFFF;',
    short: '警戒区域',
    alertLevel: '',
    map: {
      declare: {
        color: '#4527A0',
      },
      lift: {
        color: '#607D8B',
      },
    },
  },
};
