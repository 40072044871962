<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row justify="end" class="mx-2">
          <span class="mr-2 red--text">*</span>
          必須項目
        </v-row>
        <FormulateForm
          name="input_form"
          :formulate-value="slotProps.value"
          @input="(v) => slotProps.change(v)"
          @submit-raw="formSubmit"
        >
          <!-- 発令日時等 -->
          <v-row class="pa-3">
            <field
              v-for="(formItem, index) in customFormConfig"
              :key="`${formItem.name}---${index}`"
              :field-path="formItem.name"
              :field-info="formItem"
              :column="'1'"
              :required="formItem.required"
              :edit-mode="true"
              :custom-validation="customValidation(formItem.name)"
            />
          </v-row>

          <!-- 通常の入力項目 -->
          <CustomSection
            v-for="section in layout.sections"
            :key="section.id"
            v-bind="{
              title: section.label,
              editMode: true,
              ...section,
            }"
          >
            <field
              v-for="item in section.items"
              :key="item.fieldPath"
              :field-path="item.fieldPath"
              :field-info="objectInfo.properties[item.fieldPath]"
              :column="section.column"
              :required="item.required"
              :edit-mode="true"
              :custom-validation="customValidation(item.fieldPath)"
            />
            <template #bottom_title>
              <template v-if="section.label == '全体情報'">
                <div class="px-5 pt-2">
                  <span class="mx-2">サンプル文表示</span>
                  <v-btn
                    v-for="(target, targetIndex) in Object.keys(
                      sampleText.content || {},
                    )"
                    :key="targetIndex"
                    depressed
                    class="mx-2"
                    @click="openSampleTextModal(target)"
                  >
                    {{ _.get(objectInfo, `properties[${target}].label`, null) }}
                  </v-btn>
                </div>
              </template>
            </template>
          </CustomSection>
        </FormulateForm>
      </v-card-text>
    </v-card>

    <v-dialog v-model="sampleText.show" max-width="1000">
      <v-card>
        <v-card-title class="text-headline">
          サンプル文 ({{
            _.get(objectInfo, `properties[${sampleText.target}].label`, null)
          }})
        </v-card-title>

        <v-card-text>
          <span
            style="white-space: pre;"
            class="black--text"
            v-text="_.get(sampleText.content, sampleText.target, null)"
          ></span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="sampleText.show = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import field from '@/components/detail/field';
import CustomSection from '@/components/detail/section';
import { internalDataMinify, recordDataToObject } from './util';

export default {
  name: 'EvacuationInfo',

  components: {
    field,
    CustomSection,
  },

  props: {
    // stepperアクティブ
    active: {
      type: Boolean,
      default: false,
    },
    // 発令種別のリスト
    announceTypes: {
      type: Array,
      default: () => [],
    },
    // 内部データ
    internalData: {
      type: Array,
      default: () => [],
    },
    // 区域データ
    recordData: {
      type: Object,
      default: () => ({}),
    },
    // 区域データの計算されたもの
    recordDataNumberFields: {
      type: Object,
      default: () => ({}),
    },
    // layoutスロットのprops
    slotProps: {
      type: Object,
      default: () => ({}),
    },
    // loadData終了時のデータ
    loadedData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    // レイアウト
    layout: {},
    // オブジェクト情報
    objectInfo: {},
    // データ
    tempValue: {},

    // 追加項目のフォームの設定
    customFormConfig: [
      // 発令日時の設定
      {
        name: '_announceDatetime',
        label: '発令・解除日時',
        type: 'date-time',
        required: true,
      },
    ],
    // 追加項目の値
    customFormValue: {},
    // 内部データを反映済み
    appliedInternalData: false,

    // サンプル文
    sampleText: {
      // 表示
      show: false,
      // 対象(Reason__c, Guideline__c)
      target: null,
      // 内容
      content: {},
    },

    // フォームの個別バリデーション
    customValidations: [
      {
        fieldName: 'CityHousehold__c',
        value: {
          validation: ['val01'],
          validationRules: {
            val01: ({ getFormValues }) => {
              const values = getFormValues();
              const { CityHousehold__c, CityPeople__c } = values;
              if (
                _.isNil(CityHousehold__c) ||
                CityHousehold__c === '' ||
                _.isNil(CityPeople__c) ||
                CityPeople__c === ''
              )
                return true;
              return Number(CityHousehold__c) <= Number(CityPeople__c);
            },
          },
          validationMessages: {
            val01:
              '市町全体対象世帯数は市町全体対象人数より少ない数を入力してください。',
          },
        },
      },
    ],
  }),

  watch: {
    loadedData: {
      handler(to) {
        if (!this.appliedInternalData && to) {
          this.applyAnnounceDatetime(to.announceDatetime);
          this.appliedInternalData = true;
        }
      },
      deep: true,
    },
    recordDataNumberFields: {
      handler(to) {
        if (!_.isEmpty(to)) {
          // 市町全体世帯数と人数を反映する
          const {
            Household__c: CityHousehold__c,
            People__c: CityPeople__c,
          } = to;
          // 現在の値を保持
          const currentValues = { ..._.cloneDeep(this.slotProps.value) };
          // 空の値をセット
          this.$formulate.setValues('input_form', {});
          // 中身が入った値をセット
          this.$formulate.setValues('input_form', {
            ...currentValues,
            CityHousehold__c,
            CityPeople__c,
          });
        }
      },
      deep: true,
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadLayout(), this.loadSampleText()]),
      );
    },
    // レイアウトの読み込み
    async loadLayout() {
      const objectName = 'CDS_T_EvacuationAdvisoryManager__c';
      const { layout, objectInfo } = await this.$util.getLayout(
        objectName,
        'EvacuationAdvisoryManagerLayout',
      );
      // 「非表示」セクションを隠す
      this.$set(this, 'layout', {
        ...layout,
        sections: layout.sections.filter(
          (section) => section.label != '非表示' && section.items?.length > 0,
        ),
      });
      this.$set(this, 'objectInfo', objectInfo);
    },
    // サンプル文の読み込み
    async loadSampleText() {
      const config = await this.$util.getConfigValue(
        'CDS_EvacuationSampleText',
      );
      this.$set(this.sampleText, 'content', JSON.parse(config));
    },
    // formのsubmit
    formSubmit(form) {
      const recordData = _.cloneDeep(this.recordData);
      const internalData = _.cloneDeep(this.internalData);

      // 世帯数と人数のバリデート
      if (
        internalData.some((value) => {
          // どちらかに値が入ってない場合は除外
          if (_.isNil(value.People__c) || _.isNil(value.Household__c))
            return false;
          // どちらとも値が入っている状態で比較する
          return value.People__c < value.Household__c;
        })
      ) {
        this.openSnackBar({
          message:
            '人数より世帯数の方が大きくなっている区域があります。1つ前の画面で確認してください。',
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        return;
      }

      // 保存前に、発令日時等をrecordDataとinternalDataに入れる

      // 内部データ
      const internalDataApplied = internalData.map((d) => ({
        ...d,
        // 発令日時の値があればそれを採用。なければ新区域なので入力値を設定
        announceDatetime:
          d.announceDatetime || this.slotProps.value._announceDatetime,
      }));
      // console.log(
      //   '%c[info] internalDataApplied ->',
      //   'color: darksalmon;',
      //   internalDataApplied,
      // );
      // 内部データの組み立て
      const internalDataForMinify = {
        records: internalDataApplied,
        announceDatetime: this.slotProps.value._announceDatetime,
      };
      // 内部データを圧縮
      const internalDataMinified = internalDataMinify(internalDataForMinify);
      // console.log(
      //   '%c[info] internalDataMinified ->',
      //   'color: darksalmon;',
      //   internalDataMinified,
      // );

      // recordDataの用意
      // 発令日時のセット
      _.map(recordData, (typeValue) => {
        _.map(typeValue, (recordValue) => {
          recordValue.map((record) => {
            // 発令日時の値があればそれを採用。なければ新区域なので入力値を設定
            record.announceDatetime =
              record.announceDatetime || this.slotProps.value._announceDatetime;
          });
        });
      });

      // レコードデータをオブジェクト用とpolgon用として変換
      const childObject = recordDataToObject(recordData, this.announceTypes);
      // console.log(
      //   '%c[info] kuiki object ->',
      //   'color: darksalmon;',
      //   childObject,
      //   JSON.stringify(childObject),
      // );

      // オブジェクトに情報登録
      const targetObject = { ...this.slotProps.value };
      // 内部データを登録
      targetObject.InternalData__c = internalDataMinified;
      // 区域レコードデータを登録
      targetObject._recordData = childObject;
      // console.log('%c[info] prepared object for save ->', 'color: darksalmon;', {
      //   ...targetObject,
      // });
      // データを反映
      this.slotProps.change(targetObject);

      // 保存処理実行
      this.slotProps.validate(form);
    },

    // 発令解除日時の初期読み込み
    applyAnnounceDatetime(ad) {
      if (ad && this.slotProps.value && !this.slotProps.value.IsFixed__c) {
        this.slotProps.change({
          ...this.slotProps.value,
          announceDatetime: ad,
        });
      }
    },

    // サンプル文モーダル
    openSampleTextModal(target) {
      this.sampleText.target = target;
      this.sampleText.show = true;
    },

    customValidation(fieldPath) {
      return (
        this.customValidations?.find((c) => c.fieldName === fieldPath) || null
      );
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
