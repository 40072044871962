<template>
  <div>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>mdi-target</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>ホーム表示・設定</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-btn small block @click="setCityViewBounds">市内全域表示</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn small block @click="loadViewBounds">ホーム表示に移動</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn small block @click="saveViewBounds"
        >現在の表示領域をホーム表示に設定</v-btn
      >
    </v-list-item>
    <v-divider />

    <v-list-item>
      <v-list-item-avatar>
        <v-icon>mdi-map-search</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>住所・場所検索</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-text-field
        ref="map-search"
        placeholder="場所を入力してください"
        outlined
        dense
      />
    </v-list-item>
    <v-divider />

    <v-list-item>
      <v-list-item-avatar>
        <v-icon>mdi-map-marker</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>緯度経度検索</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-text-field class="mx-1" label="緯度" outlined dense v-model="lat" />
      <v-text-field class="mx-1" label="経度" outlined dense v-model="lng" />
    </v-list-item>
    <v-list-item>
      <v-btn small block @click="latlngMove">移動</v-btn>
    </v-list-item>
    <v-divider />
  </div>
</template>

<script>
import cookies from 'browser-cookies';

export default {
  data: (vm) => ({
    lat: 34.710865,
    lng: 137.7239283,
  }),
  props: {},
  computed: {},
  async mounted() {
    const { map } = this.$store.state.GIS;
    // 地点情報検索
    const searchDOM = this.$refs['map-search'].$el;
    const inputDOM = searchDOM.querySelector('input');
    const autocomplete = new google.maps.places.Autocomplete(inputDOM, {
      bounds: google.maps.Map.defaultBounds,
    });
    // 選択時動作
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      map.panTo(place.geometry.location);
    });
  },
  methods: {
    /**
     * 緯度経度指定移動
     */
    latlngMove() {
      const { map } = this.$store.state.GIS;
      map.panTo({
        lat: Number(this.lat),
        lng: Number(this.lng),
      });
    },
    /**
     * 保存されている表示領域を読み出して設定
     */
    loadViewBounds() {
      // TODO:boudsが未設定の場合の処理
      const { map } = this.$store.state.GIS;
      const bounds = JSON.parse(cookies.get('CDS_GIS_ViewBounds'));
      map.fitBounds(bounds);
    },
    /**
     * 表示領域を保存
     */
    saveViewBounds() {
      const { map } = this.$store.state.GIS;
      const bounds = map.getBounds();
      cookies.set('CDS_GIS_ViewBounds', JSON.stringify(bounds));
    },
    /**
     * 市内全域を表示
     */
    setCityViewBounds() {
      const { map } = this.$store.state.GIS;
      map.fitBounds(google.maps.Map.defaultBounds);
    },
  },
  components: {},
};
</script>
<style></style>
