import App from './App.vue';
import { user } from '@/beforeGuard/defaultRules';

export default {
  path: '/CDS_VF_GISConnectResultDetail',
  name: 'CDS_VF_GISConnectResultDetail',
  component: App,
  meta: {
    pageProperty: {
      title: 'ArcGIS連携履歴',
      controller: 'CDS_CTR_GISConnectResultDetail',
    },
    guardRules: [user],
  },
};
