import { getUrl } from '@/assets/js/s3.js';
export default {
  /**
   * 描画データ覧取得
   */
  loadDrawDataList(vm) {
    const { controller } = vm.$pageProperty;
    const { disaster } = vm.$store.state.disaster;
    const { organization } = vm.$store.state.user;
    return vm.$store.dispatch(
      'loading/register',
      vm.$con.invoke({
        controller,
        method: 'getDrawDataList',
        params: {
          disasterID: disaster.Id,
          organizationID: organization.Id,
        },
      }),
    );
  },
  /**
   * 描画データ取得
   */
  async loadDrawData(vm, data) {
    const res = await vm.$store.dispatch(
      'loading/register',
      vm.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getAttachment',
        params: {
          ids: JSON.stringify([data.Id]),
          objectName: 'GIS_T_DrawData__c',
        },
      }),
    );
    let json = {};
    if (res.length > 0) {
      const FileId = res[0]?.attachments[0]?.FileId;
      const url = await getUrl(FileId);
      json = await fetch(url).then((d) => d.text());
    }
    return { ...data, global: !data.CDS_T_Disaster__c, json };
  },
};
