import Vue from 'vue';
// 使用する関数を都度読み込むこと(容量の節約のため)
import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$dateFns = this;
    Vue.dateFns = this;
  },
  /**
   * date-fns#format
   */
  format,

  /**
   * dateFnsでDateオブジェクトなどを文字列にフォーマットする
   * @param {Date|Number} value 値
   * @param {String} nullValue エラーもしくはnullの時に返す値
   * @param {String} formatPattern フォーマットのパターン
   * @param {Object} option フォーマットのオプション
   *
   * @returns {String} フォーマットされた文字列
   */
  fnsFormat(value, nullValue = '', formatPattern, option = { locale: ja }) {
    if (!value) return nullValue;
    try {
      // perseISOしてトライ
      return format(parseISO(value), formatPattern, option);
    } catch (ignored) {
      //
    }
    try {
      // 日付にしてトライ
      return format(new Date(value), formatPattern, option);
    } catch (ignored) {
      //
    }
    try {
      // 文字列でそのままトライ
      return format(value, formatPattern, option);
    } catch (ignored) {
      //
    }
    // 無理なら空で返す
    return nullValue;
  },
};

Vue.use(plugin);
export default plugin;

// filter
Vue.filter('datetimesec', function (
  value,
  nullValue,
  formatPattern = 'P HH:mm:ss',
  option,
) {
  return plugin.fnsFormat(value, nullValue, formatPattern, option);
});

Vue.filter('datetime', function (
  value,
  nullValue,
  formatPattern = 'P HH:mm',
  option,
) {
  return plugin.fnsFormat(value, nullValue, formatPattern, option);
});

Vue.filter('date', function (value, nullValue, formatPattern = 'P', option) {
  return plugin.fnsFormat(value, nullValue, formatPattern, option);
});

Vue.filter('time', function (
  value,
  nullValue,
  formatPattern = 'HH:mm',
  option,
) {
  return plugin.fnsFormat(value, nullValue, formatPattern, option);
});
