import cookies from 'browser-cookies';
import connection from '@/plugins/connection';
import Cognito from '../assets/js/cognito';
// *仕様
// ルールに従いcookieの中身をチェックする。
// 中身を確認し対象のデータがサーバ上に存在するか確認し、
// 存在しない場合は指定ページへ自動遷移させる。
// cookieへのセットは各Storeで行う。
// TODO:store-cookie.jsは削除予定

// 災害情報
export const disaster = {
  // 読み出しイベント
  load: async () => {
    const disasterId = cookies.get(disaster.cookieKey);
    const val = await connection.invoke({
      controller: 'CDS_CTR_Common',
      method: 'getDisaster',
      params: { disasterId },
      mock: (args) => {
        if (!args.params.disasterId) return null;
        else
          return {
            Id: args.params.disasterId,
            Name: 'テスト災害',
            DisasterType__c: '実災害',
            DisasterStatus__c: '対応中',
            DisasterKind__c: '一般災害',
            HappenDate__c: new Date(),
          };
      },
    });
    if (!val) throw Error('災害情報が設定されていません。');
    return { disaster: { disaster: val } };
  },
  // 読み出し値がない場合の遷移先
  errorPath: 'CDS_VF_SelectDisaster',
  // cookieのKey
  cookieKey: 'CDS_disaster_id',
};

/**
 * ユーザレコードをDBから取得する
 * @param {*} uid
 */
async function getWebUserById(uid) {
  return (
    (await connection.invoke({
      controller: 'CDS_CTR_Common',
      method: 'getUser',
      params: { uid },
    })) || {}
  );
}

// ユーザ情報
export const user = {
  // 読み出しイベント
  load: async () => {
    let uid;
    let realUid;

    // cognitoによる認証チェック
    const cognitoOptions = {
      UserPoolId: window.appConfig?.cognito?.userPoolId,
      ClientId: window.appConfig?.cognito?.userPoolClientId,
    };
    const cognito = new Cognito(cognitoOptions);
    const cognito_user = await cognito.getLoggedInUser();
    // ログイン情報が取得できない場合にはログイン画面へリダイレクト
    if (!cognito_user) {
      console.error('未ログイン');
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
      return;
    }
    // cognitoのログイン情報をデフォルトとする
    realUid = uid = cognito_user.username;
    // 指定が無ければ、cookieのuidの値からそれぞれユーザを取得しuserに値をセット
    try {
      const { uid: cookieUid } = JSON.parse(cookies.get(user.cookieKey));
      uid = cookieUid;
    } catch (error) {
      console.warn('cookieからのユーザ情報読み込みに失敗しました。');
      console.warn(error);
    }

    // uid, realUidに対して、値があれば取得し結果にセット
    const resultUserStore = {};

    await Promise.all([
      // uidに基づいて取得
      (async () => {
        if (uid) {
          // ユーザ情報の取得
          const {
            CDS_M_Organization__r: userOrg,
            ...user
          } = await getWebUserById(uid);

          // 取得したwebユーザ情報を結果にセット
          resultUserStore.user = user;

          // 組織情報の取得
          if (userOrg && userOrg.Id) {
            const organization = await connection.invoke({
              controller: 'CDS_CTR_Common',
              method: 'getOrganization',
              params: { organizationId: userOrg.Id },
            });
            resultUserStore.organization = organization;
          }
        }
      })(),
      // realUidに基づいて取得
      (async () => {
        if (realUid) {
          const user = await getWebUserById(realUid);
          resultUserStore.realUser = user;
        }
      })(),
    ]);
    if (
      !resultUserStore.user ||
      !resultUserStore.user.Id ||
      !resultUserStore.realUser
    ) {
      // ログイン成功後、ユーザ情報を取得できなかった場合はログイン画面に戻す
      throw new Error('ユーザ情報が存在しません。');
    }

    // ユーザの存在を確認してからcookieに値をセット
    cookies.set(
      user.cookieKey,
      JSON.stringify({
        uid,
        realUid,
      }),
    );

    return { user: resultUserStore };
  },
  // 読み出し値がない場合の遷移先
  errorPath: 'login',
  // cookieのKey
  cookieKey: 'CDS_user_info',
};
export default {
  disaster,
  user,
};
