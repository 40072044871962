<template>
  <v-row class="pt-4">
    <v-col cols="12" md="6">
      <v-card elevation="0">
        <div>
          <Layout
            v-model="reportForm.value"
            v-bind="reportForm.attrs"
            @submit-raw="handleOnSubmit"
          />
        </div>
        <v-card-actions>
          <Confirm
            message="「報告日時」は、追加および削除が任意のタイミングで可能です。よろしいですか？"
            @on-click-yes="handleClickCreateButton"
          >
            <template #default="{ on }">
              <v-btn depressed color="primary" v-on="on">
                <v-icon left>
                  mdi-clock-start
                </v-icon>
                報告日時作成
              </v-btn>
            </template>
          </Confirm>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card elevation="0">
        <div>
          <v-data-table
            v-model="recordTable.selected"
            v-bind="recordTable.attrs"
            class="cdsTable"
            :mobile-breakpoint="0"
          >
            <template #item.ReportDate__c="props">
              <Display
                v-bind="{
                  value: props.item.IsSetDate__c ? props.value : '体制設置日時',
                  itemProps: props,
                  fieldInfo: {
                    type: props.item.IsSetDate__c ? 'date-time' : 'string',
                  },
                }"
              />
            </template>
          </v-data-table>
        </div>

        <v-card-actions class="pt-0">
          <Confirm
            message="選択された報告日時を削除します。よろしいですか？"
            @on-click-yes="handleClickDeleteButton"
          >
            <template #default="{ on }">
              <v-btn depressed dark color="red" v-on="on">
                <v-icon left>
                  mdi-delete-forever
                </v-icon>
                報告日時削除
              </v-btn>
            </template>
          </Confirm>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <ExtraForm />
    </v-col>
  </v-row>
</template>

<script>
import Layout from '@/components/detail/Layout.vue';
import Display from '@/components/list/Display.vue';
import Confirm from '@/components/common/Confirm';
import ExtraForm from './ExtraForm.vue';

import { reportFormLayout, reportFormObjectInfo } from './form';
import { mapActions } from 'vuex';
import { compareAsc } from 'date-fns';

export default {
  name: 'StaffReportCreateInnerContent',

  components: {
    Layout,
    Display,
    Confirm,
    ExtraForm,
  },

  data: () => ({
    objectInfo: null,
    reportForm: {
      attrs: {
        name: 'ReportCreateForm',
        layout: reportFormLayout,
        objectInfo: reportFormObjectInfo,
        editMode: true,
      },
      value: {},
    },
    recordTable: {
      attrs: {
        headers: [],
        items: [],
        'show-select': true,
        'item-key': 'Id',
        'disable-sort': true,
      },
      selected: [],
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([
          this.loadObjectInfo(),
          this.setInitialValue(),
          this.getCreatedRecords(),
        ]),
      );
    },

    /******** 初期ロード ********/

    async loadObjectInfo() {
      this.objectInfo = await this.$util.getObjectInfo(
        'CDS_T_StaffReportTime__c',
      );
      this.recordTable.attrs.headers = ['ReportDate__c'].map((fieldPath) => {
        return {
          value: fieldPath,
          text: this.objectInfo.properties[fieldPath]?.label,
        };
      });
    },

    /**
     * 初期値の設定
     */
    async setInitialValue() {
      const fromValue = this.getCurrentDatetime();
      // 24時間加算する
      const toValue = this.getCurrentDatetime();
      toValue.setHours(toValue.getHours() + 24);
      // 値を設定
      this.$formulate.setValues(this.reportForm.attrs.name, {
        from: fromValue.toJSON(),
        to: toValue.toJSON(),
      });
    },

    /**
     * 現在時刻を分とミリ秒を0にして返す
     */
    getCurrentDatetime() {
      const c = new Date();
      return new Date(
        `${c.getFullYear()}-${
          c.getMonth() + 1
        }-${c.getDate()} ${c.getHours()}:00:00`,
      );
    },

    /**
     * 報告日時の保存
     */
    async saveReportTimeRecords() {
      try {
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller: this.$pageProperty.controller,
            method: 'createReportTimeRecords',
            params: {
              disasterId: this.$store.state.disaster.disaster.Id,
              ...this.reportForm.value,
            },
          }),
        );
        this.loadCreatedRecords();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    async loadCreatedRecords() {
      await this.$store.dispatch('loading/register', this.getCreatedRecords());
    },

    /**
     * 作成済み報告日時レコードを取得する
     */
    async getCreatedRecords() {
      const result =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getCreatedReportTimeRecords',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};
      const { objects } = result;
      this.recordTable.attrs.items = objects || [];
    },

    /******** ハンドラ ********/

    // 作成ボタンを押した時
    handleClickCreateButton() {
      this.$formulate.submit(this.reportForm.attrs.name);
    },

    // formでsubmitがコールされた時
    async handleOnSubmit(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        const { value } = this.reportForm;
        // 開始日時より終了日時が早かったらNG
        if (compareAsc(new Date(value.from), new Date(value.to)) > 0) {
          throw new Error('開始日時より終了日時が遅くなるようにしてください。');
        }

        await this.saveReportTimeRecords();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 削除ボタンを押した時
    async handleClickDeleteButton() {
      if (this.recordTable.selected.length > 0) {
        try {
          await this.$store.dispatch(
            'loading/register',
            this.$con.invoke({
              controller: this.$pageProperty.controller,
              method: 'deleteReportTimeRecords',
              params: {
                ids: this.recordTable.selected.map((v) => v.Id),
              },
            }),
          );
        } catch (error) {
          this.saveFail(error.message);
        }
      }
      this.recordTable.selected = [];
      this.init();
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>
