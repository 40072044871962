/**
 * レイアウト
 */
export const reportSearchLayout = {
  fullName: 'CDS_T_Staff__c.ReportSearchLayout',
  label: '報告日時作成レイアウト',
  sobjecttype: 'CDS_T_Staff__c',
  name: 'ReportSearchLayout',
  sections: [
    {
      label: null,
      detailHeading: true,
      editHeading: true,
      column: '1',
      items: [
        {
          type: 'field',
          fieldPath: 'CDS_M_Organization__c',
          required: true,
          readonly: false,
        },
      ],
    },
  ],
};

/**
 * 人数項目
 */
export const staffNumFields = [
  {
    fieldPath: 'StaffName',
    label: '拠点名',
  },
  {
    fieldPath: 'CDS_M_Organization__r.Name',
    label: '組織名',
  },
  {
    fieldPath: 'ReportDate__c',
    label: '報告日時',
  },
  {
    fieldPath: 'IsExtra__c',
    label: '臨時',
    fieldInfo: {
      label: '臨時',
      type: 'boolean',
    },
  },
  {
    fieldPath: 'StaffNum__c',
    label: '参集人数',
    fieldInfo: {
      label: '参集人数',
      type: 'number',
    },
    readonly: false,
    required: true,
  },
  {
    fieldPath: 'PrevStaffNum',
    label: '前回報告値',
    fieldInfo: {
      label: '前回報告値',
      type: 'number',
    },
  },
].map((f) => {
  const { fieldPath, label, ...other } = f;

  return {
    fieldPath,
    key: fieldPath,
    column: false,
    readonly: true,
    fieldInfo: {
      label: label,
      type: 'string',
    },
    ...other,
  };
});
