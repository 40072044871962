<option>
{
  controller:'CDS_CTR_UserList',
  title:'ユーザ一覧'
}
</option>

<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <div>
      <!-- リストビュー -->
      <ListView
        v-bind="{
          objectName: 'User',
          detailLayoutName: 'SelfEditLayout',
          detailPageName: 'CDS_VF_UserDetail',
        }"
        :search-props="{
          searchConditionToUrlParameter: true,
          defaultSearchCondition,
        }"
        :table-props="{
          defaultListOptions,
          csvFileNameFormat: 'ユーザ一覧-%date%.csv',
          csvFieldSetName: 'CSVFieldSet',
          bulkFieldSetName: 'BulkFieldSet',
        }"
      >
      </ListView>
    </div>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import { disaster } from '@/beforeGuard/defaultRules';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },
  data: () => ({
    defaultListOptions: {
      sortBy: ['Sort__c'],
      sortDesc: [false],
    },
  }),

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },
  methods: {
    // デフォルト検索条件
    defaultSearchCondition() {
      return { IsValid__c: true };
    },
  },
};
</script>
