<template>
  <Confirm
    message="このユーザをすべての端末からサインアウトさせます。よろしいですか？"
    @on-click-yes="signOut"
  >
    <template #default="{ on }">
      <ActionButton
        btn-value="サインアウト"
        icon="mdi-logout-variant"
        v-on="on"
      />
    </template>
  </Confirm>
</template>

<script>
import { mapActions } from 'vuex';
import ActionButton from '@/components/detail/ActionButton.vue';
import Confirm from '@/components/common/Confirm';

export default {
  components: { ActionButton, Confirm },

  props: {
    username: {
      type: String,
      default: null,
    },
  },

  methods: {
    async signOut() {
      await this.$store.dispatch('loading/register', this.requestSignOut());
    },
    /**
     * サインアウトさせる
     */
    async requestSignOut() {
      try {
        const result = await this.$con.invoke({
          controller: 'CDS_CTR_UserDetail',
          method: 'signOutUser',
          params: {
            Username: this.username,
          },
        });
        if (result.error) throw new Error(result.error);
        this.openSnackBar({
          message: `サインアウトしました。`,
          props: {
            color: 'green',
            bottom: true,
            timeout: 3000,
          },
          closable: true,
        });
      } catch (error) {
        console.error(error);
        this.openSnackBar({
          message: `サインアウトに失敗しました。${this.$t(error.message)}`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        this.$refs.form.resetValidation();
      }
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
