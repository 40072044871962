import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { ja } from '@braid/vue-formulate-i18n';
import library from '@/components/inputs';
import contextLib from '@braid/vue-formulate/src/libs/context';
import { has, equals } from '@braid/vue-formulate/src/libs/utils';
import { isPlainObject } from 'lodash';

// https://github.com/wearebraid/vue-formulate/blob/master/src/libs/context.js を上書きする
function modelGetterOverridePlugin(instance) {
  const formulateInputComponent = instance.options.components.FormulateInput;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { context: originalContext, ...other } = contextLib;

  /**
   * Set the value from a model.
   **/
  function modelSetter(value) {
    let didUpdate = false;
    if (!equals(value, this.proxy, this.type === 'group')) {
      this.proxy = value;
      didUpdate = true;
    }
    if (
      !this.context.ignored &&
      this.context.name &&
      typeof this.formulateSetter === 'function'
    ) {
      this.formulateSetter(this.context.name, value);
    }
    if (didUpdate) {
      this.$emit('input', value);
    }
  }

  function defineModel(context) {
    return Object.defineProperty(context, 'model', {
      get: modelGetter.bind(this),
      set: (value) => {
        if (!this.mntd || !this.debounceDelay) {
          // eslint-disable-next-line no-setter-return
          return modelSetter.call(this, value);
        }
        this.dSet(modelSetter, [value], this.debounceDelay);
      },
      enumerable: true,
    });
  }

  function modelGetter() {
    const model = this.isVmodeled ? 'formulateValue' : 'proxy';
    if (
      this.type === 'checkbox' &&
      !Array.isArray(this[model]) &&
      this.options
    ) {
      return [];
    }
    // 上書き場所
    // modelがbooleanでfalseのときに空文字列が帰ってきてしまうので、その対策で追記
    // https://github.com/wearebraid/vue-formulate/blob/master/src/libs/context.js#L543
    if (this.type === 'boolean') {
      return this[model];
    }
    if (!this[model] && this[model] !== 0) {
      return '';
    }
    return this[model];
  }

  function blurHandler() {
    if (this.errorBehavior === 'blur' || this.errorBehavior === 'value') {
      this.behavioralErrorVisibility = true;
    }
    this.$nextTick(() => this.$emit('blur-context', this.context));
  }

  const forComputed = {
    context() {
      return defineModel.call(this, {
        addLabel: this.logicalAddLabel,
        removeLabel: this.logicalRemoveLabel,
        attributes: this.elementAttributes,
        blurHandler: blurHandler.bind(this),
        classification: this.classification,
        component: this.component,
        debounceDelay: this.debounceDelay,
        disableErrors: this.disableErrors,
        errors: this.explicitErrors,
        formShouldShowErrors: this.formShouldShowErrors,
        getValidationErrors: this.getValidationErrors.bind(this),
        groupErrors: this.mergedGroupErrors,
        hasGivenName: this.hasGivenName,
        hasValue: this.hasValue,
        hasLabel: this.label && this.classification !== 'button',
        hasValidationErrors: this.hasValidationErrors.bind(this),
        help: this.help,
        helpPosition: this.logicalHelpPosition,
        id: this.id || this.defaultId,
        ignored: has(this.$options.propsData, 'ignored'),
        isValid: this.isValid,
        imageBehavior: this.imageBehavior,
        label: this.label,
        labelPosition: this.logicalLabelPosition,
        limit: this.limit === Infinity ? this.limit : parseInt(this.limit, 10),
        name: this.nameOrFallback,
        minimum: parseInt(this.minimum, 10),
        performValidation: this.performValidation.bind(this),
        pseudoProps: this.pseudoProps,
        preventWindowDrops: this.preventWindowDrops,
        removePosition: this.mergedRemovePosition,
        repeatable: this.repeatable,
        rootEmit: this.$emit.bind(this),
        rules: this.ruleDetails,
        setErrors: this.setErrors.bind(this),
        showValidationErrors: this.showValidationErrors,
        slotComponents: this.slotComponents,
        slotProps: this.slotProps,
        type: this.type,
        uploadBehavior: this.uploadBehavior,
        uploadUrl: this.mergedUploadUrl,
        uploader: this.uploader || this.$formulate.getUploader(),
        validationErrors: this.validationErrors,
        value: this.value,
        visibleValidationErrors: this.visibleValidationErrors,
        isSubField: this.isSubField,
        classes: this.classes,
        ...this.typeContext,
      });
    },
    ...other,
  };

  formulateInputComponent.computed = {
    ...formulateInputComponent.computed,
    ...forComputed,
  };
}

// https://github.com/wearebraid/vue-formulate/blob/master/src/FormulateForm.vue#L182
// deepなobjectに対応する
function formOverridePlugin(instance) {
  const formulateFormComponent = instance.options.components.FormulateForm;

  formulateFormComponent.watch = {
    ...formulateFormComponent.watch,
    formulateValue: {
      ...formulateFormComponent.watch.formulateValue,
      handler(values) {
        if (this.isVmodeled && values && typeof values === 'object') {
          for (const key in values) {
            const value = values[key];
            // deepなオブジェクトを「.」繋ぎのキーに入れて読み取れるようにする
            if (isPlainObject(value)) {
              Object.entries(deepObjectToFlat(key, value)).forEach(([k, v]) => {
                values[k] = v;
              });
            }
          }
          this.setValues(values);
        }
      },
    },
  };
}

function deepObjectToFlat(parentKey, value) {
  if (isPlainObject(value)) {
    return Object.entries(value).reduce((prev, [nextKey, nextValue]) => {
      return {
        ...prev,
        ...deepObjectToFlat(`${parentKey}.${nextKey}`, nextValue),
      };
    }, {});
  } else {
    return { [parentKey]: value };
  }
}

Vue.use(VueFormulate, {
  plugins: [ja, modelGetterOverridePlugin, formOverridePlugin],
  locale: 'ja',
  library,
});
