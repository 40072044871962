<template>
  <v-row v-if="alerts.length !== 0" justify="center" class="mt-0 mb-2">
    <v-col cols="12" md="10" lg="9" class="py-0 mb-n4">
      <AlertBox
        v-for="alert in alerts"
        :key="alert.Id"
        :title="alert.Name"
        :content="alert.Notice__c"
        :link="alert.URL__c"
      />
    </v-col>
  </v-row>
</template>

<script>
import AlertBox from './AlertBox';

export default {
  components: {
    AlertBox,
  },
  data: () => ({
    alerts: [],
  }),
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.getData()]),
      );
    },
    async getData() {
      const res = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getAlert',
        params: {
          disasterId: this.$store.state.disaster.disaster.Id,
        },
      });
      if (Array.isArray(res)) {
        this.alerts = res;
      }
    },
  },
};
</script>
