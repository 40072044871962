<template>
  <v-dialog v-model="computedModal" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">臨時区域{{ isNew ? '作成' : '編集' }}</span>
      </v-card-title>
      <v-container class="py-0">
        <Layout
          v-model="editItem"
          :name="formName"
          :layout="layout"
          :object-info="objectInfo"
          :edit-mode="true"
          @submit-raw="validate"
        />
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          閉じる
        </v-btn>
        <v-btn
          color="blue darken-1"
          dark
          depressed
          @click="$formulate.submit(formName)"
        >
          {{ isNew ? '追加' : '反映' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Layout from '@/components/detail/Layout';
import { mapActions } from 'vuex';

export default {
  name: 'EvacuationEditorExtraModal',

  components: {
    Layout,
  },

  model: {
    prop: 'modal',
    event: 'modal-updated',
  },

  props: {
    // モーダル
    modal: { type: Boolean, default: false },
    // アイテム
    item: { type: Object, default: null },
    // 発令種別
    announceTypes: { type: Array, default: () => [] },
  },

  data: () => ({
    // フォーム名
    formName: 'evacuation_extra_form',
    // 編集対象
    editItem: {},

    // オブジェクト名
    objectName: 'CDS_T_EvacuationAdvisory__c',
    // レイアウト名
    layoutName: 'ExtraInputLayout',
    // レイアウト
    layout: {},
    // オブジェクト情報
    objectInfo: {},
  }),

  computed: {
    // モーダル
    computedModal: {
      get() {
        return this.modal;
      },
      set(value) {
        this.$emit('modal-updated', value);
      },
    },
    // 新規かどうか
    isNew() {
      return !_.get(this, 'editItem.Id', null);
    },

    // sfdcとareaのキーの読み替え
    keyPair() {
      return [
        // 区域名
        {
          areaKey: 'Name',
          sfdcKey: 'AreaName__c',
        },
        // 区域名かな
        {
          areaKey: 'Phonetic__c',
          sfdcKey: 'AreaNameKana__c',
        },
        // 発令or解除
        {
          areaKey: 'orderStatus',
          sfdcKey: 'OrderStatus__c',
        },
        // 発令種別
        {
          areaKey: 'announceTypeName',
          sfdcKey: 'AnnounceType__c',
          to_area: (v) =>
            (this.announceTypes.find(({ label }) => label === v) || {}).name,
          to_sfdc: (v) =>
            (this.announceTypes.find(({ name }) => name === v) || {}).label,
        },
        // 発令・解除日時
        {
          areaKey: 'announceDatetime',
          sfdcKey: 'AnnounceDateTime__c',
        },
        // 原因種別
        {
          areaKey: 'reasonType',
          sfdcKey: 'ReasonType__c',
        },
        // 世帯数
        {
          areaKey: 'Household__c',
          sfdcKey: 'Household__c',
        },
        // 人数
        {
          areaKey: 'People__c',
          sfdcKey: 'Population__c',
        },
      ];
    },
  },

  watch: {
    modal(to) {
      // モーダルを開こうとした時
      if (to) {
        this.$nextTick(() => {
          // 値が残るので一回クリアする
          this.$formulate.setValues(this.formName, {});
          // アイテムをコピーする
          this.$formulate.setValues(
            this.formName,
            this.formatAreaToSfdc(_.cloneDeep(this.item)),
          );
        });
      }
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.loadLayout()]);
    },

    /**
     * レイアウト読み出し
     */
    async loadLayout() {
      try {
        const { layout, objectInfo } = await this.$util.getLayout(
          this.objectName,
          this.layoutName,
        );
        this.$set(this, 'layout', {
          ...layout,
          sections: layout.sections.filter(({ label }) => label != '非表示'),
        });
        this.$set(this, 'objectInfo', objectInfo);
      } catch (error) {
        this.openSnackBar({
          message: 'レイアウトの読み込みに失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    // バリデート
    async validate(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }
        this.apply();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 反映
    apply() {
      const result = this.formatSfdcToArea(this.editItem);

      // イベント通知
      this.$emit('apply', result);
      this.close();
    },

    // 閉じる
    close() {
      this.computedModal = false;
    },

    /********** フォーマット **********/

    // Editorに合わせてformat
    // sfdcからarea
    formatSfdcToArea(sfdc) {
      return this.formatAreaAndSfdc(sfdc, true);
    },

    // areaからsfdc
    formatAreaToSfdc(area) {
      return this.formatAreaAndSfdc(area, false);
    },

    // format
    formatAreaAndSfdc(value, toArea = true) {
      // 変換の定義に合わせて変換する
      const origin = ['area', 'sfdc'];
      const [to, from] = toArea ? origin : origin.reverse();
      let res = {};
      if (!_.isEmpty(value)) {
        this.keyPair.map((def) => {
          const to_keyName = def[`${to}Key`];
          const to_function = def[`to_${to}`];
          const from_keyName = def[`${from}Key`];
          const from_value = value[from_keyName];
          res[to_keyName] =
            (to_function && to_function(from_value)) || from_value;
        });
        // 定義されたキー以外のものはそのままコピーする
        const extValues = _.omit(
          value,
          this.keyPair.map((def) => def[`${from}Key`]),
        );
        res = { ...extValues, ...res };
      }
      return res;
    },

    ...mapActions('snackbar', ['openSnackBar', 'saveFail']),
  },
};
</script>
