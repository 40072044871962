import Vue from 'vue';
import fetchRetry from 'fetch-retry';

// TODO エラーになったときのエラーオブジェクトパーサーを作成する

const fetch = fetchRetry(window.fetch, {
  retries: 3,
  retryDelay: 1000,
  // bad gatewayエラー(API GatewayでのCORS)もリトライ対象とする
  retryOn: [502],
});

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$con = this;
    Vue.con = this;
  },

  /**
   * 通信を行う
   * @param {Object} args argument
   * @param {String} args.controller コントローラー
   * @param {String} args.method メソッド名
   * @param {Object|Array} args.params パラメータ
   * @param {Object} args.sfdcOptions sfdxOptions
   * @param {Function} args.mock ローカル開発時に実行する関数
   *
   * @returns {Object} result
   */
  async invoke(args) {
    const { controller, method, params } = args;
    // ユーザを取得して保持
    if (!this.user) {
      this.user = await Vue.cognito.getLoggedInUser();
    }
    const { user } = this;

    const token = await Vue.$cache.get({
      key: 'cognito_token',
      async exec() {
        return await Vue.cognito.getAuthToken();
      },
      age: 5,
    });

    try {
      const endpoint = `${
        Vue.appConfig.api || ''
      }/api/controller/${controller}/${method}`;
      const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
          // 認証情報を入れる
          Authorization: token,
          // いつもの
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          // キャッシュさせない
          'Cache-Control': 'no-cache',
        },
        credentials: 'include',
        body: JSON.stringify({
          user,
          params,
          ext: {
            currentUrl: location.href,
          },
        }),
      }).then((r) => r.json());
      if (result?.message === 'Unauthorized') {
        console.error(result);
        throw new Error('Unauthorized');
      }
      if (result?.errors) {
        throw new Error(result.errors?.message);
      }
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

Vue.use(plugin);
export default plugin;
