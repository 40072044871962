<template>
  <div class="d-flex justify-center">
    <v-btn dark color="primary" class="mr-2" @click="toggleWeatherMap">
      <slot name="btn" />
    </v-btn>
    <div v-if="showWeatherMap" class="d-flex justify-center">
      <v-card
        style="
          width: 700px;
          top: 50px;
          margin-left: -100px;
          position: absolute;
        "
      >
        <v-card-text class="text--primary">
          <div class="mt-2">
            <v-slider
              v-if="targetTimes.length > 0"
              v-model.lazy="timeSlider"
              :max="targetTimes.length - 1"
              min="0"
              :label="targetTimes[timeSlider].label + ' 時点'"
              dense
              ticks
              :tick-labels="tickLabels"
              @change="changeTime()"
            >
            </v-slider>
          </div>
        </v-card-text>
      </v-card>
      <slot name="legend" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    targetTimes: { type: Array, default: () => [] },
    tileProps: { type: Function, default: null },
    value: { type: Number, default: 0 },
  },
  data: () => ({
    showWeatherMap: false,
    timeTicks: [],
    layerMapType: {},
  }),
  computed: {
    tickLabels() {
      return this.targetTimes.map((v, i) => (i % 12 === 0 ? v.label : ''));
    },
    timeSlider: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    toggleWeatherMap() {
      if (this.showWeatherMap) {
        this.clearWeatherMap();
      } else {
        this.changeTime();
      }
      this.showWeatherMap = !this.showWeatherMap;
    },
    changeTime() {
      const targetTime = this.targetTimes[this.timeSlider];
      this.setWeatherMap(targetTime);
    },
    setWeatherMap(targetTime) {
      this.clearWeatherMap();
      const { map } = this.$store.state.GIS;
      // 気象庁にタイル画像が用意されていないと表示されないのでズームレベルを制限する
      map.setZoom(10);
      this.layerMapType = new google.maps.ImageMapType(
        this.tileProps(targetTime),
      );

      map.overlayMapTypes.insertAt(0, this.layerMapType);
    },
    clearWeatherMap() {
      if (this.layerMapType) {
        const { map } = this.$store.state.GIS;
        let delId;
        map.overlayMapTypes.forEach((element, idx) => {
          if (element && element === this.layerMapType) {
            delId = idx;
          }
        });
        if (delId !== undefined) map.overlayMapTypes.setAt(delId, null);
        this.layerMapType = null;
      }
    },
  },
};
</script>
