/**
 * レイアウト
 */
export const reportFormLayout = {
  fullName: 'ReportCreateForm.ReportDateLayout',
  label: '報告日時作成レイアウト',
  sobjecttype: 'ReportCreateForm',
  name: 'ReportDateLayout',
  sections: [
    {
      label: null,
      detailHeading: true,
      editHeading: true,
      column: '1',
      items: [
        {
          type: 'field',
          fieldPath: 'from',
          required: true,
          readonly: false,
        },
        {
          type: 'field',
          fieldPath: 'to',
          required: true,
          readonly: false,
        },
        {
          type: 'field',
          fieldPath: 'interval',
          required: true,
          readonly: false,
        },
      ],
    },
  ],
};

/**
 * オブジェクト情報
 */
export const reportFormObjectInfo = {
  label: '報告日時作成オブジェクト',
  properties: {
    from: {
      label: '開始日時',
      type: 'date-time',
      format: 'date-time',
      inputProps: {
        hideNow: true,
        canSelectMinute: false,
      },
    },
    to: {
      label: '終了日時',
      type: 'date-time',
      format: 'date-time',
      inputProps: {
        hideNow: true,
        canSelectMinute: false,
      },
    },
    interval: {
      label: '報告間隔',
      type: 'picklist',
      format: 'picklist',
      picklistValues: [...Array(24)].map((v, i) => ({
        label: `${i + 1}`,
        value: i + 1,
        defaultValue: i === 0,
      })),
      enum: [...Array(24)].map((v, i) => i + 1),
      inputProps: {
        suffix: '時間',
      },
    },
  },
};
