export const RequestStatus__c = {
  要請前: {
    labelClass: 'blue-grey lighten-4',
    textClass: 'blue-grey--text text--lighten-4',
    chartColor: '#CFD8DC',
  },
  未対応: {
    labelClass: 'red accent-2 white--text',
    textClass: 'red--text text--accent-2',
    chartColor: '#FF5252',
  },
  調整中: {
    labelClass: 'orange white--text',
    textClass: 'orange--text',
    chartColor: '#FF9800',
  },
  回答済: {
    labelClass: 'light-blue white--text',
    textClass: 'light-blue--text',
    chartColor: '#03A9F4',
  },
  対応不可: {
    labelClass: 'deep-purple darken-3 white--text',
    textClass: 'deep-purple--text text--darken-3',
    chartColor: '#4527A0',
  },
};
