<template>
  <detail
    v-if="isVisible"
    use-version
    object-name="CDS_T_No4_2__c"
    layout-name="No4_2Layout"
    default-title="新規被害総括作成"
    :saved-page="detailPageName"
    :detail-page-name="detailPageName"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    use-correction
    :fix-message="
      isFujisanConnected
        ? '確定します。この操作でFUJISANに連携されます。よろしいですか？'
        : undefined
    "
    :fix-text="isFujisanConnected ? '確定/FUJISAN連携' : undefined"
  >
    <template #underHeader="{editMode,isFixed,value}">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="editMode ? 1 : isFixed ? 3 : 2"
      >
        <v-stepper-header>
          <v-stepper-step step="1">
            入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">
            確定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            完了
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
    <template #layout="slotProps">
      <DetailLayout :slot-props="slotProps" />
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import DetailLayout from './DetailLayout';

export default {
  components: {
    detail,
    DetailLayout,
  },

  data: () => ({
    // 詳細ページ
    detailPageName: 'CDS_VF_No4_2Detail',
  }),

  computed: {
    // 表示するか
    isVisible() {
      // id指定があるか、モードがnか
      return !!this.$query.current().id || this.$query.current().mode === 'n';
    },
    // 初期値の設定
    initValue() {
      return {
        ...this.$util.getDefaultInitValue(this.$store.state),
        Name: '浜松市被害総括',
      };
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
    isFujisanConnected() {
      return this.$store.getters['disaster/isFujisanConnected'];
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      if (!this.isVisible) {
        // パラメータにIdの指定がなければ、0報を取得する
        const { recordId } = await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller: this.$pageProperty.controller,
            method: 'getBaseRecordId',
            params: {
              disasterId: this.$store.state.disaster.disaster.Id,
            },
          }),
        );
        // Idが取得できればリダイレクト
        if (recordId) {
          this.$transition.to(
            this.detailPageName,
            {
              id: recordId,
            },
            {
              keepRetUrl: true,
            },
          );
        }
        // 取得したIdもなければ、新規作成にする
        else {
          this.$transition.to(
            this.detailPageName,
            {
              mode: 'n',
            },
            {
              keepRetUrl: true,
            },
          );
        }
      }
    },
  },
};
</script>
