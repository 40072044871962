import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/lib/services/goto';
import { routes } from './routes';
import { setupHandler } from './util';

/**
 * 同じページへの遷移のハンドラ
 * @param {*} e
 */
function navigationErrorHandler(e) {
  const { name } = e;
  if (!['NavigationDuplicated'].includes(name)) {
    console.warn({ error: e });
    // throw e;
  }
  return e;
}

/**
 * 同じページへの遷移でエラーになるので回避する
 */
VueRouter.prototype._push = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve || reject)
    return VueRouter.prototype._push.call(this, location, resolve, reject);
  return VueRouter.prototype._push
    .call(this, location)
    .catch(navigationErrorHandler);
};
/**
 * 同じページへの遷移でエラーになるので回避する
 */
VueRouter.prototype._replace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve || reject)
    return VueRouter.prototype._replace.call(this, location, resolve, reject);
  return VueRouter.prototype._replace
    .call(this, location)
    .catch(navigationErrorHandler);
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  // ページ遷移時のスクロール
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
  routes,
});

// ナビゲーションガード等の設定
setupHandler({ router, routes });

export default router;
