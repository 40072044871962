var render = function render(){var _vm=this,_c=_vm._self._c;return _c('detail',{attrs:{"use-version":"","object-name":"CDS_T_WelfareShelter__c","layout-name":"WelfareShelterLayout","default-title":"福祉避難所詳細","subtitle":"福祉避難所詳細","saved-page":"CDS_VF_WelfareShelterDetail","detail-page-name":"CDS_VF_WelfareShelterDetail","detail-layout-name":"CDS_T_WelfareShelter__c-検索レイアウト","list-page":"CDS_VF_WelfareShelterList?le=true","list-view-props":{
    canSearch: false,
  },"every-overwrite-value":_vm.everyOverwriteValue,"init-value":_vm.initValue,"load-master":_vm.loadMaster,"override":_vm.override,"use-correction":"","fix-message":_vm.isFujisanConnected
      ? '確定します。この操作でFUJISANに連携されます。よろしいですか？'
      : undefined,"fix-text":_vm.isFujisanConnected ? '確定/FUJISAN連携' : undefined},scopedSlots:_vm._u([{key:"DetailMenus",fn:function({editMode, value, objectName, savedPage, listPage}){return [(
        !editMode &&
        value.CDS_M_Organization__c === _vm.$store.state.user.organization.Id
      )?[_c('Transfer',{attrs:{"object-name":objectName,"value":value,"next-page":listPage || savedPage}})]:_vm._e()]}},{key:"underHeader",fn:function({editMode,isFixed,value}){return [(value.Id == null || value.Version__c == 0)?_c('v-stepper',{staticClass:"elevation-0 mb-n3",attrs:{"alt-labels":_vm.$vuetify.breakpoint.mobile,"value":editMode ? 1 : isFixed ? 3 : 2}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1"}},[_vm._v(" 入力 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" 確定 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" 完了 ")])],1)],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }