<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <div>
      <v-card elevation="0">
        <div>
          <Layout
            v-model="searchForm.value"
            v-bind="searchForm.attrs"
            @submit-raw="handleOnSubmitSearch"
          />
        </div>
        <v-card-actions>
          <v-btn depressed color="primary" @click="handleClickSearch">
            <v-icon left>
              mdi-download
            </v-icon>
            ダウンロード
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <Document :content="documentContent" />
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import Document from './Document.vue';
import Layout from '@/components/detail/Layout.vue';

import { searchFormLayout, searchFormObjectInfo } from './form';
import { mapActions } from 'vuex';

/**
 * 現在日時の分を0にして返す
 */
function getCurrentDatetimeMinutesZero() {
  const c = new Date();
  return new Date(
    `${c.getFullYear()}-${
      c.getMonth() + 1
    }-${c.getDate()} ${c.getHours()}:00:00`,
  );
}
const current = getCurrentDatetimeMinutesZero();

export default {
  components: {
    defaultLayout,
    PageHeader,
    Document,
    Layout,
  },

  data: () => ({
    searchForm: {
      attrs: {
        name: 'ReportDownloadForm',
        layout: searchFormLayout,
        objectInfo: searchFormObjectInfo,
        editMode: true,
      },
      value: {
        baseDatetime: current.toJSON(),
      },
    },

    documentContent: null,
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.$store.dispatch(
        'loading/register',
        (async () => {
          await Promise.all([this.initialSetUp()]);
        })(),
      );
    },

    async initialSetUp() {
      // 災害種別に応じて初期値を設定する
      const { DisasterKind__c } = this.$store.state.disaster.disaster;

      const outputType = DisasterKind__c.includes('一般災害')
        ? '風水害その他'
        : '地震';
      this.$formulate.setValues(this.searchForm.attrs.name, {
        ...this.searchForm.value,
        outputType,
      });
    },

    async startDownload() {
      this.$store.dispatch('loading/register', this.loadData());
    },

    async loadData() {
      try {
        const {
          CDS_M_Organization__c,
          baseDatetime,
          outputType,
        } = this.searchForm.value;

        // データを取得
        const result = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getReportData',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            orgIds: CDS_M_Organization__c || [],
            baseDatetime,
            outputType,
          },
        });

        if (!result && !result.data) return;

        const { data } = result;

        this.documentContent = {
          data: {
            reportDate: this.$options.filters?.datetime(
              baseDatetime,
              '',
              'yyyy年 M月 d日 HH時 mm分',
            ),
            disaster: {
              isKunren:
                this.$store.state.disaster.disaster.DisasterType__c === '訓練',
              name: this.$store.state.disaster.disaster.Name,
            },
            ...data,
          },
        };

        console.log('final document data', this.documentContent);
      } catch (error) {
        this.openErrorSnackBar({ message: error.message });
      }
    },

    /******** ハンドラ ********/

    // 検索ボタンを押下した時
    handleClickSearch() {
      this.$formulate.submit(this.searchForm.attrs.name);
    },

    // 検索
    async handleOnSubmitSearch(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        await this.startDownload();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>
