<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'SBS_T_ConnectResult__c',
        detailPageName: 'CDS_VF_SBSConnectResultDetail',
        isDisasterRelated: false,
      }"
      :search-props="{
        syncConditionWithUrl: true,
      }"
      :table-props="{
        defaultListOptions,
        canCreate: false,
        canEdit: false,
        canFix: false,
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import { disaster } from '@/beforeGuard/defaultRules';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['ReceiveDatetime__c'],
      sortDesc: [true],
    },
  }),

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },
};
</script>
