import { render, staticRenderFns } from "./Measurement.vue?vue&type=template&id=24b88838&scoped=true"
import script from "./Measurement.vue?vue&type=script&lang=js"
export * from "./Measurement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b88838",
  null
  
)

export default component.exports