import Vue from 'vue';

// ファイルサイズを表示するフィルタ
Vue.filter('filesize', function (value = 0) {
  if (value > 1000000) return `${Math.round(value / 1000000)} MB`;
  if (value > 1000) return `${Math.round(value / 1000)} KB`;

  return `${value} B`;
});

// 数字をカンマ区切りのテキストにするフィルタ
const numberFormat = new Intl.NumberFormat('ja');
Vue.filter('numberFormat', (value, nullvalue = null) => {
  return _.isNil(value)
    ? nullvalue
    : isNaN(Number(value))
    ? value
    : numberFormat.format(Number(value));
});
