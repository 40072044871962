<template>
  <v-dialog v-model="dialog" scrollable max-width="400">
    <template #activator="{ on }">
      <v-btn outlined v-on="on">
        履歴の取得
      </v-btn>
    </template>
    <v-card class="pa-2">
      <v-card-title class="mb-2">
        <v-icon>mdi-table-account</v-icon>
        履歴の取得
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation @submit="validate">
        <v-card-text class="py-0 px-2">
          <v-text-field
            v-model="value.hours"
            label="時間"
            :rules="[rules.nonzero]"
            required
            outlined
            dense
            suffix="時間前まで"
            type="number"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="error"
            class="mr-4"
            depressed
            @click="validate"
          >
            <v-icon left>
              mdi-table-account
            </v-icon>
            取得
          </v-btn>
          <v-btn
            :disabled="!valid"
            class="mr-4"
            depressed
            @click="() => (dialog = false)"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginHistoryRetrieve',

  data: () => ({
    dialog: false,
    valid: true,
    value: {
      hours: null,
    },
    rules: {
      nonzero: (v) => v > 0 || '値は0より大きくしてください。',
    },
  }),

  methods: {
    /**
     * フォームのバリデート
     */
    async validate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      await this.$store.dispatch('loading/register', this.retrieve());
    },

    /**
     * 取得
     */
    async retrieve() {
      try {
        const result = await this.$con.invoke({
          controller: 'CDS_CTR_LoginHistory',
          method: 'retrieveData',
          params: {
            hours: Number(this.value.hours || 2),
          },
        });
        if (result.error) throw new Error(result.error);
        this.dialog = false;
        this.openSnackBar({
          message: `取得しました。一覧表をリロードしてください。`,
          props: {
            color: 'green',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
      } catch (error) {
        console.error(error);
        console.log(this.$t(error.message));
        this.openSnackBar({
          message: `取得に失敗しました。${this.$t(error.message)}`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        this.$refs.form.resetValidation();
      }
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
