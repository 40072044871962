<template>
  <JMATileCommon
    v-if="targetTimes.length > 0"
    v-model="timeSlider"
    :target-times="targetTimes"
    :tile-props="tilePropsFunction"
  >
    <template #btn>
      土砂災害
    </template>
    <template #legend>
      <v-card style="position: absolute; right: 20px; top: 50px;">
        <v-card-text class="text--primary">
          <img
            src="https://www.jma.go.jp/bosai/risk/images/legend_jp_normal_land.svg"
          />
        </v-card-text>
      </v-card>
    </template>
  </JMATileCommon>
</template>
<script>
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import JMATileCommon from './JMATileCommon';
export default {
  components: {
    JMATileCommon,
  },
  data: () => ({
    timeSlider: 0,
    targetTimes: [],
  }),
  async mounted() {
    this.targetTimes = (
      await fetch(
        'https://www.jma.go.jp/bosai/jmatile/data/risk/targetTimes.json',
      ).then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
    )
      .reverse()
      .map((v) => {
        const { validtime } = v;
        const utcDate = new Date(
          Date.UTC(
            validtime.substr(0, 4),
            validtime.substr(4, 2),
            validtime.substr(6, 2),
            validtime.substr(8, 2),
            validtime.substr(10, 2),
            validtime.substr(12, 2),
          ),
        );
        const jstDate = utcToZonedTime(utcDate, 'Asia/Tokyo');
        const jstHour = format(jstDate, 'H:mm');
        return { ...v, label: jstHour };
      });

    // スライダーの初期状態は最新日時
    this.timeSlider = this.targetTimes.length - 1;
  },
  methods: {
    tilePropsFunction(targetTime) {
      return {
        getTileUrl: (coord, zoom) => {
          let x = (coord.x % Math.pow(2, zoom)).toString();
          let y = coord.y.toString();
          return 'https://www.jma.go.jp/bosai/jmatile/data/risk/{basetime}/none/{validtime}/surf/land/{z}/{x}/{y}.png'
            ?.replace('{z}/{x}/{y}', zoom + '/' + x + '/' + y || null)
            .replace('{basetime}', targetTime.basetime)
            .replace('{validtime}', targetTime.validtime);
        },
        tileSize: new google.maps.Size(256, 256),
        minZoom: 4,
        maxZoom: 10,
        // opacity: this.opacitySlider[item.Id] / 100,
      };
    },
  },
};
</script>
