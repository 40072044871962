<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_FirstAid__c',
        detailPageName: 'CDS_VF_FirstAidDetail',
        isDisasterRelated: true,
        showImageTab: true,
        showMapTab: true,
        hasVersion: true,
        masterObjectName: 'CDS_M_FirstAid__c',
      }"
      :search-props="{
        syncConditionWithUrl: true,
        showBaseDatetime: !le,
        defaultSearchCondition,
      }"
      :table-props="{
        defaultListOptions,
        createButtonName: '臨時応急救護所',
        masterFieldName: 'CDS_M_FirstAid__c',
        listSaveValidate: validate,
        viewAttachment: true,
        viewComment: !le,
        inputFieldSetName: 'ListInputFieldSet,ListFieldSet',
        listFieldSetName: 'FixListFieldSet,ListFieldSet',
        defaultInputFieldSetName: 'ListInputFieldSet,DefaultListFieldSet',
        defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
        csvFieldSetName: 'ListInputFieldSet,FixListFieldSet,ListFieldSet',
        applyMaxFields: !le,
      }"
      :google-map-props="{
        gisDynamicSettingName: 'GIS_C_D_FirstAid',
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import { validateFirstAid } from '@/validation';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['No__c'],
      sortDesc: [false],
    },
  }),

  computed: {
    le() {
      return this.$query.current().le === 'true';
    },
  },

  methods: {
    // デフォルト検索条件
    defaultSearchCondition() {
      let result = {};

      if (this.le) {
        const Class1__c = this.$store.state.user.organization?.Class1__c;
        // 本部以外はデフォルト表示を所属区名にする
        if (Class1__c && Class1__c !== '本部') {
          result.Ward__c = [Class1__c];
        }
      }

      result.ErrataType__c = [null, '訂正'];

      return result;
    },

    validate(listData) {
      if (!listData || listData.length === 0) {
        return true;
      }
      // 開設状況 必須
      const openStatusValidate = listData.every((d) => d.OpenStatus__c);
      if (!openStatusValidate) return '開設状況は必須項目です';

      // 共通のバリデート
      const result = new Set(
        _.flatten(
          listData
            .map(validateFirstAid)
            .filter((v) => !!v)
            .map((v) => v.result),
        ),
      );
      if (result.size > 0) return [...result].join('\n');

      return true;
    },
  },
};
</script>
