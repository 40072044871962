import {
  mdiHomeAlert,
  mdiEllipse,
  mdiHomeFlood,
  mdiDomain,
  mdiWaves,
  mdiBridge,
  mdiRoad,
  mdiAlert,
  mdiFire,
  mdiElevationRise,
  mdiTrain,
  mdiHomeAccount,
  mdiWarehouse,
  mdiGarageVariant,
  mdiExitRun,
  mdiHandHeart,
  mdiOfficeBuilding,
} from '@mdi/js';

export default {
  mdiHomeAlert,
  mdiEllipse,
  mdiHomeFlood,
  mdiDomain,
  mdiWaves,
  mdiBridge,
  mdiRoad,
  mdiAlert,
  mdiFire,
  mdiElevationRise,
  mdiTrain,
  mdiHomeAccount,
  mdiWarehouse,
  mdiGarageVariant,
  mdiExitRun,
  mdiHandHeart,
  mdiOfficeBuilding,
};
