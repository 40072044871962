import Vue from 'vue';
import qs from 'qs';

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$query = this;
    Vue.query = this;
  },
  // qs,

  /**
   * 現在のURLパラメータをjsonオブジェクトにして返す
   *
   * @example
   * // http://~~~.jp/pageName?a=1&b=2 にアクセスした場合
   * this.$query.current() // 返り値: {a: "1", b: "2"}
   * @returns {Object} クエリパラメータのオブジェクト
   */
  current() {
    const search = window.location.search.replace(/^\?/g, '');
    if (!search) return {};
    return qs.parse(search, { depth: 10 });
  },

  /**
   * URLパラメータを変更する
   * @param {Object} option オプション
   * @param {Object} option.query クエリオブジェクト
   */
  updateHistoryQuery(option = {}) {
    const { query, stateMethod = 'replaceState' } = option;
    history[stateMethod](
      undefined,
      undefined,
      query && !_.isEmpty(query) && qs.stringify(query)
        ? '?' + qs.stringify(query)
        : location.pathname,
    );
  },
};

Vue.use(plugin);
export default plugin;
