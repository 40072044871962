<template>
  <detail
    v-if="hasId"
    use-version
    object-name="CDS_T_EvacuationAdvisoryManager__c"
    layout-name="EvacuationAdvisoryManagerLayout"
    default-title="避難情報発表"
    :saved-page="detailPageName"
    :detail-page-name="detailPageName"
    :list-view-props="{
      canSearch: false,
    }"
    :disable-actions="computedDetailButtonActions"
    :fix-message="
      isFujisanConnected
        ? '確定します。この操作でFUJISANに連携されます。よろしいですか？'
        : undefined
    "
    :fix-text="isFujisanConnected ? '確定/FUJISAN連携' : undefined"
  >
    <template #underHeader="{isFixed,value}">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="isFixed ? 5 : 4"
      >
        <v-stepper-header>
          <v-stepper-step :complete="true" step="1">
            発令・解除区域入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="true" step="2">
            発令・解除区域確認
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="true" step="3">
            発令情報入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="isFixed" step="4">
            確定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="isFixed" step="5">
            完了
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
    <template #layout="slotProps">
      <DetailLayout :slot-props="slotProps" />
    </template>
  </detail>
  <default-layout v-else>
    <template #pageHeader>
      <page-header />
    </template>
    <div class="text-center my-5">
      <span class="text-h6">避難情報はありません。</span>
    </div>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import detail from '@/components/detail/';
import DetailLayout from './DetailLayout';
import cookies from 'browser-cookies';
import defaultRules from '@/beforeGuard/defaultRules';
import connection from '@/plugins/connection';
import transition from '@/plugins/transition';

// 詳細ページのeditModeのセッターを保持
const _editModeSet = detail.computed.editMode.set;

// 詳細ページ
const detailPageName = 'CDS_VF_EvacuationAdvisoryDetail';
// 編集ページ
const editPageName = 'CDS_VF_EvacuationAdvisoryEdit';

export default {
  components: {
    defaultLayout,
    PageHeader,
    detail: {
      ...detail,
      computed: {
        ...detail.computed,
        editMode: {
          ...detail.computed.editMode,
          set: function (val) {
            // 編集モードに変わった時
            if (!this.editMode_ && val) {
              // ページ遷移する
              this.$transition.to(
                'CDS_VF_EvacuationAdvisoryEdit',
                {
                  id: this.$query.current().id,
                  e: true,
                },
                {
                  keepRetUrl: true,
                },
              );
            }
            // 通常の動作
            _editModeSet.bind(this)(val);
          },
        },
      },
    },
    DetailLayout,
  },

  // このページにルーティングされる直前に流れるbefore guard
  beforeRouteEnter(to, from, next) {
    const run = async () => {
      // 現在のクエリ
      const currentQuery = to.query;

      // idがあるか
      const hasId = !!currentQuery.id;
      // 閲覧モードか
      const isReadonly = currentQuery.ro === 'true';

      // 災害のIdがなければ終わり
      const disasterId = cookies.get(defaultRules.disaster.cookieKey);
      if (!disasterId) return {};

      // idがない時
      if (!hasId) {
        // パラメータにIdの指定がなければ、0報または最新報を取得する
        const { recordId } = await connection.invoke({
          controller: 'CDS_CTR_EvacuationAdvisoryEdit',
          method: 'getBaseRecordId',
          params: {
            disasterId,
            isFixed: isReadonly,
          },
        });

        let resultQuery = null;

        // 編集モード
        if (!isReadonly) {
          // Idが取得できればurlにidを付与
          if (recordId) {
            resultQuery = {
              id: recordId,
            };
          }
          // 取得したIdもなければ、新規作成にする
          else {
            return transition.to(
              'CDS_VF_EvacuationAdvisoryEdit',
              {
                mode: 'n',
              },
              {
                keepRetUrl: true,
              },
            );
          }
        }
        // 閲覧モード
        else {
          if (recordId) {
            resultQuery = {
              id: recordId,
              ro: true,
            };
          }
        }

        if (resultQuery) {
          next({
            ...to,
            query: {
              ...to.query,
              ...resultQuery,
              _u: _.uniqueId(),
            },
          });
          return;
        }
      }

      next();
    };
    run();
  },

  data: () => ({
    // 詳細ページ
    detailPageName,
    // 編集ページ
    editPageName,
  }),

  computed: {
    // 表示するか
    hasId() {
      // id指定があるか
      return !!this.$query.current().id;
    },
    // 閲覧モードか
    isReadonly() {
      return this.$query.current().ro === 'true';
    },
    // ボタンの表示制御
    computedDetailButtonActions() {
      if (this.isReadonly) {
        return [
          'save',
          'add-attachment',
          'cancel',
          'edit',
          'fix',
          'remove',
          'next-version',
        ];
      } else {
        return [];
      }
    },
    isFujisanConnected() {
      return this.$store.getters['disaster/isFujisanConnected'];
    },
  },
};
</script>
