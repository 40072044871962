export default (_Rectangle) => {
  class Rectangle extends _Rectangle {
    /**
     * 矩形データの頂点データを配列で出力する
     * @return {[type]} [description]
     */
    getCoordinates() {
      const coordinates = [];
      const bounds = this.getBounds();
      coordinates.push([bounds.getNorthEast().lng(), bounds.getNorthEast().lat()]); //1
      coordinates.push([bounds.getSouthWest().lng(), bounds.getNorthEast().lat()]); //4
      coordinates.push([bounds.getSouthWest().lng(), bounds.getSouthWest().lat()]); //3
      coordinates.push([bounds.getNorthEast().lng(), bounds.getSouthWest().lat()]); //2
      coordinates.push(coordinates[0]);
      return coordinates;
    }


    /**
     * 矩形データをJSON形式で出力する(符号化は行わない)
     * @return {[type]} [description]
     */
    getJSON() {
      return {
        type: 'Feature',
        properties: {
          fillColor: this.fillColor,
          strokeColor: this.strokeColor,
        },
        geometry: {
          type: 'Rectangle',
          coordinates: this.getBounds()
        }
      }
    }
    /**
     * 矩形データをGeoJSON形式で出力する
     * @return {[type]} [description]
     */
    getGeoJSON() {
      const feature = {
        type: 'Feature',
        properties: {
          googleFeatureType: 'rectangle'
        },
        geometry: {
          type: 'Polygon',
          coordinates: [this.getCoordinates()]
        }
      };
      if (this.fillColor) feature.properties.fillColor = this.fillColor;
      return feature;
    }
    /**
     * 矩形中心点出力
     * @return {[type]} [description]
     */
    getCenter() {
      return this.getBounds().getCenter();
    }
    loadJSON(polygon) {
      // const option = {
      //   bounds: polygon.geometry.coordinates,
      //   map,
      //   ...polygon.properties
      // };
      // return new window.google.maps.Rectangle(option);
      const { properties, geometry } = polygon;
      this.setBounds(geometry.coordinates);
      this.setOptions(properties);

    }

  }
  return Rectangle;
}