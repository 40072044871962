<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <TotalView
      v-bind="{
        objectName: 'CDS_T_HeliportTotal__c',
        targetObjectName: 'CDS_T_Heliport__c',
        detailPageName: 'CDS_VF_HeliportDetail',
        isDisasterRelated: true,
        detailLayoutName: 'ListEditLayout',
        handleClickRowFunc: handleClickRowFunc,
      }"
      :search-props="{
        layoutName: 'TotalSearchLayout',
      }"
      :table-props="{
        defaultListOptions,
      }"
    >
    </TotalView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import TotalView from '@/components/list/TotalView';

export default {
  components: {
    defaultLayout,
    PageHeader,
    TotalView,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['BaseDatetime__c'],
      sortDesc: [true],
    },
  }),

  methods: {
    handleClickRowFunc(item) {
      // 集計対象
      const { TargetOrganization__c } = item;
      let targetOrgName = TargetOrganization__c?.trim();
      if (targetOrgName === '指定なし') {
        targetOrgName = null;
      }
      this.$transition.to('CDS_VF_HeliportList', {
        bdt: item.BaseDatetime__c,
        search: JSON.stringify({
          Ward__c: targetOrgName
            ? targetOrgName.split(',').filter((v) => !!v)
            : undefined,
        }),
      });
    },
  },
};
</script>
