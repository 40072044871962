<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab
        title="事案情報"
        icon="mdi-chat-alert"
        :active="tab === 0"
        :on="{
          click: () => {
            tab = 0;
          },
        }"
      />
      <BarCardTab
        title="被害状況"
        icon="mdi-file-table-box"
        :active="tab === 1"
        :on="{
          click: () => {
            tab = 1;
          },
        }"
      />
    </template>
    <div>
      <DamageInfo
        v-if="tab === 0"
        :update-event="updateEvent[0]"
        @loading="handleUpdateLoading(0, $event)"
      ></DamageInfo>
      <DamageState
        v-if="tab === 1"
        :update-event="updateEvent[1]"
        @loading="handleUpdateLoading(1, $event)"
      ></DamageState>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';

import DamageInfo from './DamageInfo.vue';
import DamageState from './DamageState.vue';

export default {
  components: {
    BarCard,
    BarCardTab,
    DamageInfo,
    DamageState,
  },
  data: () => ({
    loadingAll: [false, false],
    tab: 0,
    updateEvent: [{}, {}],
  }),
  computed: {
    loading() {
      return this.loadingAll.some((v) => v);
    },
  },
  methods: {
    handleClickBar() {
      this.$transition.to('CDS_VF_DamageReportList', null, {
        notApplyRetUrl: true,
      });
    },
    handleUpdateLoading(index, to) {
      this.loadingAll.splice(index, 1, to);
    },
    init() {
      this.updateEvent.splice(this.tab, 1, {});
    },
  },
};
</script>
