var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({staticClass:"notice-list-view",attrs:{"search-props":{
      syncConditionWithUrl: true,
      defaultSearchCondition: _vm.defaultSearchCondition,
      forceCondition: _vm.forceCondition,
      changeObjectInfo: _vm.changeObjectInfo,
      changeLayout: _vm.changeLayout,
    },"table-props":{
      viewAttachment: true,
      csvOutputConfig: _vm.csvOutputConfig,
      paramsExtension: _vm.searchRequestAdditional,
      bulkFieldSetName: 'BulkFieldSet',
      canCreate: _vm.isEditMode,
      canEdit: _vm.isEditMode,
    }},scopedSlots:_vm._u([{key:"datatable.item.DestinationOrganization__c",fn:function(props){return [_c('div',{staticClass:"notice-destination-organization"},[_c('div',{staticClass:"notice-destination-organization-inner"},[_vm._v(" "+_vm._s(_vm.orgDisplay(props.item))+" ")])])]}}])},'ListView',{
      objectName: 'CDS_T_Notice__c',
      detailPageName: 'CDS_VF_NoticeDetail',
      showImageTab: true,
      isDisasterRelated: true,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }