/**
 * レイアウト
 */
export const searchFormLayout = {
  fullName: 'ReportCreateForm.ReportDateLayout',
  label: '報告日時作成レイアウト',
  sobjecttype: 'ReportCreateForm',
  name: 'ReportDateLayout',
  sections: [
    {
      label: null,
      detailHeading: true,
      editHeading: true,
      column: '3',
      items: [
        {
          type: 'field',
          fieldPath: 'from',
          required: true,
          readonly: false,
        },
        {
          type: 'field',
          fieldPath: 'to',
          required: true,
          readonly: false,
        },
      ],
    },
  ],
};

/**
 * オブジェクト情報
 */
export const searchFormObjectInfo = {
  label: '報告日時作成オブジェクト',
  properties: {
    from: {
      label: '開始日時',
      type: 'date-time',
      format: 'date-time',
      inputProps: {
        hideNow: true,
        canSelectMinute: false,
      },
    },
    to: {
      label: '終了日時',
      type: 'date-time',
      format: 'date-time',
      inputProps: {
        hideNow: true,
        canSelectMinute: false,
      },
    },
  },
};
