<template>
  <v-app dark>
    <!--スナックバー-->
    <Snackbar />
    <!-- ローディング -->
    <CircularLoading />
    <!-- ホットリロード -->
    <HotReloadMonitor />
  </v-app>
</template>

<script>
import Snackbar from '@/components/app/Snackbar.vue';
import CircularLoading from '@/components/CircularLoading.vue';
import HotReloadMonitor from '@/components/core/HotReloadMonitor.vue';

export default {
  name: 'RedirectApp',
  components: {
    Snackbar,
    CircularLoading,
    HotReloadMonitor,
  },
  async mounted() {
    await this.$nextTick();
    const { nextRoute } = this.$route.params;
    if (nextRoute) {
      try {
        return this.$router.replace({
          ...nextRoute,
          params: {
            ...nextRoute.params,
            redirectCount: (nextRoute.params?.redirectCount || 0) + 1,
          },
        });
      } catch (error) {
        // ignored
      }
    }
    // どこにも行かなかったらトップに遷移
    window.location.href = '/';
  },
};
</script>
