<template>
  <JMATileCommon
    v-if="targetTimes.length > 0"
    v-model="timeSlider"
    :target-times="targetTimes"
    :tile-props="tilePropsFunction"
  >
    <template #btn>
      雨雲の動き
    </template>
    <template #legend>
      <v-card style="position: absolute; right: 20px; top: 50px;">
        <v-card-text class="text--primary">
          <img
            src="https://www.jma.go.jp/bosai/nowc/images/legend_jp_normal_hrpns.svg"
          />
        </v-card-text>
      </v-card>
    </template>
  </JMATileCommon>
  <!-- <div class="d-flex justify-center">
    <v-btn dark color="primary" @click="toggleWeatherMap">
      <v-icon dark>
        mdi-weather-pouring
      </v-icon>
      雨雲の動き
    </v-btn>
    <div v-if="showWeatherMap" class="d-flex justify-center">
      <v-card
        style="
          width: 700px;
          top: 50px;
          margin-left: -100px;
          position: absolute;
        "
      >
        <v-card-text class="text--primary">
          <div class="mt-2">
            <v-slider
              v-if="targetTimes.length > 0"
              v-model.lazy="timeSlider"
              :max="targetTimes.length - 1"
              min="0"
              :label="targetTimes[timeSlider].label + ' 時点'"
              dense
              ticks
              :tick-labels="tickLabels"
              @change="changeTime()"
            >
            </v-slider>
          </div>
        </v-card-text>
      </v-card>
      <v-card style="position: absolute; right: 20px; top: 50px;">
        <v-card-text class="text--primary">
          <img
            src="https://www.jma.go.jp/bosai/nowc/images/legend_jp_normal_hrpns.svg"
          />
        </v-card-text>
      </v-card>
    </div>
  </div> -->
</template>
<script>
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import JMATileCommon from './JMATileCommon';
export default {
  components: {
    JMATileCommon,
  },
  data: () => ({
    timeSlider: 0,
    targetTimes: [],
  }),
  async mounted() {
    // 過去 https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json
    // 未来 https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json
    this.targetTimes = (
      await Promise.all(
        [
          {
            url:
              'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json',
            type: 'future',
          },
          {
            url:
              'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json',
            type: 'past',
          },
          // {
          //   url:
          //     'https://www.jma.go.jp/bosai/jmatile/data/risk/targetTimes.json',
          //   type: 'other',
          // },
        ].map(async ({ url, type }) => {
          const targetTimes = await fetch(url).then((res) => {
            if (res.ok) {
              return res.json();
            }
          });
          return targetTimes.map((v) => ({ ...v, type }));
        }),
      )
    )
      .reduce((prev, next) => {
        return [...prev, ...next];
      }, [])
      .reverse()
      .map((v) => {
        const { validtime } = v;
        const utcDate = new Date(
          Date.UTC(
            validtime.substr(0, 4),
            validtime.substr(4, 2),
            validtime.substr(6, 2),
            validtime.substr(8, 2),
            validtime.substr(10, 2),
            validtime.substr(12, 2),
          ),
        );
        const jstDate = utcToZonedTime(utcDate, 'Asia/Tokyo');
        const jstHour = format(jstDate, 'H:mm');
        return { ...v, label: jstHour };
      });

    // 予測のtargetTimesのbasetimeは現在のbasetimeと同じものが設定されているのでN2のbasetimeを取得する
    const currentTime = this.targetTimes.find((v) => v.type === 'future');

    // this.setWeatherMap(currentTime.basetime, currentTime.basetime);
    // スライダーの初期状態はtargetTimes_N1のbasetimeとvalidtimeが一緒のもの
    this.timeSlider = this.targetTimes.indexOf(
      this.targetTimes.find(
        (v) =>
          v.type === 'past' &&
          v.basetime === currentTime.basetime &&
          v.validtime === currentTime.basetime,
      ),
    );
  },
  methods: {
    tilePropsFunction(targetTime) {
      return {
        getTileUrl: (coord, zoom) => {
          let x = (coord.x % Math.pow(2, zoom)).toString();
          let y = coord.y.toString();
          // return item.URL__c?.replace(
          return 'https://www.jma.go.jp/bosai/jmatile/data/nowc/{basetime}/none/{validtime}/surf/hrpns/{z}/{x}/{y}.png'
            ?.replace('{z}/{x}/{y}', zoom + '/' + x + '/' + y || null)
            .replace('{basetime}', targetTime.basetime)
            .replace('{validtime}', targetTime.validtime);
        },
        tileSize: new google.maps.Size(256, 256),
        minZoom: 4,
        maxZoom: 10,
        // opacity: this.opacitySlider[item.Id] / 100,
      };
    },
  },
};

// import { format } from 'date-fns';
// import { utcToZonedTime } from 'date-fns-tz';

// export default {
//   data: () => ({
//     showWeatherMap: false,
//     timeSlider: 0,
//     timeTicks: [],
//     targetTimes: [],
//     layerMapType: {},
//   }),
//   computed: {
//     tickLabels() {
//       return this.targetTimes.map((v, i) => (i % 12 === 0 ? v.label : ''));
//     },
//   },
//   async mounted() {
//     // 過去 https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json
//     // 未来 https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json
//     this.targetTimes = (
//       await Promise.all(
//         [
//           {
//             url:
//               'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json',
//             type: 'future',
//           },
//           {
//             url:
//               'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json',
//             type: 'past',
//           },
//           {
//             url:
//               'https://www.jma.go.jp/bosai/jmatile/data/risk/targetTimes.json',
//             type: 'other',
//           },
//         ].map(async ({ url, type }) => {
//           const targetTimes = await fetch(url).then((res) => {
//             if (res.ok) {
//               return res.json();
//             }
//           });
//           return targetTimes.map((v) => ({ ...v, type }));
//         }),
//       )
//     )
//       .reduce((prev, next) => {
//         return [...prev, ...next];
//       }, [])
//       .reverse()
//       .map((v) => {
//         const { validtime } = v;
//         const utcDate = new Date(
//           Date.UTC(
//             validtime.substr(0, 4),
//             validtime.substr(4, 2),
//             validtime.substr(6, 2),
//             validtime.substr(8, 2),
//             validtime.substr(10, 2),
//             validtime.substr(12, 2),
//           ),
//         );
//         const jstDate = utcToZonedTime(utcDate, 'Asia/Tokyo');
//         const jstHour = format(jstDate, 'H:mm');
//         return { ...v, label: jstHour };
//       });

//     // 予測のtargetTimesのbasetimeは現在のbasetimeと同じものが設定されているのでN2のbasetimeを取得する
//     const currentTime = this.targetTimes.find((v) => v.type === 'future');

//     // this.setWeatherMap(currentTime.basetime, currentTime.basetime);
//     // スライダーの初期状態はtargetTimes_N1のbasetimeとvalidtimeが一緒のもの
//     this.timeSlider = this.targetTimes.indexOf(
//       this.targetTimes.find(
//         (v) =>
//           v.type === 'past' &&
//           v.basetime === currentTime.basetime &&
//           v.validtime === currentTime.basetime,
//       ),
//     );
//   },
//   methods: {
//     toggleWeatherMap() {
//       if (this.showWeatherMap) {
//         this.clearWeatherMap();
//       } else {
//         this.changeTime();
//       }
//       this.showWeatherMap = !this.showWeatherMap;
//     },
//     changeTime() {
//       const targetTime = this.targetTimes[this.timeSlider];
//       this.setWeatherMap(targetTime.basetime, targetTime.validtime);
//     },
//     setWeatherMap(basetime, validtime) {
//       // 雨雲のうごきをとりあえず表示したい
//       this.clearWeatherMap();
//       const { map } = this.$store.state.GIS;
//       this.layerMapType = new google.maps.ImageMapType({
//         getTileUrl: (coord, zoom) => {
//           let x = (coord.x % Math.pow(2, zoom)).toString();
//           let y = coord.y.toString();
//           // return item.URL__c?.replace(
//           return 'https://www.jma.go.jp/bosai/jmatile/data/nowc/{basetime}/none/{validtime}/surf/hrpns/{z}/{x}/{y}.png'
//             ?.replace('{z}/{x}/{y}', zoom + '/' + x + '/' + y || null)
//             .replace('{basetime}', basetime)
//             .replace('{validtime}', validtime);
//         },
//         tileSize: new google.maps.Size(256, 256),
//         minZoom: 4,
//         maxZoom: 10,
//         // opacity: this.opacitySlider[item.Id] / 100,
//       });

//       map.overlayMapTypes.insertAt(0, this.layerMapType);
//     },
//     clearWeatherMap() {
//       if (this.layerMapType) {
//         const { map } = this.$store.state.GIS;
//         let delId;
//         map.overlayMapTypes.forEach((element, idx) => {
//           if (element && element === this.layerMapType) {
//             delId = idx;
//           }
//         });
//         if (delId !== undefined) map.overlayMapTypes.setAt(delId, null);
//         this.layerMapType = null;
//       }
//     },
//   },
// };
</script>
