<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header :do-update="objectName" />
    </template>
    <!-- オブジェクトの選択 -->
    <div class="d-flex align-center mt-3">
      <div>
        <v-select
          v-model="objectName"
          outlined
          clearable
          dense
          hide-details
          :placeholder="objectName ? undefined : 'マスタを選択してください。'"
          :items="computedMasterOptions"
          class="select-master"
        />
      </div>
    </div>
    <!-- リストビュー -->
    <ListView
      v-if="objectName && !_.isEmpty(computedObjectInfo)"
      :key="objectName"
      v-bind="{
        objectName,
        detailLayoutName: computedObjectInfo.ListEditLayoutName,
        detailPageName,
        listName: 'masterList',
      }"
      :search-props="{
        syncConditionWithUrl: true,
        layoutName: computedObjectInfo.SearchLayoutName,
      }"
      :table-props="{
        defaultListOptions: computedDefaultListOptions,
        csvFileNameFormat: `${computedObjectInfo.Label}-%date%.csv`,
        createPage: create,
        csvFieldSetName: 'CSVFieldSet,ListFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
        csvOutputConfig: {
          __final: csvOutputConfigFinal,
        },
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import { disaster } from '@/beforeGuard/defaultRules';

import CsvImport from './buttons/CsvImport.vue';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  provide: {
    listButton: {
      masterList: {
        leftEnd: [
          {
            component: CsvImport,
            props: {
              label: 'leftEndButton',
            },
          },
        ],
      },
    },
  },

  data: (vm) => ({
    // マスタオブジェクト定義
    definition: [],
    // 選択済みオブジェクト
    objectName: vm.$query.current().objectName,
    // 詳細ページ名
    detailPageName: 'CDS_VF_MasterDetail',
    // 追加削除ヘッダー
    editHeader: '追/修/削',
  }),

  computed: {
    // マスタの選択肢
    computedMasterOptions() {
      return this.definition
        .filter((def) => !def.Hide)
        .map((def) => ({
          text: def.Label,
          value: def.ObjectName,
        }));
    },
    // 選択済みオブジェクトの情報
    computedObjectInfo() {
      return (
        this.definition.find((def) => def.ObjectName === this.objectName) || {}
      );
    },
    // デフォルトソートなど
    computedDefaultListOptions() {
      const { SortFieldName: fieldName } = this.computedObjectInfo;
      if (fieldName) {
        const splitNames = fieldName.split(' ');
        return {
          sortBy: [splitNames[0]],
          sortDesc: [splitNames.length > 1 ? Boolean(splitNames[1]) : false],
        };
      }
      return {};
    },
  },

  watch: {
    objectName(to) {
      this.$query.updateHistoryQuery({
        query: {
          ...this.$query.current(),
          objectName: to,
          search: {},
        },
      });
    },
  },

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.loadMasterDefinition()]);
    },
    // マスタの選択肢を取得する
    async loadMasterDefinition() {
      try {
        const res = await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller: this.$pageProperty.controller,
            method: 'getMasterDefinition',
          }),
        );
        this.definition = res || [];
      } catch (error) {
        // ignored
      }
    },
    // 新規作成
    create() {
      this.$transition.to(this.detailPageName, {
        mode: 'n',
        objectName: this.objectName,
      });
    },

    /**
     * csv出力の変更
     */
    async csvOutputConfigFinal(finalJson) {
      const edit = structuredClone(finalJson);
      const result = edit.map((row, index) => {
        return index === 0 ? [this.editHeader, ...row] : ['', ...row];
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-master {
  width: 350px;
}
</style>
