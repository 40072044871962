import cookies from 'browser-cookies';
import { user as rule } from '@/beforeGuard/defaultRules';

function setCookie(state) {
  cookies.set(
    rule.cookieKey,
    JSON.stringify({
      uid: state.user.Id,
      realUid: state.realUser.Id,
    }),
  );
}

// 組織
export default {
  namespaced: true,

  state: {
    // webユーザ情報(代行ログインユーザ情報)
    user: {},
    // 組織情報
    organization: {},
    // 本当のユーザ情報(代行ログイン時でも変わらない)
    realUser: {},
  },

  getters: {
    exist: (state) => {
      return !!(state.organization && state.organization.Name);
    },
    // 代行ログインしているか
    isActing: (state) => {
      return !!(
        state.user &&
        state.realUser &&
        state.user.Id != state.realUser.Id
      );
    },
  },

  mutations: {
    init(state, { user, organization, realUser }) {
      state.user = user;
      state.organization = organization;
      // realUserは値があった場合のみ入れ替え
      realUser && (state.realUser = realUser);
      setCookie(state);
    },
  },

  actions: {
    async setUser({ commit }, { user, organization } = {}) {
      commit('init', { user, organization });
    },
    init({ commit }, v) {
      commit('init', v);
    },
    erase({ commit }) {
      commit('init', { user: {}, organization: {}, realUser: {} });
      cookies.erase(rule.cookieKey);
    },
    // ユーザIdの再設定(代行ログイン解除時など)
    setUserId({ state }, userId) {
      setCookie({
        user: {
          Id: userId,
        },
        realUser: state.realUser,
      });
    },
  },
};
