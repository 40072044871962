var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"search-props":{
      syncConditionWithUrl: true,
      applyErrataToCondition: true,
      forceCondition: _vm.forceCondition,
      changeObjectInfo: _vm.changeObjectInfo,
    },"table-props":{
      inputFieldSetName: 'ListFieldSet',
      canEdit: false,
      canCreate: !_vm.rep,
      defaultListOptions: _vm.defaultListOptions,
      viewAttachment: true,
    },"google-map-props":{
      gisDynamicSettingName: 'GIS_C_D_Request',
    }},scopedSlots:_vm._u([{key:"datatable.item.RequestStatus__c",fn:function(props){return [_c('RequestStatusLabel',{attrs:{"value":props.value}})]}},{key:"datatable.item.CDS_M_Organization__c",fn:function(props){return [_c('div',{staticClass:"request-destination-organization"},[_c('div',{staticClass:"request-destination-organization-inner"},[_vm._v(" "+_vm._s(_vm.orgDisplay(props.value))+" ")])])]}},{key:"datatable.item.DestinationOrganization__c",fn:function(props){return [_c('div',{staticClass:"request-destination-organization"},[_c('div',{staticClass:"request-destination-organization-inner"},[_vm._v(" "+_vm._s(_vm.orgDisplay(props.value))+" ")])])]}}])},'ListView',{
      objectName: 'CDS_T_Request__c',
      detailPageName: 'CDS_VF_RequestDetail',
      isDisasterRelated: true,
      showImageTab: true,
      showMapTab: true,
      hasVersion: true,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }