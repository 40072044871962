import App from './App.vue';

export default {
  path: '/CDS_VF_EvacuationAdvisoryEdit',
  name: 'CDS_VF_EvacuationAdvisoryEdit',
  component: App,
  meta: {
    pageProperty: {
      controller: 'CDS_CTR_EvacuationAdvisoryEdit',
      title: '避難情報発表詳細',
    },
  },
};
