export const infoType = [
  {
    fieldPath: 'EmergencyWarningType__c',
    label: '特別警報',
    color: 'black',
    dark: true,
    showLegend: true,
    order: 1,
  },
  {
    fieldPath: 'LandslideAlert__c',
    label: '土砂災害警戒情報',
    color: 'purple',
    dark: true,
    showLegend: true,
    order: 2,
  },
  {
    fieldPath: 'WarningType__c',
    label: '警報',
    color: 'red',
    dark: true,
    showLegend: true,
    order: 3,
  },
  {
    fieldPath: 'AdvisoryType__c',
    label: '注意報',
    color: 'yellow',
    dark: false,
    showLegend: true,
    order: 4,
  },
  {
    fieldPath: 'Other__c',
    label: 'その他',
    color: 'black',
    dark: true,
    showLegend: false,
    order: 5,
  },
];
