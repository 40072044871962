import mapLoader from '@/googlemap/loader';
export default {
  namespaced: true,

  state: {
    map: {},
    mapLoading: true,
    dataLoading: false,
    autoReload: false,
  },

  getters: {
    autoReload: state => state.autoReload,
    mapLoading: state => state.mapLoading,
    dataLoading: state => state.dataLoading,
  },

  mutations: {
    setLoading(state, v) {
      state.dataLoading = v;
    },
    setAutoReload(state, v) {
      state.autoReload = v;
    },
    setMap(state, v) {
      state.mapLoading = false;
      state.map = v;
    },
  },

  actions: {
    // マップの初期化
    async loadMap({ commit }, { dom, query }) {
      const loader = await mapLoader();
      const map = loader.createMap(dom);
      // ページパラメタの扱い
      // 初期緯度経度
      const { lat, lng, z } = query;
      if (lat && lng) {
        map.panTo({
          lat: Number(lat),
          lng: Number(lng),
        });
      }
      if (z) {
        map.setZoom(Number(z));
      }

      commit('setMap', map);
    },
  },
};
