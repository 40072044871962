import App from './App.vue';
import { user } from '@/beforeGuard/defaultRules';

export default {
  path: '/CDS_VF_LoginHistory',
  name: 'CDS_VF_LoginHistory',
  component: App,
  meta: {
    pageProperty: {
      title: 'ログイン履歴',
      controller: 'CDS_CTR_LoginHistory',
    },
    guardRules: [user],
  },
};
