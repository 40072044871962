<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="(e) => $store.commit('GIS/setAutoReload', e)" />
        </v-list-item-action>
        <v-list-item-icon>
          <v-icon>mdi-reload</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>自動更新</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style scoped></style>
