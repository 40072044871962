<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header
        title="ユーザ選択"
        subtitle="ユーザを選択してください。"
        icon="fa-synagogue"
      ></page-header>
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'User',
      }"
      :search-props="{
        layoutName: 'SelectSearchLayout',
        forceCondition: {
          IsValid__c: true,
        },
      }"
      :table-props="{
        listFieldSetName: 'SelectListFieldSet',
        defaultListOptions,
        canEdit: false,
        canCreate: false,
        canCsvDownload: false,
        onClickFunc: onClick,
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import { user as rule } from '@/beforeGuard/defaultRules';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['Sort__c'],
      sortDesc: [false],
    },
  }),

  async mounted() {
    try {
      const { user: initStore } = await rule.load();
      this.$store.dispatch('user/init', initStore);
    } catch (e) {
      // ignored
    }
  },

  methods: {
    // クリック時
    async onClick(item) {
      // ローディング
      this.$store.dispatch('loading/setTransition', true);

      // storeの値を参照し、realUidがなければadminの初回ユーザ選択とみなす
      const storeRealUser = this.$store.state.user.realUser;
      // adminの初回ユーザ選択か
      const isAdminFirst = !storeRealUser.Id;

      // webユーザIdから組織情報を取得する
      const { CDS_M_Organization__c: organizationId } = item;
      // 組織情報の取得
      const organization = await this.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getOrganization',
        params: {
          organizationId,
        },
      });

      // webユーザ情報のセット
      if (isAdminFirst) {
        // 初回時はrealUserも更新する
        await this.$store.dispatch('user/init', {
          user: item,
          organization,
          realUser: { ...this.$store.state.user.realUser, ...item },
        });
      } else {
        // 通常は実ユーザは更新しない
        await this.$store.dispatch('user/setUser', {
          user: item,
          organization,
        });
      }

      // 戻る
      this.$transition.fallback();
    },
  },
};
</script>
