var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Layout',{attrs:{"name":"input_form","layout":_vm.layout,"object-info":_vm.objectInfo,"edit-mode":_vm.slotProps.editMode},on:{"submit-raw":_vm.slotProps.validate},model:{value:(_vm.slotProps.value),callback:function ($$v) {_vm.$set(_vm.slotProps, "value", $$v)},expression:"slotProps.value"}}),(_vm.slotProps.value.Id)?_c('ListView',_vm._b({staticClass:"evacuation-advisory-detail-area-table",attrs:{"search-props":{
      canSearch: false,
      forceCondition: {
        CDS_T_EvacuationAdvisoryManager__c: _vm.slotProps.value.Id,
      },
    },"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      canCreate: false,
      canEdit: false,
      canReload: false,
      onClickFunc: null,
    }}},'ListView',{
      objectName: 'CDS_T_EvacuationAdvisory__c',
    },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }