// ECharts
// https://github.com/ecomfe/vue-echarts
// https://echarts.apache.org/examples/en/

import Vue from 'vue';

// クレジット
import EChartsCredit from '@/components/common/EChartsCredit';
Vue.component('EChartCredit', EChartsCredit);

// echarts本体
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart } from 'echarts/charts';
import {
  TooltipComponent,
  PolarComponent,
  LegendComponent,
  LegendScrollComponent,
  TitleComponent,
  GridComponent,
  DatasetComponent,
  AxisPointerComponent,
} from 'echarts/components';

import EChart from 'vue-echarts';

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TooltipComponent,
  PolarComponent,
  LegendComponent,
  LegendScrollComponent,
  TitleComponent,
  GridComponent,
  DatasetComponent,
  AxisPointerComponent,
]);

Vue.component('EChart', EChart);

// css
import '@/assets/css/echarts.css';
