var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header',{attrs:{"do-update":_vm.objectName}})]},proxy:true}])},[_c('div',{staticClass:"d-flex align-center mt-3"},[_c('div',[_c('v-select',{staticClass:"select-master",attrs:{"outlined":"","clearable":"","dense":"","hide-details":"","placeholder":_vm.objectName ? undefined : 'マスタを選択してください。',"items":_vm.computedMasterOptions},model:{value:(_vm.objectName),callback:function ($$v) {_vm.objectName=$$v},expression:"objectName"}})],1)]),(_vm.objectName && !_vm._.isEmpty(_vm.computedObjectInfo))?_c('ListView',_vm._b({key:_vm.objectName,attrs:{"search-props":{
      syncConditionWithUrl: true,
      layoutName: _vm.computedObjectInfo.SearchLayoutName,
    },"table-props":{
      defaultListOptions: _vm.computedDefaultListOptions,
      csvFileNameFormat: `${_vm.computedObjectInfo.Label}-%date%.csv`,
      createPage: _vm.create,
      csvFieldSetName: 'CSVFieldSet,ListFieldSet',
      bulkFieldSetName: 'BulkFieldSet',
      csvOutputConfig: {
        __final: _vm.csvOutputConfigFinal,
      },
    }}},'ListView',{
      objectName: _vm.objectName,
      detailLayoutName: _vm.computedObjectInfo.ListEditLayoutName,
      detailPageName: _vm.detailPageName,
      listName: 'masterList',
    },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }