<template>
  <tr v-if="active" class="tr-area">
    <td :class="`cell-area ${isExtra ? 'pl-10' : 'pl-16'} d-flex align-center`">
      <template v-if="!isExtra">
        <v-simple-checkbox
          class="my-1 mr-2 area-row-checkbox"
          :value="selected"
          color="primary"
          @input="handleInput"
        />
        <div class="area-row-label" @click.prevent="handleInput">
          {{ area.Name }}
        </div>
      </template>
      <div v-else class="d-flex v-label align-center flex-grow-1 theme--light">
        <div
          class="d-flex v-label align-center flex-grow-1 theme--light extra-area-name"
          @click="$emit('extra-click', area)"
        >
          {{ area.Name }}
        </div>
        <v-icon v-ripple dense @click="$emit('extra-delete', area)">
          mdi-close
        </v-icon>
      </div>
    </td>
    <td class="cell-household">
      <v-text-field
        v-model.number.lazy="area.Household__c"
        outlined
        dense
        height="28"
        single-line
        hide-details
        type="number"
        autocomplete="off"
      ></v-text-field>
    </td>
    <td class="cell-people">
      <v-text-field
        v-model.number.lazy="area.People__c"
        outlined
        dense
        height="28"
        single-line
        hide-details
        type="number"
        autocomplete="off"
      ></v-text-field>
    </td>
    <td
      v-for="(announceType, announceTypeIndex) in announceTypes"
      :key="announceTypeIndex"
      :class="['cell-announce-type', announceType.name]"
    >
      <AnnounceTypeAreaCell
        :status="
          area.announceTypeName === announceType.name ? area.orderStatus : null
        "
        :old-status="
          area.oldAnnounceTypeName === announceType.name
            ? area.oldOrderStatus
            : null
        "
      />
    </td>
    <td class="cell-reason-type">
      {{ area.reasonType }}
    </td>
  </tr>
</template>

<script>
import AnnounceTypeAreaCell from './AnnounceTypeAreaCell';

export default {
  name: 'EvacuationEditorAreaRow',

  components: {
    AnnounceTypeAreaCell,
  },

  props: {
    // 行を表示するか
    active: { type: Boolean, default: false },
    // エリアのチェックボックスの選択
    selected: { type: Boolean, default: false },
    // 区域
    area: { type: Object, default: () => ({}) },
    // 発令種別
    announceTypes: { type: Array, default: () => [] },
    // 臨時かどうか
    isExtra: { type: Boolean, default: false },
  },

  computed: {
    // モーダル
    computedSelectedArea: {
      get() {
        return this.selectedArea;
      },
      set(value) {
        this.$emit('selected-area-updated', value);
      },
    },
  },

  methods: {
    // 選択状況をtoggleする
    handleInput() {
      this.$emit('selected-updated', {
        value: !this.selected,
        Id: this.area.Id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-area-name:hover {
  cursor: pointer;
}
.cell-area {
  .area-row-checkbox {
    i {
      font-size: 20px;
    }
  }
  div.area-row-label {
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
