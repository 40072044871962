var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('div',[_c('Layout',_vm._b({on:{"submit-raw":_vm.handleOnSubmit},model:{value:(_vm.reportForm.value),callback:function ($$v) {_vm.$set(_vm.reportForm, "value", $$v)},expression:"reportForm.value"}},'Layout',_vm.reportForm.attrs,false))],1),_c('v-card-actions',[_c('Confirm',{attrs:{"message":"「報告日時」は、追加および削除が任意のタイミングで可能です。よろしいですか？"},on:{"on-click-yes":_vm.handleClickCreateButton},scopedSlots:_vm._u([{key:"default",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"primary"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-clock-start ")]),_vm._v(" 報告日時作成 ")],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('div',[_c('v-data-table',_vm._b({staticClass:"cdsTable",attrs:{"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.ReportDate__c",fn:function(props){return [_c('Display',_vm._b({},'Display',{
                value: props.item.IsSetDate__c ? props.value : '体制設置日時',
                itemProps: props,
                fieldInfo: {
                  type: props.item.IsSetDate__c ? 'date-time' : 'string',
                },
              },false))]}}]),model:{value:(_vm.recordTable.selected),callback:function ($$v) {_vm.$set(_vm.recordTable, "selected", $$v)},expression:"recordTable.selected"}},'v-data-table',_vm.recordTable.attrs,false))],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('Confirm',{attrs:{"message":"選択された報告日時を削除します。よろしいですか？"},on:{"on-click-yes":_vm.handleClickDeleteButton},scopedSlots:_vm._u([{key:"default",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","dark":"","color":"red"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete-forever ")]),_vm._v(" 報告日時削除 ")],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ExtraForm')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }