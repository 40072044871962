import App from './App.vue';

export default {
  path: '/CDS_VF_GIS',
  name: 'CDS_VF_GIS',
  component: App,
  meta: {
    pageProperty: {
      title: 'GIS',
      controller: 'CDS_CTR_GIS',
    },
  },
};
