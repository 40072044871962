<template>
  <detail
    use-version
    object-name="CDS_T_EvacuationAdvisoryManager__c"
    layout-name="EvacuationAdvisoryManagerLayout"
    default-title="避難情報発表"
    saved-page="CDS_VF_EvacuationAdvisoryDetail"
    detail-page-name="CDS_VF_EvacuationAdvisoryDetail"
    list-page="CDS_VF_EvacuationAdvisoryList?le=true"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    :disable-actions="computedDetailButtonActions"
    :hide-menu="stepper !== 3"
    @loaded-data="onLoadedData"
  >
    <template #layout="slotProps">
      <v-stepper v-model="stepper" class="evacuation-stepper elevation-0">
        <v-stepper-header class="elevation-0 ma-n2">
          <v-stepper-step :complete="stepper > 1" step="1">
            発令・解除区域入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 2" step="2">
            発令・解除区域確認
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            発令情報入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4">
            確定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="5">
            完了
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-row no-gutters class="mb-2">
              <div class="d-flex align-center">
                <span class="text-body-2 align-center">
                  <v-icon>mdi-information-outline</v-icon>
                  発令区域を設定してください。※避難発令情報はその時点で発令された情報のみでなく、最新の状況が全て伝わるように、発表時点の全ての発令・解除の状況を含めてください。
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                class="mx-2"
                :disabled="internalData.length === 0"
                @click="stepper = 2"
              >
                次へ
              </v-btn>
            </v-row>
            <EvacuationEditor
              :active="stepper === 1"
              :announce-types="computedAnnounceType"
              :reason-types="computedReasonType"
              :slot-props="slotProps"
              :loaded-data="loadedData"
              :latest-internal-data="latestInternalData"
              @form-valid="form.editValid = $event"
              @update-internal-data="onInternalDataUpdated"
              @update-record-data="onRecordDataUpdated"
            />
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-row no-gutters class="mb-2">
              <div class="d-flex align-center">
                <span class="text-body-2 align-center">
                  <v-icon>mdi-information-outline</v-icon>
                  発令区域を確認してください。間違いがある場合は修正してください。
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-btn depressed class="mx-2" @click="stepper = 1">
                戻る
              </v-btn>
              <v-btn
                color="primary"
                outlined
                class="mx-2"
                :disabled="!form.checkValid || checkedRecordDataIsEmpty"
                @click="stepper = 3"
              >
                次へ
              </v-btn>
            </v-row>
            <EvacuationChecker
              :active="stepper === 2"
              :announce-types="computedAnnounceType"
              :record-data="originalRecordDataToChecker"
              :do-apply-alert-level="doApplyAlertLevel"
              @form-valid="form.checkValid = $event"
              @update-record-data="checkedRecordData = $event"
              @update-record-data-number-fields="
                checkedRecordDataNumberFields = $event
              "
            />
          </v-stepper-content>

          <v-stepper-content step="3" class="pa-0">
            <v-row no-gutters class="mb-2">
              <v-spacer></v-spacer>
              <v-btn depressed class="mx-2" @click="stepper = 2">
                戻る
              </v-btn>
            </v-row>
            <EvacuationInfo
              :active="stepper === 3"
              :announce-types="computedAnnounceType"
              :internal-data="internalData"
              :record-data="checkedRecordDataToInfo"
              :record-data-number-fields="checkedRecordDataNumberFields"
              :slot-props="slotProps"
              :loaded-data="loadedData"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import EvacuationEditor from './components/EvacuationEditor';
import EvacuationChecker from './components/EvacuationChecker';
import EvacuationInfo from './components/EvacuationInfo';

import { internalDataEnlarge } from './components/util';
import { AnnounceType__c } from '@/assets/js/objects/CDS_T_EvacuationAdvisory__c';
import { mapActions } from 'vuex';

export default {
  components: {
    detail: {
      ...detail,
      computed: {
        ...detail.computed,
        editMode: {
          ...detail.computed.editMode,
          set: function (val) {
            // 閲覧モードに変わった時
            if (this.editMode_ && !val) {
              // ページ遷移する
              if (this.original?.object?.Id) {
                // Idがあれば詳細ページへ
                return this.$transition.to(this.savedPage, {
                  id: this.value.Id,
                });
              } else {
                // Idがなければメインページへ
                return this.$transition.fallback();
              }
            }
            // 通常の動作
            this._editModeSet.bind(this)(val);
          },
        },
      },
      methods: {
        ...detail.methods,
        // computedのeditModeのsetを保持
        _editModeSet: detail.computed.editMode.set,
        _save: detail.methods.save,
        save: async function () {
          try {
            const { objectName } = this;
            const { controller } = this.$pageProperty;
            const newData = {
              ...this.original.object,
              ..._.omit(this.value, ['_recordData', '_announceDatetime']),
              attributes: { type: objectName },
            };
            // 区域の情報
            const areaData = this.value._recordData;
            // バージョン利用時は保存のタイミングで確定を外す
            if (this.useVersion) {
              newData.IsFixed__c = false;
            }

            const {
              manager: resultManager,
              areas: resultAreas,
            } = await this.$store.dispatch(
              'loading/register',
              this.$con.invoke({
                controller,
                method: 'saveEvacuationData',
                params: {
                  manager: newData,
                  // polygonを除去(添付ファイルとして保存するため)
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  areas: areaData.map(({ Polygon__c, ...a }) => a),
                },
              }),
            );

            // 各保存済み区域レコードに対して図形情報を添付する
            await this.$store.dispatch(
              'loading/register',
              Promise.all(
                resultAreas.map(async (area) => {
                  // lineNoを頼りに図形情報を取り出し
                  const polygon = areaData.find(
                    (v) => Number(v.LineNo__c) === Number(area.LineNo__c),
                  )?.Polygon__c;
                  // polygonがなければおわり
                  if (!polygon || _.isEmpty(polygon)) return;
                  // polygonは単純なfeatureの配列(のjson文字列)で入ってくるので、GIS用のフォーマットにここで変換する
                  const polygonObj = JSON.parse(polygon);
                  const forGISPolygon = JSON.stringify({
                    features: polygonObj,
                  });
                  // 添付ファイルに追加
                  // 保存された区域のオブジェクトId
                  const { Id: areaObjectId } = area;
                  // 添付ファイルの名称
                  const fileName = `gis__hidden__evacuation_area_${areaObjectId}_data.json`;
                  // ファイル作成
                  const file = new Blob([forGISPolygon], {
                    type: 'application/json',
                  });
                  file.name = fileName;
                  // アップロード
                  // 添付する
                  await this.attachmentUploader.start(
                    [
                      {
                        file,
                        progres: null,
                        uploadComplete: false,
                        progresEvent: ({ loaded, total }) => {
                          this.progres = (loaded / total) * 100;
                        },
                      },
                    ],
                    areaObjectId,
                    'CDS_T_EvacuationAdvisory__c',
                  );
                }),
              ),
            );

            // 添付ファイル保存
            await this.saveAttachment(resultManager.Id);

            this.saveComplete();
            this.deleteAttachment = [];
            this.$transition.to(this.savedPage, {
              id: resultManager.Id,
              sb: JSON.stringify({
                message: '保存しました',
                props: {
                  color: 'green',
                  bottom: true,
                  timeout: 2000,
                },
                closable: true,
              }),
            });
          } catch (e) {
            console.error(e);
            this.saveFail(e.message);
          }
        },
      },
    },
    EvacuationEditor,
    EvacuationChecker,
    EvacuationInfo,
  },

  data: (vm) => ({
    // stepper
    stepper: Number(vm.$query.current().page || 1),

    // オブジェクト情報
    objectInfo: {},

    // detailのloadDataが終了した時点でのデータ
    loadedData: {},

    // 内部データ
    internalData: [],
    // 最新報の内部データ
    latestInternalData: {},
    // レコードデータ
    originalRecordData: {},
    originalRecordDataToChecker: {},
    // 確認済みのレコードデータ
    checkedRecordData: {},
    checkedRecordDataToInfo: {},
    // 確認済みのレコードデータの世帯数・人数
    checkedRecordDataNumberFields: {},

    // 各フォームのバリデーション
    form: {
      // 入力画面のバリデーション
      editValid: null,
      // 確認画面のバリデーション
      checkValid: null,
    },
  }),

  computed: {
    // 初期値の設定
    initValue() {
      return {
        Name: '避難情報発表',
        ...this.$util.getDefaultInitValue(this.$store.state),
      };
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
    // 発令種別のリスト
    computedAnnounceType() {
      return _.get(
        this.objectInfo,
        'properties.AnnounceType__c.picklistValues',
        [],
      ).map((at) => ({
        ...at,
        ...AnnounceType__c[at.value],
      }));
    },
    // 原因種別のリスト
    computedReasonType() {
      return _.get(
        this.objectInfo,
        'properties.ReasonType__c.picklistValues',
        [],
      );
    },
    // ボタンの表示制御
    computedDetailButtonActions() {
      return this.stepper === 3 ? [] : ['save'];
    },
    // 警戒レベルを自動設定するか
    doApplyAlertLevel() {
      // 災害情報の取得
      const {
        disaster: { disaster },
      } = this.$store.state;
      // 災害種類
      const disasterKind = disaster.DisasterKind__c;
      // 災害種類に「一般災害」が入っていたら、警戒レベルの自動設定を行う
      return disasterKind.includes('一般災害');
    },

    // 確認画面の区域数チェック
    checkedRecordDataIsEmpty() {
      // 2階層まわして、値が1つでもあればfalse
      for (const announceTypeName in this.checkedRecordData) {
        // 発令/解除をキーに持つオブジェクト
        const announceType = this.checkedRecordData[announceTypeName];
        for (const orderStatus in announceType) {
          if (announceType[orderStatus].length > 0) return false;
        }
      }
      return true;
    },
  },

  watch: {
    stepper(to, from) {
      if (to === from) return;
      // 1 -> 2
      if (to === 2 && from === 1) {
        this.$set(
          this,
          'originalRecordDataToChecker',
          structuredClone(this.originalRecordData),
        );
      }
      // 2 -> 3
      if (to === 3 && from === 2) {
        this.$set(
          this,
          'checkedRecordDataToInfo',
          structuredClone(this.checkedRecordData),
        );
      }
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      // URLクエリのチェック
      await this.checkQuery();
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadObjectInfo(), this.loadLatestData()]),
      );
    },
    // URLクエリのチェックを行う(0報チェック)
    async checkQuery() {
      // 0報を取得し、現在のパラメータが正しいかチェックする
      const { recordId } = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getBaseRecordId',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            isFixed: false,
          },
        }),
      );
      const query = this.$query.current();
      if (query.mode === 'n' && !recordId) {
        // modeがnで、0報のIdがなければ正しい
        return;
      }
      if (query.id && recordId == query.id) {
        // idに値があり、取得した0報のIdと一致すれば正しい
        return;
      }
      // それ以外の場合は、URLクエリがおかしいので、Detailにリダイレクト
      this.$transition.to('CDS_VF_EvacuationAdvisoryDetail', null, {
        keepRetUrl: true,
      });
    },
    // オブジェクト情報の取得
    async loadObjectInfo() {
      try {
        const res = await this.$util.getObjectInfo(
          'CDS_T_EvacuationAdvisory__c',
        );
        if (res) {
          this.$set(this, 'objectInfo', res);
        }
      } catch (error) {
        this.openSnackBar({
          message: 'オブジェクト情報の取得に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },
    // 最新の確定データを取得
    async loadLatestData() {
      const { id: objectId } = this.$query.current();
      // idがない場合は取得しない
      if (!objectId) return;
      try {
        const res = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getLatestData',
          params: {
            objectId,
          },
        });
        if (res) {
          this.$set(
            this,
            'latestInternalData',
            internalDataEnlarge(res.internalData),
          );
        }
        // console.log(
        //   '%clatest internaldata ->',
        //   'color: lightblue;',
        //   this.latestInternalData,
        // );
      } catch (error) {
        this.openSnackBar({
          message: '確定データの取得に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    // 詳細のデータ読み込み終了
    onLoadedData(d) {
      if (d.InternalData__c) {
        const enlarged = internalDataEnlarge(d.InternalData__c);
        this.$set(this, 'loadedData', enlarged);
      }
    },

    // イベントハンドラ
    // 内部データ更新
    onInternalDataUpdated(to) {
      this.internalData = to;
    },
    // レコードデータ更新
    onRecordDataUpdated(to) {
      this.$set(this, 'originalRecordData', to);
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>

<style lang="scss">
@import './pageStyle.scss';
</style>
