<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab title="本部体制" icon="mdi-shield-home" />
    </template>
    <div class="pa-1">
      <v-row class="ma-0 pt-1">
        <v-col cols="12" class="pa-0">
          <div :class="[color[headInfo.honbuSystem]]" class="ma-1 pa-3">
            <div class="text-sm-h5 text-h6">市本部</div>
            <div class="text-sm-body-2 text-caption">
              {{ headInfo.honbuSystem }}
            </div>
          </div>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div :class="[color[headInfo.northSystem]]" class="ma-1 pa-3">
            <div class="text-sm-h6 text-body-1">北部</div>
            <div class="text-sm-body-2 text-caption">
              {{ headInfo.northSystem }}
            </div>
          </div>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div :class="[color[headInfo.southSystem]]" class="ma-1 pa-3">
            <div class="text-sm-h6 text-body-1">南部</div>
            <div class="text-sm-body-2 text-caption">
              {{ headInfo.southSystem }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';

export default {
  components: {
    BarCard,
    BarCardTab,
  },
  data: () => ({
    loading: false,
    headquarter: {
      status: {},
    },
    color: {
      未設置: '',

      情報収集: 'yellow lighten-1',
      災害対策準備室: 'red white--text',
      災害対策連絡室: 'purple white--text',

      '１次体制': 'black white--text',
      '２次体制': 'black white--text',

      警戒本部体制: 'black white--text',

      廃止: 'grey white--text',

      '応援体制（外部組織）': 'pink darken-4 white--text',
    },
  }),
  computed: {
    headInfo() {
      return {
        honbuSystem: this.headquarter?.status?.System__c || '未設置',
        northSystem: this.headquarter?.status?.NorthSystem__c || '未設置',
        southSystem: this.headquarter?.status?.SouthSystem__c || '未設置',
      };
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await this.getStat();
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 情報の取得
    async getStat() {
      const res = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getHeadquarterStat',
        params: {
          disasterId: this.$store.state.disaster.disaster.Id,
        },
      });
      if (res) {
        this.$set(this.headquarter, 'status', res);
      }
    },

    handleClickBar() {
      this.$transition.to('CDS_VF_HeadquarterList', null, {
        notApplyRetUrl: true,
      });
    },
  },
};
</script>
