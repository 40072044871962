<template>
  <v-list>
    <v-subheader>レイヤー情報</v-subheader>
    <v-list-item-group
      v-model="selectedLayerIndex"
      return-object
      active-class="border"
      color="indigo"
      @change="loadLayerData"
    >
      <v-list-item v-for="(item, i) in layerItems" :key="i">
        <v-list-item-content>
          <v-list-item-title v-text="item.Label" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-subheader>描画データ</v-subheader>
    <v-list-item-group
      v-model="selectedPaintIndex"
      return-object
      active-class="border"
      color="indigo"
      @change="loadDrawData"
    >
      <v-list-item v-for="(item, i) in paintItems" :key="i">
        <v-list-item-content>
          <v-list-item-title v-text="item.Name" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import util from '../../../util/paint';

export default {
  data: (vm) => ({
    selectedLayerIndex: {},
    selectedPaintIndex: {},
    layerItems: [],
    paintItems: [],
    drawingManager: {},
    showKmlLayer: [],
  }),
  props: {},
  computed: {},
  async mounted() {
    const { map } = this.$store.state.GIS;
    this.paintItems = await util.loadDrawDataList(this);
    this.drawingManager = new CustomDrawingManger({
      map,
      usePopupWindow: true,
    });
    await this.loadLayerDataList();
  },
  methods: {
    /**
     * 描画データ取得
     */
    async loadDrawData(index) {
      if (this.paintItems[index]) {
        const drawData = await util.loadDrawData(this, this.paintItems[index]);
        this.drawingManager.loadJSON(drawData.json);
        this.drawingManager.fitBounds();
      } else {
        this.drawingManager.clear();
      }
    },
    /**
     * レイヤーデータ一覧取得
     */
    async loadLayerDataList() {
      const { controller } = this.$pageProperty;
      this.layerItems = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'getLayerDataList',
          params: [],
        }),
      );
    },
    async loadLayerData(index) {
      const { controller } = this.$pageProperty;
      const { map } = this.$store.state.GIS;
      const selectedLayer = this.layerItems[index];
      // 現在表示されているものは解除する
      this.showKmlLayer.forEach((kmlLayer) => kmlLayer.setMap(null));
      this.showKmlLayer = [];
      // 選択されていれば表示処理を行う
      if (selectedLayer) {
        this.showKmlLayer = selectedLayer.URL__c.split(';').map(
          (url) =>
            new google.maps.KmlLayer({
              map,
              url,
              clickable: false,
              preserveViewport: true,
            }),
        );
        this.showKmlLayer.forEach((kml) => console.log(kml, kml.getUrl()));
      }
    },
  },
  components: {},
};
</script>
<style></style>
