<template>
  <v-navigation-drawer
    app
    clipped
    permanent
    :mini-variant.sync="mini"
    width="320"
    :style="{ top: $vuetify.breakpoint.xs ? '133px' : '96px' }"
  >
    <v-row no-gutters class="fill-height">
      <!-- メニュー大階層表示 -->
      <v-navigation-drawer
        clipped
        permanent
        class="blue lighten-3"
        width="320"
        :mini-variant="!_.isEmpty(selected)"
        dark
      >
        <v-list-item class="px-2">
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider />

        <v-list-item
          v-for="item in items"
          :key="item.component"
          dense
          @click="selected = item"
        >
          <v-list-item-action>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>
      <!-- メニュー小階層表示 -->
      <v-list
        v-show="!_.isEmpty(selected) && !mini"
        class="grow"
        dense
        width="180"
      >
        <!-- ヘッダ -->
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="selected.color">{{ selected.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ selected.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon x-small @click="selected = {}">
              <v-icon color="grey lighten-1"> mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <!-- メニュー内容 -->
        <keep-alive>
          <component :is="selected.component" v-bind="selected" />
        </keep-alive>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import DynamicData from './items/DynamicData';
import StaticData from './items/StaticData';
import Search from './items/Search';
import Layer from './items/Layer';
import HazardMap from './items/HazardMap';
import Paint from './items/Paint';
export default {
  components: { DynamicData, StaticData, Search, Layer, HazardMap, Paint },
  data: (vm) => ({
    mini: false,
    selected: {},
    items: [
      {
        icon: 'mdi-fire',
        title: '動的情報',
        component: 'DynamicData',
        color: 'red',
      },
      {
        icon: 'mdi-bank',
        title: '静的情報',
        component: 'StaticData',
        color: 'deep-purple',
      },
      {
        icon: 'mdi-palette',
        title: '描画モード',
        component: 'Paint',
        color: 'green',
      },
      {
        icon: 'mdi-layers-triple',
        title: 'レイヤー',
        component: 'Layer',
        color: 'orange',
      },
      {
        icon: 'mdi-map-legend',
        title: 'ハザードマップ',
        component: 'HazardMap',
        color: 'red',
      },
      {
        icon: 'mdi-map-search',
        title: '位置検索',
        component: 'Search',
        color: 'blue',
      },
    ],
  }),
  computed: {},
  methods: {},
};
</script>
<style></style>
