var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('div',[_c('ListView',_vm._b({attrs:{"search-props":{
        searchConditionToUrlParameter: true,
        defaultSearchCondition: _vm.defaultSearchCondition,
      },"table-props":{
        defaultListOptions: _vm.defaultListOptions,
        csvFileNameFormat: 'ユーザ一覧-%date%.csv',
        csvFieldSetName: 'CSVFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
      }}},'ListView',{
        objectName: 'User',
        detailLayoutName: 'SelfEditLayout',
        detailPageName: 'CDS_VF_UserDetail',
      },false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }