var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      viewAttachment: true,
      viewComment: !_vm.le,
      inputFieldSetName: 'ListInputFieldSet,ListFieldSet',
      listFieldSetName: 'FixListFieldSet,ListFieldSet',
      defaultInputFieldSetName: 'ListInputFieldSet,DefaultListFieldSet',
      defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
      bulkFieldSetName: 'BulkFieldSet',
      csvFieldSetName: 'ListInputFieldSet,FixListFieldSet,ListFieldSet',
      listSaveValidate: _vm.validate,
    },"search-props":{
      syncConditionWithUrl: true,
      applyErrataToCondition: true,
      defaultSearchCondition: _vm.defaultSearchCondition,
    },"google-map-props":{
      gisDynamicSettingName: 'GIS_C_D_DamageReport',
    }},scopedSlots:_vm._u([{key:"listimage.default",fn:function({object}){return [_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("事案名称")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(object.Name)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-card-account-details")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("事案概要")]),_c('v-list-item-subtitle',{staticClass:"overflow-auto",staticStyle:{"white-space":"pre-wrap","max-height":"300px"}},[_vm._v(" "+_vm._s(object.Detail__c)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("事案発生日時")]),_c('v-list-item-subtitle',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm._f("datetimesec")(object.HappenDate__c))+" ")])],1)],1)],1),_c('v-divider')]}}])},'ListView',{
      objectName: 'CDS_T_DamageReport__c',
      detailPageName: 'CDS_VF_DamageReportDetail',
      showImageTab: true,
      showMapTab: true,
      isDisasterRelated: true,
      hasVersion: true,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }