<template>
  <h2>
    <v-avatar size="60">
      <img alt="Logo" :src="icon" class="mr-2" />
    </v-avatar>
    {{ title }}
  </h2>
</template>

<script>
import constant from '@/assets/js/constant';

export default {
  data: () => ({
    title: constant.systemName,
    icon: constant.systemIcon,
  }),
};
</script>
