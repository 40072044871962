import Vue from 'vue';

/**
 * グローバル定数を定義する場所
 */

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$gc = this;
    Vue.gc = this;
  },

  // 運用の管理者
  operationMaster: ['災害対策本部事務局'],
};

Vue.use(plugin);
export default plugin;
