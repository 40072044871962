<template>
  <div>
    <v-row v-if="slotProps.editMode" justify="center" class="ma-0">
      <v-col cols="6" md="3" lg="2" class="pa-3">
        <v-badge
          color="deep-purple accent-4"
          icon="fa-check"
          bordered
          overlap
          style="width: 100%;"
          class="d-block"
          :value="damageFlag"
        >
          <v-btn
            dark
            block
            x-large
            :class="['red', 'text-center', 'text-h5']"
            @click="onClickDamaged()"
          >
            被害あり
          </v-btn>
        </v-badge>
      </v-col>
      <v-col cols="6" md="3" lg="2" class="pa-3">
        <v-badge
          color="deep-purple accent-4"
          icon="fa-check"
          bordered
          overlap
          style="width: 100%;"
          class="d-block"
          :value="!damageFlag"
        >
          <Confirm
            v-if="!disable.includes('cancel')"
            message="被害状況等の入力欄に被害なしとしてすべて0を設定します。（すでに値が入っている場合は0で上書きされます。）よろしいですか？"
            @on-click-yes="onClickNoDamage()"
          >
            <template #default="{ on }">
              <v-btn
                dark
                block
                x-large
                :class="['green', 'text-center', 'text-h5']"
                @click="on.click"
              >
                被害なし
              </v-btn>
            </template>
          </Confirm>
        </v-badge>
      </v-col>
    </v-row>
    <Layout
      v-model="_value"
      name="input_form"
      :layout="layout"
      :object-info="objectInfo"
      :edit-mode="slotProps.editMode"
      :not-show-section-names="computedHiddenSectionNames"
      :custom-validations="customValidations"
      @submit-raw="slotProps.validate"
    />
  </div>
</template>

<script>
import Layout from '@/components/detail/Layout';
import Confirm from '@/components/common/Confirm';

export default {
  components: {
    Layout,
    Confirm,
  },
  props: {
    slotProps: {
      type: Object,
      default: () => ({}),
    },
    // 無効なボタンリスト
    disable: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    // 表示レイアウト情報
    layout: {},
    // 表示オブジェクト情報
    objectInfo: {},
    //被害フラグ
    damageFlag: true,
    //数値オブジェクトリスト
    numObjectList: {},
    // 非表示固定セクション名
    notShowSectionNames: [],
    // 被害なしのときも表示するセクション名
    alwaysAppearSectionNames: ['入力者情報', '被害状況'],
    // カスタムバリデート
    customValidations: [
      {
        fieldName: 'DisasterRelatedNum__c',
        value: {
          validation: ['val01'],
          validationRules: {
            val01: ({ getFormValues }) => {
              const values = getFormValues();
              const { DeadNum__c, DisasterRelatedNum__c } = values;
              if (
                _.isNil(DeadNum__c) ||
                DeadNum__c === '' ||
                _.isNil(DisasterRelatedNum__c) ||
                DisasterRelatedNum__c === ''
              ) {
                return true;
              }
              return Number(DeadNum__c) >= Number(DisasterRelatedNum__c);
            },
          },
          validationMessages: {
            val01:
              '「うち災害関連死者数」は「死者数」を超えないように入力してください。',
          },
        },
      },
    ],
  }),

  computed: {
    _value: {
      get() {
        return this.slotProps.value;
      },
      set(v) {
        this.slotProps.change(v);
      },
    },
    // 訂正セクションを表示するか
    appearTeiseiSection() {
      return !this.slotProps.editMode || !!this._value.Id;
    },
    // 非表示セクション
    computedHiddenSectionNames() {
      return [
        ...new Set([
          ...this.notShowSectionNames,
          ...(this.appearTeiseiSection ? [] : ['訂正']),
        ]),
      ];
    },
  },

  watch: {
    'slotProps.editMode': function (newVal) {
      // 編集から確認画面へ遷移した際、すべてのセクションを表示
      if (!newVal) {
        this.notShowSectionNames = [];
      }
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadData(), this.loadLayout()]),
      );
    },

    // レイアウトの読み込み
    async loadLayout() {
      const { layoutName, objectName } = this.slotProps;
      const { layout, objectInfo } = await this.$util.getLayout(
        objectName,
        layoutName,
      );
      this.$set(this, 'layout', layout);
      this.$set(this, 'objectInfo', objectInfo);
    },
    //データの読み込み
    async loadData() {
      //数値入力項目セット取得
      const { fieldSet } = await this.$util.getFieldSet(
        this.slotProps.objectName,
        'NumObjectFieldSet',
      );
      this.numObjectList = fieldSet;
    },

    onClickDamaged() {
      if (!this.damageFlag) {
        this.notShowSectionNames = [];
        this.damageFlag = true;
      }
    },
    onClickNoDamage() {
      if (this.damageFlag) {
        const hideSectionNames = this.layout.sections
          .filter((section) => {
            const { label } = section;
            // 訂正は別で制御するので除外
            if (label === '訂正') return false;
            // 非表示セクションのみを取り出し
            return !this.alwaysAppearSectionNames.includes(label);
          })
          .map((section) => section.label);

        // 非表示セクションを設定
        this.notShowSectionNames = hideSectionNames;

        this.assignZero(this._value);
        this.damageFlag = false;
      }
    },
    // ゼロを代入
    assignZero(val) {
      this.numObjectList.fields.forEach((obj) => {
        this.$set(val, obj.fieldPath, 0);
      });
    },
  },
};
</script>
