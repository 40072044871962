<template functional>
  <div
    :class="`announce-type-ward-cell d-flex align-center justify-center ${
      $options.computedStatus(props).class
    }`"
  >
    <div class="announce-type-ward-cell-inner text-center">
      {{ $options.computedStatus(props).text }}
    </div>
  </div>
</template>

<script>
function computedDeclareNumber(ward, announceTypeName) {
  const declareTargetAreas = ward.children.filter(
    (area) =>
      area.announceTypeName === announceTypeName && area.orderStatus === '発令',
  );
  return {
    target: declareTargetAreas.length,
    total: ward.children.length,
  };
}

export default {
  name: 'AnnounceTypeWardCell',

  props: {
    ward: {
      type: Object,
      required: true,
    },
    announceTypeName: {
      type: String,
      required: true,
    },
  },

  computedStatus(props) {
    const { ward, announceTypeName } = props;
    const status = computedDeclareNumber(ward, announceTypeName);
    if (status.total > 0) {
      // 全域
      if (status.target === status.total) {
        return {
          text: '全域',
          class: 'announce-all',
        };
      } else if (status.target > 0) {
        return {
          text: `一部発令(${status.target}/${status.total})`,
          class: 'announce-part',
        };
      }
    }
    return {};
  },
};
</script>

<style lang="scss" scoped>
$color-pack: false;

@import '~vuetify/src/styles/main.sass';

// 外側のセル
.announce-type-ward-cell {
  height: 100%;
}
// 全域
.announce-all {
  background: map-get($pink, lighten-4);
}
// 一部発令
.announce-part {
  background: map-get($orange, lighten-5);
}
</style>
