<template>
  <div class="fill-height pt-2">
    <v-tabs v-model="tab" class="fill-height">
      <!-- 検索ボタン -->
      <slot name="search">
        <Search v-bind="computedSearchProps" @search="handleSearch" />
      </slot>
      <v-tab
        v-for="{ tableProps: tp, icon, label } in computedTableProps"
        :key="`lifelinelisttab-${tp.objectName}`"
        :disabled="list.editMode"
      >
        <v-icon left>
          {{ icon }}
        </v-icon>
        {{ label }}
      </v-tab>
      <!-- tab追加用 -->
      <v-tab
        v-for="slotName of slots.tab"
        :key="slotName"
        :disabled="list.editMode"
      >
        <slot :name="`tab.${slotName}`" v-bind="{ list, search }"></slot>
      </v-tab>

      <v-tabs-items v-model="tab" touchless style="height: calc(100% - 100px);">
        <v-tab-item
          v-for="{ tableProps: tp, urls } in computedTableProps"
          :key="`lifelinelisttabitem-${tp.objectName}`"
          class="fill-height"
          eager
        >
          <div v-if="urls && urls.length > 0" class="pa-2 overflow-x-auto">
            <v-card elevation="4" class="d-inline-block">
              <v-card-text class="text--primary py-3 px-4">
                <span class="mx-1">最新情報</span>
                <v-icon small class="mx-1">
                  mdi-arrow-right-thick
                </v-icon>
                <v-chip
                  v-for="url in urls"
                  :key="`${url.label}${url.value}`"
                  :href="url.value"
                  target="_blank"
                  class="mx-1"
                  color="orange lighten-2"
                  link
                >
                  {{ url.label }}
                </v-chip>
              </v-card-text>
            </v-card>
          </div>
          <ListTable
            v-if="Object.keys(objectInfo).length > 0"
            v-bind="tp"
            @update:editMode="handleUpdateEditMode"
            @update:invoke-request="handleUpdateInvokeRequest"
            @click-row="handleClickRow(tp.objectName, $event)"
            @load-completed="$emit('load-completed', $event, tp.objectName)"
          >
            <template
              v-for="slotName of slots.datatable"
              #[`datatable.${slotName}`]="props"
            >
              <slot :name="`datatable.${slotName}`" v-bind="props"></slot>
            </template>
          </ListTable>
        </v-tab-item>
        <!-- tab追加用 -->
        <v-tab-item
          v-for="slotName of slots.tabitem"
          :key="slotName"
          class="fill-height"
        >
          <slot :name="`tabitem.${slotName}`" v-bind="{ list, search }"></slot>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <!-- 一覧編集 -->
    <template v-for="detailProps in computedDetailProps">
      <Detail
        v-if="
          detailProps.detailLayoutName &&
          detailProps.objectName === editObjectName
        "
        :key="`lifelinelistdetail-${detailProps.objectName}`"
        v-model="detail"
        v-bind="detailProps"
      />
    </template>
  </div>
</template>

<script>
import { getSlots } from '@/components/list/util';
import Detail from '@/components/list/Detail.vue';
import Search from '@/components/list/search/index.vue';
import ListTable from '@/components/list/ListTable.vue';
import ListImage from '@/components/list/ListImage.vue';
import GoogleMap from '@/components/list/GoogleMap.vue';

export default {
  name: 'ListView',
  components: {
    Search,
    Detail,
    ListTable,
    ListImage,
    GoogleMap,
  },
  props: {
    /********** 共通 **********/
    // オブジェクト設定
    objectSettings: { type: Array, required: true },
    // 災害関連
    isDisasterRelated: { type: Boolean, default: false },
    // 一覧コンポーネント名(一意な英数字)
    listName: { type: String, default: null },
    // バージョン情報を持っているか
    hasVersion: { type: Boolean, default: false },

    /********** 詳細 **********/
    // 詳細ページ名
    detailPageName: { type: String, default: null },
    // 詳細編集レイアウト名
    detailLayoutName: { type: String, default: null },

    /********** 一覧 **********/
    // テーブルに渡すプロパティ
    tableProps: { type: Object, default: () => ({}) },
    /********** 検索 **********/
    // 検索に渡すプロパティ
    searchProps: { type: Object, default: () => ({}) },
  },
  data: () => ({
    tab: null,

    objectInfo: {},

    // 検索
    search: null,

    // リスト
    list: {
      editMode: false,
      invokeRequest: null,
    },

    // 詳細
    detail: {
      dialog: false,
      item: null,
    },
    editObjectName: null,
  }),
  computed: {
    // listEdit
    le() {
      return this.hasVersion ? this.$query.current().le === 'true' : undefined;
    },

    // テーブルに渡すプロパティ
    computedTableProps() {
      return this.objectSettings.map((v) => {
        const {
          objectName,
          listName,
          detailPageName,
          tableProps = {},
          ...settings
        } = v;
        return {
          ...settings,
          tableProps: {
            detailPageName: detailPageName || this.detailPageName,
            listName: listName || this.listName,
            ...this.tableProps,
            ...tableProps,
            objectName,
            objectInfo: this.objectInfo[objectName] || {},
            searchData: this.search,
            le: this.le,
            hasVersion: this.hasVersion,
          },
        };
      });
    },

    // 詳細に渡すプロパティ
    computedDetailProps() {
      return this.objectSettings
        .map(({ objectName, detailLayoutName }) => {
          return {
            objectName,
            objectInfo: this.objectInfo[objectName] || {},
            detailLayoutName: detailLayoutName || this.detailLayoutName,
          };
        })
        .filter((v) => !!v.detailLayoutName);
    },

    // 検索に渡すプロパティ
    computedSearchProps() {
      return {
        ...this.searchProps,
        objectName: this.objectSettings[0].objectName,
        disabled: this.list.editMode,
        listName: this.listName,
        le: this.le,
      };
    },

    // スロット
    slots() {
      return ['tab', 'tabitem', 'datatable', 'listimage'].reduce(
        (prev, next) => {
          return {
            ...prev,
            [next]: getSlots(next, this.$slots, this.$scopedSlots),
          };
        },
        {},
      );
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.loadObjectInfo();
    },

    /********** 初期 **********/
    async loadObjectInfo() {
      const objsInfo = await Promise.all(
        this.objectSettings.map(async ({ objectName }) => {
          if (!objectName) return {};
          return {
            objectName,
            objectInfo: await this.$util.getObjectInfo(objectName),
          };
        }),
      );
      this.$set(
        this,
        'objectInfo',
        objsInfo.reduce((prev, { objectName, objectInfo }) => {
          return {
            ...prev,
            [objectName]: objectInfo,
          };
        }, {}),
      );
    },

    /********** 検索 **********/
    // 検索ボタンが押された時
    async handleSearch(searchData) {
      this.$set(this, 'search', searchData);
    },

    /********** 共通 **********/

    // editModeが変更された時
    handleUpdateEditMode(editMode) {
      this.list.editMode = editMode;
    },

    // 行がクリックされた時
    handleClickRow(objectName, item) {
      if (this.list.editMode) {
        this.$set(this, 'editObjectName', objectName);
        this.$nextTick(() => {
          this.$set(this.detail, 'item', item);
          this.$set(this.detail, 'dialog', true);
        });
      } else {
        this.toDetail(objectName, item);
      }
    },

    // リクエストが更新された時
    handleUpdateInvokeRequest(invokeRequest) {
      this.$set(this.list, 'invokeRequest', invokeRequest);
    },

    /********** 詳細 **********/

    // 詳細に遷移
    toDetail(objectName, item) {
      if (item.Id) {
        this.transitionDetailPage(objectName, {
          id: item.Id,
        });
      }
    },

    // 詳細ページに移動
    transitionDetailPage(objectName, options) {
      const detailPageName = this.objectSettings.find(
        (v) => v.objectName === objectName,
      )?.detailPageName;
      if (detailPageName) {
        this.$transition.to(detailPageName, options);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
