export const OpenStatus__c = {
  準備中: {
    labelClass: 'grey lighten-1',
    textClass: 'grey--text',
    chartColor: '#BDBDBD',
  },
  応急危険度判定: {
    labelClass: 'red accent-2 white--text',
    textClass: 'red--text text--accent-2',
    chartColor: '#FF5252',
  },
  開設: {
    labelClass: 'orange white--text',
    textClass: 'orange--text',
    chartColor: '#FF9800',
  },
  閉鎖: {
    labelClass: 'light-green white--text',
    textClass: 'light-green--text',
    chartColor: '#8BC34A',
  },
  利用不可: {
    labelClass: 'deep-purple darken-3 white--text',
    textClass: 'deep-purple--text text--darken-3',
    chartColor: '#4527A0',
  },
};
