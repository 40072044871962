<template>
  <detail
    use-version
    object-name="CDS_T_JMAInfo__c"
    layout-name="JMAInfoLayout"
    default-title="気象庁等発表情報詳細"
    subtitle="気象庁等発表情報詳細"
    saved-page="CDS_VF_JMAInfoDetail"
    detail-page-name="CDS_VF_JMAInfoDetail"
    list-page="CDS_VF_JMAInfoList?le=true"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    :load-master="loadMaster"
    :disable-actions="['remove']"
  >
    <template #underHeader="{editMode,isFixed,value}">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="editMode ? 1 : isFixed ? 3 : 2"
      >
        <v-stepper-header>
          <v-stepper-step step="1">
            入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">
            確定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            完了
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import JMATypeLavel from '@/components/app/JMATypeLavel.vue';
import { infoType } from '@/assets/js/objects/CDS_T_JMAInfo__c';

export default {
  components: {
    detail,
    JMATypeLavel,
  },

  provide: {
    listItem: {
      detail_history: infoType.map(({ fieldPath, color, dark }) => ({
        slotName: `item.${fieldPath}`,
        component: JMATypeLavel,
        color,
        dark,
      })),
    },
  },

  computed: {
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return {
        ...this.$util.getDefaultEveryOverwriteValue(this.$store.state),
        ReportDate__c: null,
      };
    },
  },

  methods: {
    /**
     * マスタデータを読み出す
     */
    async loadMaster() {
      const { controller } = this.$pageProperty;
      const { id, mid } = this.$query.current() || {};
      if (!id) {
        if (mid) {
          //マスタデータ読み出し
          const { object: master } = await this.$con.invoke({
            controller,
            method: 'getData',
            params: {
              id: mid,
              objectName: 'CDS_M_JMAInfo__c',
            },
          });
          if (master) {
            return {
              ...master,
              CDS_M_JMAInfo__r: master,
              CDS_M_JMAInfo__c: master.Id,
            };
          }
        }
      }
      return {};
    },
  },
};
</script>
