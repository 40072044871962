var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"search-props":{
      syncConditionWithUrl: true,
      showBaseDatetime: !_vm.le,
      defaultSearchCondition: _vm.defaultSearchCondition,
    },"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      createButtonName: '臨時活動拠点',
      masterFieldName: 'CDS_M_ActivityBase__c',
      listSaveValidate: _vm.validate,
      viewAttachment: true,
      viewComment: !_vm.le,
      inputFieldSetName: 'ListInputFieldSet,ListFieldSet',
      listFieldSetName: 'FixListFieldSet,ListFieldSet',
      defaultInputFieldSetName: 'ListInputFieldSet,DefaultListFieldSet',
      defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
      bulkFieldSetName: 'BulkFieldSet',
      csvFieldSetName: 'ListInputFieldSet,FixListFieldSet,ListFieldSet',
      applyMaxFields: !_vm.le,
    },"google-map-props":{
      gisDynamicSettingName: 'GIS_C_D_ActivityBase',
    }}},'ListView',{
      objectName: 'CDS_T_ActivityBase__c',
      detailPageName: 'CDS_VF_ActivityBaseDetail',
      isDisasterRelated: true,
      showImageTab: true,
      showMapTab: true,
      hasVersion: true,
      masterObjectName: 'CDS_M_ActivityBase__c',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }