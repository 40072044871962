<template>
  <router-view />
</template>

<script setup>
import { getCurrentInstance, onMounted } from 'vue';

const instance = getCurrentInstance();

/**
 * プラグインを取得する
 */
function usePlugin(name) {
  if (!instance) {
    throw new Error(`Should be used in setup().`);
  }
  return instance.proxy[name];
}

const store = usePlugin('$store');
const router = usePlugin('$router');
const con = usePlugin('$con');
const transition = usePlugin('$transition');

const registerMenuDataToStore = async () => {
  // メニューデータを読み込み
  const { menuitem, menusection } = await con.invoke({
    controller: 'CDS_CTR_Common',
    method: 'getMenuData',
    params: {},
  });
  store.dispatch('menu/setMenuData', { menuitem, menusection });
};
const registerLinkCollectionToStore = async () => {
  // メニューデータを読み込み
  const data = await con.invoke({
    controller: 'CDS_CTR_Common',
    method: 'getLinkCollection',
    params: {},
  });
  store.dispatch('menu/setLinkCollection', data);
};

// ストアをtransitionに登録(loadingをtransitionから呼び出すため)
const registerStoreToTransition = () => {
  transition._store = store;
};

// routerに登録
const registerRouter = () => {
  // 編集中のブラウザバック時にダイアログを表示
  // → 通常の保存処理にもダイアログを表示される場合があるため一旦コメントアウト
  // router.beforeEach((to, from, next) => {
  //   console.log('store.state.detail.editing: ' + store.state.detail.editing);
  //   if (store.state.detail.editing) {
  //     const answer = window.confirm(
  //       '入力内容を破棄してページを離れてもよろしいですか？',
  //     );
  //     if (answer) {
  //       store.dispatch('detail/setEditing', false);
  //       return next();
  //     } else {
  //       return next(false);
  //     }
  //   }
  //   next();
  // });
  // ページ遷移時にloadingさせる
  router.beforeEach((to, from, next) => {
    store.dispatch('loading/setTransition', true);
    next();
  });
  router.afterEach(() => {
    // メニューのoverlayを非表示にする
    store.dispatch('menu/clearActiveMenues');
    // ページ遷移後にローディングを解除する
    store.dispatch('loading/setTransition', false);
  });
};

registerStoreToTransition();
registerRouter();

onMounted(() => {
  registerMenuDataToStore();
  registerLinkCollectionToStore();
});
</script>
