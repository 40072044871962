export const objectSettings = [
  {
    objectName: 'CDS_T_LifelineElectricity__c',
    icon: 'mdi-lightning-bolt',
    label: '停電情報',
    detailPageName: 'CDS_VF_LifelineElectricityDetail',
    listName: 'LifelineElectricityList',
    urls: [
      {
        label: '中部電力HP',
        value: 'https://www.chuden.co.jp/',
      },
    ],
  },
  {
    objectName: 'CDS_T_LifelineRailway__c',
    icon: 'mdi-train',
    label: '鉄道',
    detailPageName: 'CDS_VF_LifelineRailwayDetail',
    listName: 'LifelineRailwayList',
    urls: [
      {
        label: 'JR東海HP',
        value: 'http://jr-central.co.jp/',
      },
      {
        label: '遠鉄HP',
        value: 'http://www.entetsu.co.jp/',
      },
      {
        label: '天竜浜名湖鉄道HP',
        value: 'https://www.tenhama.co.jp/',
      },
    ],
  },
  {
    objectName: 'CDS_T_LifelineWater__c',
    icon: 'mdi-water',
    label: '水道・下水',
    detailPageName: 'CDS_VF_LifelineWaterDetail',
    listName: 'LifelineWaterList',
  },
  {
    objectName: 'CDS_T_LifelineNetwork__c',
    icon: 'mdi-antenna',
    label: '通信',
    detailPageName: 'CDS_VF_LifelineNetworkDetail',
    listName: 'LifelineNetworkList',
    urls: [
      {
        label: 'NTT西日本HP',
        value: 'https://www.ntt-west.co.jp/',
      },
    ],
  },
  {
    objectName: 'CDS_T_LifelineGas__c',
    icon: 'mdi-fire',
    label: '都市ガス',
    detailPageName: 'CDS_VF_LifelineGasDetail',
    listName: 'LifelineGasList',
    urls: [
      {
        label: '都市ガスHP',
        value: 'https://www.salaenergy.co.jp/',
      },
    ],
  },
  {
    objectName: 'CDS_T_LifelineLPGas__c',
    icon: 'mdi-gas-cylinder',
    label: 'LPガス',
    detailPageName: 'CDS_VF_LifelineLPGasDetail',
    listName: 'LifelineLPGasList',
    urls: [
      {
        label: '静岡県LPガス協会HP',
        value: 'http://www.shizuokalpg.or.jp/',
      },
    ],
  },
];
