<template>
  <detail
    object-name="User"
    layout-name="DetailLayout"
    default-title=""
    :subtitle="$pageProperty.title"
    saved-page="CDS_VF_UserDetail"
    detail-page-name="CDS_VF_UserDetail"
    disable-attachment
    :list-page="$transition.fallbackPage"
    :default-hidden-field-names="['UserId__c']"
    :disable-actions="['remove', 'attachment']"
    :override="{ validate }"
  >
    <template #DetailMenus="{value, editMode}">
      <change-password v-if="!editMode" :username="value.Username" />
      <SignOut v-if="!editMode" :username="value.Username" />
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import changePassword from './changePassword.vue';
import SignOut from './signOut.vue';
import { disaster } from '@/beforeGuard/defaultRules';

export default {
  components: {
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        /**
         * データ読み出しをオーバラライド
         */
        _loadData: detail.methods.loadData,
        loadData: async function () {
          const query = this.$query.current();
          // ID未指定で新規作成でない場合は自ユーザのIDを指定
          if (!query.id && query.mode !== 'n') {
            this.$query.updateHistoryQuery({
              query: {
                ...this.$query.current(),
                id: this.$store.state.user.user.Id,
              },
            });
          }
          // 通常読み出しを実行
          await this._loadData();
        },
      },
    },
    changePassword,
    SignOut,
  },

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },

  methods: {
    validate({ value }) {
      if (!value?.Username?.match(/^[0-9a-zA-Z]*$/)) {
        throw new Error('ユーザ名は半角英数字を入力してください。');
      }
    },
  },
};
</script>
