<template>
  <v-row no-gutters>
    <v-col>
      <v-tabs v-model="tab" background-color="rgba(255,255,255,0)">
        <v-tab v-for="area in tabValues" :key="area.name">
          {{ area.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="area in tabValues" :key="area.name">
          <v-data-table
            :headers="area.headers"
            :items="records"
            :items-per-page="10"
            disable-sort
            hide-default-footer
            dense
            class="cdsTable"
            :mobile-breakpoint="0"
          >
            <template #body.append="{headers}">
              <tr class="total-row">
                <td
                  v-for="header of headers"
                  :key="`body-append-${header.value}`"
                  :class="header.value === 'ward' ? [] : ['text-end']"
                >
                  {{ total[header.value] }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    updateEvent: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    tab: null,
    tabValues: [
      {
        name: '要救助者数',
        value: 'SurvivorNum',
        headers: [
          {
            text: '区',
            value: 'ward',
          },
          {
            text: '要救助者数',
            value: 'survivor',
            cellClass: 'text-end',
          },
        ],
      },
      {
        name: '人的被害',
        value: 'HumanDamage',
        headers: [
          {
            text: '区',
            value: 'ward',
          },
          {
            text: '死者数',
            value: 'dead',
            cellClass: 'text-end',
          },
          {
            text: '負傷者数',
            value: 'injury',
            cellClass: 'text-end',
          },
          {
            text: '行方不明数',
            value: 'lost',
            cellClass: 'text-end',
          },
        ],
      },
      {
        name: '住家被害（棟）',
        value: 'HouseDamage',
        headers: [
          {
            text: '区',
            value: 'ward',
          },
          {
            text: '全壊',
            value: 'completeBldg',
            cellClass: 'text-end',
          },
          {
            text: '半壊',
            value: 'halfBldg',
            cellClass: 'text-end',
          },
          {
            text: '一部破損',
            value: 'partialBldg',
            cellClass: 'text-end',
          },
          {
            text: '床上浸水',
            value: 'aboveFloorBldg',
            cellClass: 'text-end',
          },
          {
            text: '床下浸水',
            value: 'belowFloorBldg',
            cellClass: 'text-end',
          },
        ],
      },
    ],
    records: [],
    total: {},
  }),
  computed: {},
  watch: {
    updateEvent: {
      handler() {
        this.init();
      },
      deep: true,
    },
    loading(to) {
      this.$emit('loading', to);
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await Promise.all([this.getStat()]);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 情報の取得
    async getStat() {
      const { records = [], total = {} } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getDamageStateStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};
      if (records) {
        this.records = records;
      }
      if (total) {
        this.$set(this, 'total', total);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.total-row {
  td {
    color: white;
    background: #78909c !important;
    font-weight: bold;
  }
}
</style>
