import App from './App.vue';

export default {
  path: '/CDS_VF_No4_1Detail',
  name: 'CDS_VF_No4_1Detail',
  component: App,
  meta: {
    pageProperty: {
      title: '被害即報詳細',
      controller: 'CDS_CTR_No4_1Detail',
    },
  },
};
