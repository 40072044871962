<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_Request__c',
        detailPageName: 'CDS_VF_RequestDetail',
        isDisasterRelated: true,
        showImageTab: true,
        showMapTab: true,
        hasVersion: true,
      }"
      :search-props="{
        syncConditionWithUrl: true,
        applyErrataToCondition: true,
        forceCondition,
        changeObjectInfo,
      }"
      :table-props="{
        inputFieldSetName: 'ListFieldSet',
        canEdit: false,
        canCreate: !rep,
        defaultListOptions,
        viewAttachment: true,
      }"
      :google-map-props="{
        gisDynamicSettingName: 'GIS_C_D_Request',
      }"
    >
      <template #datatable.item.RequestStatus__c="props">
        <RequestStatusLabel :value="props.value" />
      </template>
      <template #datatable.item.CDS_M_Organization__c="props">
        <div class="request-destination-organization">
          <div class="request-destination-organization-inner">
            {{ orgDisplay(props.value) }}
          </div>
        </div>
      </template>
      <template #datatable.item.DestinationOrganization__c="props">
        <div class="request-destination-organization">
          <div class="request-destination-organization-inner">
            {{ orgDisplay(props.value) }}
          </div>
        </div>
      </template>
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import RequestStatusLabel from '@/components/app/RequestStatusLabel';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView: {
      ...ListView,
      components: {
        ...ListView.components,
        Search: {
          ...ListView.components.Search,
          methods: {
            ...ListView.components.Search.methods,
            _loadLayout: ListView.components.Search.methods.loadLayout,
            loadLayout: async function () {
              await this._loadLayout();
              if (!this.le) {
                // 確定情報を閲覧する場合は要請前を除外する
                const fieldInfo = this.search.objectInfo.properties
                  .RequestStatus__c;
                fieldInfo.picklistValues = fieldInfo.picklistValues.filter(
                  ({ value }) => value !== '要請前',
                );
              }
            },
          },
        },
      },
    },
    RequestStatusLabel,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['RequestDate__c'],
      sortDesc: [true],
    },
    organizations: [],
  }),

  computed: {
    le() {
      return this.$query.current().le === 'true';
    },
    rep() {
      return this.$query.current().rep === 'true';
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await Promise.all([this.loadOrganization()]);
    },

    async loadOrganization() {
      // 組織マスタの取得
      const res = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getDestinationOrganizationList',
      });
      this.organizations = res;
    },

    async changeObjectInfo({ objectInfo }) {
      return {
        ...objectInfo,
        properties: {
          ...objectInfo.properties,
          // 要請先組織をリレーション仕様に上書き
          DestinationOrganization__c: {
            label: '要請先組織',
            helpText: '',
            type: 'reference',
            isId: true,
            $ref: '#/definitions/CDS_M_Organization__c',
          },
        },
      };
    },

    // 検索条件の追加等
    async forceCondition({ searchObject, targetCondition, le }) {
      let result = structuredClone(searchObject);
      // 入力モードのときは、自組織が作成もしくは提供先に指定されている場合
      if (le) {
        result.AND = [
          ...(result.AND || []),
          // 自組織または提供先に所属している場合 という条件を追加
          {
            OR: [
              {
                // 自組織
                CDS_M_Organization__c: this.$store.state.user.organization.Id,
              },
              {
                AND: {
                  // 提供先に所属かつ要請前以外
                  DestinationOrganization__c: this.$store.state.user
                    .organization.Id,
                  RequestStatus__c: {
                    not: '要請前',
                  },
                },
              },
            ],
          },
        ];
      }
      // 閲覧モードの時は、要請前を表示しない
      else {
        // 対応状況
        const status = targetCondition.RequestStatus__c;
        // 指定がなければ、要請前を除外する条件を指定
        if (!status || status.length === 0) {
          result.AND = [
            ...(result.AND || []),
            {
              RequestStatus__c: {
                not: '要請前',
              },
            },
          ];
        }
        // 指定がある場合は要請前は選べないはずなので問題なし
      }

      return result;
    },

    orgDisplay(v) {
      if (!v) return '';
      return this.organizations.find((o) => o.Id === v)?.Name || '';
    },
  },
};
</script>

<style lang="scss">
.request-destination-organization {
  max-width: 30em;
  &-inner {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
