<template>
  <div class="esrimap-area d-print-none" style="position: relative;">
    <div
      v-if="showGISButton"
      :class="[
        context.attributes.disabled || $vuetify.breakpoint.mobile
          ? 'hiddenGISButton'
          : '',
      ]"
    >
      <v-btn depressed :href="GISURL" target="_blank" class="mb-2">
        地理情報システム(GIS)を起動して、本事案を地図の中心に表示する
      </v-btn>
    </div>
    <div id="mapDiv" class="mapStyle">
      <div class="viewSelectArea">
        <v-select
          v-model="backgroundMap"
          class="viewSelect"
          :items="mapItems"
          item-text="name"
          item-value="id"
          hide-details
          dense
          solo
          flat
        >
        </v-select>
      </div>

      <div class="resultDiv">
        <div class="resultContent">
          <span class="results">
            <span id="result_latitude">{{ viewLatitude }}</span>
            <span>
              /
            </span>
            <span id="result_longitude">{{ viewLongitude }}</span>
          </span>
          <br />
          <span v-if="resultUTM" class="results">
            <span>
              UTM:
            </span>
            <span id="result_utm">{{ resultUTM }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EsriLoader } from '@/arcGIS/loader';
import { config } from '@/arcGIS/esri';

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    canDraw: { type: Boolean, default: false },
    showGISButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mapItems: config.mapItems,
    latitude: config.latitude,
    longitude: config.longitude,
    resultUTM: null,
    mapLoader: {},
    selectMap: config.selectMap,
    GISURL: null,
  }),
  computed: {
    backgroundMap: {
      get() {
        return this.selectMap;
      },
      set(value) {
        this.selectMap = value;
        this.mapLoader.changeMap(value);
      },
    },
    viewLatitude() {
      return this.latitude.toFixed(3);
    },
    viewLongitude() {
      return this.longitude.toFixed(3);
    },
  },
  watch: {
    context: {
      handler(val) {
        this.mapLoader.switchingFeature(!val.attributes.disabled);
      },
      deep: true,
    },
  },
  async mounted() {
    this.mapLoader = new EsriLoader({
      model: this.context.model,
      canDraw: this.canDraw,
      isEdit: !this.context.attributes.disabled,
      apiUrlPromise: (async () => {
        const { url } = await this.$con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'getEsriApiUrl',
        });
        return url;
      })(),
    });
    this.mapLoader.loadMap();
    //座標設定
    this.mapLoader.getUTM = (event) => {
      this.resultUTM = event;
      this.setArcGISOnlineLink();
    };
    this.mapLoader.getCoordinate = (event) => {
      this.latitude = event[0];
      this.longitude = event[1];
    };

    // 図形変更時のイベントハンドラ登録
    this.mapLoader.FeatureChangeEventHandler = () => {
      const d = this.mapLoader.getGraphData();
      this.$set(this.context, 'model', JSON.stringify(d));
    };

    // 住所登録
    this.mapLoader.chAddress = (value) => {
      //住所情報を設定
      this.context.attributes.reverseGeocodingEventhandler?.(value);
    };
  },
  methods: {
    async setArcGISOnlineLink() {
      if (this.$store.state.disaster.disaster.DisasterType__c == '訓練') {
        this.GISURL =
          (await this.$util.getConfigValue('GIS_ApplicationURL_Kunren')) + '&';
      } else {
        this.GISURL = await this.$util.getConfigValue('GIS_ApplicationURL');
      }
      this.GISURL +=
        'center=' +
        this.longitude +
        ';' +
        this.latitude +
        '&level=' +
        config.zoom;
    },
  },
};
</script>
<style lang="scss">
@import '~@arcgis/core/assets/esri/themes/light/main.css';

.esrimap-area {
  .hiddenGISButton {
    display: none;
  }
  .viewSelectArea {
    position: absolute;
    z-index: 2;
    right: 15px;
    padding: 0 2px;
    font-size: 12px;
    margin-top: 15px;
    background: #fff;
    box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
    -moz-box-shado: 0 1px 2px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
    width: 260px;

    .v-input {
      font-size: 14px !important;

      &.viewSelect {
        & > .v-input__control {
          min-height: 32px;
          & > .v-input__slot {
            min-height: 32px;
          }
        }
      }
    }
  }
  .mapStyle {
    height: 500px;
  }
  .resultDiv {
    position: absolute;
    z-index: 2;
    width: auto;
    right: 15px;
    bottom: 30px;
    padding: 4px;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
    -moz-box-shado: 0 1px 2px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  }
  .resultContent {
    margin: 3px;
    font-size: 12px;
  }
  .results {
    margin: auto;
  }

  .esri-search {
    width: 320px;
    ul {
      padding-left: 0;
    }
  }
}
</style>
