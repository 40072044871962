import App from './App.vue';

export default {
  path: '/CDS_VF_ReportDownload',
  name: 'CDS_VF_ReportDownload',
  component: App,
  meta: {
    pageProperty: {
      title: '報告書作成',
      controller: 'CDS_CTR_ReportDownload',
    },
  },
};
