<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_JMAInfo__c',
        detailPageName: 'CDS_VF_JMAInfoDetail',
        isDisasterRelated: true,
        hasVersion: true,
        masterObjectName: 'CDS_M_JMAInfo__c',
      }"
      :table-props="{
        defaultListOptions,
        viewComment: !le,
        inputFieldSetName: 'ListFieldSet',
        listFieldSetName: 'FixListFieldSet,ListFieldSet',
        defaultInputFieldSetName: 'DefaultListFieldSet',
        defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
        csvFieldSetName: 'CSVFieldSet,ListFieldSet',
        canCreate: false,
        masterFieldName: 'CDS_M_JMAInfo__c',
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import JMATypeLavel from '@/components/app/JMATypeLavel.vue';
import { infoType } from '@/assets/js/objects/CDS_T_JMAInfo__c';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
    JMATypeLavel,
  },

  provide: {
    listItem: {
      default: infoType.map(({ fieldPath, color, dark }) => ({
        slotName: `item.${fieldPath}`,
        component: JMATypeLavel,
        color,
        dark,
      })),
    },
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['No__c'],
      sortDesc: [false],
    },
  }),

  computed: {
    le() {
      return this.$query.current().le === 'true';
    },
  },
};
</script>
