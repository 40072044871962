<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header :title="title" />
    </template>
    <v-row class="mt-3">
      <v-col cols="12">
        <h2>ルーム参加者</h2>
      </v-col>
      <v-col v-for="user in roomUsers" :key="user.Id" cols="12" md="3">
        <v-card>
          <v-card-title>
            {{ user.Name }}
          </v-card-title>
          <v-card-text>
            {{ user.CDS_M_Organization__r.Name }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>メッセージ</h2>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="tableHeaders"
          :items="tableItems"
          item-key="id"
          class="elevation-1 cdsTable"
        >
          <template #item.message="{value}">
            <div style="overflow-x: scroll; height: 30px;" class="my-2">
              {{ value }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import { AES as cryptoAES, enc as cryptoEnc } from 'crypto-js';

export default {
  components: {
    defaultLayout,
    PageHeader,
  },

  data: () => ({
    roomInfo: {},
    tableHeaders: [
      {
        text: 'ユーザ名',
        value: 'name',
      },
      {
        text: '組織名',
        value: 'orgName',
      },
      {
        text: 'メッセージ',
        value: 'message',
      },
      {
        text: '投稿日時',
        value: 'created',
      },
    ],
  }),

  computed: {
    roomId() {
      return this.$query.current().id;
    },
    title() {
      return this.roomInfo?.Name;
    },
    roomUsers() {
      return this.roomInfo.ChatRoomOnUser?.map((cr) => cr.User) || [];
    },
    displayMessages() {
      return (
        this.roomInfo.Messages?.map((msg) => {
          return {
            ...msg,
            body: this.decodeMessage(msg.body),
          };
        }) || []
      );
    },
    tableItems() {
      return this.displayMessages.map((msg) => {
        return {
          id: msg.Id,
          name: msg.CreatedBy?.Name,
          orgName: msg.CreatedBy?.CDS_M_Organization__r?.Name,
          message: msg.body,
          created: this.$options.filters?.datetime(msg.CreatedDate) || '',
        };
      });
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      console.log('roomid', this.roomId);
      if (!this.roomId) return;
      await Promise.all([this.loadMessage()]);
    },

    async loadMessage() {
      const result = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getMessages',
          params: {
            roomId: this.roomId,
          },
        }),
      );
      console.log('loadmessage result', result);

      if (result.roomInfo) {
        this.roomInfo = result.roomInfo;
      }
    },

    decodeMessage(msg) {
      try {
        const decrypted = cryptoAES.decrypt(msg, this.roomId);
        return decrypted.toString(cryptoEnc.Utf8);
      } catch (error) {
        // ignored
      }
      return msg;
    },
  },
};
</script>
