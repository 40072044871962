import Vue from 'vue';
import Cognito from '../assets/js/cognito';

const plugin = {
  install(Vue) {
    const cognitoOptions = {
      UserPoolId: Vue.appConfig?.cognito?.userPoolId,
      ClientId: Vue.appConfig?.cognito?.userPoolClientId,
    };

    const cognitoInstance = new Cognito(cognitoOptions);

    Vue.prototype.$cognito = cognitoInstance;
    Vue.cognito = cognitoInstance;
  },
};

Vue.use(plugin);
export default plugin;
