var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('LifelineListView',_vm._b({attrs:{"table-props":{
      viewAttachment: true,
      viewComment: !_vm.le,
      inputFieldSetName: 'ListFieldSet',
      listFieldSetName: 'FixListFieldSet,ListFieldSet',
      defaultInputFieldSetName: 'ListFieldSet',
      defaultListFieldSetName: 'FixListFieldSet,ListFieldSet',
      bulkFieldSetName: 'BulkFieldSet',
      csvFieldSetName: 'CSVFieldSet,ListFieldSet',
    },"search-props":{
      applyErrataToCondition: true,
      showBaseDatetime: !_vm.le,
    }}},'LifelineListView',{
      objectSettings: _vm.objectSettings,
      detailLayoutName: 'DetailLayout',
      isDisasterRelated: true,
      hasVersion: true,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }