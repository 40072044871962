import App from './App.vue';

export default {
  path: '/CDS_VF_DamageReportDocument',
  name: 'CDS_VF_DamageReportDocument',
  component: App,
  meta: {
    pageProperty: {
      title: '被害報告出力',
      controller: 'CDS_CTR_DamageReportDetail',
    },
  },
};
