<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab title="支援要請" icon="mdi-frequently-asked-questions" />
    </template>
    <div>
      <div>
        <v-tabs v-model="tab" background-color="rgba(255,255,255,0)">
          <v-tab
            v-for="(area, areaIndex) in request.info.areas"
            :key="areaIndex"
          >
            {{ area }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item
            v-for="(area, areaIndex) in request.info.areas"
            :key="areaIndex"
          >
            <div class="pt-3">
              <v-row no-gutters justify="space-around">
                <v-col
                  v-for="(status, statusIndex) in request.info.requestStatuses"
                  :key="statusIndex"
                  :cols="status.cols"
                  class="py-1 px-2"
                >
                  <div :class="`${status.textClass} text-center`">
                    <div>
                      {{ status.name }}
                    </div>
                    <div class="text-h5">
                      {{ _.get(dataMap, `${area}.${status.name}`, 0) }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="pb-3">
        <e-chart :option="request.chart" autoresize style="height: 150px;" />
      </div>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';
import { RequestStatus__c } from '@/assets/js/objects/CDS_T_Request__c';

export default {
  components: {
    BarCard,
    BarCardTab,
  },
  data: () => ({
    loading: false,
    tab: null,

    objectInfo: {},

    // データ本体
    dataSource: [],
    // map形式のデータ
    dataMap: {},
  }),
  computed: {
    // 区名
    wards() {
      return _.get(
        this.objectInfo,
        'properties.Ward__c.picklistValues',
        [],
      ).map((v) => v.value);
    },
    // 凡例 (未着手, 対応中, ...)
    legend() {
      return _.get(
        this.objectInfo,
        'properties.RequestStatus__c.picklistValues',
        [],
      )
        .map((v) => v.value)
        .filter((v) => v != '要請前');
    },
    // 支援要請
    request() {
      return {
        // 数字
        info: {
          // エリア
          areas: ['市全体', ...this.wards],
          // 対応状況
          requestStatuses: [...this.legend, '計'].map((l) => ({
            name: l,
            textClass: '',
            cols: l == '計' ? '12' : 'auto',
            ...RequestStatus__c[l],
          })),
        },
        // グラフ
        chart: {
          color: this.legend.map((l) => RequestStatus__c[l].chartColor),
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            top: '0%',
            data: this.legend,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '48',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: this.wards,
            inverse: true,
          },
          dataset: {
            dimensions: ['Ward__c', ...this.legend],
            source: this.dataSource,
          },
          series: [...Array(this.legend.length)].map(() => ({
            type: 'bar',
            stack: 'stackname',
            label: {
              show: true,
              position: 'insideRight',
            },
            cursor: 'default',
          })),
          textStyle: {
            fontFamily: [
              'Hiragino Kaku Gothic ProN',
              'Hiragino Sans',
              'Roboto',
              'sans-serif',
            ],
          },
        },
      };
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await Promise.all([this.getObjectInfo(), this.getStat()]);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // オブジェクト情報の取得
    async getObjectInfo() {
      const objectInfo = await this.$util.getObjectInfo('CDS_T_Request__c');
      this.$set(this, 'objectInfo', objectInfo);
    },
    // 情報の取得
    async getStat() {
      const { resultList = [], resultMap = {} } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getRequestStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};
      if (resultList && Array.isArray(resultList)) {
        this.dataSource = resultList;
      }
      if (_.isObject(resultMap) && !_.isEmpty(resultMap)) {
        this.$set(this, 'dataMap', resultMap);
      }
    },

    handleClickBar() {
      this.$transition.to('CDS_VF_RequestList', null, {
        notApplyRetUrl: true,
      });
    },
  },
};
</script>
