import defaultRules from './defaultRules';
import transition from '@/plugins/transition';

class BeforeGuardError extends Error {
  constructor(message, url) {
    super(message);
    this.url = url;
  }
}

/**
 * rulesに定義されたルールをもとにページ初期表示時に必要な初期storeを生成する。
 * 必要な値がcookie等にセットされていない場合はセットするページへ自動遷移する。
 * またセットされていない場合は返り値のオブジェクトに_error = trueがセットされる。
 */
export default async (rules = defaultRules) => {
  let initStore = {};

  const result = await Promise.allSettled(
    Object.values(rules).map(async (rule) => {
      const loadedStore = await rule.load().catch((e) => {
        throw new BeforeGuardError(e.message, rule.errorPath);
      });
      initStore = { ...initStore, ...loadedStore };
    }),
  );

  const rejected = result.filter(({ status }) => status === 'rejected').pop();
  if (rejected) {
    initStore._error = true;
    console.log(rejected.reason);
    transition.to(
      rejected.reason.url,
      { message: rejected.reason.message },
      {
        href: true,
        notApplyRetUrl: true,
      },
    );
  }

  return initStore;
};
