import dataWindow from '@/components/gis/dataWindow';
import Vue from 'vue';
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import { load } from './OverlappingMarkerSpiderfier';
import vuetify from '@/plugins/vuetify';

/**
 * 地図に図形を追加してアイコンを表示
 * @param {*} param0
 */
export function createPolygons({ map, json, path, color }) {
  return map.addPolygons(json).reduce((ret, target) => {
    // 着色
    target.overlay.setOptions({
      strokeColor: color,
      fillColor: color,
    });
    // アイコン追加
    const icon = {
      path,
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000000',
      strokeOpacity: 1,
      scale: 1.2,
      anchor: new google.maps.Point(12, 12),
    };
    if ('Marker' === target.type) {
      target.overlay.setIcon(icon);
    } else if ('Polyline' === target.type) {
      target.overlay.setOptions({
        icons: [{ icon, offset: '50%', fixedRotation: true }],
      });
    } else {
      const marker = new google.maps.Marker({
        icon,
        map,
        position: target.overlay.getCenter(),
      });
      ret.push({ type: 'Marker', overlay: marker });
    }
    return [...ret, target];
  }, []);
}

/**
 * 情報ウィンドウを生成する
 * @param {*} polygons
 * @param {*} props
 */
export function createPopupWindow(polygons, props) {
  const dataWindowDom = new Vue({
    vuetify,
    render: (createElement) => createElement(dataWindow, { props }),
  });
  dataWindowDom.$mount();
  polygons.forEach((polygon) => {
    polygon.overlay.addListener('click', () => {
      polygon.dataWindow && polygon.dataWindow.close();
      polygon.dataWindow = new google.maps.InfoWindow({
        content: dataWindowDom.$el,
      });
      polygon.dataWindow.setPosition(polygon.overlay.getCenter());
      polygon.dataWindow.open(polygon.overlay.getMap());
    });
  });
}
/**
 * マーカーをクラスタリングする
 * @param {*} map
 * @param {*} polygons
 */
export function markerClustering(map, polygons) {
  const markers = polygons
    .filter((d) => d.type === 'Marker')
    .map(({ overlay }) => {
      overlay.setMap(null);
      return overlay;
    });

  const OverlappingMarkerSpiderfier = load();

  const oms = new OverlappingMarkerSpiderfier(map, {
    markersWontMove: true,
    markersWontHide: true,
    basicFormatEvents: true,
    nearbyDistance: 40,
    circleSpiralSwitchover: 8,
    spiralFootSeparation: 20,
    spiralLengthStart: 16,
    spiralLengthFactor: 12,
    circleFootSeparation: 50,
    circleStartAngle: 180,
    keepSpiderfied: true,
  });
  markers.map((m) => {
    oms.addMarker(m);
  });

  const cluster = new MarkerClusterer({
    map,
    markers,
    algorithm: new SuperClusterAlgorithm({ radius: 10, maxZoom: 17 }),
    onClusterClick(event, cluster, map) {
      map.setZoom(18);
      map.setCenter(cluster.position);
    },
  });
  return cluster;
}
