<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_Chronology__c',
        detailPageName: 'CDS_VF_ChronologyDetail',
        isDisasterRelated: true,
        showImageTab: true,
      }"
      :search-props="{
        syncConditionWithUrl: true,
      }"
      :table-props="{
        defaultListOptions,
        canEdit: false,
        viewAttachment: true,
        forInjectComponentData: {
          config,
        },
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import RecordId from './item/RecordId.vue';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  provide: {
    listItem: {
      default: [
        {
          slotName: 'item.RecordId__c',
          component: RecordId,
        },
      ],
    },
  },

  data: () => ({
    config: [],
    defaultListOptions: {
      sortBy: ['ReportDate__c'],
      sortDesc: [true],
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadConfig()]),
      );
    },
    // 設定の読み込み
    async loadConfig() {
      const config = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getConfig',
      });
      this.$set(this, 'config', config);
    },
  },
};
</script>
