import App from './App.vue';

export default {
  path: '/',
  name: 'index',
  component: App,
  meta: {
    pageProperty: {
      title: 'ポータル',
      controller: 'CDS_CTR_Main',
    },
  },
};
