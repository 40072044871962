import Vue from 'vue';
import qs from 'qs';
import queryPlugin from './query';
import router from '../router';

// 新規タブで開くか
let openNewTab;

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$transition = this;
    Vue.transition = this;

    // キー押下検知
    const updateKey = (event) => {
      // キーが押されているか
      openNewTab = !!(event.ctrlKey || event.shiftKey);
    };
    document.body.addEventListener('keydown', (event) => {
      updateKey(event);
    });
    document.body.addEventListener('keyup', (event) => {
      updateKey(event);
    });
  },

  /**
   * ページ遷移を行う
   * @param {String} pageName ページ名
   * @param {Object} query クエリパラメータ
   * @param {Object} options オプション
   * @param {Object} options.hack ページ遷移で割り込み処理を入れる
   * @param {Boolean} options.notApplyRetUrl retUrlを入れない場合にtrueにする
   * @param {Boolean} options.keepRetUrl retUrlを現在のパラメータからそのまま引き継ぐ
   * @param {Boolean} options.openNewTab 新規タブで開くか
   * @param {Boolean} options.href location.hrefで開くか
   *
   * @example
   * this.$transition.to('CDS_VF_Example', {a: 1, b: 2})
   */
  to(pageName, query = null, options = {}) {
    // ページ遷移
    // 行先
    const gotoUrl = this.getToLink(pageName, query, options);

    // 新規タブで開くか
    if (options && Object.keys(options).includes('openNewTab')) {
      openNewTab = !!options.openNewTab;
    }

    this.direct(gotoUrl, { href: !!options?.href });
  },

  /**
   * ページ遷移先のURLを取得する
   * @param {String} pageName ページ名
   * @param {Object} query クエリパラメータ
   * @param {Object} options オプション
   * @param {Object} options.hack ページ遷移で割り込み処理を入れる
   * @param {Boolean} options.notApplyRetUrl retUrlを入れない場合にtrueにする
   * @param {Boolean} options.keepRetUrl retUrlを現在のパラメータからそのまま引き継ぐ
   *
   * @example
   * this.$transition.getToLink('CDS_VF_Example', {a: 1, b: 2})
   */
  getToLink(pageName, query = null, options = {}) {
    // pageNameを解析する
    const { pageName: realPageName, query: realQuery } = this.convertPageName(
      pageName,
      query,
    );

    if (!options || !options.notApplyRetUrl) {
      // retUrlを与える
      realQuery.retUrl = realQuery.retUrl || this.generateRetUrl();
    }

    // retUrlを引き継ぐ場合
    if (options && options.keepRetUrl) {
      realQuery.retUrl = queryPlugin.current().retUrl;
    }

    // 同じページへの遷移を検知するためクエリにユニークな値を付与する
    realQuery._u = _.uniqueId();

    const nextPage = [
      realPageName.startsWith('/') || this.isExternalUrl(realPageName)
        ? ''
        : '/',
      realPageName,
      !_.isEmpty(realQuery) ? '?' + qs.stringify(realQuery) : '',
    ];
    if (options && options.hack) return options.hack(nextPage);

    // ページ遷移
    // 行先
    const gotoUrl = nextPage.join('');

    return gotoUrl;
  },

  /**
   * ページ名にクエリパラメータが入っている可能性があるため、変換する
   * @param {String} pageName
   * @param {Object} query
   * @returns {Object} ページ名,クエリオブジェクト
   */
  convertPageName(pageName, query = null) {
    let realPageName;
    let tempQuery = {};
    if (pageName.includes('?')) {
      const splitPageName = pageName.split('?');
      realPageName = splitPageName[0];
      tempQuery = qs.parse(splitPageName[1], { depth: 10 });
    } else {
      realPageName = pageName;
    }
    return {
      pageName: realPageName,
      query: _.merge(tempQuery, query),
    };
  },

  /**
   * 直接遷移する
   * @param {String} gotoUrl
   */
  direct(gotoUrl, options) {
    const isNewTab = openNewTab;
    openNewTab = false;
    if (isNewTab) {
      // 別タブの場合はそのまま開く
      const targetUrl = this.isExternalUrl(gotoUrl)
        ? gotoUrl
        : `${location.origin}${gotoUrl}`;
      window.open(targetUrl, '_blank');
    } else {
      if (options?.href || this.isExternalUrl(gotoUrl)) {
        // 通常の場合はローディングを表示してから遷移
        // thisの_storeに$storeを入れているので、storeを使ってローディングを表示
        this._store && this._store.dispatch('loading/setForce', true);
        window.location.href = gotoUrl;
      } else {
        this._store && this._store.dispatch('loading/setTransition', true);
        router.push(gotoUrl);
      }
    }
  },

  /**
   * 外部のURLかどうかを判定する
   * @param {string} url
   * @returns
   */
  isExternalUrl(url) {
    return url.startsWith('http://') || url.startsWith('https://');
  },

  /**
   * フォールバックページ
   */
  fallbackPage: '',
  /**
   * フォールバック
   */
  fallback() {
    this.to(this.fallbackPage, null, { notApplyRetUrl: true });
  },
  /**
   * フォールバックリンク
   */
  getFallbackLink() {
    return this.getToLink(this.fallbackPage, null, { notApplyRetUrl: true });
  },

  /**
   * retUrlに基づいて戻る
   * @param {Boolean} fallbackIfRetUrlIsNotExist retUrlがない場合にfallbackするか(デフォルトtrue)
   */
  back(fallbackIfRetUrlIsNotExist = true) {
    // retUrlの取り出し
    const retUrl = queryPlugin.current().retUrl;
    retUrl
      ? this.direct(retUrl)
      : fallbackIfRetUrlIsNotExist
      ? this.fallback()
      : window.history.back();
  },

  /**
   * retUrlを生成する
   * @returns {String} retUrl文字列
   */
  generateRetUrl() {
    // 現在のページのURL
    const currentPageUrl = window.location.href;
    // retUrlを新しく指定したもの
    const nextRetUrl = this.filterRetUrl(currentPageUrl, 3);
    // retUrlからorigin部分を除去
    const nextRetUrlObj = new URL(nextRetUrl);
    // 同じページの場合はorigin部をナシにする
    if (nextRetUrlObj.origin === window.location.origin) {
      const result = nextRetUrl.replace(
        new RegExp(
          `^${window.location.origin.replace(
            /[.*+\-?^${}()|[\]\\//]/g,
            '\\$&',
          )}`,
        ),
        '',
      );
      return result;
    }
    // retUrlを返す
    return nextRetUrl;
  },

  /**
   * retUrlが指定回数以上かどうかを見て、削除などする
   * @param {String} urlString url string
   * @param {Number} limit 回数制限
   * @param {Number} count カウント
   * @returns {String} retUrl削除などした後のurl文字列
   */
  filterRetUrl(urlString, limit, count = 1) {
    // パラメータで分割
    const [host, ...paramsArray] = urlString.split('?');
    // ハテナがたくさんある場合に備えて(あるわけないけど)念のためjoinする
    const params = paramsArray.join('?');
    // パラメータをオブジェクトに変換
    const paramsObject = this.paramsStringToObject(params);

    if (paramsObject.retUrl) {
      if (count >= limit) {
        // 制限回数を超えたら、retUrlを削除する
        delete paramsObject.retUrl;
      } else {
        // 制限がまだなら、再帰する
        paramsObject.retUrl = this.filterRetUrl(
          paramsObject.retUrl,
          limit,
          count + 1,
        );
      }
    }
    // パラメータを再指定してfullUrlをお返し
    return [
      host,
      _.isEmpty(paramsObject) ? '' : `?${qs.stringify(paramsObject)}`,
    ].join('');
  },

  /**
   * URLパラメータの文字列をオブジェクトに変換する
   * @param {String} paramsString 文字列
   * @returns オブジェクト
   */
  paramsStringToObject(paramsString) {
    return paramsString ? qs.parse(paramsString, { depth: 10 }) : {};
  },
};

Vue.use(plugin);
export default plugin;
