import App from './App.vue';

export default {
  path: '/CDS_VF_FirstAidTotalList',
  name: 'CDS_VF_FirstAidTotalList',
  component: App,
  meta: {
    pageProperty: {
      title: '応急救護所集計',
      controller: 'CDS_CTR_FirstAidTotalList',
    },
  },
};
