<template>
  <detail
    object-name="COM_LoginHistory__c"
    layout-name="DetailLayout"
    default-title="ログイン履歴"
    subtitle="ログイン履歴詳細"
    saved-page="CDS_VF_LoginHistoryDetail"
    detail-page-name="CDS_VF_LoginHistoryDetail"
    list-page="CDS_VF_LoginHistory"
    :list-view-props="{
      canSearch: false,
    }"
    :can-edit="false"
    :disable-attachment="true"
    :use-version="false"
    :disable-actions="['edit', 'remove']"
  >
    <template #DetailMenus="{value}">
      <ActionButton
        btn-value="ユーザ情報に移動"
        icon="mdi-account"
        @click="handleClickToUser(value)"
      />
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import ActionButton from '@/components/detail/ActionButton.vue';
import { disaster } from '@/beforeGuard/defaultRules';

export default {
  components: {
    detail,
    ActionButton,
  },

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },

  methods: {
    handleClickToUser(v) {
      this.$transition.to(
        'CDS_VF_UserDetail',
        {
          id: v.id,
        },
        {
          openNewTab: true,
          notApplyRetUrl: true,
        },
      );
    },
  },
};
</script>
