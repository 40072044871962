<template>
  <div>
    <v-form ref="evacuationCheckForm" v-model="form.valid" lazy-validation>
      <div
        v-for="(announceType, announceTypeIndex) in announceTypes"
        :key="announceTypeIndex"
        class="my-5"
      >
        <div
          :class="`announce-type-header px-4 py-1`"
          :style="`${announceType.labelStyle}`"
        >
          <span>{{ announceType.label }}</span>
          <span
            v-if="doApplyAlertLevel && announceType.alertLevel"
            class="ml-3"
          >
            [{{ announceType.alertLevel }}]
          </span>
        </div>
        <div
          v-if="announceType.alertLevel"
          class="mini-input text-body-2 d-flex mt-3"
        >
          <div class="d-flex align-center">
            警戒レベルを一括設定
          </div>
          <div class="d-flex ml-3" style="width: 300px;">
            <v-select
              v-model="alertLevelSelected[announceType.name]"
              :items="['', announceType.alertLevel]"
              dense
              outlined
              hide-details="auto"
              height="28"
              @change="onSelectAlertLevelAll(announceType, $event)"
            ></v-select>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <v-simple-table
              :class="`evacuation-checker-table evacuation-table-default mini-input announce-type-name-${announceType.name}`"
            >
              <colgroup>
                <col span="1" style="width: 4%;" />
                <col span="1" style="width: 4%;" />
                <col span="1" style="width: 14%;" />
                <col span="1" />
                <col span="1" style="width: 15%;" />
                <col span="1" style="width: 12%;" />
                <col span="1" style="width: 13%;" />
                <col span="1" style="width: 10%;" />
                <col span="1" style="width: 10%;" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    class="text-center px-0"
                    style="padding-right: 0px !important;"
                  >
                    削除
                  </th>
                  <th
                    class="text-center px-0"
                    style="padding-right: 0px !important;"
                  >
                    区分
                  </th>
                  <th class="text-left">
                    警戒レベル
                  </th>
                  <th class="text-left">
                    発令区域
                  </th>
                  <th class="text-left">
                    ふりがな
                  </th>
                  <th class="text-left">
                    発令日時
                  </th>
                  <th class="text-left">
                    避難原因種別
                  </th>
                  <th class="text-left">
                    対象世帯数
                  </th>
                  <th class="text-left">
                    対象人数
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="(orderStatus, orderStatusIndex) in orderStatuses"
                :key="orderStatusIndex"
              >
                <template
                  v-if="
                    _.get(
                      workRecordData,
                      `${announceType.name}.${orderStatus.label}`,
                      null,
                    )
                  "
                >
                  <tr
                    v-for="(record, recordIndex) in workRecordData[
                      announceType.name
                    ][orderStatus.label]"
                    :key="recordIndex"
                    :class="`order-status-${orderStatus.id}`"
                  >
                    <td class="text-center px-0">
                      <v-icon
                        v-ripple
                        dense
                        @click="
                          removeRow({
                            orderStatus,
                            announceType,
                            record,
                            recordIndex,
                          })
                        "
                      >
                        mdi-close
                      </v-icon>
                    </td>
                    <td class="text-center px-0">
                      {{ orderStatus.label }}
                    </td>
                    <td>
                      <v-select
                        v-if="announceType.alertLevel"
                        v-model="record.alertLevel"
                        :items="['', announceType.alertLevel]"
                        dense
                        outlined
                        hide-details="auto"
                        height="28"
                        :readonly="doApplyAlertLevel"
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model.trim.lazy="record.Name"
                        outlined
                        dense
                        height="28"
                        single-line
                        hide-details="auto"
                        type="text"
                        autocomplete="off"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.trim.lazy="record.Phonetic__c"
                        outlined
                        dense
                        height="28"
                        single-line
                        hide-details="auto"
                        type="text"
                        autocomplete="off"
                      ></v-text-field>
                    </td>
                    <td>
                      {{ formatDatetime(record.announceDatetime) }}
                    </td>
                    <td>
                      <v-text-field
                        v-model.trim.lazy="record.reasonType"
                        outlined
                        dense
                        height="28"
                        single-line
                        hide-details="auto"
                        type="text"
                        autocomplete="off"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-if="orderStatus.label === '発令'"
                        v-model.number.lazy="record.Household__c"
                        outlined
                        dense
                        height="28"
                        single-line
                        hide-details="auto"
                        type="number"
                        autocomplete="off"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-if="orderStatus.label === '発令'"
                        v-model.number.lazy="record.People__c"
                        outlined
                        dense
                        height="28"
                        single-line
                        hide-details="auto"
                        type="number"
                        autocomplete="off"
                      ></v-text-field>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import { orderStatuses } from './util';

export default {
  name: 'EvacuationChecker',

  props: {
    // stepperアクティブ
    active: {
      type: Boolean,
      default: false,
    },
    // 発令種別のリスト
    announceTypes: {
      type: Array,
      default: () => [],
    },
    // 発令状況
    recordData: {
      type: Object,
      default: () => ({}),
    },
    // 警戒レベルを設定するか
    doApplyAlertLevel: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    // 作業用レコードデータ
    workRecordData: {},
    // フォーム
    form: {
      valid: null,
    },
    // 警戒レベル
    alertLevelSelected: {},
    // 発令or解除
    orderStatuses,
    // 入力のバリデーション
    rules: {
      required: (value) => !!value || '必須項目です。',
    },
  }),

  watch: {
    'form.valid'(to) {
      this.$emit('form-valid', to);
    },
    recordData: {
      handler(to) {
        this.$set(this, 'workRecordData', _.cloneDeep(to));
        // console.log(
        //   '%c[checker] recorddata updated ->',
        //   'color: darkorange;',
        //   to,
        // );
        this.applyAlertLevel();
      },
      deep: true,
    },
    workRecordData: {
      handler(to) {
        this.$emit('update-record-data', to);
        // console.log(
        //   '%c[checker] workRecordData updated ->',
        //   'color: darkorange;',
        //   to,
        // );
        const numberFields = this.calcNumberField(to);
        this.$emit('update-record-data-number-fields', numberFields);
        // console.log(
        //   '%c[checker] workRecordData updated numberFields ->',
        //   'color: darkorange;',
        //   numberFields,
        // );
      },
      deep: true,
    },
  },

  methods: {
    // 警戒レベルが選択された時に各区域に設定
    onSelectAlertLevelAll(announceType, value) {
      this.orderStatuses.map((orderStatus) => {
        this.workRecordData[announceType.name][orderStatus.label].map(
          (record) => {
            this.$set(record, 'alertLevel', value);
          },
        );
      });
    },
    // 日付を変換する
    formatDatetime(datetime) {
      if (!datetime) return '(次画面で設定します)';
      return this.$dateFns.fnsFormat(datetime, '', 'P HH:mm');
    },

    // 行の削除
    removeRow({ orderStatus, announceType, recordIndex }) {
      if (confirm(`行を削除しますか？`)) {
        this.$set(
          this.workRecordData[announceType.name],
          `${orderStatus.label}`,
          _.get(
            this.workRecordData,
            `${announceType.name}.${orderStatus.label}`,
            [],
          ).filter((r, i) => i !== recordIndex),
        );
      }
    },

    // 全体の世帯数・人数を計算する
    calcNumberField(data) {
      const calcTargetArray = _.flatten(
        _.map(data, (r) =>
          _.flatten(_.map(r, (d) => d)).filter((v) => v.orderStatus == '発令'),
        ),
      );
      const res = {};
      ['Household__c', 'People__c'].map((type) => {
        res[type] = _.sum(calcTargetArray.map((v) => Number(v[type] || 0)));
      });
      return res;
    },

    // 警戒レベルの自動設定
    applyAlertLevel() {
      // 現在設定されている警戒レベルの選択状態をクリアする
      this.$set(this, 'alertLevelSelected', {});
      // 自動設定するか
      if (this.doApplyAlertLevel) {
        // 警戒レベル自動設定
        this.announceTypes
          .filter((announceType) => announceType.alertLevel)
          .map((announceType) => {
            this.$set(
              this.alertLevelSelected,
              announceType.name,
              announceType.alertLevel,
            );
            this.onSelectAlertLevelAll(announceType, announceType.alertLevel);
          });
      }
    },
  },
};
</script>
