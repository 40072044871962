import App from './App.vue';

export default {
  path: '/CDS_VF_ActivityBaseList',
  name: 'CDS_VF_ActivityBaseList',
  component: App,
  meta: {
    pageProperty: {
      title: '活動拠点一覧',
      controller: 'CDS_CTR_ActivityBaseList',
    },
  },
};
