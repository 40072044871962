<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="0">
        <div>
          <Layout
            v-model="searchForm.value"
            v-bind="searchForm.attrs"
            @submit-raw="handleOnSubmitSearch"
          />
        </div>
        <v-card-actions>
          <v-btn depressed color="primary" @click="handleClickSearch">
            検索
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card elevation="0">
        <div>
          <v-data-table
            dense
            v-bind="staffTable.attrs"
            class="staff-total-table cdsTable fix-first-col"
            :mobile-breakpoint="0"
          >
            <template #body.prepend="{headers}">
              <tr class="staff-total-row">
                <td
                  v-for="header of headers"
                  :key="`body-prepend-${header.value}`"
                  class="text-end"
                >
                  {{ staffTable.total[header.value] }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Layout from '@/components/detail/Layout.vue';
import Display from '@/components/list/Display.vue';
import Confirm from '@/components/common/Confirm';

import { searchFormLayout, searchFormObjectInfo } from './form';
import { mapActions } from 'vuex';
import { compareAsc } from 'date-fns';

export default {
  name: 'StaffTableInnerContent',

  components: {
    Layout,
    Display,
    Confirm,
  },

  data: () => ({
    searchForm: {
      attrs: {
        name: 'StaffTableSearchForm',
        layout: searchFormLayout,
        objectInfo: searchFormObjectInfo,
        editMode: true,
      },
      value: {},
    },
    staffTable: {
      attrs: {
        headers: [],
        items: [],
        'items-per-page': -1,
        'disable-sort': true,
      },
      total: {},
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        (async () => {
          await Promise.all([this.setInitialValue()]);
          await this.getTableRecord();
        })(),
      );
    },

    /******** 初期ロード ********/

    /**
     * 初期値の設定
     */
    async setInitialValue() {
      // 最初の報告日時の取得
      const result = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getFirstReportTime',
        params: {
          disasterId: this.$store.state.disaster.disaster.Id,
        },
      });

      let fromValue = this.getCurrentDatetime();

      if (result && result.record && result.record.ReportDate__c) {
        fromValue = new Date(result.record.ReportDate__c);
      }

      // 24時間加算する
      const toValue = structuredClone(fromValue);
      toValue.setHours(toValue.getHours() + 24);
      // 値を設定
      this.searchForm.value = {
        from: fromValue.toJSON(),
        to: toValue.toJSON(),
      };
    },

    /**
     * 現在時刻を分とミリ秒を0にして返す
     */
    getCurrentDatetime() {
      const c = new Date();
      return new Date(
        `${c.getFullYear()}-${
          c.getMonth() + 1
        }-${c.getDate()} ${c.getHours()}:00:00`,
      );
    },

    async loadTableRecord() {
      this.$store.dispatch('loading/register', this.getTableRecord());
    },

    async getTableRecord() {
      try {
        const result = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getTableRecord',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            ...this.searchForm.value,
          },
        });
        const { headers, items, total } = result;
        this.$set(
          this.staffTable.attrs,
          'headers',
          headers?.map((h) => ({
            ...h,
            align: 'end',
          })),
        );
        this.$set(this.staffTable.attrs, 'items', items);
        this.$set(this.staffTable, 'total', total);
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /******** ハンドラ ********/

    // 検索ボタンを押下した時
    handleClickSearch() {
      this.$formulate.submit(this.searchForm.attrs.name);
    },

    // 検索
    async handleOnSubmitSearch(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        const { value } = this.searchForm;
        // 開始日時より終了日時が早かったらNG
        if (compareAsc(new Date(value.from), new Date(value.to)) > 0) {
          throw new Error('開始日時より終了日時が遅くなるようにしてください。');
        }

        await this.loadTableRecord();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>

<style lang="scss">
.staff-total-table {
  .staff-total-row {
    td {
      color: white;
      background: #78909c !important;
      font-weight: bold;
    }
  }
}
</style>
