/**
 * レイアウト
 */
export const searchFormLayout = {
  fullName: 'ReportCreateForm.ReportDateLayout',
  label: '報告書レイアウト',
  sobjecttype: 'ReportCreateForm',
  name: 'ReportDateLayout',
  sections: [
    {
      label: null,
      detailHeading: true,
      editHeading: true,
      column: '3',
      items: [
        {
          type: 'field',
          fieldPath: 'CDS_M_Organization__c',
          required: false,
          readonly: false,
        },
        {
          type: 'field',
          fieldPath: 'baseDatetime',
          required: true,
          readonly: false,
        },
        {
          type: 'field',
          fieldPath: 'outputType',
          required: true,
          readonly: false,
        },
      ],
    },
  ],
};

/**
 * オブジェクト情報
 */
export const searchFormObjectInfo = {
  label: '報告書オブジェクト',
  properties: {
    CDS_M_Organization__c: {
      label: '#組織マスタ',
      helpText: '',
      type: 'reference',
      isId: true,
      $ref: '#/definitions/CDS_M_Organization__c',
      inputProps: {
        multiple: true,
      },
    },
    baseDatetime: {
      label: '基準日時',
      type: 'date-time',
      format: 'date-time',
    },
    outputType: {
      label: '災害区分',
      helpText: '',
      type: 'picklist',
      format: 'picklist',
      enum: ['地震', '風水害その他'],
      picklistValues: [
        {
          label: '地震',
          value: '地震',
          defaultValue: false,
        },
        {
          label: '風水害その他',
          value: '風水害その他',
          defaultValue: false,
        },
      ],
    },
  },
};
