import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';
import ja from 'vuetify/es5/locale/ja.js';

// fontawesomeを入れると5MBを超えてしまうためNG

// https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-894201049
Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  lang: {
    locales: { ja },
    current: 'ja',
  },
});
