<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header
        icon="mdi-magnify"
        title="検索"
        subtitle="システム全体を検索できます。"
      />
    </template>

    <v-row>
      <v-col cols="12">
        <SearchForm
          v-model="searchForm.value"
          :form-name="searchForm.name"
          @submit-raw="handleOnSubmitSearch"
        />
        <div class="px-4 pb-4">
          <v-btn color="primary" outlined @click="handleClickSearch">
            <v-icon left>
              mdi-magnify
            </v-icon>
            <span>
              検索
            </span>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            検索結果
          </v-card-title>
          <div>
            <v-data-table
              v-bind="table.attrs"
              class="cdsTable search-result-clickable"
              @click:row="handleClickRow"
            >
              <template #item.lastModifiedDate="{value}">
                {{ value | datetime }}
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import SearchForm from '@/components/core/parts/SearchForm.vue';

import { mapActions } from 'vuex';

export default {
  components: {
    defaultLayout,
    PageHeader,
    SearchForm,
  },

  data: () => ({
    searchForm: {
      name: 'GlobalSearchFormOnPage',
      value: {},
    },
    table: {
      attrs: {
        headers: [
          {
            text: '情報種別',
            value: 'infoType',
            sortable: true,
          },
          {
            text: '名称等',
            value: 'name',
            sortable: false,
          },
          {
            text: '検索ヒット部分',
            value: 'hit',
            sortable: false,
          },
          {
            text: '報',
            value: 'version',
            sortable: true,
          },
          {
            text: '最終更新日時',
            value: 'lastModifiedDate',
            sortable: true,
          },
        ],
        items: [],
        dense: true,
        'items-per-page': 50,
        'footer-props': {
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 50, 100, 200],
          'items-per-page-all-text': 'すべて',
        },
      },
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.valueFromQuery()]),
      );
    },

    // urlクエリから検索条件を設定する
    async valueFromQuery() {
      const { search } = this.$query.current();
      if (search) {
        this.$formulate.setValues(this.searchForm.name, search);
        await this.$nextTick();

        // 検索条件がある場合はそのまま検索実行する
        this.getResult();
      }
    },

    async loadResult() {
      this.$store.dispatch('loading/register', this.getResult());
    },

    async getResult() {
      try {
        const result = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getSearchResult',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            ...this.searchForm.value,
          },
        });

        console.log('search result', result?.records);
        this.table.attrs.items = result?.records;
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /******** ハンドラ ********/

    // ボタン押下時
    handleClickSearch() {
      this.$formulate.submit(this.searchForm.name);
    },

    // フォームsubmit時
    async handleOnSubmitSearch(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        // urlにセット
        this.$query.updateHistoryQuery({
          query: {
            ...this.$query.current(),
            search: this.searchForm.value,
          },
        });

        this.loadResult();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 行をクリックした時
    handleClickRow(item) {
      if (item.link) {
        this.$transition.to(item.link, {}, {});
      }
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>

<style lang="scss">
.cdsTable.v-data-table.search-result-clickable tbody tr:hover {
  cursor: pointer;
}
</style>
