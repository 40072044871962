import App from './App.vue';

export default {
  path: '/redirect',
  name: 'redirect',
  component: App,
  meta: {
    pageProperty: {
      forceTitle: 'リダイレクトしています...',
    },
    guardRules: [],
  },
};
