var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('TotalView',_vm._b({attrs:{"search-props":{
      layoutName: 'TotalSearchLayout',
    },"table-props":{
      defaultListOptions: _vm.defaultListOptions,
    }}},'TotalView',{
      objectName: 'CDS_T_ActivityBaseTotal__c',
      targetObjectName: 'CDS_T_ActivityBase__c',
      detailPageName: 'CDS_VF_ActivityBaseDetail',
      isDisasterRelated: true,
      detailLayoutName: 'ListEditLayout',
      handleClickRowFunc: _vm.handleClickRowFunc,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }