import App from './App.vue';

export default {
  path: '/CDS_VF_SelectUser',
  name: 'CDS_VF_SelectUser',
  component: App,
  meta: {
    pageProperty: {
      title: 'ユーザ選択',
      controller: 'CDS_CTR_SelectUser',
    },
    guardRules: [],
  },
};
