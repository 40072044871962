import App from './App.vue';

export default {
  path: '/CDS_VF_LifelineList',
  name: 'CDS_VF_LifelineList',
  component: App,
  meta: {
    pageProperty: {
      title: 'ライフライン一覧',
      controller: 'CDS_CTR_LifelineList',
    },
  },
};
