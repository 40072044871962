import { covertLatLngToNumArray } from '@/googlemap/util';
export default (_Polygon) => {
  class Polygon extends _Polygon {
    /**
     * 多角形データを符号化JSON形式で出力する
     * @return {[type]} [description]
     */
    getJSON() {
      return {
        type: 'Feature',
        properties: {
          fillColor: this.fillColor,
          strokeColor: this.strokeColor,
        },
        geometry: {
          type: 'Polygon',
          coordinates: window.google.maps.geometry.encoding.encodePath(
            this.getPath(),
          ),
        },
      };
    }

    /**
     * 多角形中心点出力
     * @return {[type]} [description]
     */
    getCenter() {
      let [lat, lng] = this.getPath()
        .getArray()
        .reduce(
          (sum, val) => {
            sum[0] += val.lat();
            sum[1] += val.lng();
            return sum;
          },
          [0, 0],
        );
      lat = lat / this.getPath().getArray().length;
      lng = lng / this.getPath().getArray().length;
      return new window.google.maps.LatLng(lat, lng);
    }
    /**
     * 多角形データの頂点データを配列で出力する
     * @return {[type]} [description]
     */
    getCoordinates() {
      const coordinates = this.getPath()
        .getArray()
        .map((val) => covertLatLngToNumArray(val));
      coordinates.push(coordinates[0]);
      return coordinates;
    }
    /**
     * 多角形の面積を取得
     * @return {[type]} [description]
     */
    getMeasureResult() {
      return window.google.maps.geometry.spherical.computeArea(this.getPath());
    }
    loadJSON({ geometry, properties }) {
      if (Array.isArray(geometry.coordinates)) {
        this.setPaths(
          geometry.coordinates.map((coor) =>
            google.maps.geometry.encoding.decodePath(coor),
          ),
        );
      } else {
        this.setPath(
          google.maps.geometry.encoding.decodePath(geometry.coordinates),
        );
      }
      this.setOptions(properties);
    }
  }
  return Polygon;
};
