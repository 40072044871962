// https://codesandbox.io/s/94rz54rv6o

export default function hotModuleMonitor() {
  if (module && module.hot && process.env.NODE_ENV === 'development') {
    const styleHMR = `
      padding:2px 5px;
      background:linear-gradient(#666, #333);
      color:white;
      border-top-left-radius:3px;
      border-bottom-left-radius:3px;`;
    const styleReloaded = `
      padding:2px 5px;
      background:linear-gradient(#6e6, #3b3);
      color:white;
      border-top-right-radius:3px;
      border-bottom-right-radius:3px;`;

    module.hot.addStatusHandler((status) => {
      const element = document.querySelector('#hotReloadMonitorPanel');
      if (status === 'apply') {
        console.log('%cHMR%c' + 'Reloaded', styleHMR, styleReloaded);
        element.innerHTML = null;
        element.innerHTML = '<div id="hotReloadMonitor">Reloaded</div>';
      }
    });
  }
}
