<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab
        title="発表中の気象注意報・警報など"
        icon="mdi-weather-pouring"
      />
    </template>
    <div class="d-flex align-center text-body-2 px-2 pt-2">
      <div v-if="!!announceDatetime">
        <span class="mx-2 text-sm-body-2 text-caption">{{
          announceDatetime
        }}</span>
        <span class="text-sm-body-2 text-caption">更新</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <a
          href="https://www.jma.go.jp/bosai/map.html#10/35.016/138.005/&elem=all&contents=warning"
          target="_blank"
        >
          <span>
            気象庁HP
            <v-icon small class="mx-0">
              mdi-open-in-new
            </v-icon>
          </span>
        </a>
      </div>
    </div>
    <div class="d-flex mt-2 px-1">
      <v-spacer></v-spacer>
      <v-card color="white" elevation="0" tile>
        <div class="d-flex mx-1 my-1">
          <JMATypeLavel
            v-for="it in infoType.filter((v) => v.showLegend)"
            :key="it.fieldPath"
            :color="it.color"
            :dark="it.dark"
            :value="it.label"
            wrapper-class="mx-0"
          />
        </div>
      </v-card>
    </div>
    <div class="mt-2">
      <v-simple-table>
        <tbody>
          <tr
            v-for="{ Id, Name, __hasData, __hasInfo, __info } in displayRecords"
            :key="Id"
          >
            <td>
              {{ Name }}
            </td>
            <td>
              <template v-if="!__hasData">
                情報はありません。
              </template>
              <template v-else-if="!__hasInfo">
                発表はありません。
              </template>
              <template v-else>
                <div class="d-flex mx-n1">
                  <JMATypeLavel
                    v-for="it in __info"
                    :key="`${Id}${Name}${it.fieldPath}${it.label}`"
                    :color="it.color"
                    :dark="it.dark"
                    :value="it.label"
                    wrapper-class="mx-0"
                  />
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';
import JMATypeLavel from '@/components/app/JMATypeLavel.vue';

import { infoType } from '@/assets/js/objects/CDS_T_JMAInfo__c';

export default {
  components: {
    BarCard,
    BarCardTab,
    JMATypeLavel,
  },
  data: () => ({
    loading: false,

    infoType,

    // 発表日時
    announceDatetime: null,

    // レコード
    records: [],
  }),

  computed: {
    displayRecords() {
      return this.records.map((r) => {
        // 最新報のレコードを取り出し
        const latest = r.JMAInfoToJMAInfoM__r?.[0];

        const __info =
          (latest &&
            _.sortBy(infoType, 'order').reduce((prev, next) => {
              const value = latest[next.fieldPath];
              if (!value) return prev;
              return [
                ...prev,
                ...value
                  .split(';')
                  .map((v) => v.trim())
                  .filter((v) => !!v)
                  .map((v) => ({
                    ...next,
                    label: v,
                  })),
              ];
            }, [])) ||
          [];

        return {
          ...r,
          // 最新報が存在するか
          __hasData: !!latest,
          // 情報は存在するか
          __hasInfo: __info.length > 0,
          __info,
        };
      });
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await Promise.all([this.getStat()]);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 情報の取得
    async getStat() {
      const { objList = null, announceDatetime = null } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getJMAInfoStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};

      if (objList) {
        this.records = objList;
      }

      if (announceDatetime) {
        this.$set(
          this,
          'announceDatetime',
          this.$dateFns.fnsFormat(
            announceDatetime,
            '',
            'yyyy年MM月dd日(E) HH:mm',
          ),
        );
      }
    },
    handleClickBar() {
      this.$transition.to(
        'CDS_VF_JMAInfoList',
        {},
        {
          notApplyRetUrl: true,
        },
      );
    },
  },
};
</script>
