import Vue from 'vue';
import Vuex from 'vuex';
import snackbarInitialize from '@/store/plugins/snackbar-initialize';

import disaster from '@/store/disaster';
import user from '@/store/user';
import snackbar from '@/store/snackbar';
// import mainMenu from '@/store/mainMenu';
import menu from '@/store/menu';
import loading from '@/store/loading';
import detail from '@/store/detail';

Vue.use(Vuex);

export default ({ initState = {} }) => {
  const modules = {
    disaster,
    user,
    snackbar,
    // mainMenu,
    menu,
    loading,
    detail,
  };
  // initStateに存在する同名のmoduleに関して初期stateをセットする
  Object.entries(initState).forEach(([key, value]) => {
    if (modules[key]) modules[key].state = value;
  });
  return new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules,
    plugins: [snackbarInitialize()],
  });
};
