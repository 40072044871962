import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import Vue from 'vue';

let _username = 'admin';

export default class Cognito {
  constructor(props) {
    if (Vue.appConfig?.cognito?.use !== 'false') {
      // ユーザプール
      this.userPool = new CognitoUserPool({
        UserPoolId: props.UserPoolId,
        ClientId: props.ClientId,
      });
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }

  /**
   * ログイン
   * @param {*} username
   * @param {*} password
   */
  async login(username, password) {
    // とりあえずcognitoが無効な場合はadminユーザを返す
    if (!this.enabled) {
      return {
        username: _username,
      };
    }
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const user = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: resolve,
        onFailure: reject,
        // 初回認証時はパスワードの変更が要求されるので、仮パスワードと同じパスワードを再設定する
        newPasswordRequired(user_attributes, required_attributes) {
          console.log(
            'newPasswordRequired',
            user_attributes,
            required_attributes,
          );
          user.completeNewPasswordChallenge(
            authDetails.password,
            {},
            {
              onSuccess(result) {
                console.log('successfully change password', result);
                resolve();
              },
              onFailure(err) {
                console.warn('failure change password', err);
                reject();
              },
            },
          );
        },
      });
    });
  }

  /**
   * ログアウト
   */
  async logout() {
    if (!this.enabled) return;
    this.userPool.getCurrentUser()?.signOut();
  }

  /**
   * ログイン済みのユーザ情報を取得する
   * @returns ユーザ情報
   */
  async getLoggedInUser() {
    // とりあえずcognitoが無効な場合はadminユーザを返す
    if (!this.enabled) return { username: _username };
    try {
      const userInfo = await this.getAuthenticatedUser();
      if (userInfo) {
        const { user } = userInfo;
        const userAttributes = await this.getUserAttributes(user);
        return {
          ...user,
          ...userAttributes,
        };
      }
    } catch (error) {
      console.error('ログイン済みユーザ情報の取得に失敗しました。');
      console.error(error);
    }
    return null;
  }

  /**
   * ログインしているかどうか
   * @returns ログインしていればtrue
   */
  async isLoggedIn() {
    if (!this.enabled) return true;
    try {
      const user = await this.getAuthenticatedUser();
      if (user) return true;
    } catch (error) {
      console.error('ログイン状態の取得に失敗しました');
      console.error(error);
    }
    return false;
  }

  /**
   * トークンの取得
   * @returns token
   */
  async getAuthToken() {
    if (!this.enabled) return 'dummy_token';
    const userInfo = await this.getAuthenticatedUser();
    if (!userInfo) return null;
    return userInfo.session?.getIdToken()?.getJwtToken() || null;
  }

  /**
   * AWS SDKの通信で利用する認証情報を取得
   * @returns
   */
  async getCredentials() {
    const { appConfig } = window;

    const credentialsOptions = {
      client: new CognitoIdentityClient({ region: appConfig.region }),
      identityPoolId: appConfig.cognito.idPoolId,
      logins: {
        [appConfig.cognito
          .userPoolProviderName]: await Vue.cognito.getAuthToken(),
      },
    };

    return fromCognitoIdentityPool(credentialsOptions);
  }

  /**
   * パスワード変更
   * @param {string} oldPassword
   * @param {string} newPassword
   */
  async changePassword(oldPassword, newPassword) {
    if (!this.enabled) return {};
    const userInfo = await this.getAuthenticatedUser();
    if (!userInfo) throw new Error('ログインしていません。');
    const { user } = userInfo;
    return await new Promise((resolve, reject) => {
      user.changePassword(oldPassword, newPassword, (err, result) => {
        console.log('password changed result: ' + result);
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  /**
   * 属性を更新する
   * @param {object} obj
   * @returns 結果
   */
  async updateAttributes(obj) {
    if (!this.enabled) return {};
    try {
      const userInfo = await this.getAuthenticatedUser();
      if (userInfo) {
        const { user } = userInfo;

        const attrList = (await this.jsonToAttribute(obj)).map(
          (attr) => new CognitoUserAttribute(attr),
        );

        const result = await new Promise((resolve, reject) => {
          user.updateAttributes(attrList, (err, result) => {
            if (err) {
              reject(err);
            } else {
              resolve(result);
            }
          });
        });

        return result;
      }
    } catch (error) {
      console.error('ユーザ情報(属性)の更新に失敗しました。');
      console.error(error);
    }
  }
  /**
   * パスワードリセット
   * @param {*} username
   */
  async forgotPassword(username) {
    const user = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
    return new Promise((onSuccess, onFailure) =>
      user.forgotPassword({
        onSuccess,
        onFailure,
        inputVerificationCode: ({ CodeDeliveryDetails }) =>
          onSuccess({ CodeDeliveryDetails, user }),
      }),
    );
  }

  /*********************************/
  /* ユーティリティ系
  /*********************************/

  /**
   * cognitoUserListを取得する
   * @returns
   */
  async getListUser() {
    const { appConfig } = window;
    const client = new CognitoIdentityProviderClient({
      region: appConfig.region,
      credentials: await this.getCredentials(),
    });
    const command = new ListUsersCommand({
      UserPoolId: this.userPool.getUserPoolId(),
    });
    return await client.send(command);
  }

  /**
   * 認証したユーザを取得
   * @returns user, session
   */
  async getAuthenticatedUser() {
    // cognitoユーザ
    const user = this.userPool.getCurrentUser();
    if (!user) return null;

    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            resolve({
              user,
              session,
            });
          }
        }
      });
    });
  }

  /**
   * ユーザの情報を取得
   * @param {*} user cognito user
   * @returns user attributes
   */
  async getUserAttributes(user) {
    const attr = await new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
    return this.attributeToJson(attr);
  }

  /**
   * attributesはこのような形で入ってくるので整形する
   * const sample = [
   *   {
   *     Name: 'email',
   *     Value: 'xxx@gmail.com'
   *   },
   *   ...
   * ]
   * @param {*} attr
   * @returns json
   */
  async attributeToJson(attr) {
    if (!Array.isArray(attr) || attr.length === 0) return {};

    const result = {};
    attr.map((att) => {
      result[att.getName()] = att.getValue();
    });
    return result;
  }

  /**
   * attributesはこのような形にしなければならないので整形する
   * const sample = [
   *   {
   *     Name: 'email',
   *     Value: 'xxx@gmail.com'
   *   },
   *   ...
   * ]
   * @param {object} json
   * @returns attrArray
   */
  async jsonToAttribute(obj) {
    if (!obj || Object.keys(obj).length === 0) return [];
    const result = [];
    Object.keys(obj).map((key) => {
      const value = obj[key];
      result.push({
        Name: key,
        Value: value,
      });
    });
    return result;
  }
}
