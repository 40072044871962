var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"search-props":{
      syncConditionWithUrl: true,
    },"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      canCreate: false,
      canEdit: false,
      canFix: false,
    }}},'ListView',{
      objectName: 'SBS_T_ConnectResult__c',
      detailPageName: 'CDS_VF_SBSConnectResultDetail',
      isDisasterRelated: false,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }