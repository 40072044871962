import createDocument from 'docx-templates';

/**
 * ドキュメントを生成する
 * @param {*} param0.data データ
 * @param {*} param0.template テンプレート
 * @param {*} param0.additionalJsContext 追加
 * @returns
 */
export async function generateDocument({
  data,
  template,
  additionalJsContext = {},
}) {
  const doc = await createDocument({
    data,
    template,
    additionalJsContext,
    cmdDelimiter: ['{', '}'],
  });

  return doc;
}

/**
 * ファイルをダウンロードさせる
 * @param {*} param0.data データ
 * @param {*} param0.fileName ファイル名
 * @param {*} param0.contentType コンテントタイプ
 */
export async function downloadData({
  data,
  fileName,
  contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}) {
  const blob = new Blob([data], { type: contentType });

  //IE,Edgeのダウンロード
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  }
  //IE,Edge以外のダウンロード
  else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}
