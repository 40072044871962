<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <template #activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on">
          <v-icon left>
            mdi-upload
          </v-icon>
          CSVインポート
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 blue-grey lighten-5">
          CSVインポート
        </v-card-title>

        <v-card-text class="text--primary">
          <v-row>
            <v-col cols="12">
              <div class="my-3">
                <h3>CSVファイルを選択してください</h3>
              </div>
              <div>
                <v-form v-model="fileInput.form" lazy-validation>
                  <v-file-input
                    v-model="fileInput.value"
                    :rules="fileInput.rules"
                    accept="text/csv"
                    show-size
                    dense
                    hide-details="auto"
                    truncate-length="15"
                    outlined
                    placeholder="ファイルを選択..."
                  ></v-file-input>
                </v-form>
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn
                :disabled="!fileInput.value || !fileInput.form"
                color="primary"
                depressed
                @click="startImport"
              >
                インポート開始
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="import_log_outer elevation-1">
                <div class="import_log_inner">
                  <p
                    v-for="(log, logIndex) in logs"
                    :key="`${logIndex}--${log}`"
                    class="mb-1"
                  >
                    {{ log }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { parse } from 'csv-parse/sync';
import iconv from 'iconv-lite';

export default {
  props: {
    label: { type: String, default: '' },
    instance: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialog: false,
    fileInput: {
      value: null,
      form: null,
      rules: [
        (value) => {
          const result =
            !value ||
            value.type === 'text/csv' ||
            'csvファイルを指定してください。';
          return result;
        },
      ],
    },

    // 追加削除ヘッダー
    editHeader: '追/修/削',

    logs: [],
  }),

  computed: {
    objectName() {
      return this.$query.current().objectName;
    },
  },

  methods: {
    async startImport() {
      await this.$store.dispatch('loading/register', this.loadData());
    },

    async loadData() {
      this.logs = [];

      try {
        this.addLog('インポートを開始します');

        // ファイルの確認
        const csvFile = this.fileInput.value;

        if (!csvFile) throw new Error('ファイルが指定されていません。');
        if (csvFile.type !== 'text/csv')
          throw new Error('CSVファイルを指定してください。');
        if (csvFile.size === 0)
          throw new Error(
            'ファイルサイズが0です。正しいファイルを指定してください。',
          );

        // ファイルの読み込み
        const fileContents = await new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.onload = function (event) {
              if (event.target.readyState !== 2) return;
              if (event.target.error) {
                return reject(event.target.error);
              }
              const buffer = event.target.result;
              const decodeString = iconv.decode(
                Buffer.from(buffer),
                'shift_jis',
              );
              return resolve(decodeString);
            };
            reader.readAsArrayBuffer(csvFile);
          } catch (error) {
            reject(error);
          }
        });

        // csvに変換
        const [csvHeader, ...csvJson] = parse(fileContents, {});

        if (csvHeader.length === 0 || csvHeader.every((v) => !v))
          throw new Error('データがありません。');
        if (csvHeader[0] !== this.editHeader)
          throw new Error('CSVインポート用のCSVファイルではありません。');

        // 件数を出力
        this.addLog(`総数: ${csvJson.length} 件`);
        // 行数を付与
        const allRow = csvJson.map((row, rowNo) => ({ row, rowNo }));
        // 追加
        const addRow = allRow.filter(({ row }) => row[0] === '追');
        // 修正
        const updateRow = allRow.filter(({ row }) => row[0] === '修');
        // 削除
        const deleteRow = allRow.filter(({ row }) => row[0] === '削');
        // 何もしない
        const keepRow = allRow.filter(
          ({ row }) => !['追', '修', '削'].includes(row[0]),
        );
        this.addLog(
          `追加: ${addRow.length} 件, 修正: ${updateRow.length} 件, 削除: ${deleteRow.length} 件, それ以外(何もしない): ${keepRow.length} 件`,
        );

        // 何もしなければ終了
        if (keepRow.length === csvJson.length) {
          this.addLog('何もしません。');
          return;
        }

        // インポート開始
        const importResult = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'importCsv',
          params: {
            objectName: this.objectName,
            header: csvHeader.slice(1),
            add: addRow.map(({ row, rowNo }) => ({ rowNo, row: row.slice(1) })),
            update: updateRow.map(({ row, rowNo }) => ({
              rowNo,
              row: row.slice(1),
            })),
            del: deleteRow.map(({ row, rowNo }) => ({
              rowNo,
              row: row.slice(1),
            })),
          },
        });

        if (importResult.status !== 'ok') {
          const e = new Error();
          e.messages = importResult.messages;
          throw e;
        }

        this.addLog('インポートに成功しました');
        this.saveComplete('インポートに成功しました');
        // 後処理
        // 空にする
        this.fileInput.value = null;
        // リロード
        this.instance?.handleReloadData?.();
      } catch (error) {
        this.addLog(error?.messages || error?.message);
        this.addLog('インポートに失敗しました。');
        this.saveFail(error?.messages?.join(',') || error?.message);
        console.warn(error);
      } finally {
        this.addLog('インポートを終了します');
      }
    },

    addLog(...messages) {
      messages.map((m) => {
        this.logs.push(m);
      });
    },

    /********** その他 **********/
    ...mapActions('snackbar', ['saveComplete', 'saveFail']),
  },
};
</script>

<style lang="scss" scoped>
.import_log_outer {
  height: 100px;
  overflow-y: scroll;
  border: 1px solid #90a4ae;
}
</style>
