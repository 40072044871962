<template>
  <div>
    <Layout
      v-model="slotProps.value"
      name="input_form"
      :layout="layout"
      :object-info="objectInfo"
      :edit-mode="slotProps.editMode"
      @submit-raw="slotProps.validate"
    />
    <!-- 区域リスト -->
    <ListView
      v-if="slotProps.value.Id"
      v-bind="{
        objectName: 'CDS_T_EvacuationAdvisory__c',
      }"
      :search-props="{
        canSearch: false,
        forceCondition: {
          CDS_T_EvacuationAdvisoryManager__c: slotProps.value.Id,
        },
      }"
      :table-props="{
        defaultListOptions,
        canCreate: false,
        canEdit: false,
        canReload: false,
        onClickFunc: null,
      }"
      class="evacuation-advisory-detail-area-table"
    >
    </ListView>
  </div>
</template>

<script>
import Layout from '@/components/detail/Layout';
import ListView from '@/components/list/ListView';

export default {
  components: {
    Layout,
    ListView,
  },

  props: {
    slotProps: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    // レイアウト
    layout: {},
    // オブジェクト情報
    objectInfo: {},
    // 区域のリストのソート
    defaultListOptions: {
      sortBy: ['LineNo__c'],
      sortDesc: [false],
      itemsPerPage: 100,
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadLayout()]),
      );
    },

    // レイアウトの読み込み
    async loadLayout() {
      const { layoutName, objectName } = this.slotProps;
      const { layout, objectInfo } = await this.$util.getLayout(
        objectName,
        layoutName,
      );
      // 「非表示」セクションを隠す
      this.$set(this, 'layout', {
        ...layout,
        sections: layout.sections.filter(
          (section) => section.label != '非表示',
        ),
      });
      this.$set(this, 'objectInfo', objectInfo);
    },
  },
};
</script>

<style lang="scss">
.v-application .evacuation-advisory-detail-area-table {
  .v-data-table.cdsTable.listView {
    table tbody {
      tr td {
        div.AreaName__c,
        div.AreaNameKana__c {
          white-space: normal;
        }
      }
    }
  }
}
</style>
