var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" 臨時拠点データ ")]),_c('v-card-text',[_c('FormulateForm',_vm._b({on:{"submit-raw":_vm.handleOnSubmit},model:{value:(_vm.extraForm.value),callback:function ($$v) {_vm.$set(_vm.extraForm, "value", $$v)},expression:"extraForm.value"}},'FormulateForm',_vm.extraForm.attrs,false),[_c('FormulateInput',{staticClass:"extra-area",attrs:{"type":"group","name":"extra","repeatable":"","remove-position":"after","label":"臨時拠点"},scopedSlots:_vm._u([{key:"default",fn:function({index}){return [_c('v-row',{staticClass:"extra-row ma-0"},_vm._l((_vm.displayFields),function(f){return _c('field',_vm._b({key:f.fieldPath},'field',{
                ...f,
                fieldInfo: {
                  ...f.fieldInfo,
                  reverseGeocodingEventhandler: _vm.handleOnDecidedLocation(
                    index,
                  ),
                },
                // 名称変更は許可しない
                readonly: _vm.recordHasId(index) && f.fieldPath === 'Name',
              },false))}),1)]}},{key:"remove",fn:function({removeItem, index}){return [_c('div',{staticClass:"d-flex px-2 align-center"},[(!_vm.getExtraRecord(index).Id)?_c('v-btn',{attrs:{"icon":""},on:{"click":removeItem}},[_c('v-icon',[_vm._v("mdi-close")])],1):_c('div',{staticStyle:{"width":"36px","height":"36px"}})],1)]}},{key:"addmore",fn:function({addMore}){return [_c('v-btn',{attrs:{"depressed":"","color":"blue-grey lighten-5"},on:{"click":addMore}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" 臨時拠点を追加する ")],1)]}}])})],1)],1),_c('v-card-actions',[_c('Confirm',{attrs:{"message":"「臨時拠点」は、一度作成すると名称変更および拠点の削除はできません。(拠点名称は正しいですか?)"},on:{"on-click-yes":_vm.handleClickExtraButton},scopedSlots:_vm._u([{key:"default",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"primary"}},on),[_vm._v(" 臨時拠点データ保存 ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }