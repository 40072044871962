<template>
  <public-layout>
    <v-card class="elevation-12" :tile="isMobile">
      <v-card-text>
        <v-row class="py-4">
          <v-col align-self="center" class="text-center">
            <logo />
          </v-col>
          <v-col cols="12">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit="validate"
            >
              <v-text-field
                v-model="value.username"
                prepend-icon="mdi-account"
                label="ユーザ名"
                :rules="[rules.required]"
                required
                outlined
                dense
                @keyup.enter="validate"
              />
              <v-text-field
                v-model="value.password"
                prepend-icon="mdi-key"
                label="パスワード"
                :rules="[rules.required]"
                required
                outlined
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @keyup.enter="validate"
                @click:append="showPassword = !showPassword"
              />
              <!-- <ForgotPasswordModal /> -->
              <v-btn
                :disabled="!valid"
                color="info"
                depressed
                block
                @click="validate"
              >
                ログイン
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div style="height: 100px;"></div>
  </public-layout>
</template>

<script>
import publicLayout from '@/components/layout/public';
import logo from './Logo.vue';
// import ForgotPasswordModal from './ForgotPasswordModal.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    publicLayout,
    logo,
    // ForgotPasswordModal,
  },
  data: () => ({
    valid: true,
    showPassword: false,
    value: {
      username: null,
      password: null,
    },
    rules: {
      required: (v) => !!v || '必須項目です。',
    },
    exValue: {
      v1: null,
      v2: null,
    },
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  mounted() {
    let uname = this.$route.query.uname;
    let pwd = this.$route.query.pwd;
    this.value.username = uname === undefined ? null : uname;
    this.value.password = pwd === undefined ? null : pwd;
  },
  methods: {
    /**
     * フォームのバリデート
     */
    async validate() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          this.value.password = null;
        });
        return;
      }
      await this.$store.dispatch('loading/register', this.login());
    },

    /**
     * ログイン
     */
    async login() {
      try {
        const result = await this.$cognito.login(
          this.value.username,
          this.value.password,
        );
        console.log('loginresult:', result);
        if (await this.$cognito.isLoggedIn()) {
          await this.$store.dispatch('user/erase');
          window.location.href = '/';
        } else {
          this.openSnackBar({
            message: 'ログインに失敗しました。',
            props: {
              color: 'red',
              bottom: true,
              timeout: 10000,
            },
            closable: true,
          });
        }
      } catch (error) {
        console.error(error);
        this.openSnackBar({
          message:
            'ログインに失敗しました。ユーザ名とパスワードを確認してください。',
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        this.value.password = null;
        this.$refs.form.resetValidation();
      }
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
