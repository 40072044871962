<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>

    <ListView
      v-bind="{
        objectName: 'CDS_T_Publicity__c',
        detailLayoutName: 'DetailPreviewLayout',
        detailPageName: 'CDS_VF_PublicityDetail',
      }"
      :table-props="{
        csvFieldSetName: 'CSVFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
        canEdit: false,
        canCreate: isEditMode,
      }"
      :search-props="{
        syncConditionWithUrl: true,
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';

export default {
  name: 'PublicityList',

  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  computed: {
    isEditMode() {
      return this.$query.current()?.e === 'true';
    },
  },
};
</script>
