var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"search-props":{
      syncConditionWithUrl: true,
      applyErrataToCondition: !_vm.le,
      defaultSearchCondition: _vm.defaultSearchCondition,
    },"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      listFieldSetName: 'FixListFieldSet,ListFieldSet',
      defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
      csvFieldSetName: 'FixListFieldSet,ListFieldSet',
      createButtonName: '臨時本部体制',
      masterFieldName: 'CDS_M_Organization__c',
      canCreate: _vm.$gc.operationMaster.includes(
        _vm.$store.state.user.organization.Name,
      ),
      canEdit: _vm.$gc.operationMaster.includes(
        _vm.$store.state.user.organization.Name,
      ),
      viewAttachment: true,
      viewComment: !_vm.le,
    },"google-map-props":{
      gisDynamicSettingName: 'GIS_C_D_Headquarter',
    }}},'ListView',{
      objectName: 'CDS_T_Headquarter__c',
      detailPageName: 'CDS_VF_HeadquarterDetail',
      isDisasterRelated: true,
      showImageTab: true,
      showMapTab: true,
      hasVersion: true,
      masterObjectName: 'CDS_M_Organization__c',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }