<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-ruler</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="distance">
        <v-list-item-action>
          <v-icon>mdi-ruler</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>距離</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="area">
        <v-list-item-action>
          <v-icon>mdi-vector-polygon</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>面積</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { waitGoogleMapLoad } from '@/googlemap/util';

export default {
  data: () => ({ drawingManager: {} }),
  methods: {
    /**
     * 距離計測
     */
    async distance() {
      this.drawingManager.setDrawingMode('polyline');
      try {
        const { overlay } = await new Promise((resolve) =>
          google.maps.event.addListenerOnce(
            this.drawingManager,
            'overlaycomplete',
            resolve,
          ),
        );
        this.drawingManager.setDrawingMode('');
        // km少数第一位で四捨五入
        const distance = Math.round(overlay.getMeasureResult() / 100) / 10;
        const { map } = this.$store.state.GIS;

        await map.showSnackbar({
          body: `計測結果:${distance.toString()}km`,
          icon: 'mdi-map-marker-radius',
          color: 'primary',
        });
      } catch (error) {
      } finally {
        if (this.drawingManager.current)
          this.drawingManager.current.setMap(null);
        this.drawingManager.setDrawingMode('');
      }
    },
    /**
     * 面積測定
     */
    async area() {
      this.drawingManager.setDrawingMode('polygon');
      try {
        const { map } = this.$store.state.GIS;

        const { overlay } = await new Promise((resolve) =>
          google.maps.event.addListenerOnce(
            this.drawingManager,
            'overlaycomplete',
            resolve,
          ),
        );
        this.drawingManager.setDrawingMode('');
        // km少数第一位で四捨五入
        const space = Math.round(overlay.getMeasureResult() / 100000) / 10;
        await map.showSnackbar({
          body: `計測結果:${space.toString()}k㎡`,
          icon: 'mdi-map-marker-radius',
          color: 'primary',
        });
      } catch (error) {
      } finally {
        if (this.drawingManager.current)
          this.drawingManager.current.setMap(null);
        this.drawingManager.setDrawingMode('');
      }
    },
  },
  async mounted() {
    await waitGoogleMapLoad();
    const { map } = this.$store.state.GIS;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      map,
      drawingControl: false,
    });
    // 常に1図形しか表示させない
    this.drawingManager.addListener('overlaycomplete', ({ overlay }) => {
      if (this.drawingManager.current) this.drawingManager.current.setMap(null);
      this.drawingManager.current = overlay;
    });
  },
};
</script>

<style scoped></style>
