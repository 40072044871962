<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab
        title="避難所"
        icon="mdi-home-account"
        :active="tab === 0"
        :on="{
          click: () => {
            tab = 0;
          },
        }"
      />
      <BarCardTab
        title="福祉避難所"
        icon="mdi-human-wheelchair"
        :active="tab === 1"
        :on="{
          click: () => {
            tab = 1;
          },
        }"
      />
      <BarCardTab
        title="応急救護所"
        icon="mdi-medical-bag"
        :active="tab === 2"
        :on="{
          click: () => {
            tab = 2;
          },
        }"
      />
    </template>
    <div>
      <Refuge
        v-if="tab === 0"
        :update-event="updateEvent[0]"
        @loading="handleUpdateLoading(0, $event)"
      ></Refuge>
      <WelfareShelter
        v-if="tab === 1"
        :update-event="updateEvent[1]"
        @loading="handleUpdateLoading(1, $event)"
      ></WelfareShelter>
      <FirstAid
        v-if="tab === 2"
        :update-event="updateEvent[2]"
        @loading="handleUpdateLoading(2, $event)"
      ></FirstAid>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';

import Refuge from './Refuge.vue';
import WelfareShelter from './WelfareShelter.vue';
import FirstAid from './FirstAid.vue';

export default {
  components: {
    BarCard,
    BarCardTab,
    Refuge,
    WelfareShelter,
    FirstAid,
  },
  data: () => ({
    loadingAll: [false, false, false],
    tab: 0,
    updateEvent: [{}, {}, {}],
  }),
  computed: {
    loading() {
      return this.loadingAll.some((v) => v);
    },
  },
  methods: {
    handleClickBar() {
      switch (this.tab) {
        case 0: {
          return this.$transition.to('CDS_VF_RefugeList', null, {
            notApplyRetUrl: true,
          });
        }
        case 1: {
          return this.$transition.to('CDS_VF_WelfareShelterList', null, {
            notApplyRetUrl: true,
          });
        }
        case 2: {
          return this.$transition.to('CDS_VF_FirstAidList', null, {
            notApplyRetUrl: true,
          });
        }
      }
    },
    handleUpdateLoading(index, to) {
      this.loadingAll.splice(index, 1, to);
    },
    init() {
      this.updateEvent.splice(this.tab, 1, {});
    },
  },
};
</script>
