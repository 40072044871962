var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"search-props":{
      syncConditionWithUrl: true,
    },"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      canEdit: false,
      viewAttachment: true,
      forInjectComponentData: {
        config: _vm.config,
      },
    }}},'ListView',{
      objectName: 'CDS_T_Chronology__c',
      detailPageName: 'CDS_VF_ChronologyDetail',
      isDisasterRelated: true,
      showImageTab: true,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }