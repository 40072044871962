// メニューを画面遷移のたびにDBから取得しないためにストアに保持する
export default {
  namespaced: true,

  state: {
    editing: false,
  },
  getters: {},

  mutations: {
    setEditing(state, value) {
      state.editing = value;
    },
  },
  actions: {
    setEditing({ commit }, value) {
      commit('setEditing', !!value);
    },
  },
};
