import App from './App.vue';

export default {
  path: '/CDS_VF_DamageReportDetail',
  name: 'CDS_VF_DamageReportDetail',
  component: App,
  meta: {
    pageProperty: {
      title: '事案報告詳細',
      controller: 'CDS_CTR_DamageReportDetail',
    },
  },
};
