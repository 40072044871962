<template>
  <div>
    <v-btn
      v-if="slotProps.editMode"
      color="primary"
      outlined
      class="d-flex align-center mb-5 mx-5"
      @click="openModal()"
      >「課/班」編集</v-btn
    >
    <v-banner outlined class="mb-5 mx-5">
      課/班<br />
      <td v-if="sectionName.length == 0">-</td>
      <td v-for="(section, index) in sectionName" :key="section.id">
        {{ section.name }}<span v-if="index != sectionName.length - 1"> ,</span>
      </td>
    </v-banner>
    <v-dialog v-model="showModal" max-width="500" scrollable>
      <v-card>
        <v-card-title class="text-headline">
          「課/班」編集
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="modalHeight">
          <v-row v-for="section in sectionName" :key="section.id">
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                v-model.trim="section.name"
                class="py-2"
              />
            </v-col>
            <v-col cols="3">
              <v-btn
                @click="deleteRow(section.id)"
                color="error"
                outlined
                class="mt-2"
              >
                <v-icon>mdi-delete</v-icon>
                <span>削除</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-btn @click="addRow()" color="primary" outlined>
                追加
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="closeModal()" color="blue darken-1" text>
            キャンセル
          </v-btn>
          <v-btn @click="saveData()" color="blue darken-1" dark>反映</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    slotProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    propsValue: {
      get() {
        return this.slotProps.value;
      },
      set(v) {
        this.slotProps.change(v);
      },
    },
  },
  data: () => ({
    // モーダル表示用
    showModal: false,
    // 課/班オブジェクト
    sectionName: [],
  }),
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.loadSectionName()]);
    },
    loadSectionName() {
      if (this.propsValue.SectionName__c)
        this.sectionName = JSON.parse(this.propsValue.SectionName__c);
    },
    // モーダル処理
    openModal() {
      this.loadSectionName();
      if (this.sectionName.length == 0) {
        this.sectionName.push({
          id: 1,
          name: '',
          staffNum: 0,
          totalStaffNum: 0,
        });
      }
      this.showModal = true;
    },
    closeModal() {
      this.loadSectionName();
      this.showModal = false;
    },
    addRow() {
      const addId =
        this.sectionName.length == 0
          ? 1
          : this.sectionName[this.sectionName.length - 1].id + 1;
      this.sectionName.push({
        id: addId,
        name: '',
        staffNum: 0,
        totalStaffNum: 0,
      });
    },
    deleteRow(id) {
      const index = this.sectionName.findIndex((v) => v.id === id);
      this.sectionName.splice(index, 1);
    },
    saveData() {
      //未入力を削除
      this.sectionName = this.sectionName.filter((v) => !!v.name);

      if (this.sectionName) {
        this.$set(
          this.propsValue,
          'SectionName__c',
          JSON.stringify(this.sectionName),
        );
      }
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.modalHeight {
  height: 500px;
}
.tableSize {
  width: 100%;
}
</style>
