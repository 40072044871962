import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import popupWindow from '@/components/gis/popupWindow';
import drawControl from '@/components/gis/drawControl';

import * as Util from '@/googlemap/util';
export default (DrawingManager) => {
  /**
   * 拡張描画ツールクラス
   */
  class CustomDrawingManger extends DrawingManager {
    set enable(val) {
      this.__enable = val;
      this._drawControl.$children[0].enable = val;
      if (val) {
        // 描画モード有効時
        // geometryがあるかないか
        let hasGeometry = false;
        // 各feature内geometryにイベントを追加
        this.features.forEach((feature) => {
          const geometries = [].concat(
            feature.overlay.features || feature.overlay,
          );
          hasGeometry = hasGeometry || geometries.length > 0;
          if (!feature.clickEvent) {
            geometries.forEach(
              (geometry) =>
                (feature.clickEvent = geometry.addListener('click', () =>
                  this.clickEventHandler(feature),
                )),
            );
          }
        });
        // 描画モード有効時かつ登録済みの図形が1つもない場合にGPSを取得する
        if (!hasGeometry) {
          (async () => {
            const latlng = await this.getGps();
            if (latlng) {
              this.getMap().setCenter(latlng);
              this.getMap().setZoom(16);
            }
          })();
        }
      } else {
        // 描画モード無効時
        this.setDrawingMode('');
        this.features.forEach(({ clickEvent, ...feature }) => {
          if (clickEvent) google.maps.event.removeListener(clickEvent);
          feature.overlay.setEditable && feature.overlay.setEditable(false);
          feature.overlay.setDraggable && feature.overlay.setDraggable(false);
        });
      }
    }
    get enable() {
      return this.__enable;
    }
    /**
     * コンストラクタ
     */
    constructor(options = {}) {
      super(options);
      // 標準のdrawingControlを非表示にする
      this.setOptions({
        drawingControl: false,
      });
      // 図形登録後に登録処理を操作履歴に追加する
      this.addListener('overlaycomplete', (feature) => {
        this._drawControl.$children[0].mode = 0;
        this.recordCommand(
          'add',
          feature,
          (param) => {
            if (this.onlyOne) {
              this.features.forEach(({ overlay }) => overlay.setMap(null));
              this.features.splice(0);
            }
            this.addFeature(param);
          },
          (param, prev) => {
            if (this.onlyOne && prev) {
              this.addFeature(prev);
            }
            this.deleteFeature(param);
          },
        );
      });
      // 各種設定値等を初期化
      this.usePopupWindow = options.usePopupWindow;
      this.setColor(options.color || '#000000');
      this.events = [];
      this.features = [];
      this.circleSetting = {
        radius: [1, 10, 20],
        scale: 0,
      };
      // コントローラDOM生成
      this.reverseGeocodingEventhandler = options.reverseGeocodingEventhandler;
      const useReverseGeocoding =
        typeof this.reverseGeocodingEventhandler === 'function';
      this._drawControl = new Vue({
        vuetify,
        render: (h) =>
          h(drawControl, {
            props: {
              drawController: this,
              useReverseGeocoding,
              ...options.drawControlProps,
            },
            on: {
              // 画面の中心点を住所に設定ボタンクリックイベント
              'reverse-geocoding': async () => {
                try {
                  this._drawControl.$children[0].mode = 0;
                  let response;
                  // 単一図形のみの場合
                  if (this.onlyOne) {
                    const center = this.features[0].overlay.getCenter();
                    response = await Util.reverseGeocoding(center);
                  } else {
                    // 複数図形の場合
                    // 選択点を指定
                    const { latLng } = await this.getPointPostion();
                    response = await Util.reverseGeocoding(latLng);
                  }
                  // 住所情報を設定
                  this.reverseGeocodingEventhandler(response);
                  // 完了スナックバーを表示
                  this.map.showSnackbar({
                    body: '選択点を住所に反映しました。',
                    icon: 'mdi-information-outline',
                    timeout: 4000,
                    color: 'success',
                  });
                } catch (error) {
                  console.error(error);
                  this.map.showSnackbar({
                    body: '住所の取得に失敗しました',
                    icon: 'mdi-alert',
                    timeout: 4000,
                    color: 'error',
                  });
                } finally {
                  // カーソルを戻す
                  this.map.setOptions({ draggableCursor: null });
                }
              },
              ...options.drawControlOn,
            },
          }),
      });
      this._drawControl.$mount();
      // 地図が生成されている場合のみコントローラを描画
      if (this.map) {
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
          this._drawControl.$el,
        );
      }
    }
    /**
     * 地点選択を行う
     */
    getPointPostion() {
      return new Promise((resolve) => {
        // 説明文スナックバーを表示
        this.map.showSnackbar({
          body: '住所を指定する場所をクリックしてください。',
          icon: 'mdi-map-marker-radius',
          color: 'primary',
        });
        // カーソルを変更
        this.map.setOptions({ draggableCursor: 'crosshair' });
        google.maps.event.addListenerOnce(this.map, 'click', resolve);
      });
    }
    /**
     * 操作履歴を追加して実行する
     * @param {*} コマンド名
     * @param {*} 引数
     * @param {*} 操作
     * @param {*} もとに戻す操作
     * @param {*} trueの場合操作を実行しない
     */
    recordCommand(name, params, action, undo, noExec) {
      if (!this.commandDisable) {
        const newCommand = { name, params, action, undo };
        this.command = this.command || [];
        this.commandIndex = this.commandIndex || 0;
        if (this.commandIndex < this.command.length) {
          this.command.splice(
            this.commandIndex,
            this.command.length - this.commandIndex,
          );
        }
        this.command.push(newCommand);
        this.commandIndex++;
        if (!noExec) {
          newCommand.action(params);
        }
        this.FeatureChangeEventHandler && this.FeatureChangeEventHandler();
      }
    }
    /**
     * もとに戻す
     * @return {[type]} [description]
     */
    undo() {
      if (this.command && this.commandIndex > 0) {
        this.commandIndex--;
        this.commandDisable = true;
        const target = this.command[this.commandIndex];
        const { params: prev = null } =
          this.command[this.commandIndex - 1] || {};
        target.undo(target.params, prev);
        this.commandDisable = false;
        this.FeatureChangeEventHandler && this.FeatureChangeEventHandler();
      }
    }
    /**
     * やり直し
     * @return {[type]} [description]
     */
    redo() {
      if (this.command && this.commandIndex < this.command.length) {
        this.commandDisable = true;
        const target = this.command[this.commandIndex];
        target.action(target.params);
        this.commandDisable = false;
        this.commandIndex++;
        this.FeatureChangeEventHandler && this.FeatureChangeEventHandler();
      }
    }

    /**
     * 描画色を一括設定
     * @param {[type]} color [description]
     */
    setColor(color) {
      this.setOptions({
        circleOptions: { fillColor: color, strokeColor: color },
        polygonOptions: { fillColor: color, strokeColor: color },
        polylineOptions: { strokeColor: color },
        rectangleOptions: { fillColor: color, strokeColor: color },
      });
      //図形選択時に色変更されたら選択図形の色を変える
      if (this.selectedFeature) {
        const changeColor = function ({ overlay, color }) {
          overlay.setOptions &&
            overlay.setOptions({
              fillColor: color,
              strokeColor: color,
            });
        };

        const { overlay } = this.selectedFeature;
        this.recordCommand(
          'changeColor',
          {
            overlay,
            color,
          },
          changeColor,
          changeColor,
          false,
        );
      }
    }

    /**
     * 描画モード設定
     * @param {*} mode
     * @param {*} option
     */
    setDrawingMode(mode, option) {
      this.finishDirection();
      // 標準の描画モードに存在しない描画モードをハンドリング
      switch (mode) {
        //マーカ選択
        case 'marker-custom':
          this.setOptions(option);
          // 親メソッドの呼び出し
          this.setDrawingMode('marker');
          break;
        // 円選択
        case 'circle-custom':
          {
            this.setDrawingMode(null);
            const scaleSetting = {
              0: { lable: 'km', value: 1000 },
              1: { lable: 'm', value: 1 },
              2: { lable: 'マイル', value: 1609.344 },
              3: { lable: '海里', value: 1852 },
            };
            const event = window.google.maps.event.addListenerOnce(
              this.map,
              'click',
              ({ latLng }) => {
                // クリックされた箇所をセンターに指定
                const center = new window.google.maps.LatLng(
                  latLng.lat(),
                  latLng.lng(),
                );
                // 円を描画
                const { map } = this;
                const { radius, scale } = this.circleSetting;
                const circles = radius.map(
                  (r) =>
                    new window.google.maps.Circle({
                      radius: r * scaleSetting[scale].value,
                      fillOpacity: 0,
                      center,
                      map,
                    }),
                );
                // 描画完了処理
                const overlay = new window.google.maps.MultiCircle({
                  circles,
                  circleSetting: {
                    fillOpacity: 0.5,
                    ...this.circleOptions,
                  },
                });
                window.google.maps.event.trigger(this, 'overlaycomplete', {
                  type: 'MultiCircle',
                  overlay,
                });
                this.setDrawingMode('circle-custom');
              },
            );
            // 登録済みイベントのキャンセル
            this.events &&
              this.events.forEach(window.google.maps.event.removeListener);
            this.events.push(event);
          }
          break;
        // 経路探索選択
        case 'road':
          this.setDrawingMode(null);
          this.waypointMarker = [];
          this.directionsService = new window.google.maps.DirectionsService();
          this.directionsDisplay = new window.google.maps.DirectionsRenderer({
            map: this.map,
            draggable: true,
          });
          (async () => {
            // 始点設定
            this.map.showSnackbar({
              body: '経路の始点を選択してください',
              icon: 'mdi-map-marker-radius',
              color: 'primary',
            });
            await new Promise((resolve) => {
              const event = window.google.maps.event.addListenerOnce(
                this.map,
                'click',
                ({ latLng }) => {
                  const position = new window.google.maps.LatLng(
                    latLng.lat(),
                    latLng.lng(),
                  );
                  this.waypointMarker.push(
                    new window.google.maps.Marker({ position, map: this.map }),
                  );
                  resolve();
                },
              );
              this.events.push(event);
            });
            // 終点設定
            this.map.showSnackbar({
              body: '経路の終点を選択してください',
              icon: 'mdi-map-marker-radius',
              color: 'primary',
            });
            await new Promise((resolve) => {
              const event = window.google.maps.event.addListenerOnce(
                this.map,
                'click',
                ({ latLng }) => {
                  const position = new window.google.maps.LatLng(
                    latLng.lat(),
                    latLng.lng(),
                  );
                  this.waypointMarker.push(
                    new window.google.maps.Marker({ position, map: this.map }),
                  );
                  resolve();
                },
              );
              this.events.push(event);
            });
            // 経路探索
            const direction = await new Promise((resolve, reject) => {
              // 設定した始点終点のマーカを消去してポイント情報のみに修正
              const waypoint = this.waypointMarker.map((d) => {
                d.setMap(null);
                return d.getPosition();
              });

              const request = {
                origin: waypoint[0] /* 出発地点 */,
                destination: waypoint[1] /* 到着地点 */,
                travelMode:
                  window.google.maps.DirectionsTravelMode
                    .DRIVING /* 交通手段 */,
              };
              this.directionsService.route(request, (response, status) => {
                if (status == window.google.maps.DirectionsStatus.OK) {
                  resolve(response);
                } else {
                  reject(status);
                }
              });
            });
            // 探索結果表示
            this.directionsDisplay.setDirections(direction);
            this.map.showSnackbar({
              body: '経路を編集して問題なければ経路外をクリックしてください',
              icon: 'mdi-information',
              color: 'primary',
            });
            // 経路決定
            const overlay = await new Promise((resolve) => {
              // 無関係の場所をクリックで経路探索終了
              const event = window.google.maps.event.addListenerOnce(
                this.map,
                'click',
                () => {
                  resolve(this.finishDirection());
                },
              );
              this.events.push(event);
            });
            // 図形登録
            const feature = {
              type: 'polyline',
              overlay,
            };
            window.google.maps.event.trigger(this, 'overlaycomplete', feature);
            this.map.showSnackbar({
              body: '経路を描画しました',
              icon: 'mdi-information',
              color: 'green',
              timeout: 4000,
            });
          })();
          break;
        default:
          // 親メソッドの呼び出し
          super.setDrawingMode(mode);
      }
    }
    /**
     * ルート探索を途中で中断した際に表示中の情報を非表示もしくは確定する
     * @return {[type]} [description]
     */
    finishDirection() {
      // イベントリスナの解除
      this.events &&
        this.events.forEach(window.google.maps.event.removeListener);
      // 描画途中の始点終点を削除
      this.waypointMarker && this.waypointMarker.forEach((d) => d.setMap(null));
      // 表示されている経路表示情報を削除
      if (this.directionsDisplay) {
        this.directionsDisplay.setMap(null);
        // 経路情報探索結果のポリラインを返す
        if (this.directionsDisplay.getDirections()) {
          var path = window.google.maps.geometry.encoding.decodePath(
            this.directionsDisplay.getDirections().routes[0].overview_polyline,
          );
          return new window.google.maps.Polyline({
            path: path,
            strokeColor: this.polylineOptions.strokeColor,
          });
        }
      }
      return null;
    }
    /**
     * 有効無効切り替え
     * @return {[type]} [description]
     */
    setEnable(enable) {
      this.enable = enable;
      // todo:そのまま使うと文字入力時等でも反応してしまうのでは？
      // if (enable) {
      //   this.keyEvent = function (e) {
      //     switch (e.key) {
      //       case 'Backspace':
      //         if (this.selectedFeature) {
      //           this.recordCommand(
      //             'delete',
      //             this.selectedFeature,
      //             function () { self.deleteFeature(this.params); },
      //             function () { self.addFeature(this.params); }
      //           );
      //         }
      //         break;
      //       case 'z':
      //         if (e.metaKey || e.ctrlKey) {
      //           this.undo();
      //         }
      //         break;
      //       case 'y':
      //         if (e.ctrlKey) {
      //           this.redo();
      //         }
      //         break;
      //     }
      //   }.bind(this);
      //   document.addEventListener('keydown', this.keyEvent);
      // } else {
      //   this.keyEvent && document.removeEventListener('keydown', this.keyEvent);
      // }
    }
    /**
     * 図形追加処理
     * @param {[type]} feature [description]
     */
    addFeature(feature) {
      this.features = this.features || [];
      this.features.push(feature);
      const { overlay } = feature;
      if (overlay) {
        // redoしてきたときにmapを設定する
        const geometries = [].concat(overlay.features || overlay);
        geometries.forEach((geometry) => {
          geometry.setMap(this.map);
          // 編集可能時に図形選択イベントを設定する
          if (this.enable && !feature.clickEvent) {
            feature.clickEvent = google.maps.event.addListener(
              geometry,
              'click',
              () => this.clickEventHandler(feature),
            );
          }
        });
      }
    }
    /**
     * 図形選択イベントハンドラー
     * 各やり直し処理を記載
     * @param  {[type]} feature [description]
     * @return {[type]}         [description]
     */
    clickEventHandler(feature) {
      this.clickMapEvent &&
        window.google.maps.event.removeListener(this.clickMapEvent);
      // すべての図形の編集移動許可を解除
      this.features.forEach(({ overlay }) => {
        const geometries = [].concat(overlay.features || overlay);
        geometries.forEach((geometry) => {
          if (geometry.setEditable) geometry.setEditable(false);
          geometry.setDraggable(false);
        });
      });
      // 選択図形の編集移動を許可
      feature.overlay.setEditable && feature.overlay.setEditable(true);
      feature.overlay.setDraggable && feature.overlay.setDraggable(true);

      // 各図形タイプごとの独自処理
      const type = feature.type.toLowerCase();
      switch (type) {
        // 同心円
        case 'multicircle':
          //外心円を移動させる
          feature.overlay.features[0].setDraggable(true);
          break;
        // マーカ
        case 'marker':
          // ポップアップウィンドウを表示させる
          if (this.usePopupWindow) {
            const _popupWindow = new Vue({
              render: (createElement) =>
                createElement(popupWindow, {
                  props: { value: feature.overlay.text },
                  on: {
                    input: (val) => {
                      feature.overlay.text = val;
                      // ラベルに文字を表示する
                      if (val) {
                        feature.overlay.setLabel(
                          val.length > 5 ? val.slice(0, 5) + '...' : val,
                        );
                      } else {
                        feature.overlay.setLabel('');
                      }
                      this.FeatureChangeEventHandler &&
                        this.FeatureChangeEventHandler();
                    },
                  },
                }),
            });
            _popupWindow.$mount();
            var infoWindow = new google.maps.InfoWindow({
              content: _popupWindow.$el,
              pixelOffset: new google.maps.Size(0, -30),
            });
            feature.overlay.setInfoWindow(infoWindow);
          }
          break;
      }

      // 図形編集時の動作コマンドを保存する
      // イベントハンドラの追加
      // パス編集対応オブジェクトの場合
      const { overlay } = feature;
      if (overlay.getPath) {
        const path = overlay.getPath();
        // パスの移動
        window.google.maps.event.addListener(path, 'set_at', (i, previous) => {
          this.recordCommand(
            'move_point',
            { i, previous, overlay, current: path.getAt(i) },
            ({ i, overlay, current }) => overlay.getPath().setAt(i, current),
            ({ i, overlay, previous }) => overlay.getPath().setAt(i, previous),
            true,
          );
        });
        // パスの追加
        window.google.maps.event.addListener(path, 'insert_at', (i) => {
          this.recordCommand(
            'insert_point',
            { i, overlay, current: path.getAt(i) },
            ({ i, overlay, current }) => overlay.getPath().insertAt(i, current),
            ({ i, overlay }) => overlay.getPath().removeAt(i),
            true,
          );
        });
      }
      // 円オブジェクトの場合
      if (overlay.getRadius) {
        overlay.prevRadius = overlay.getRadius();
        // 半径変更
        window.google.maps.event.addListener(overlay, 'radius_changed', () => {
          this.recordCommand(
            'circle_resize',
            {
              overlay,
              prev: overlay.prevRadius,
              current: overlay.getRadius(),
            },
            ({ overlay, current }) => overlay.setRadius(current),
            ({ overlay, prev }) => overlay.setRadius(prev),
            true,
          );
          overlay.prevRadius = overlay.getRadius();
        });
      }
      // 矩形オブジェクトの場合
      if (
        Object.prototype.isPrototypeOf.call(
          window.google.maps.Rectangle,
          overlay,
        )
      ) {
        overlay.prevBounds = overlay.getBounds().toJSON();
        window.google.maps.event.addListener(
          feature.overlay,
          'bounds_changed',
          () => {
            this.recordCommand(
              'rect_resize',
              {
                overlay,
                prev: overlay.prevBounds,
                current: overlay.getBounds().toJSON(),
              },
              ({ overlay, current }) => overlay.setBounds(current),
              ({ overlay, prev }) => overlay.setBounds(prev),
              true,
            );
            // 移動時にbounds_changedが反応しないようにする
            if (!this.commandDisable)
              overlay.prevBounds = overlay.getBounds().toJSON();
          },
        );
      }
      // 図形の移動
      const getPosition = function (feature) {
        var position;
        if (
          Object.prototype.isPrototypeOf.call(
            window.google.maps.Circle,
            feature,
          )
        ) {
          return feature.getCenter();
        }
        position = feature.getPath && feature.getPath().getArray().concat();
        position =
          position || (feature.getBounds && feature.getBounds().toJSON());
        position = position || (feature.getPosition && feature.getPosition());
        position = position || (feature.getCenter && feature.getCenter());
        return position;
      };
      // 複数円の場合に外心円を移動対象とする
      const targetGeometory = [].concat(overlay.features || overlay)[0];
      // 開始
      let originPosition;
      window.google.maps.event.addListener(targetGeometory, 'dragstart', () => {
        this.commandDisable = true;
        originPosition = getPosition(targetGeometory);
      });
      // 終了
      window.google.maps.event.addListener(targetGeometory, 'dragend', () => {
        this.commandDisable = false;
        const newPosition = getPosition(targetGeometory);
        this.recordCommand(
          'move',
          {
            originPosition,
            newPosition,
          },
          ({ newPosition }) => {
            // パス編集対応オブジェクトの場合
            if (targetGeometory.setPath) {
              targetGeometory.setPath(newPosition);
              // イベントが外れてしまうため
              //todo:setAtEventHandlerの処遇を検討
              // window.google.maps.event.addListener(targetGeometory.getPath(), 'set_at', setAtEventHandler);
              // window.google.maps.event.addListener(targetGeometory.getPath(), 'insert_at', insertAtEventHandler);
            }
            targetGeometory.setPosition &&
              targetGeometory.setPosition(newPosition);
            targetGeometory.setCenter && targetGeometory.setCenter(newPosition);
            targetGeometory.setBounds && targetGeometory.setBounds(newPosition);
          },
          ({ originPosition }) => {
            if (targetGeometory.setPath) {
              targetGeometory.setPath(originPosition);
              // イベントが外れてしまうため
              //todo:setAtEventHandlerの処遇を検討
              // window.google.maps.event.addListener(targetGeometory.getPath(), 'set_at', setAtEventHandler);
              // window.google.maps.event.addListener(targetGeometory.getPath(), 'insert_at', insertAtEventHandler);
            }
            targetGeometory.setPosition &&
              targetGeometory.setPosition(originPosition);
            targetGeometory.setCenter &&
              targetGeometory.setCenter(originPosition);
            targetGeometory.setBounds &&
              targetGeometory.setBounds(originPosition);
          },
          true,
        );
      });
      // 選択図形を設定
      this.selectedFeature = feature;
      // 選択図形以外をクリックしたときに編集解除
      this.clickMapEvent = window.google.maps.event.addListener(
        this.getMap(),
        'click',
        () => {
          this.features.forEach(({ overlay }) => {
            const geometries = [].concat(overlay.features || overlay);
            geometries.forEach((geometry) => {
              geometry.setEditable && geometry.setEditable(false);
              geometry.setDraggable && geometry.setDraggable(false);
            });
          });
          this.selectedFeature = null;
        },
      );
    }
    /**
     * 保持している描画データを指定して削除
     * @param  {[type]} f [description]
     * @return {[type]}   [description]
     */
    deleteFeature(f) {
      const i = this.features.indexOf(f);
      this.features.splice(i, 1);
      const overlay = f.overlay;
      const geometries = [].concat(overlay.features || overlay);
      geometries.forEach((geometry) => geometry.setMap(null));
    }
    /**
     * 描画コントローラ内のデータをGeoJSON形式で出力する
     * @return {[type]} [description]
     */
    // getGeoJSON() {
    //   const geoJSON = {};
    //   geoJSON.type = 'FeatureCollection';
    //   json.features = this.features.map(({ overlay }) => overlay.getGeoJSON());
    //   return geoJSON;
    // }
    /**
     * 描画コントローラ内のデータを圧縮JSON形式で出力する
     * @return {[type]} [description]
     */
    getJSON() {
      const json = {};
      json.type = 'FeatureCollection';
      json.features = this.features.map(({ overlay }) => overlay.getJSON());
      return json;
    }
    /**
     * [getJSON description]
     * @return {[type]} [description]
     */
    loadJSON(json, isImport) {
      // インポートモードでなければ表示中の図形を初期化する
      if (!isImport) {
        this.clear();
      }
      CustomDrawingManger.parseJSON(json, this.getMap()).forEach((d) =>
        this.addFeature(d),
      );
    }
    /**
     *  表示中の図形データをすべて削除除する
     * @return {[type]} [description]
     */
    clear() {
      this.features.forEach(({ overlay }) => {
        const geometries = [].concat(overlay.features || overlay);
        geometries.forEach((geometry) => geometry.setMap(null));
      });
      this.features = [];
      this.command = [];
    }
    /**
     * 表示中の図形がすべて表示されるように表示領域を移動する
     * @return {[type]} [description]
     */
    fitBounds() {
      const t = new window.google.maps.LatLngBounds();
      // 図形が登録されてなければ移動しない
      if (this.features.length == 0) return;
      this.features.forEach(({ overlay }) => {
        if (overlay.getPath) {
          overlay.getPath().forEach((latlng) => t.extend(latlng));
        } else if (overlay.getBounds) {
          t.union(overlay.getBounds());
        } else if (overlay.getPosition) {
          t.extend(overlay.getPosition());
        }
      });
      this.getMap().fitBounds(t);
      // ZoomLVが一定を超えてしまっている場合は見ずらいのでzoom outする
      google.maps.event.addListenerOnce(this.getMap(), 'idle', () => {
        if (this.getMap().getZoom() > 15) {
          this.getMap().setZoom(15);
        }
      });
    }
    /**
     * GPSを取得する
     * @returns {google.maps.LatLng | null} location
     */
    async getGps() {
      if (navigator.geolocation) {
        try {
          const position = await new Promise((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject, {
              enableHighAccuracy: false,
              timeout: 10000,
              maximumAge: 60000,
            }),
          );
          // googleのlatlngにして返す
          const latlng = new window.google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );
          return latlng;
        } catch (error) {
          console.error(error);
          const errorMessages = [
            error.message,
            '位置情報の取得を許可されていません。',
            '位置情報の取得に失敗しました。',
            '位置情報を取得中にタイムアウトしました。',
          ];
          const message = errorMessages[error.code];

          this.map.showSnackbar({
            body: `GPSの取得に失敗しました。(${message})`,
            icon: 'mdi-alert',
            timeout: 3000,
            color: 'error',
          });
        }
      }
      return null;
    }
    /**
     * 圧縮JSON形式の文字列から図形情報を出力する
     * @param  {[type]} json [description]
     * @return {[type]}      [description]
     */
    static parseJSON(json, map) {
      if (!json || !JSON.parse(json).features) return [];
      return JSON.parse(json).features.map((polygon) => {
        const overlay = new window.google.maps[polygon.geometry.type]();
        overlay.loadJSON(polygon);
        overlay.setMap(map);
        return {
          type: polygon.geometry.type,
          overlay,
        };
      });
    }
  }
  return CustomDrawingManger;
};
