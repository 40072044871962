import loadGoogleMapsApi from 'load-google-maps-api';
import CustomDrawingManger from '@/googlemap/DrawingLibraryExtension/CustomDrawingManger';
import Circle from '@/googlemap/DrawingLibraryExtension/Circle';
import Marker from '@/googlemap/DrawingLibraryExtension/Marker';
import MultiCircle from '@/googlemap/DrawingLibraryExtension/MultiCircle';
import Polygon from '@/googlemap/DrawingLibraryExtension/Polygon';
import Polyline from '@/googlemap/DrawingLibraryExtension/Polyline';
import Rectangle from '@/googlemap/DrawingLibraryExtension/Rectangle';
import snackbar from '@/components/gis/snackbar';
import showGISBtn from '@/components/gis/showGISBtn';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

/**
 * google maps apiを呼び出す
 * @returns
 */
export async function loadApi() {
  // keyを取得
  const { value: key } = await Vue.prototype.$con.invoke({
    controller: 'CDS_CTR_Common',
    method: 'getConfigValue',
    params: {
      key: 'GoogleMap_API_KEY',
    },
  });
  const loader = await loadGoogleMapsApi({
    key,
    libraries: ['drawing', 'geometry', 'places'],
    v: 'weekly',
  });
  return loader;
}

export default async () => {
  const loader = await loadApi();
  await new Promise((resolve) => setTimeout(resolve, 100));
  // 円クラスの拡張
  loader.Circle.prototype = Circle(loader.Circle).prototype;
  // マーカークラスの拡張
  loader.Marker.prototype = Marker(loader.Marker).prototype;
  // 同心円クラスの作成
  loader.MultiCircle = MultiCircle;
  // 多角線クラスの拡張
  loader.Polygon.prototype = Polygon(loader.Polygon).prototype;
  // 多角形クラスの拡張
  loader.Polyline.prototype = Polyline(loader.Polyline).prototype;
  // 四角クラスの拡張
  loader.Rectangle.prototype = Rectangle(loader.Rectangle).prototype;
  // 拡張描画クラスの作成
  loader.CustomDrawingManger = CustomDrawingManger(
    loader.drawing.DrawingManager,
  );
  window.CustomDrawingManger = loader.CustomDrawingManger;
  // 市域を設定(検索やデフォルト表示で利用)
  window.google.maps.Map.defaultBounds = {
    east: 138.022821,
    north: 35.302117,
    south: 34.647706,
    west: 137.472102,
  };

  // 背景地図
  loader.mapTypes = {
    // 国土地理院地図
    gsiMapType: new google.maps.ImageMapType({
      name: '地理院地図',
      tileSize: new google.maps.Size(256, 256),
      minZoom: 5,
      maxZoom: 18,
      getTileUrl: (tileCoord, zoom) => {
        const x = (tileCoord.x % Math.pow(2, zoom)).toString();
        const y = tileCoord.y.toString();
        return `https://cyberjapandata.gsi.go.jp/xyz/std/${zoom}/${x}/${y}.png`;
      },
    }),
    // 白地図(国土地理院)
    whiteMapType: new google.maps.ImageMapType({
      name: '白地図',
      tileSize: new google.maps.Size(256, 256),
      minZoom: 5,
      maxZoom: 18,
      getTileUrl: (tileCoord, zoom) => {
        const x = (tileCoord.x % Math.pow(2, zoom)).toString();
        const y = tileCoord.y.toString();
        return `https://cyberjapandata.gsi.go.jp/xyz/blank/${zoom}/${x}/${y}.png`;
      },
    }),
    // 衛星写真(国土地理院)
    gsiPhotoMapType: new google.maps.ImageMapType({
      name: '衛星写真(国土地理院)',
      tileSize: new google.maps.Size(256, 256),
      minZoom: 5,
      maxZoom: 18,
      getTileUrl: (tileCoord, zoom) => {
        const x = (tileCoord.x % Math.pow(2, zoom)).toString();
        const y = tileCoord.y.toString();
        return `https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/${zoom}/${x}/${y}.jpg`;
      },
    }),

    // ラベルなし(描画モード用)
    noPOIMapType: new google.maps.StyledMapType(
      [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#89afef' }],
        },
      ],
      { name: 'ラベル無し' },
    ),
  };

  /**
   * Snackバーを画面上に表示する
   * @param {*} props
   */
  loader.Map.prototype.showSnackbar = function (props) {
    return new Promise((resolve) => {
      const _snackbar = new Vue({
        vuetify,
        render: (h) =>
          h(snackbar, {
            props,
            on: { close: resolve },
          }),
      });
      _snackbar.$mount();
      this.controls[google.maps.ControlPosition.TOP_CENTER].clear();
      this.controls[google.maps.ControlPosition.TOP_CENTER].push(_snackbar.$el);
    });
  };

  loader.Map.prototype.addPolygons = function (json) {
    const polygons = loader.CustomDrawingManger.parseJSON(json, this);
    this.polygons = polygons;
    return polygons;
  };
  loader.Map.prototype.clearPolygons = function () {
    const { polygons = [] } = this;
    polygons.forEach(({ overlay }) => overlay.setMap(null));
    this.polygons = [];
  };
  /**
   * GISで表示ボタンを表示するかどうか
   */
  loader.Map.prototype.setGISShowBtn = function (val) {
    this.controls[google.maps.ControlPosition.BOTTOM_LEFT].clear();
    if (val) {
      const showGISBtnDom = new Vue({
        vuetify,
        render: (h) =>
          h(showGISBtn, {
            on: {
              click: () => {
                const { lat, lng } = this.getCenter().toJSON();
                window.open(
                  `/apex/CDS_VF_GIS?lat=${lat}&lng=${lng}&z=${this.getZoom()}`,
                );
              },
            },
          }),
      });
      showGISBtnDom.$mount();
      this.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(
        showGISBtnDom.$el,
      );
    }
  };
  loader.createMap = (dom) => {
    const map = new loader.Map(dom, {
      mapTypeControlOptions: {
        mapTypeIds: [
          google.maps.MapTypeId.HYBRID,
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.TERRAIN,
          'gsiMapType',
          'whiteMapType',
          'gsiPhotoMapType',
        ],
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      center: {
        lat: 34.710865,
        lng: 137.726117,
      },
      zoom: 12,
    });
    Object.entries(loader.mapTypes).forEach(([key, type]) =>
      map.mapTypes.set(key, type),
    );
    return map;
  };

  return loader;
};

/**
 * 雨雲(test)
 *
 */
// this.map.overlayMapTypes.insertAt(
//   0,
//   new CoordMapType(new google.maps.Size(256, 256)),
// );
class CoordMapType {
  constructor(tileSize) {
    this.tileSize = tileSize;
  }
  getTile(coord, zoom, ownerDocument) {
    const img = ownerDocument.createElement('img');
    const x = (coord.x % Math.pow(2, zoom)).toString();
    const y = coord.y.toString();
    img.src = `https://tile.openweathermap.org/map/precipitation_new/${zoom}/${x}/${y}.png?appid=0f5fa144a1cb1fd6f2f1c30d91e9a416`;
    img.style.width = this.tileSize.width + 'px';
    img.style.height = this.tileSize.height + 'px';
    return img;
  }
  // releaseTile(tile) {}
}
