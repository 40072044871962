<template functional>
  <div
    class="my-1 v-input--selection-controls d-flex flex-grow-1 magicCheckboxPanel"
    :class="[data.class, data.staticClass]"
    @click="
      listeners['click']({
        value: props.value,
        clickRes: $options.clickRes($options.iconStyle(props.value)),
      })
    "
  >
    <v-icon
      v-ripple="{}"
      dense
      :color="$options.iconColor($options.iconStyle(props.value))"
    >
      {{ $options.iconStyle(props.value) }}
    </v-icon>
    <div class="d-flex ml-2 v-label align-center flex-grow-1 theme--light">
      {{ props.label }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VIcon } from 'vuetify/lib';

Vue.component('VIcon', VIcon);

export default {
  name: 'MagicCheckbox',

  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },

  iconStyle(value) {
    if (value === true) {
      return 'mdi-checkbox-marked';
    } else if (
      value === false ||
      value === undefined ||
      value === null ||
      _.isEmpty(value)
    ) {
      return 'mdi-checkbox-blank-outline';
    } else {
      return 'mdi-minus-box';
    }
  },
  iconColor(iconStyle) {
    if (iconStyle === 'mdi-checkbox-blank-outline') {
      return 'rgba(0, 0, 0, 0.54);';
    } else {
      return 'primary';
    }
  },
  clickRes(iconStyle) {
    if (iconStyle === 'mdi-checkbox-marked') {
      return false;
    } else {
      return true;
    }
  },
};
</script>

<style lang="scss" scoped>
.magicCheckboxPanel:hover {
  cursor: pointer;
}
</style>
