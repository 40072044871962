<template>
  <v-treeview
    v-if="!loading.item"
    v-model="selected"
    selectable
    dense
    :items="items"
    class="text-caption"
  >
    <template #label="{item}">
      <v-icon v-if="item.icon" :color="item.color">
        {{ item.icon }}
      </v-icon>
      {{ item.name }}
    </template>
  </v-treeview>
  <v-skeleton-loader v-else :type="Array(5).fill('list-item').join(',')" />
</template>

<script>
import mdi from '@/assets/mdiIcons';
import * as dataDrawUtil from '@/googlemap/dataDrawUtil';
import { getUrl } from '@/assets/js/s3.js';

export default {
  components: {},
  props: {},
  data: (vm) => ({
    metadata: [],
    selected: [],
    data: {},
    conditionMetadatas: [],
    selectedRootMetadataIds: [],
    polygons: [],
    autoReloadTimer: null,

    loading: {
      item: true,
      data: false,
    },
  }),
  computed: {
    autoReload() {
      return this.$store.getters['GIS/autoReload'];
    },
    // 選択チェックボックス表示用表示設定一覧
    items() {
      return _.orderBy(this.metadata, ['Sort__c'], ['asc']).map(
        ({ Label, Id, conditions }) => ({
          name: Label,
          id: Id,
          // ロード中は選択リストを無効化
          disabled: this.loading.data,
          // 子要素を整形
          children: _.orderBy(conditions, ['Sort__c'], ['asc']).map(
            ({ Id, Label, Icon__c, Color__c }) => {
              // Color__cが#から始まっていれば凡例に色をつける
              const color = Color__c.charAt(0) === '#' ? Color__c : null;
              return {
                name: Label,
                id: Id,
                icon: mdi[Icon__c],
                color,
              };
            },
          ),
        }),
      );
    },
  },
  watch: {
    selected(ids) {
      this.reload();
    },
    // 自動再読み込みを設定
    autoReload(val) {
      if (val) {
        this.autoReloadTimer = setInterval(this.reload.bind(this), 60000);
      } else if (this.autoReloadTimer) {
        clearInterval(this.autoReloadTimer);
      }
    },
  },
  async mounted() {
    await this.loadMetadata();
  },
  methods: {
    /**
     * 表示設定メタデータを読み出す
     */
    async loadMetadata() {
      const { controller } = this.$pageProperty;
      this.loading.item = true;
      try {
        this.metadata =
          (await this.$con.invoke({
            controller,
            method: 'getDynamicMetaDataList',
          })) || [];

        this.conditionMetadatas = {};
        await Promise.all(
          this.metadata.map(async (rootMetadata) => {
            const {
              conditions = {},
              TargetObject__c,
              ItemSetName__c,
            } = rootMetadata;
            // 項目セット取得
            const { fieldSet } = await this.$util.getFieldSet(
              TargetObject__c,
              ItemSetName__c,
            );
            rootMetadata.fieldSet = fieldSet;

            conditions.forEach((d) => {
              this.conditionMetadatas[d.Id] = d;
            });
          }),
        );
      } catch (error) {
        console.error(error);
      }

      this.loading.item = false;
    },
    /**
     * 選択済み動的情報を再表示する
     */
    async reload() {
      this.$store.commit('GIS/setLoading', true);
      const selectedMetadata = this.selected.map(
        (id) => this.conditionMetadatas[id],
      );
      this.selectedRootMetadataIds = _.unionBy(
        selectedMetadata,
        'GIS_C_DynamicMetaData__c',
      ).map((d) => d.GIS_C_DynamicMetaData__c);
      this.loading.data = true;
      try {
        await this.$nextTick();
        // データ読み出し
        await this.loadData();
        // データ描画
        const { map } = this.$store.state.GIS;
        this.polygons.forEach(({ overlay, type }) => {
          overlay.setMap(null);
          if (type === 'culuster') {
            overlay.clearMarkers();
          }
        });
        this.polygons = [];
        this.selectedRootMetadataIds.forEach((rootId) => {
          this.drawData(
            this.data[rootId],
            this.metadata.find((d) => d.Id === rootId),
          );
        });
      } catch (error) {
        console.error('動的情報の表示に失敗.');
        console.error(error);
      }
      this.loading.data = false;
      this.$store.commit('GIS/setLoading', false);
    },
    /**
     * 表示データを読み出す
     */
    async loadData() {
      const { controller } = this.$pageProperty;
      const { disaster } = this.$store.state.disaster;
      await Promise.all(
        this.selectedRootMetadataIds.map(async (rootId) => {
          // オブジェクト表示条件
          const settingMetaData =
            this.metadata.find(({ Id }) => rootId === Id) || {};
          // 対象のSObjectを読み出す
          let datas = await this.$con.invoke({
            controller,
            method: settingMetaData.CustomApexMethod__c || 'getDynamicDataList',
            params: [rootId, disaster.Id],
          });
          console.log(datas);
          if (!datas || datas.length === 0) return [];
          // 対象データが存在していればその添付ファイルを読み出す
          const ids = JSON.stringify(datas.map((d) => d.Id));
          datas = await this.$con.invoke({
            controller: 'CDS_CTR_Common',
            method: 'getAttachment',
            params: {
              objectName: settingMetaData.TargetObject__c,
              ids,
            },
          });
          console.log(datas);
          if (settingMetaData.PlaceField__c) {
            // 位置情報からマーカを設定
            datas.forEach((d) => {
              if (!d.object[settingMetaData.PlaceField__c]) return;
              const latlng = JSON.parse(
                d.object[settingMetaData.PlaceField__c],
              );
              const lat_s = latlng.latitude;
              const lng_s = latlng.longitude;
              if (!lat_s || !lng_s) return;
              const lat = Number(lat_s);
              const lng = Number(lng_s);
              const marker = new google.maps.Marker({
                position: { lat, lng },
              });
              d.json = JSON.stringify({ features: [marker.getJSON()] });
            });
          } else {
            // GIS用GEOJSONを読み出す
            await Promise.all(
              datas.map(async (d) => {
                const { attachments } = d;
                if (!attachments) return;
                const { FileId } =
                  attachments.find(({ Name }) =>
                    Name.match(/gis_(.*)_data.json/),
                  ) || {};
                if (!FileId) return;
                const url = await getUrl(FileId);
                d.json = await fetch(url).then((res) => res.text());
              }),
            );
          }
          // 表示条件ごとに振り分ける
          const records = settingMetaData.conditions || [];
          this.data[rootId] = {};
          records.forEach(({ Id: childID, Condition__c }) => {
            this.data[rootId][childID] = datas.filter(({ object }) =>
              Function('obj', `return ${Condition__c}`)(object),
            );
          });
        }),
      );
    },
    /**
     * 描画処理
     */
    drawData(datas, rootMetadata) {
      const { map } = this.$store.state.GIS;
      if (!datas) return;
      Object.entries(datas)
        .filter(([id]) => this.selected.includes(id))
        .map(([id, values]) => {
          const conditionMetadata = this.conditionMetadatas[id];
          values.forEach((d) => {
            let color = conditionMetadata.Color__c;
            if (color.charAt(0) !== '#') {
              color = Function('obj', `return ${color}`)(d.object);
            }
            // 図形描画
            const polygons = dataDrawUtil.createPolygons({
              map,
              color,
              json: d.json,
              path: mdi[conditionMetadata.Icon__c],
            });
            // ポップアップウィンドウ表示
            const url = rootMetadata.URL__c
              ? rootMetadata.URL__c.replace(/\{id\}/, d.object.Id)
              : '';
            dataDrawUtil.createPopupWindow(polygons, {
              title: rootMetadata.Label,
              icon: mdi[conditionMetadata.Icon__c],
              fieldSet: rootMetadata.fieldSet,
              color,
              url,
              object: d.object,
            });
            this.polygons = [...this.polygons, ...polygons];
          });
        });
      // マーカークラスタリング
      const culuster = dataDrawUtil.markerClustering(map, this.polygons);
      this.polygons.push({ type: 'culuster', overlay: culuster });
    },
  },
};
</script>
<style></style>
