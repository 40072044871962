<template>
  <bar-card :loading="loading" :bar-click="handleClickBar" @reload="init()">
    <template #bar_tab>
      <BarCardTab title="クロノロジー" icon="mdi-timeline-text-outline" />
    </template>
    <div>
      <v-data-table
        :headers="chronology.headers"
        :items="chronology.records"
        :items-per-page="30"
        disable-sort
        hide-default-footer
        class="cdsTable"
        :mobile-breakpoint="0"
        :height="$vuetify.breakpoint.smAndDown ? '100%' : '350'"
        :fixed-header="!$vuetify.breakpoint.smAndDown"
        @click:row="onClickRow"
      >
        <template #item.ReportDate__c="props">
          {{ props.value | datetime }}
        </template>
      </v-data-table>
    </div>
  </bar-card>
</template>

<script>
import BarCard from '@/components/common/BarCard';
import BarCardTab from '@/components/common/BarCardTab';

export default {
  components: {
    BarCard,
    BarCardTab,
  },
  data: () => ({
    loading: false,
    // クロノロジー
    chronology: {
      headers: [
        { text: '登録日時', value: 'ReportDate__c' },
        { text: '情報種別', value: 'InfoType__c' },
        { text: '件名', value: 'Name' },
        { text: '本文', value: 'Body__c' },
      ],
      records: [],
    },
  }),

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await this.getStat();
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 情報の取得
    async getStat() {
      const res = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getChronologyStat',
        params: {
          disasterId: this.$store.state.disaster.disaster.Id,
        },
      });
      if (res && Array.isArray(res)) {
        this.chronology.records = res;
      }
    },
    // 行クリック時
    async onClickRow(item) {
      this.$transition.to(
        'CDS_VF_ChronologyDetail',
        {
          id: item.Id,
        },
        {
          notApplyRetUrl: true,
        },
      );
    },
    handleClickBar() {
      this.$transition.to('CDS_VF_ChronologyList', null, {
        notApplyRetUrl: true,
      });
    },
  },
};
</script>
