var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      csvFileNameFormat: '災害一覧-%date%.csv',
      csvFieldSetName: 'CSVFieldSet',
      listSaveValidate: _vm.validate,
      bulkFieldSetName: 'BulkFieldSet',
    },"search-props":{
      syncConditionWithUrl: true,
      defaultSearchCondition: _vm.defaultSearchCondition,
    }}},'ListView',{
      objectName: 'CDS_T_Disaster__c',
      detailLayoutName: 'DetailPreviewLayout',
      detailPageName: 'CDS_VF_DisasterDetail',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }