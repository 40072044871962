<template>
  <detail
    v-if="objectName && !_.isEmpty(definition)"
    :object-name="objectName"
    :layout-name="definition.LayoutName"
    :default-title="`新規${definition.Label}作成`"
    header-icon="mdi-database-edit"
    :subtitle="`マスタ管理 ${definition.Label}`"
    :saved-page="savedPage"
    detail-page-name="CDS_VF_MasterDetail"
    :list-page="`CDS_VF_MasterList?objectName=${objectName}`"
    :custom-validations="customValidations"
  >
    <template #DetailMenus="{editMode, value}">
      <template v-if="!editMode && value.IsDeleted">
        <!-- 復元 -->
        <Confirm
          message="このデータを復元します。よろしいですか？"
          @on-click-yes="undelete(value)"
        >
          <template #default="{ on }">
            <ActionButton
              btn-value="復元"
              icon="mdi-delete-restore"
              color="light-green--text text--darken-2"
              depressed
              v-on="on"
            />
          </template>
        </Confirm>
      </template>
    </template>
    <template
      v-if="objectName == 'CDS_M_Organization__c'"
      #[addButtonSectionName]="slotProps"
    >
      <SectionNameEditorModal :slot-props="slotProps" />
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import Confirm from '@/components/common/Confirm';
import ActionButton from '@/components/detail/ActionButton';
import { disaster } from '@/beforeGuard/defaultRules';
import SectionNameEditorModal from './SectionNameEditorModal.vue';

export default {
  components: {
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        _loadLayout: detail.methods.loadLayout,
        loadLayout: async function () {
          await this._loadLayout();
          // 画面の中心点を住所に設定ボタンクリック時の動作を指定
          const locationField = this.objectInfo.properties.Location__c;
          if (locationField && locationField.type === 'location') {
            this.objectInfo.properties.Location__c.reverseGeocodingEventhandler = ({
              address_components,
              formatted_address,
              utmPromise,
            }) => {
              // 発生場所に設定
              if (this.objectInfo.properties.Address__c) {
                this.$set(this.value, 'Address__c', formatted_address);
              }
              // 区名に設定
              if (this.objectInfo.properties.Ward__c) {
                const ward = address_components.find(({ types }) =>
                  types.includes('sublocality_level_1'),
                );
                if (ward && ward.long_name) {
                  this.$set(this.value, 'Ward__c', ward.long_name);
                }
              }
              // UTM座標に設定
              utmPromise.then((v) => {
                this.$set(this.value, 'UtmPoint__c', v);
              });
            };
          }
        },
      },
    },
    Confirm,
    ActionButton,
    SectionNameEditorModal,
  },
  data: () => ({
    // オブジェクト名
    objectName: null,
    // マスタオブジェクト定義
    definition: {},
    savedPage: 'CDS_VF_MasterDetail',
    addButtonSectionName: '課/班ボタン追加用',
  }),
  computed: {
    customValidations() {
      return [];
    },
  },
  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadMasterDefinition()]),
      );
    },
    // マスタの情報を取得する
    async loadMasterDefinition() {
      const { id: objectId } = this.$query.current();
      // idがあれば、それから取得する
      if (objectId) {
        // idからobjectNameを取得
        const objectName = await this.$con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'getObjectNameById',
          params: {
            objectId,
          },
        });
        this.objectName = objectName;
      }
      // idがなければ、objectNameを見る
      else {
        this.objectName = this.$query.current().objectName;
      }
      // objectNameが存在するとき、definitionを取得する
      if (this.objectName) {
        const res = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getMasterDefinition',
          params: {
            objectName: this.objectName,
          },
        });
        // definitionが存在すれば、それをセット
        if (!_.isEmpty(res)) {
          this.$set(this, 'definition', res);
        }
      }
    },
    // 復元
    async undelete(value) {
      await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'undeleteData',
          params: {
            object: JSON.stringify({
              attributes: { type: this.objectName },
              Id: value.Id,
            }),
          },
        }),
      );
      this.$transition.to(
        this.savedPage,
        {
          id: value.Id,
          sb: JSON.stringify({
            message: '復元しました',
            props: {
              color: 'green',
              bottom: true,
              timeout: 2000,
            },
            closable: true,
          }),
        },
        { keepRetUrl: true },
      );
    },
  },
};
</script>
