<template>
  <div class="pa-md-2">
    <!-- 1段目 -->
    <v-row>
      <v-col cols="12" lg="3" md="6">
        <!-- 本部体制 -->
        <Headquarter />
      </v-col>
      <v-col cols="12" lg="5" md="6">
        <!-- 気象情報 -->
        <JMAInfo />
      </v-col>
      <v-col cols="12" lg="4" md="12">
        <!-- 避難情報 -->
        <Evacuation />
      </v-col>
      <v-col cols="12" md="6">
        <!-- 被害状況 -->
        <DamageReport />
      </v-col>
      <v-col cols="12" md="6">
        <!-- ヘルスケア系 -->
        <Health />
      </v-col>
      <v-col cols="12">
        <!-- ライフライン -->
        <Lifeline />
      </v-col>
      <!-- クロノロジー -->
      <v-col cols="12">
        <Chronology />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Headquarter from './Headquarter';
import Evacuation from './Evacuation';
import DamageReport from './DamageReport/index';
import Health from './Health/index.vue';
import Request from './Request';
import Chronology from './Chronology';
import JMAInfo from './JMAInfo.vue';
import Lifeline from './Lifeline.vue';

export default {
  components: {
    Headquarter,
    Evacuation,
    DamageReport,
    Health,
    Request,
    Chronology,
    JMAInfo,
    Lifeline,
  },
};
</script>
