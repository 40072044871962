import Vue from 'vue';

let notices = [];

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$notification = this;
    Vue.notification = this;
    this.request();
  },

  isSupported() {
    return !!('Notification' in window);
  },

  request() {
    if (!this.isSupported()) {
      return;
    }
    return Notification.requestPermission();
  },

  /**
   * 通知を送信する
   * @param {string} title
   * @param {NotificationOptions} options
   * @returns {Notification}
   */
  async post(title, options) {
    if (!this.isSupported()) {
      return;
    }

    // デフォルトであれば権限を要求
    if (Notification.permission === 'default') {
      await this.request();
    }

    // 権限が付けば通知
    if (Notification.permission === 'granted') {
      const n = new Notification(title, options);
      notices.push(n);
      return n;
    }
  },

  /**
   * 通知をクリア
   * @param {Notification[]} targets
   */
  clear(targets) {
    targets.map((n) => n.close());
    notices = notices.filter((n) => !targets.includes(n));
  },

  /**
   * すべてクリア
   */
  allClear() {
    notices.map((n) => n.close());
    notices = [];
  },
};

Vue.use(plugin);
export default plugin;
