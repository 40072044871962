<template>
  <div>
    <v-list-item>
      <v-btn small block @click="createData">
        <v-icon left>
          mdi-brush
        </v-icon>
        新規描画データ作成
      </v-btn>
    </v-list-item>
    <!--描画データ管理-->
    <v-list-item>
      <v-dialog v-model="loadDialog" max-width="500px">
        <!-- 表示ボタン -->
        <template #activator="{ on, attrs }">
          <v-btn small block v-bind="attrs" v-on="on">
            <v-icon left>
              mdi-folder-open
            </v-icon>
            描画データ管理
          </v-btn>
        </template>
        <v-card>
          <!--ツールバー-->
          <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click="loadDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>描画データ管理</v-toolbar-title>
          </v-toolbar>
          <!--設定内容-->
          <v-data-table
            :headers="paintDataTable.headers"
            :items="paintDataTable.datas"
            :items-per-page="5"
            class="elevation-1"
          >
            <template #item.CDS_M_Organization__c="{ item }">
              <v-checkbox :input-value="!item.CDS_M_Organization__c" disabled />
            </template>
            <template #item.CDS_T_Disaster__c="{ item }">
              <v-checkbox :input-value="!item.CDS_T_Disaster__c" disabled />
            </template>
            <template #item.actions="{ item }">
              <v-icon small class="mr-2" @click="loadDrawData(item)">
                mdi-folder-open
              </v-icon>
              <v-icon small @click="deleteDrawData(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-list-item>
    <v-dialog v-model="saveDialog" max-width="500px">
      <v-card>
        <!--ツールバー-->
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>描画データ保存</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field v-model="data.Name" label="タイトル" required />
            <v-switch v-model="data.share" inset label="データを共有する" />
            <v-switch v-model="data.global" inset label="全災害共通データ" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="save">
            <v-icon left>
              mdi-content-save
            </v-icon>
            保存
          </v-btn>
          <v-btn @click="saveDialog = false">
            <v-icon left>
              mdi-close
            </v-icon>
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from '../../../util/paint';
export default {
  components: {},
  props: {},
  data: (vm) => ({
    loadDialog: false,
    saveDialog: false,
    paintDataTable: {
      headers: [
        { text: 'タイトル', value: 'Name' },
        { text: '共有データ', value: 'CDS_M_Organization__c' },
        { text: '全災害共通データ', value: 'CDS_T_Disaster__c' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      datas: [],
    },
    drawingManager: {},
    data: {},
  }),
  computed: {},
  watch: {
    loadDialog(val) {
      if (val) this.loadDrawDataList();
    },
  },
  mounted() {
    const { map } = this.$store.state.GIS;
    this.drawingManager = new CustomDrawingManger({
      map,
      usePopupWindow: true,
      drawingModes: [
        'marker',
        'polyline',
        'road',
        'rectangle',
        'circle',
        'polygon',
      ],
      drawControlProps: {
        useColor: true,
        useSaveButton: true,
        useCancelButton: true,
        useAccordion: false,
      },
      drawControlOn: {
        cancel: () => {
          if (
            confirm(
              '描画モードを終了します。(未保存のデータは破棄されます)よろしいですか?',
            )
          ) {
            this.drawingManager.enable = false;
            this.drawingManager.clear();
          }
        },
        save: () => (this.saveDialog = true),
      },
    });
  },
  methods: {
    /**
     * 描画開始処理
     */
    createData() {
      this.data = {};
      this.enableDrawingManger();
    },
    enableDrawingManger() {
      this.drawingManager.FeatureChangeEventHandler = () => {
        this.data.json = this.drawingManager.getJSON();
      };
      this.drawingManager.enable = true;
    },
    /**
     * データ保存処理
     */
    async save() {
      const { controller } = this.$pageProperty;
      const { disaster } = this.$store.state.disaster;
      const { organization } = this.$store.state.user;
      // 災害共通データでなければ災害IDを指定
      this.data.CDS_T_Disaster__c = this.data.global ? '' : disaster.Id;
      // 共有データでなければ組織IDを指定
      this.data.CDS_M_Organization__c = this.data.share ? '' : organization.Id;
      // データ登録
      const defaultVal = { Share__c: false, json: '{}' };
      const json = JSON.stringify(this.data.json);
      const drawDataId = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'insertDrawData',
          params: { ...defaultVal, ...this.data },
        }),
      );
      // s3へアップロード
      const file = new Blob([json], { type: 'text/json' });
      await this.$store.dispatch(
        'loading/register',
        this.attachmentUploader.start(
          [{ file }],
          drawDataId,
          'GIS_T_DrawData__c',
        ),
      );
      this.data.Id = drawDataId;
      this.saveDialog = false;
    },
    /**
     * 描画データ覧取得
     */
    async loadDrawDataList() {
      this.paintDataTable.datas = await util.loadDrawDataList(this);
    },
    /**
     * 描画データ取得
     */
    async loadDrawData(data) {
      this.data = await util.loadDrawData(this, data);
      this.drawingManager.loadJSON(this.data.json);
      this.drawingManager.fitBounds();
      this.enableDrawingManger();
      this.loadDialog = false;
    },
    /**
     * 描画データ削除
     */
    async deleteDrawData({ Id, Name }) {
      const { controller } = this.$pageProperty;
      if (confirm(`描画データ: "${Name}" を削除してもよろしいですか？`)) {
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller,
            method: 'deleteDrawData',
            params: Id,
          }),
        );
        await this.loadDrawDataList();
      }
    },
  },
};
</script>
<style></style>
