<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col cols="12" class="text-center text-subtitle-1">
            {{ message }}
          </v-col>
          <v-col cols="6" md="4" lg="3" class="text-center">
            <template v-if="loading">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </template>
            <template v-else>
              <v-btn depressed @click="close">閉じる</v-btn>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import createDocument from 'docx-templates';
import template from './DamageReportTemplate.docx';

export default {
  data: (vm) => ({
    objectName: 'CDS_T_DamageReport__c',
    // ページに表示するメッセージ
    message: null,

    // ダウンロードファイル名
    downloadFileName: (object = {}) => {
      const current = vm.$dateFns.fnsFormat(
        new Date(),
        '',
        'yyyy-MM-dd-HH-mm-ss',
      );
      return `情報メモ-${object.Name}-${current}.docx`;
    },
    // ファイルタイプ
    contentType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

    // ローディング
    loading: true,

    // セッションId
    sessionId: null,
    // オブジェクト情報
    objectInfo: {},
    // データ
    // オブジェクト
    object: {},
    // 画像
    attachments: [],
  }),
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      const { id: objectId } = this.$query.current();
      if (objectId) {
        this.message = '生成しています...';
        await this.load(objectId);
        this.message = null;
      } else {
        this.message = 'ダウンロードできる帳票はありません。';
      }
      this.loading = false;
    },

    // データ取得からダウンロードまで
    async load(objectId) {
      // セッションIdの取得とデータ取得
      await Promise.all([
        this.getSessionId(),
        this.getObjectInfo(),
        this.loadData(objectId),
      ]);
      // ドキュメント作成
      const doc = await this.generateDocument();
      // ダウンロード
      await this.downloadData(doc);
    },

    // セッションIdの取得
    async getSessionId() {
      // this.sessionId = await this.$util.getSessionId();
    },

    // オブジェクト情報の取得
    async getObjectInfo() {
      const objectInfo = await this.$util.getObjectInfo(this.objectName);
      this.$set(this, 'objectInfo', objectInfo);
      console.log('received object info', objectInfo);
    },

    // データの取得
    async loadData(objectId) {
      const { object, attachments } = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getData',
        params: {
          objectName: this.objectName,
          id: objectId,
        },
      });
      this.$set(this, 'object', object);
      this.$set(this, 'attachments', attachments || []);
    },

    // ドキュメントの生成
    async generateDocument() {
      // データの変換
      const obj = { ...this.object };
      for (const key of Object.keys(obj)) {
        const value = obj[key];
        const fieldInfo = this.objectInfo.properties[key];
        if (fieldInfo) {
          // 日付型の場合は、変換する
          if (fieldInfo.type == 'datetime') {
            obj[key] = this.$dateFns.fnsFormat(
              value,
              '',
              'yyyy年MM月dd日(E) HH:mm',
            );
          }
        }
      }
      // 元データ作成
      const documentData = {
        obj,
        // 添付ファイルを追加
        __Images: await Promise.all(
          this.attachments
            .filter((v) => v && v.Description.split('/')[0] === 'image')
            .map((v) => ({
              ...v,
              ext: v.Description.split('/')[1],
            }))
            .map(async (v) => {
              // 画像のサイズを取得
              const size = await new Promise((resolve) => {
                const element = new Image();
                element.onload = () => {
                  resolve({
                    width: element.naturalWidth,
                    height: element.naturalHeight,
                  });
                };
                element.src = `/sfc/servlet.shepherd/version/download/${v.Id}`;
              });
              return {
                ...v,
                ...size,
              };
            }),
        ),
      };
      // ドキュメントの作成
      const report = await createDocument({
        // テンプレート
        template,
        // データ
        data: documentData,
        additionalJsContext: {
          // 画像をロードする関数
          loadImage: async (image) => {
            const url = `/services/data/v49.0/sobjects/ContentVersion/${image.Id}/VersionData`;
            const response = await this.getImageData(url);
            // アスペクト比の計算をする
            // 幅は固定
            const width = 15.5;
            // 高さの計算
            const height = (width * image.height) / image.width;
            return {
              width,
              height,
              data: response,
              extension: `.${image.ext}`,
            };
          },
        },
        // テンプレート内の挿入部分の記号
        cmdDelimiter: ['{', '}'],
      });
      return report;
    },

    // 画像データを取得する
    async getImageData(url) {
      return await new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.sessionId}`);
        xhr.addEventListener('load', function (e) {
          resolve(xhr.response);
        });
        xhr.responseType = 'blob';
        xhr.send();
      });
    },

    // ダウンロード
    async downloadData(data) {
      // ファイル名
      const fileName = await this.downloadFileName(this.object);
      // blobの生成
      const blob = new Blob([data], { type: this.contentType });

      //IE,Edgeのダウンロード
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      }
      //IE,Edge以外のダウンロード
      else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 1000);
      }
    },

    // タブを閉じる
    close() {
      window.close();
    },
  },
};
</script>
