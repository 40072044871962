import query from '@/plugins/query';

// URLパラメータのsnackbarのキー名
const snackbarKeyName = 'sb';

export default () => {
  return (store) => {
    // URLパラメータのsnackbar
    const currentQuery = query.current();
    const { [snackbarKeyName]: snackbarString = null } = currentQuery;

    // JSONパースしてopenSnackBarにする
    if (snackbarString) {
      try {
        const snackbarJson = JSON.parse(snackbarString);
        if (!_.isEmpty(snackbarJson)) {
          store.dispatch('snackbar/openSnackBar', snackbarJson);
        }
      } catch {
        console.error('スナックバーの表示に失敗しました。');
      }
    }

    // snackbarのデータがどうであれreplaceStateする
    query.updateHistoryQuery({
      query: _.omit(currentQuery, snackbarKeyName),
    });
  };
};
