import cookies from 'browser-cookies';
import { disaster as rule } from '@/beforeGuard/defaultRules';
// disaster
export default {
  namespaced: true,

  state: {
    disaster: {},
  },

  getters: {
    exist: (state) => {
      return !!(
        state.disaster &&
        state.disaster.Name &&
        state.disaster.DisasterType__c
      );
    },
    isFujisanConnected: (state, getters) => {
      return !!(
        getters.exist &&
        state.disaster.FujisanName__c &&
        state.disaster.ExternalId__c
      );
    },
  },

  mutations: {
    setDisaster(state, v) {
      state.disaster = v;
      if (v.Id) {
        cookies.set(rule.cookieKey, v.Id);
      } else {
        cookies.erase(rule.cookieKey);
      }
    },
  },

  actions: {
    async setDisaster({ commit }, v) {
      commit('setDisaster', v || {});
    },
  },
};
