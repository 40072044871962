<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header
        title="災害選択"
        subtitle="災害を選択してください。"
        icon="fa-house-damage"
      ></page-header>
    </template>
    <div>
      <!-- 実災害or訓練 -->
      <div>
        <v-row justify="center" no-gutters>
          <v-col
            v-for="(disasterType, index) in disasterTypes"
            :key="index"
            cols="6"
            md="3"
            lg="2"
            class="pa-3"
          >
            <v-badge
              color="deep-purple accent-4"
              icon="fa-check"
              bordered
              overlap
              style="width: 100%;"
              class="d-block"
              :value="DisasterType__c == disasterType.name"
            >
              <v-btn
                block
                dark
                x-large
                :class="[disasterType.color, 'text-center', 'text-h5']"
                @click="DisasterType__c = disasterType.name"
              >
                {{ disasterType.name }}
              </v-btn>
            </v-badge>
          </v-col>
        </v-row>
      </div>
      <!-- リストビュー -->
      <template v-if="DisasterType__c">
        <ListView
          v-bind="{
            objectName: 'CDS_T_Disaster__c',
          }"
          :key="listViewKey"
          :table-props="{
            listFieldSetName: 'SelectListFieldSet',
            canEdit: false,
            canCreate: false,
            canCsvDownload: false,
            onClickFunc: onClick,
            defaultListOptions: defaultListOptions,
          }"
          :search-props="{
            layoutName: 'SelectSearchLayout',
            defaultSearchCondition: {
              DisasterStatus__c: ['対応中'],
            },
            forceCondition: convertCondition,
          }"
          @load-completed="onLoadCompleted"
        >
        </ListView>
      </template>
    </div>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: () => ({
    disasterTypes: [
      {
        name: '実災害',
        color: 'red',
      },
      {
        name: '訓練',
        color: 'green',
      },
    ],
    defaultListOptions: {
      sortBy: ['HappenDate__c'],
      sortDesc: [true],
    },
    DisasterType__c: null,
    // listViewのキーを変更することで、強制的にリレンダーを走らせる
    listViewCount: 1,
    // 実災害or訓練選択後、初回の一覧ロードか
    initialLoad: true,
  }),

  computed: {
    listViewKey() {
      return `listview-${this.listViewCount}`;
    },
    autoSelect() {
      return this.$query.current().autoSelect !== 'false';
    },
  },

  watch: {
    DisasterType__c() {
      this.listViewCount++;
      this.initialLoad = true;
    },
  },

  methods: {
    // 一覧のデータが読み込まれた時
    async onLoadCompleted(list) {
      // 初回ロードでかつ件数が1件の時、自動選択する
      if (this.autoSelect && this.initialLoad) {
        // 件数確認
        const totalSize = list.dataTableProps['server-items-length'];
        if (totalSize === 1) {
          // データ取得
          const targetData = list.listData[0];
          if (targetData) {
            await this.onClick(targetData);
          }
        }
      }
      this.initialLoad = false;
    },
    // クリック時
    async onClick(item) {
      this.$store.dispatch('loading/setTransition', true);
      await this.$store.dispatch('disaster/setDisaster', item);
      this.$transition.fallback();
    },
    // リストの検索条件に追加指定する
    async convertCondition({ searchObject }) {
      searchObject.AND = [
        ...(searchObject.AND || []),
        {
          DisasterType__c: this.DisasterType__c,
        },
      ];
      return searchObject;
    },
  },
};
</script>
