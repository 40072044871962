<template>
  <div>
    <v-tabs
      v-model="tab.value"
      background-color="light-blue lighten-5"
      centered
      class="mb-2"
    >
      <v-tab href="#evacuation-tab-area">
        区域設定
      </v-tab>
      <v-tab href="#evacuation-tab-map">
        地図表示
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab.value">
      <v-tab-item value="evacuation-tab-area">
        <v-form ref="evacuationEditForm" v-model="form.valid" lazy-validation>
          <v-simple-table
            dense
            class="evacuation-editor-table mini-input evacuation-table-default"
          >
            <!-- カラムの情報 -->
            <colgroup>
              <col span="1" style="width: 15%;" />
              <col span="1" style="width: 10%;" />
              <col span="1" style="width: 10%;" />
              <col
                :span="announceTypes.length"
                :style="`width: ${
                  (100 - (15 + 10 + 10 + 10)) / announceTypes.length
                }%;`"
              />
              <col span="1" style="width: 10%;" />
            </colgroup>
            <!-- ヘッダー -->
            <thead>
              <tr class="tr-head">
                <th class="cell-area text-left">
                  区域
                </th>
                <th class="cell-household text-left">
                  対象世帯数
                </th>
                <th class="cell-people text-left">
                  対象人数
                </th>
                <th
                  v-for="(announceType, announceTypeIndex) in announceTypes"
                  :key="announceTypeIndex"
                  :class="[
                    'cell-announce-type',
                    'text-left',
                    announceType.name,
                  ]"
                >
                  <v-tooltip top transition="fade-transition">
                    <template #activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        announceType.short || announceType.label
                      }}</span>
                    </template>
                    <span>{{ announceType.label }}</span>
                  </v-tooltip>
                </th>
                <th class="cell-reason-type text-left">
                  原因種別
                </th>
              </tr>
            </thead>
            <!-- 本体 -->
            <tbody>
              <template v-for="(ward, wardIndex) in masterArea">
                <!-- 区 -->
                <tr :key="wardIndex" class="tr-ward">
                  <td class="cell-area">
                    <div class="d-flex">
                      <v-checkbox
                        v-model="ward.open"
                        dense
                        hide-details
                        class="my-1"
                        on-icon="mdi-menu-down"
                        off-icon="mdi-menu-right"
                      ></v-checkbox>
                      <MagicCheckbox
                        x-small
                        :value="checkWardCheckbox(ward)"
                        :label="ward.name"
                        @click="onClickWard($event, ward)"
                      />
                    </div>
                  </td>
                  <td class="cell-household"></td>
                  <td class="cell-people"></td>
                  <td
                    v-for="(announceType, announceTypeIndex) in announceTypes"
                    :key="announceTypeIndex"
                    :class="['cell-announce-type', announceType.name]"
                  >
                    <AnnounceTypeWardCell
                      :ward="ward"
                      :announce-type-name="announceType.name"
                    />
                  </td>
                  <td class="cell-reason-type">
                    {{
                      _.uniq(
                        ward.children
                          .map((c) => c.reasonType)
                          .filter((r) => !!r),
                      ).join(',')
                    }}
                  </td>
                </tr>
                <!-- 区域 -->
                <template v-for="(area, areaIndex) in ward.children">
                  <EvacuationEditorAreaRow
                    :key="`${wardIndex}-${areaIndex}`"
                    :area="area"
                    :announce-types="announceTypes"
                    :active="ward.open"
                    :selected="ward.selectedArea.includes(area.Id)"
                    @selected-updated="handleClickArea(ward, $event)"
                  />
                </template>
              </template>
            </tbody>
            <tbody>
              <tr>
                <td :colspan="4 + announceTypes.length">
                  <div class="d-flex">
                    <span class="d-flex align-center mx-2">臨時区域</span>
                    <v-btn
                      color="primary"
                      outlined
                      small
                      class="d-flex align-center mx-2"
                      @click="openExtraEditModal()"
                    >
                      臨時区域を追加する
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
            <!-- 臨時区域 -->
            <tbody>
              <template v-for="(area, areaIndex) in extraArea">
                <EvacuationEditorAreaRow
                  :key="`extra-${areaIndex}-${area.Id}`"
                  :active="true"
                  :area="area"
                  :announce-types="announceTypes"
                  :is-extra="true"
                  @extra-click="onClickExtraArea"
                  @extra-delete="onClickExtraDelete"
                />
              </template>
            </tbody>
          </v-simple-table>
        </v-form>
      </v-tab-item>
      <v-tab-item eager value="evacuation-tab-map">
        <EvacuationEditorMap
          :active="tab.value === 'evacuation-tab-map'"
          :record-data="computedRecordData"
        />
      </v-tab-item>
    </v-tabs-items>

    <!-- 臨時区域の作成/編集モーダル -->
    <EvacuationEditorExtraModal
      v-model="extra.modal"
      :item="extra.item"
      :announce-types="announceTypes"
      @apply="applyExtraModalValue"
    />

    <!-- 操作パネル -->
    <EvacuationEditorTool
      :reason-types="reasonTypes"
      :announce-types="announceTypes"
      @declare="onClickDeclare"
      @lift="onClickLift"
      @lift-all="onClickLiftAll"
    />
  </div>
</template>

<script>
import MagicCheckbox from './MagicCheckbox';
import AnnounceTypeWardCell from './AnnounceTypeWardCell';
import EvacuationEditorAreaRow from './EvacuationEditorAreaRow';
import EvacuationEditorMap from './EvacuationEditorMap';
import EvacuationEditorTool from './EvacuationEditorTool';
import EvacuationEditorExtraModal from './EvacuationEditorExtraModal';

import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EvacuationEditor',

  components: {
    MagicCheckbox,
    AnnounceTypeWardCell,
    EvacuationEditorAreaRow,
    EvacuationEditorMap,
    EvacuationEditorTool,
    EvacuationEditorExtraModal,
  },

  props: {
    // stepperアクティブ
    active: {
      type: Boolean,
      default: false,
    },
    // 発令種別のリスト
    announceTypes: {
      type: Array,
      default: () => [],
    },
    // 原因種別のリスト
    reasonTypes: {
      type: Array,
      default: () => [],
    },
    // layoutスロットのprops
    slotProps: {
      type: Object,
      default: () => ({}),
    },
    // loadData終了時のデータ
    loadedData: {
      type: Object,
      default: () => ({}),
    },
    latestInternalData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    tab: {
      value: 'evacuation-tab-area',
    },

    // エリアマスタ元データ
    originalAreaMaster: [],
    // マスタから取得したエリア
    masterArea: [],
    // 臨時のエリア
    extraArea: [],

    // フォーム
    form: {
      valid: null,
    },

    // 臨時
    extra: {
      // 作成編集モーダル
      modal: false,
      // 作成編集用アイテム
      item: {},
      // 名称
      name: '臨時区域',
    },

    // 内部データを反映済み
    appliedInternalData: false,
    // 初期ロード
    initialLoadTask: null,
  }),

  computed: {
    // チェックボックスの選択
    // 区域が多いとselectedAreaが膨大になり処理が重くなるので、区ごとに選択状況を管理しcomputedで全部をまとめる
    selectedArea: {
      get() {
        return this.masterArea.reduce((prev, { selectedArea }) => {
          return [...prev, ...selectedArea];
        }, []);
      },
      set(newValue) {
        this.masterArea.map((ward) => {
          // 各区をまわして、自分の区のエリアのIdであるものをあつめてそれをセットする
          const myAreas = ward.children
            .map(({ Id }) => Id)
            .filter((Id) => newValue.includes(Id));
          this.$set(ward, 'selectedArea', myAreas);
        });
      },
    },
    // 発令または解除する地域のリストを出力
    computedTargetDeclareOrLiftAreas() {
      return this.getTargetDeclareOrLiftAreas();
    },
    // 内部データ
    computedInternalData() {
      return this.generateInternalData();
    },
    // 区域オブジェクト用データ
    computedRecordData() {
      return this.generateRecordData();
    },
  },

  watch: {
    'form.valid'(to) {
      this.$emit('form-valid', to);
    },
    loadedData: {
      handler(to) {
        if (!this.appliedInternalData && to) {
          this.applyInternalData(to.records || []);
          this.appliedInternalData = true;
        }
      },
      deep: true,
    },
    latestInternalData: {
      handler(to) {
        if (to) {
          this.applyLatestInternalData(to.records || []);
        }
      },
      deep: true,
    },
    // computedTargetDeclareOrLiftAreas(to) {
    //   console.log(
    //     '%c[editor] computedTargetDeclareOrLiftAreas ->',
    //     'color: greenyellow;',
    //     to,
    //   );
    // },
    computedInternalData(to) {
      // console.log('%c[editor] computed internal data ->', 'color: hotpink;', to);
      this.$emit('update-internal-data', to);
    },
    computedRecordData(to) {
      // console.log('%c[editor] computed record data ->', 'color: hotpink;', to);
      this.$emit('update-record-data', to);
    },
    // masterArea(to) {
    //   console.log('%c[editor] masterArea ->', 'color: hotpink;', to);
    // },
    // extraArea(to) {
    //   console.log('%c[editor] extraArea ->', 'color: Fuchsia;', to);
    // },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    /********** 初期ロード **********/

    async init() {
      this.initialLoadTask = this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadAreaMaster()]),
      );
      await this.initialLoadTask;
    },
    // 避難エリアマスタの取得
    async loadAreaMaster() {
      try {
        const res = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getEvacuationArea',
        });

        if (Array.isArray(res)) {
          // 元データに追加
          this.originalAreaMaster = res;

          // 区ごとにまとめたオブジェクトを作る
          const wardMap = res
            .map((area) => {
              // 不要な項目の除去
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { CreatedDate, LastModifiedDate, attributes, ...a } = area;
              return a;
            })
            .reduce((prev, area) => {
              // 区ごとにまとめる
              return {
                ...prev,
                [area.Ward__c]: [...(prev[area.Ward__c] || []), area],
              };
            }, {});

          // 区ごとにまとめたオブジェクトを配列にする
          const result = Object.entries(wardMap).map(([name, children]) => ({
            name,
            children,
            // 区ごとに選択済みの区域を保持する
            selectedArea: [],
          }));

          this.masterArea = result;
        }
      } catch (error) {
        this.openSnackBar({
          message: 'リソースの呼び出しに失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    // 内部データを反映する
    async applyInternalData(internalData) {
      await Promise.all([this.initialLoadTask]);
      // 臨時区域以外
      // arrayを、idをキーに持つオブジェクトに変換
      const keyed = _.keyBy(
        internalData.filter((d) => !d.isExtra),
        'Id',
      );
      this.masterArea.map((ward) => {
        ward.children
          .filter((area) => Object.keys(keyed).includes(area.Id))
          .map((area) => {
            // 内部データ
            const internalAreaData = keyed[area.Id];
            // 発令種別
            this.$set(
              area,
              'announceTypeName',
              internalAreaData.announceTypeName,
            );
            // 発令or解除
            this.$set(area, 'orderStatus', internalAreaData.orderStatus);
            // 発令日時
            this.$set(
              area,
              'announceDatetime',
              internalAreaData.announceDatetime,
            );
            // 原因種別
            this.$set(area, 'reasonType', internalAreaData.reasonType);
            // 世帯数
            this.$set(
              area,
              'Household__c',
              Number(internalAreaData.Household__c || area.Household__c),
            );
            // 人数
            this.$set(
              area,
              'People__c',
              Number(internalAreaData.People__c || area.People__c),
            );
          });
      });
      // 臨時区域
      // 臨時の場合はそのまま登録する
      internalData
        .filter((d) => d.isExtra)
        .map((area) => {
          this.extraArea.push(area);
        });
    },

    // 確定報の内部データを反映する
    async applyLatestInternalData(internalData) {
      await Promise.all([this.initialLoadTask]);
      // 臨時区域以外
      // arrayを、idをキーに持つオブジェクトに変換
      const keyed = _.keyBy(
        internalData.filter((d) => !d.isExtra),
        'Id',
      );
      this.masterArea.map((ward) => {
        ward.children
          .filter((area) => Object.keys(keyed).includes(area.Id))
          .map((area) => {
            // 内部データ
            const internalAreaData = keyed[area.Id];
            // 発令種別
            this.$set(
              area,
              'oldAnnounceTypeName',
              internalAreaData.announceTypeName,
            );
            // 発令or解除
            this.$set(area, 'oldOrderStatus', internalAreaData.orderStatus);
            // 発令日時
            this.$set(
              area,
              'oldAnnounceDatetime',
              internalAreaData.announceDatetime,
            );
          });
      });
    },

    /********** チェックボックス操作 **********/

    // 区のチェックの判定
    checkWardCheckbox(ward) {
      // 入っている個数を取得
      const containsIds = ward.children
        .map(({ Id }) => Id)
        .filter((Id) => this.selectedArea.includes(Id));
      // 全部入っていれば、true
      if (containsIds.length === ward.children.length) {
        return true;
      }
      // 全部入ってなければfalse
      else if (containsIds.length === 0) {
        return false;
      }
      // 一部入っていればテキスト
      return 'part';
    },

    // 区クリック時
    onClickWard({ clickRes }, ward) {
      // areaのidの配列
      const areaIds = ward.children.map(({ Id }) => Id);
      if (clickRes) {
        this.selectedArea = [...new Set([...this.selectedArea, ...areaIds])];
      } else {
        this.selectedArea = this.selectedArea.filter(
          (id) => !areaIds.includes(id),
        );
      }
    },

    // エリアクリック時
    handleClickArea(ward, { value, Id }) {
      if (value) {
        this.$set(ward, 'selectedArea', [
          ...new Set([...ward.selectedArea, Id]),
        ]);
      } else {
        this.$set(ward, 'selectedArea', [
          ...new Set(ward.selectedArea.filter((v) => v !== Id)),
        ]);
      }
    },

    /********** 発令操作 **********/

    /*
    発令区域ごとの情報
    const area = {
      // 発令種別
      announceTypeName: 'at_1 or at_2 or ...',
      // 発令or解除
      orderStatus: '発令 or 解除',
      // 原因種別
      reasonType: '',
      // 発令解除日時
      announceDatetime: datetime,
      // 以前の発令種別
      oldAnnounceTypeName: 'at_1 or at_2 or ...',
      // 以前の発令or解除
      oldOrderStatus: '発令 or 解除',
      // 以前の発令解除日時
      oldAnnounceDatetime: datetime,
    }
    */

    // 発令ボタン押下
    onClickDeclare(event) {
      const { announceTypeName, selectedReasonType } = event;
      // 選択されているエリアがなければ、アラート
      if (this.selectedArea.length === 0) {
        return alert('区域を1つ以上選択してください。');
      }
      // 選択されている各セルに情報を設定
      this.masterArea.map((ward) => {
        ward.children
          .filter((area) => this.selectedArea.includes(area.Id))
          .map((area) => {
            this.$set(area, 'announceTypeName', announceTypeName);
            this.$set(area, 'orderStatus', '発令');
            this.$set(area, 'reasonType', selectedReasonType);
          });
      });
      // 後処理
      this.afterDeclareOrLift();
    },

    // 発令解除またはクリア
    onClickLift() {
      // 選択されているエリアがなければ、アラート
      if (this.selectedArea.length === 0) {
        return alert('区域を1つ以上選択してください。');
      }
      // 選択されている各セルに情報を設定
      this.masterArea.map((ward) => {
        ward.children
          .filter((area) => this.selectedArea.includes(area.Id))
          .map((area) => {
            this.$set(area, 'announceTypeName', null);
            this.$set(area, 'orderStatus', null);
            this.$set(area, 'reasonType', null);
          });
      });
      // 後処理
      this.afterDeclareOrLift();
    },

    // 全解除
    onClickLiftAll() {
      // 各セルに情報を設定
      this.masterArea.map((ward) => {
        ward.children
          .filter((area) => area.announceTypeName)
          .map((area) => {
            this.$set(area, 'announceTypeName', null);
            this.$set(area, 'orderStatus', null);
            this.$set(area, 'reasonType', null);
          });
      });
      // 後処理
      this.afterDeclareOrLift();
    },

    // 発令解除を何かしら行った後
    afterDeclareOrLift() {
      // 選択を解除
      this.selectedArea = [];
      // 解除を反映
      this.applyLiftAll();
    },

    // 全エリアに対して、解除を付与する
    // 前回発令または解除していて、今回発令していないセルに、解除を付与する
    applyLiftAll() {
      this.masterArea.map((ward) => {
        ward.children
          .filter(
            (area) => area.oldAnnounceTypeName && area.orderStatus !== '発令',
          )
          .map((area) => {
            this.$set(area, 'announceTypeName', area.oldAnnounceTypeName);
            this.$set(area, 'orderStatus', '解除');
            this.$set(area, 'reasonType', null);
          });
      });
    },

    /********** 臨時区域の操作 **********/

    // 区域名クリック時のハンドラ
    onClickExtraArea(area) {
      this.openExtraEditModal(area);
    },
    // 区域名の削除ボタンクリック時
    onClickExtraDelete(area) {
      if (confirm(`区域 "${area.Name}" を削除してもよろしいですか？`)) {
        this.$set(
          this,
          'extraArea',
          this.extraArea.filter(({ Id }) => Id != area.Id),
        );
      }
    },

    // 作成編集モーダルを開く
    openExtraEditModal(item) {
      this.$set(this.extra, 'item', { ...item });
      this.extra.modal = true;
    },

    // 作成編集モーダルから反映
    applyExtraModalValue(value) {
      if (_.isEmpty(value) || !_.isObject(value)) return;
      // Idがあれば、編集する
      if (value.Id) {
        const targetArea = this.extraArea.find((extra) => extra.Id == value.Id);
        if (targetArea) {
          for (const key of Object.keys(value)) {
            this.$set(targetArea, key, value[key]);
          }
        }
      }
      // Idがなければ、追加する
      else {
        // Idを生成して追加
        const saveValue = { ...value, Id: uuidv4() };
        this.extraArea.push(saveValue);
      }
    },

    /********** データを変換する系 **********/

    // 既存データから、発令解除対象の区域をフィルタリング
    getTargetDeclareOrLiftAreas() {
      const targetAreas = _.cloneDeep(
        this.masterArea.map((ward) => {
          // 発令種別があり、発令or解除が指定されているものだけ取り出し
          const children = ward.children.filter(
            (area) => area.announceTypeName && area.orderStatus,
          );
          return {
            ...ward,
            children,
            _masterChildren: this.originalAreaMaster.filter(
              (area) => area.Ward__c === ward.name,
            ),
          };
        }),
      );
      // 各区域に対して、発令種別と発令or解除が一致すれば、発令日時の情報をコピーする
      const targetAreasApplyedValue = targetAreas.map((ward) => ({
        ...ward,
        children: ward.children.map((area) => {
          let announceDatetime = null;
          // 発令種別と発令or解除が、ともに新旧一致すれば、発令日時をコピー
          if (
            area.oldAnnounceTypeName === area.announceTypeName &&
            area.oldOrderStatus === area.orderStatus
          ) {
            announceDatetime = area.oldAnnounceDatetime;
          }
          return {
            ...area,
            announceDatetime,
          };
        }),
      }));
      // 臨時区域
      // 臨時区域の対象を取得
      const targetExtraAreas = _.cloneDeep(
        // 発令種別があり、発令or解除が指定されているものだけ取り出し
        this.extraArea.filter(
          (area) => area.announceTypeName && area.orderStatus,
        ),
      );
      // 臨時区域のオブジェクトを作成
      const extraAreaObject = {
        name: this.extra.name,
        children: targetExtraAreas.map((area) => ({
          ...area,
          Ward__c: this.extra.name,
        })),
      };

      return [...targetAreasApplyedValue, extraAreaObject];
    },

    /*** 内部データ用 ***/

    // 内部データを作成
    generateInternalData() {
      // データをフラットにして、必要な項目だけを取り出し
      // 日付項目は、発令日時が入力されたタイミングであとで書き換える
      return _.flatten(
        this.computedTargetDeclareOrLiftAreas.map((ward) => ward.children),
      ).map((area) => ({
        ..._.pick(area, [
          'Id',
          'Household__c',
          'People__c',
          'announceDatetime',
          'announceTypeName',
          'orderStatus',
          'reasonType',
        ]),
        ...(area.Ward__c == this.extra.name
          ? {
              // 臨時の時は下記項目も追加
              isExtra: true,
              ..._.pick(area, ['Name', 'Phonetic__c']),
            }
          : null),
      }));
    },

    /*** 区域オブジェクト用 ***/

    // 区域オブジェクト用データを作成
    generateRecordData() {
      // 結果
      // 発令種別.発令or解除 = array
      const result = {};
      // すべての区域に対して、発令種別と発令or解除のユニークの区域に対して処理する
      this.announceTypes.map((announceType) => {
        ['発令', '解除'].map((order) => {
          // 発令種別と発令or解除でまとめた配列
          const uniqueArray = this.computedTargetDeclareOrLiftAreas
            .map((ward) => ({
              ...ward,
              // 区域は、発令種別と発令or解除が一致するものを取り出し
              children: ward.children.filter(
                (area) =>
                  area.announceTypeName === announceType.name &&
                  area.orderStatus === order,
              ),
            }))
            .filter((ward) => ward.children.length !== 0);
          // 全域でまとめるなど、整理された配列
          const packedArray = this.packingRecordData(
            uniqueArray,
            announceType.name,
            order,
          ).map((area) => ({
            ...area,
            // まとめた後に、区名が「臨時区域」ならばnullにする
            Ward__c: area.Ward__c == this.extra.name ? null : area.Ward__c,
          }));
          // 整理されたものを追加
          _.set(result, `${announceType.name}.${order}`, packedArray);
        });
      });
      return result;
    },

    // フィルタリングされた区域で、まとめられるものはまとめる(全域など)
    packingRecordData(filteredArray, announceTypeName, orderStatus) {
      // 空の場合はそのまま返す
      if (_.isEmpty(filteredArray)) return filteredArray;
      const packedArray = filteredArray.map((ward) => {
        // 発令されている区域の件数がマスタと一致したら、全域として判定する
        if (
          ward.name != this.extra.name &&
          ward._masterChildren &&
          ward.children.length === ward._masterChildren.length
        ) {
          // 全域としてまとめる
          return [
            {
              // 名称
              Name: `${ward.name}全域`,
              // かな
              Phonetic__c: 'ぜんいき',
              // 発令種別
              announceTypeName,
              // 発令or解除
              orderStatus,
              // 原因種別
              // すべての区域のユニークをjoinする
              reasonType: _.uniq(
                ward.children.map((c) => c.reasonType).filter((r) => !!r),
              ).join(','),
              // 世帯数
              // すべての区域でnullまたはundefinedならnullにする。1つでもあれば合計する。
              Household__c: this.calcNumberForPacking(
                ward,
                'Household__c',
                orderStatus,
              ),
              // 人数
              // すべての区域でnullまたはundefinedならnullにする。1つでもあれば合計する。
              People__c: this.calcNumberForPacking(
                ward,
                'People__c',
                orderStatus,
              ),
              // 発令日時
              // すべての区域の中の最大の発令日時を指定する。ただし、nullが含まれていればnullにする。(置き換えるため)
              announceDatetime:
                ward.children.filter((area) => _.isNil(area.announceDatetime))
                  .length > 0
                  ? null
                  : _.maxBy(ward.children, 'announceDatetime').announceDatetime,
              // 区名
              Ward__c: ward.name,
              // 図形(ここでは単純なfeatureの配列(のjson文字列)にする)
              Polygon__c: JSON.stringify(
                _.flatten(
                  ward.children
                    .filter(({ Polygon__c }) => !!Polygon__c)
                    .map(({ Polygon__c }) => JSON.parse(Polygon__c)),
                ),
              ),
            },
          ];
        } else {
          // 区域数が一致しない場合は、全域ではないので、区域をそのまま返す。臨時の場合はすべて区域そのまま返す
          return ward.children;
        }
      });
      // TODO 静岡市全域で発令したい場合は、ここで葵区全域,駿河区全域,清水区全域の名称がそろってたら静岡市全域として出力する処理をかませる
      return _.flatten(packedArray);
    },

    // 人数と世帯数の計算
    // すべての区域でnullまたはundefinedならnullにする。1つでもあれば合計する。
    calcNumberForPacking(ward, keyName, orderStatus) {
      // 解除の場合はnullにする
      if (orderStatus === '解除') return null;
      // すべてnullか
      if (
        ward.children.filter((area) => !_.isNil(area[keyName])).length === 0
      ) {
        return null;
      }
      // 1つでもnullでないものがあれば、sumする
      return _.sum(ward.children.map((c) => Number(c[keyName] || 0)));
    },

    /********** その他 **********/

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
