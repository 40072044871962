import App from './App.vue';

export default {
  path: '/CDS_VF_Search',
  name: 'CDS_VF_Search',
  component: App,
  meta: {
    pageProperty: {
      title: '検索',
      controller: 'CDS_CTR_Search',
    },
  },
};
