<template>
  <div ref="map" class="d-flex" :style="{ height: '500px' }" />
</template>

<script>
import mapLoader from '@/googlemap/loader';
import { AnnounceType__c } from '@/assets/js/objects/CDS_T_EvacuationAdvisory__c';

export default {
  name: 'EvacuationEditorMap',

  props: {
    // 表示中かどうか
    active: { type: Boolean, default: false },
    // 発令の情報
    recordData: { type: Object, default: () => ({}) },
  },

  data: () => ({
    map: null,
    // 発令ステータスの変換map
    statusMap: {
      発令: 'declare',
      解除: 'lift',
    },
    // 表示中の図形情報
    polygons: [],
  }),

  watch: {
    active(to, from) {
      if (to && !from) {
        this.loadPolygons();
      }
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.loadMap();
    },

    /**
     * マップの初期化
     */
    async loadMap() {
      const loader = await mapLoader();
      this.map = this.map ?? loader.createMap(this.$refs.map);
    },

    /**
     * 図形情報のロード
     */
    async loadPolygons() {
      // 図形情報のクリア
      this.clearAllPolygons();

      // 各データに対して実施

      // まずは発令種別ごとにまとめる
      await Promise.all(
        Object.entries(this.recordData).map(
          async ([announceTypeName, declareOrLift]) => {
            // 発令種別の取得
            const announceType = Object.entries(AnnounceType__c)
              .map(([label, value]) => ({
                label,
                ...value,
              }))
              .find((v) => v.name === announceTypeName);

            // 発令または解除
            await Promise.all(
              Object.entries(declareOrLift).map(async ([status, value]) => {
                this.setPolygonsToMap({
                  announceType,
                  status: this.statusMap[status],
                  value,
                });
              }),
            );
          },
        ),
      );
    },

    /**
     * 図形情報を地図に反映
     */
    async setPolygonsToMap({ announceType, status, value }) {
      // 図形の色を取得
      const color = announceType.map[status].color;

      // 図形情報を取り出してまとめる
      const polygonJsonArray = value
        ?.map((v) => v.Polygon__c)
        .filter((v) => !!v)
        .map((v) => JSON.parse(v))
        .reduce((prev, next) => [...prev, ...next], []);

      const createdPolygons = this.map
        .addPolygons(
          JSON.stringify({
            type: 'FeatureCollection',
            features: polygonJsonArray,
          }),
        )
        .reduce((prev, target) => {
          // 着色
          target.overlay.setOptions({
            strokeColor: color,
            fillColor: color,
          });

          return [...prev, target];
        }, []);

      // 表示済み図形に追加
      this.polygons = [...this.polygons, ...createdPolygons];
    },

    /**
     * すべての図形情報をクリアする
     */
    clearAllPolygons() {
      this.polygons.map(({ overlay }) => {
        overlay.setMap(null);
      });
      this.polygons = [];
    },
  },
};
</script>
