<template>
  <div></div>
</template>

<script>
import { generateDocument, downloadData } from './document';
import template from './template.docx';

import { mapActions } from 'vuex';

export default {
  name: 'ReportDownloadDocument',

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({}),

  watch: {
    content: {
      handler() {
        this.start();
      },
      deep: true,
    },
  },

  methods: {
    /**
     * ダウンロードを始める
     */
    async start() {
      await this.$store.dispatch('loading/register', this.generate());
    },

    /**
     * 生成
     */
    async generate() {
      try {
        if (
          !this.content ||
          Object.keys(this.content).length === 0 ||
          !this.content.data
        ) {
          throw new Error('データが不正です。');
        }
        const { data, fileNameGenerator } = this.content;

        // ドキュメントを生成
        const doc = await generateDocument({ data, template });

        // 生成したものをダウンロード
        await downloadData({
          data: doc,
          fileName: await (fileNameGenerator?.() || this.getFileName()),
        });
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /**
     * ファイル名を作成
     */
    getFileName() {
      const current = this.$dateFns.fnsFormat(
        new Date(),
        '',
        'yyyy-MM-dd-HH-mm-ss',
      );
      return `報告書-${current}.docx`;
    },

    /*********** 共通 ***********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>
