<template>
  <default-layout>
    <template #pageHeader>
      <page-header />
    </template>
    <InnerContent />
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import InnerContent from './InnerContent.vue';

export default {
  components: {
    defaultLayout,
    PageHeader,
    InnerContent,
  },
};
</script>
