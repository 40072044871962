/**
 * カンマ区切りの座標情報から緯度経度情報を作製
 * @param	{[type]} str [description]
 * @return {[type]}		 [description]
 */
export function convLatLngFromStr(str) {
  var pointArray = str.split(',');
  return { lat: parseFloat(pointArray[0]), lng: parseFloat(pointArray[1]) };
}
/**
 * 緯度経度オブジェクトを数値の配列に変換
 * @param  {[type]} latlng [description]
 * @return {[type]}        [description]
 */
export function covertLatLngToNumArray(latlng) {
  return [latlng.lng(), latlng.lat()];
}

/**
 * 緯度経度から住所取得
 * @param  {[type]} location 緯度経度
 */
export function reverseGeocoding(location) {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    if (geocoder) {
      geocoder.geocode({ location }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const [result] = results;
          if (result) {
            const { address_components } = result;
            // 国名と郵便番号を取り除く
            let address = address_components.filter(({ types }) => types.every(type => !['postal_code', 'country'].includes(type)));
            // 文字をつなげて住所にする
            address = address.map(d => d.long_name).reverse().join('');
            // 
            result.formatted_address = address || result.formatted_address
            resolve(result);
          } else {
            reject('住所の取得に失敗しました。検索結果が有りませんでした。');
          }
        } else {
          reject('住所の取得に失敗しました。' + status);
        }
      });
    } else {
      reject('Geocoderの初期化に失敗しました。');
    }
  });
}
/**
 * 道路通行規制情報を表示
 * @param  {[type]} modeChecked [description]
 * @param  {[type]} type        [description]
 * @param  {[type]} map         [description]
 * @return {[type]}             [description]
 */
export function projectionRoadRegulationInformation(modeChecked, type, map) {
  var mode = modeChecked ? 'douro' : 'kunren';
  var DOURO_KISEIJOUHOU_URL = 'http://kiseijouhou.pref.shizuoka.jp/';
  var kmlFileNameList = {
    '通行規制': [
      'kisei01', // 伊豆地域規制情報
      'higai01', // 伊豆地域被害情報
      'kisei02', // 東部（富士山）規制情報
      'higai02', // 東部（富士山）被害情報
      'kisei03', // 中部地域規制情報
      'higai03', // 中部地域被害情報
      'kisei04', // 西部（遠州）規制情報
      'higai04', // 西部（遠州）被害情報
    ],
    '調査済み区間': ['patrol'],
    '緊急輸送ルート': ['yusou_kukan'],
  };
  // 常に最新のKMLファイルを取得するため?+タイムスタンプを付加してアクセスする
  var timestamp = '?' + (new Date()).getTime();
  // KMLURL
  var kmlURL = DOURO_KISEIJOUHOU_URL + mode + '/';
  // KMZファイル内のセンター指定を無視する
  var kmlOptions = { preserveViewport: true };
  // 指定された道路情報タイプのkml名を取得
  var kmlFileNames = kmlFileNameList[type];
  // 各KML名ごとにレイヤを取得
  var layers = kmlFileNames.map(function (kmlFileName) {
    var kmlLayer = new window.google.maps.KmlLayer(kmlURL + kmlFileName + '.kmz' + timestamp, kmlOptions);
    kmlLayer.setMap(map);
    return kmlLayer;
  });
  return layers;
}

/**
 * Google Map APIの読み込みが終了しているかどうか確認する
 */
export function waitGoogleMapLoad() {
  let timeout = 0;
  return new Promise((resolve, reject) => {
    const check = () => {
      if (window.google) {
        resolve();
      } else {
        timeout++;
        if (timeout > 10) {
          reject('GoogleMapAPIの読み出しがタイムアウトしました。');
        }
        setTimeout(check, 1000);
      }
    };
    check();
  });
}