<template>
  <detail
    use-version
    object-name="CDS_T_LifelineLPGas__c"
    layout-name="DetailLayout"
    default-title="新規ライフライン(LPガス)登録"
    subtitle="ライフライン(LPガス)"
    saved-page="CDS_VF_LifelineLPGasDetail"
    detail-page-name="CDS_VF_LifelineLPGasDetail"
    list-page="CDS_VF_LifelineList?le=true"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    use-correction
  >
    <template #underHeader="{editMode,isFixed,value}">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="editMode ? 1 : isFixed ? 3 : 2"
      >
        <v-stepper-header>
          <v-stepper-step step="1">
            入力
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2">
            確定
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">
            完了
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';

export default {
  components: {
    detail,
  },

  computed: {
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
  },
};
</script>
<style></style>
