<template>
  <detail
    use-version
    object-name="CDS_T_WelfareShelter__c"
    layout-name="WelfareShelterLayout"
    default-title="福祉避難所詳細"
    subtitle="福祉避難所詳細"
    saved-page="CDS_VF_WelfareShelterDetail"
    detail-page-name="CDS_VF_WelfareShelterDetail"
    detail-layout-name="CDS_T_WelfareShelter__c-検索レイアウト"
    list-page="CDS_VF_WelfareShelterList?le=true"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    :load-master="loadMaster"
    :override="override"
    use-correction
    :fix-message="
      isFujisanConnected
        ? '確定します。この操作でFUJISANに連携されます。よろしいですか？'
        : undefined
    "
    :fix-text="isFujisanConnected ? '確定/FUJISAN連携' : undefined"
  >
    <template #DetailMenus="{editMode, value, objectName, savedPage, listPage}">
      <template
        v-if="
          !editMode &&
          value.CDS_M_Organization__c === $store.state.user.organization.Id
        "
      >
        <Transfer
          :object-name="objectName"
          :value="value"
          :next-page="listPage || savedPage"
        />
      </template>
    </template>
    <template #underHeader="{editMode,isFixed,value}">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="editMode ? 1 : isFixed ? 3 : 2"
      >
        <v-stepper-header>
          <v-stepper-step step="1">
            入力
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">
            確定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            完了
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import { validateWelfareShelter } from '@/validation';
import Transfer from '@/components/detail/Transfer.vue';

export default {
  components: {
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        /**
         * レイアウト読み出しをオーバラライド
         */
        _loadLayout: detail.methods.loadLayout,
        loadLayout: async function () {
          await this._loadLayout();
          // 画面の中心点を住所に設定ボタンクリック時の動作を指定
          this.objectInfo.properties.Location__c.reverseGeocodingEventhandler = ({
            address_components,
            formatted_address,
            utmPromise,
          }) => {
            // 発生場所に設定
            this.$set(this.value, 'Address__c', formatted_address);
            // 事案発生区名に設定
            const ward = address_components.find(({ types }) =>
              types.includes('sublocality_level_1'),
            );
            if (ward && ward.long_name) {
              this.$set(this.value, 'Ward__c', ward.long_name);
            }
            // UTM座標に設定
            utmPromise.then((v) => {
              this.$set(this.value, 'UtmPoint__c', v);
            });
          };
        },
      },
    },
    Transfer,
  },
  data: () => ({
    override: {
      // 入力チェック
      async validate({ value }) {
        // チェック実施
        const result = validateWelfareShelter(value);
        // nullであれば問題なし、文字列ならば問題あり
        if (result) throw new Error(result.message);
      },
    },
  }),
  computed: {
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
    isFujisanConnected() {
      return this.$store.getters['disaster/isFujisanConnected'];
    },
  },
  methods: {
    /**
     * マスタデータを読み出す
     */
    async loadMaster() {
      const { controller } = this.$pageProperty;
      const { id, mid, mode } = this.$query.current() || {};
      if (!id) {
        if (mid) {
          //マスタデータ読み出し
          const { object: master } = await this.$con.invoke({
            controller,
            method: 'getData',
            params: {
              id: mid,
              objectName: 'CDS_M_WelfareShelter__c',
            },
            mock: () => ({}),
          });
          if (master) {
            return {
              ...master,
              CDS_M_WelfareShelter__r: master,
              CDS_M_WelfareShelter__c: master.Id,
              IsExtra__c: false,
            };
          }
        }
        if (mode === 'n') {
          return {
            IsExtra__c: true,
          };
        }
      }
      return {};
    },
  },
};
</script>
