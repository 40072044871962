export const ActivityStatus__c = {
  未対応: {
    labelClass: 'red accent-2 white--text',
    textClass: 'red--text text--accent-2',
    chartColor: '#FF5252',
  },
  対応中: {
    labelClass: 'orange white--text',
    textClass: 'orange--text',
    chartColor: '#FF9800',
  },
  対応済: {
    labelClass: 'light-blue white--text',
    textClass: 'light-blue--text',
    chartColor: '#03A9F4',
  },
  対応不要: {
    labelClass: 'light-green white--text',
    textClass: 'light-green--text',
    chartColor: '#8BC34A',
  },
};
