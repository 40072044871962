// snackbar
export default {
  namespaced: true,

  state: {
    value: false,
    message: null,
    props: {},
    closable: true,
  },

  getters: {
    snackProps: (state) => state.props,
  },

  mutations: {
    setSnackBar(state, v) {
      state.value = !!v;
    },
    setMessage(state, v) {
      state.message = v;
    },
    setProps(state, v) {
      state.props = v;
    },
    setClosable(state, v) {
      state.closable = v;
    },
  },

  actions: {
    async setSnackBar({ commit }, v) {
      commit('setSnackBar', !!v);
    },
    async setMessage({ commit }, v) {
      commit('setMessage', v);
    },
    async setProps({ commit }, v) {
      commit('setProps', v);
    },
    async setClosable({ commit }, v) {
      commit('setClosable', !!v);
    },

    async openSnackBar({ dispatch }, v) {
      const options = _.defaultsDeep(v, {
        message: null,
        props: {},
        closable: false,
      });
      dispatch('setSnackBar', true);
      dispatch('setMessage', options.message);
      dispatch('setProps', options.props);
      dispatch('setClosable', options.closable);
    },
    /**
     * エラー表示
     * @param {*} param0
     * @param {*} v
     */
    async openErrorSnackBar({ dispatch }, v) {
      dispatch('openSnackBar', {
        message: v.message,
        props: {
          color: 'error',
          bottom: true,
          timeout: 5000,
        },
        closable: true,
      });
    },
    // フォーム不正
    async invalidForm({ dispatch }) {
      dispatch('openSnackBar', {
        message: 'フォームが不正です。入力画面を確認してください。',
        props: {
          color: 'error',
          bottom: true,
          timeout: 3000,
        },
        closable: true,
      });
    },
    // 保存完了
    async saveComplete({ dispatch }, v = '保存しました') {
      dispatch('openSnackBar', {
        message: v,
        props: {
          color: 'green',
          bottom: true,
          timeout: 2000,
        },
        closable: true,
      });
    },
    // 保存失敗
    async saveFail({ dispatch }, v = '') {
      dispatch('openSnackBar', {
        message: '保存に失敗しました ' + v,
        props: {
          color: 'red',
          bottom: true,
          timeout: 5000,
        },
        closable: true,
      });
    },
  },
};
