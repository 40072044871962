import { covertLatLngToNumArray } from '@/googlemap/util';
export default (_Marker) => {
  /**
   * 拡張マーカクラス
   */
  class Marker extends _Marker {
    /**
     * マーカデータGeoJSON形式で出力する
     * @return {[type]} [description]
     */
    getGeoJSON() {
      return {
        type: 'Feature',
        properties: {
          googleFeatureType: 'marker'
        },
        geometry: {
          type: 'Point',
          coordinates: covertLatLngToNumArray(this.getPosition())
        }
      };
    }
    /**
     * マーカデータJSON形式で出力する(符号化は行わない)
     * @return {[type]} [description]
     */
    getJSON() {
      return {
        type: 'Feature',
        properties: {
          icon: this.icon,
          text: this.text,
        },
        geometry: {
          type: 'Marker',
          coordinates: this.getPosition()
        }
      };
    }
    /**
     * マーカデータの頂点データを配列で出力する
     * @return {[type]} [description]
     */
    getCoordinates() {
      return [covertLatLngToNumArray(this.getPosition())];
    }

    /**
     * インフォメーションウィンドウを設定する
     * @param {[type]} infoWindow [description]
     */
    setInfoWindow(infoWindow) {
      this.infoWindow && this.infoWindow.setMap(null);
      this.infoWindow = infoWindow;
      this.addListener('position_changed', () => this.infoWindow.setPosition(this.getPosition()));
      this.infoWindow.setMap(this.getMap());
      this.infoWindow.setPosition(this.getPosition());
    }
    /**
     * マーカ中心点出力
     * @type {[type]}
     */
    getCenter() {
      return this.getPosition();
    }

    loadJSON(polygon) {
      // var option = {
      //   position: polygon.geometry.coordinates,
      //   icon: polygon.properties.icon,
      //   map: map,
      // };
      // Object.entries(polygon.properties).forEach(function (args) {
      //   option[args[0]] = args[1];
      // });
      // var marker = new window.google.maps.Marker(option);
      // marker.text = polygon.properties.text;
      // return marker;
      const { properties, geometry } = polygon;
      const { coordinates: position } = geometry;
      this.setOptions({ position, ...properties });
      this.text = properties.text;
    }
  }
  return Marker;
}