<template>
  <div>
    <v-list v-for="hazardType in hazardTypes" :key="hazardType">
      <v-subheader>{{ hazardType }}</v-subheader>
      <v-list-item
        v-for="item in hazardMapItems.filter(
          (v) => hazardType == v.HazardType__c,
        )"
        :key="item.Id"
        :value="item.Id"
        :class="{ 'v-list-item--active': activeItems.includes(item.Id) }"
        color="indigo"
        @click="toggleHazardMap(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.MasterLabel }}
          </v-list-item-title>
          <div
            v-if="activeItems.includes(item.Id)"
            class="text--secondary text-caption"
          >
            <div>{{ item.Description__c }}</div>
            <v-dialog v-model="legendDialog[item.Id]" width="500">
              <template #activator="{ on, attrs }">
                <v-btn
                  color="red lighten-2"
                  dark
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  凡例
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  {{ item.Label }}
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-slider
                    v-model.lazy="opacitySlider[item.Id]"
                    max="100"
                    min="0"
                    :label="'不透明度 ' + opacitySlider[item.Id] + '%'"
                    dense
                    @change="changeOpacity(item.Id)"
                  >
                  </v-slider>
                  <div>{{ item.Description__c }}</div>
                  <div v-if="item.LegendNewWindow__c">
                    <v-btn
                      color="red lighten-2"
                      text
                      small
                      dark
                      :href="item.LegendURL__c"
                      target="_blank"
                    >
                      凡例
                    </v-btn>
                  </div>
                  <img v-else :src="item.LegendURL__c" />
                  <div v-if="item.Attention__c" class="pt-2">
                    <div class="font-weight-bold">
                      注意点
                    </div>
                    {{ item.Attention__c }}
                  </div>
                  <div v-if="item.PublishDate__c" class="pt-2">
                    <div class="font-weight-bold">
                      提供開始
                    </div>
                    {{ item.PublishDate__c }}
                  </div>
                  <div v-if="item.Publisher__c" class="pt-2">
                    <div class="font-weight-bold">
                      出典のデータの名称／作成者等
                    </div>
                    {{ item.Publisher__c }}
                  </div>
                  <div v-if="item.Comment__c" class="pt-2">
                    <div class="font-weight-bold">
                      備考
                    </div>
                    {{ item.Comment__c }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="legendDialog[item.Id] = false"
                  >
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data: () => ({
    hazardTypes: [],
    activeItems: [],
    hazardMapItems: [],
    hazardMapLayers: {},
    legendDialog: {},
    opacitySlider: {},
  }),
  computed: {},
  watch: {},
  async mounted() {
    await this.loadHazardMapList();
  },
  methods: {
    /**
     * ハザードマップ一覧取得
     */
    async loadHazardMapList() {
      const { controller } = this.$pageProperty;
      this.hazardMapItems = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'getHazardMapList',
          params: [],
        }),
      );
      let arr = [];
      this.hazardMapItems.forEach((item) => {
        arr.push(item.HazardType__c);
        // 不透明度のデフォルト値を設定 this.opacitySlider[item.Id] = 80; ではwatchが動かなかった
        this.$set(this.opacitySlider, item.Id, 80);
        this.hazardMapLayers[item.Id] = new google.maps.ImageMapType({
          getTileUrl: (coord, zoom) => {
            const x = (coord.x % Math.pow(2, zoom)).toString();
            const y = coord.y.toString();
            return item.URL__c?.replace(
              '{z}/{x}/{y}',
              zoom + '/' + x + '/' + y || null,
            );
          },
          tileSize: new google.maps.Size(256, 256),
          minZoom: item.MinZoom__c,
          maxZoom: item.MaxZoom__c,
          opacity: this.opacitySlider[item.Id] / 100,
        });
      });
      this.hazardTypes = Array.from(new Set(arr));
    },
    async toggleHazardMap(item) {
      const { map } = this.$store.state.GIS;
      if (!this.activeItems.includes(item.Id)) {
        this.activeItems.push(item.Id);
        const layerMapType = this.hazardMapLayers[item.Id];
        // 最後に選択したレイヤーを上に重ねたい
        map.overlayMapTypes.insertAt(0, layerMapType);
      } else {
        this.activeItems = this.activeItems.filter((v) => v !== item.Id);
        let delId;
        map.overlayMapTypes.forEach((element, idx) => {
          if (element && element == this.hazardMapLayers[item.Id]) delId = idx;
        });
        if (delId !== undefined) map.overlayMapTypes.setAt(delId, null);
      }
    },
    async changeOpacity(itemId) {
      this.hazardMapLayers[itemId].setOpacity(this.opacitySlider[itemId] / 100);
    },
  },
};
</script>
