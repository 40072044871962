<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_Headquarter__c',
        detailPageName: 'CDS_VF_HeadquarterDetail',
        isDisasterRelated: true,
        showImageTab: true,
        showMapTab: true,
        hasVersion: true,
        masterObjectName: 'CDS_M_Organization__c',
      }"
      :search-props="{
        syncConditionWithUrl: true,
        applyErrataToCondition: !le,
        defaultSearchCondition,
      }"
      :table-props="{
        defaultListOptions,
        listFieldSetName: 'FixListFieldSet,ListFieldSet',
        defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
        csvFieldSetName: 'FixListFieldSet,ListFieldSet',
        createButtonName: '臨時本部体制',
        masterFieldName: 'CDS_M_Organization__c',
        canCreate: $gc.operationMaster.includes(
          $store.state.user.organization.Name,
        ),
        canEdit: $gc.operationMaster.includes(
          $store.state.user.organization.Name,
        ),
        viewAttachment: true,
        viewComment: !le,
      }"
      :google-map-props="{
        gisDynamicSettingName: 'GIS_C_D_Headquarter',
      }"
    >
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: () => ({
    defaultListOptions: {
      sortBy: ['Sort__c'],
      sortDesc: [false],
    },
  }),

  computed: {
    le() {
      return this.$query.current().le === 'true';
    },
  },

  methods: {
    defaultSearchCondition() {
      const res = {};
      if (this.le) {
        // 取消は表示しない
        res.ErrataType__c = [null, '訂正'];
        // 自組織のみ表示
        res.CDS_M_Organization__c = [this.$store.state.user.organization.Id];
      }
      return res;
    },
  },
};
</script>
