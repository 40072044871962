/**
 * 同心円クラス
 */
export default class MultiCircle {
  constructor({ circles = [], circleSetting = {}, map }) {
    this.type = 'MultiCircle';
    this.features = circles;
    this.circleSetting = circleSetting;
    this.map = map;
    // 外心円を抽出
    this.features.sort((a, b) => {
      if (a.getRadius() == b.getRadius()) return 0;
      return a.getRadius() < b.getRadius() ? 1 : -1;
    });
    const [outerCircle] = this.features;
    if (outerCircle) {
      // 外心円のみ背景色を設定する
      outerCircle.setOptions(this.circleSetting);
      // 外心円移動時にすべての円を移動する
      outerCircle.addListener('center_changed', () => {
        this.features
          .slice(1)
          .forEach((circle) => circle.setCenter(outerCircle.getCenter()));
      });
    }
  }
  /**
   * JSONを取得する
   */
  getJSON() {
    return {
      type: 'FeatureCollection',
      geometry: {
        type: 'MultiCircle',
        features: this.features.map(function (d) {
          return d.getJSON();
        }),
      },
    };
  }
  /**
   *
   */
  getBounds() {
    return this.features[0].getBounds();
  }
  /**
   * 表示対象mapを指定
   * @param {*} map
   */
  setMap(map) {
    this.features.forEach((d) => d.setMap(map));
    this.map = map;
  }
  /**
   * JSONからオブジェクトを生成
   * @param {*} polygon
   * @param {*} map
   */
  loadJSON({ geometry }) {
    this.features = geometry.features.map((prop) => {
      const circle = new google.maps.Circle();
      circle.loadJSON(prop);
    });
    this.setMap(this.map);
  }
}
