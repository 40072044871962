<template>
  <default-layout>
    <template #insideContent>
      <v-container fluid>
        <Alert />

        <Overview />

        <!-- ECharts License -->
        <e-chart-credit />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import Overview from './stat/overview';
import Alert from './stat/alert';

export default {
  components: {
    defaultLayout,
    Overview,
    Alert,
  },
};
</script>
