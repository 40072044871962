<template>
  <div>
    <!-- 操作パネルの高さ稼ぎ -->
    <div style="height: 100px;"></div>
    <!-- 操作パネル -->
    <v-app-bar dense fixed bottom style="z-index: 3;">
      <v-row justify="center" align="center">
        <div class="d-flex align-center mr-3">
          <span>原因種別:</span>
        </div>
        <div class="d-flex mini-input" style="width: 200px;">
          <v-select
            v-model="selectedReasonType"
            :items="reasonTypes"
            dense
            outlined
            hide-details
            height="36"
            append-icon=""
            item-text="label"
            item-value="value"
          ></v-select>
        </div>
        <div class="mx-2"></div>
        <v-btn
          v-for="(announceType, announceTypeIndex) in announceTypes"
          :key="announceTypeIndex"
          color="primary"
          outlined
          class="mx-1"
          @click="
            $emit('declare', {
              announceTypeName: announceType.name,
              selectedReasonType,
            })
          "
        >
          {{ announceType.short || announceType.label }}
        </v-btn>
        <v-divider vertical class="mx-5"></v-divider>
        <v-btn depressed outlined class="mr-2" @click="$emit('lift')">
          発令解除
        </v-btn>
        <v-btn depressed outlined @click="$emit('lift-all')">
          全解除
        </v-btn>
        <v-divider vertical class="mx-5"></v-divider>
        <v-btn depressed outlined @click="$emit('lift')">
          入力クリア
        </v-btn>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'EvacuationEditorTool',

  props: {
    // 原因種別
    reasonTypes: { type: Array, default: () => [] },
    // 発令種別
    announceTypes: { type: Array, default: () => [] },
  },

  data: () => ({
    // 選択した原因種別
    selectedReasonType: null,
  }),

  watch: {
    reasonTypes(to) {
      // 原因種別の初期値を設定
      if (!this.selectedReasonType) {
        this.selectedReasonType = _.get(to, '[0].value', null);
      }
    },
  },
};
</script>
