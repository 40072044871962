import Vue from 'vue';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Upload } from '@aws-sdk/lib-storage';
import {
  S3Client,
  ListObjectsCommand,
  GetObjectCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
/**
 * S3接続
 * @returns
 */
export async function loadS3() {
  return await Vue.$cache.get({
    key: 's3_client',
    async exec() {
      const token = await Vue.$cache.get({
        key: 'cognito_token',
        async exec() {
          return await Vue.cognito.getAuthToken();
        },
        age: 5,
      });

      const credentialsOptions = {
        client: new CognitoIdentityClient({ region: window.appConfig.region }),
        identityPoolId: window.appConfig.cognito.idPoolId,
        logins: {
          [window.appConfig.cognito.userPoolProviderName]: token,
        },
      };
      const s3options = {
        region: window.appConfig.region,
        credentials: fromCognitoIdentityPool(credentialsOptions),
      };
      const s3 = new S3Client(s3options);
      return s3;
    },
    age: 10,
  });
}

/**
 * S3送信処理
 * @param {*} fileName
 * @param {*} base64
 * @param {*} progresEvent
 */
export async function send(fileName, file, progresEvent) {
  const s3 = await loadS3();
  const key = `${fileName}`;
  // S3オブジェクトの取得
  const listRes = await s3.send(
    new ListObjectsCommand({
      Bucket: window.appConfig.attachmentBucket.name,
    }),
  );
  // すでにアップロード済みのファイルであればアップロードしない
  if (listRes.Contents?.find((d) => d.Key === key)) {
    return {};
  }

  const uploadParams = {
    Bucket: window.appConfig.attachmentBucket.name,
    Key: key,
    Body: file,
  };

  const parallelUploads3 = new Upload({
    client: s3,
    params: uploadParams,
  });
  parallelUploads3.on('httpUploadProgress', progresEvent);

  const result = await parallelUploads3.done();
  return { currentTarget: { response: result } };
}

export async function getUrl(key) {
  const s3 = await loadS3();
  const command = new GetObjectCommand({
    Bucket: window.appConfig.attachmentBucket.name,
    Key: key,
  });
  return getSignedUrl(s3, command, { expiresIn: 60 * 60 });
}
