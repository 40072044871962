import App from './App.vue';

export default {
  path: '/changePassword',
  name: 'changePassword',
  component: App,
  meta: {
    pageProperty: {
      title: 'パスワード変更',
    },
    guardRules: [],
  },
};
