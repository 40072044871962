import Vue from 'vue';
import _ from 'lodash';

const lodash = {
  install: function (Vue) {
    Vue.prototype._ = _;
    Vue._ = _;
  },
};

Vue.use(lodash);
export default lodash;
