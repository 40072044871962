var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bar-card',{attrs:{"loading":_vm.loading,"bar-click":_vm.handleClickBar},on:{"reload":function($event){return _vm.init()}},scopedSlots:_vm._u([{key:"bar_tab",fn:function(){return [_c('BarCardTab',{attrs:{"title":"避難所","icon":"mdi-home-account","active":_vm.tab === 0,"on":{
        click: () => {
          _vm.tab = 0;
        },
      }}}),_c('BarCardTab',{attrs:{"title":"福祉避難所","icon":"mdi-human-wheelchair","active":_vm.tab === 1,"on":{
        click: () => {
          _vm.tab = 1;
        },
      }}}),_c('BarCardTab',{attrs:{"title":"応急救護所","icon":"mdi-medical-bag","active":_vm.tab === 2,"on":{
        click: () => {
          _vm.tab = 2;
        },
      }}})]},proxy:true}])},[_c('div',[(_vm.tab === 0)?_c('Refuge',{attrs:{"update-event":_vm.updateEvent[0]},on:{"loading":function($event){return _vm.handleUpdateLoading(0, $event)}}}):_vm._e(),(_vm.tab === 1)?_c('WelfareShelter',{attrs:{"update-event":_vm.updateEvent[1]},on:{"loading":function($event){return _vm.handleUpdateLoading(1, $event)}}}):_vm._e(),(_vm.tab === 2)?_c('FirstAid',{attrs:{"update-event":_vm.updateEvent[2]},on:{"loading":function($event){return _vm.handleUpdateLoading(2, $event)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }