<template>
  <detail
    object-name="SBS_T_ConnectResult__c"
    layout-name="DetailLayout"
    default-title="ArcSBS連携履歴"
    subtitle="ArcSBS連携履歴"
    saved-page="CDS_VF_SBSConnectResultDetail"
    detail-page-name="CDS_VF_SBSConnectResultDetail"
    list-page="CDS_VF_SBSConnectResultList"
    :list-view-props="{
      canSearch: false,
    }"
    :can-edit="false"
    :disable-attachment="true"
    :use-version="false"
    :disable-actions="['edit', 'remove']"
  >
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import { disaster } from '@/beforeGuard/defaultRules';

export default {
  components: {
    detail,
  },

  async beforeCreate() {
    try {
      // berforeCreateで災害情報を読み込まないので読み込む
      const state = await disaster.load();
      await this.$store.dispatch(
        'disaster/setDisaster',
        state.disaster.disaster,
      );
    } catch (error) {
      // ignored
    }
  },
};
</script>
