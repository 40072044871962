<template>
  <div class="overflow-hidden">
    <v-stepper
      v-if="requestStatuses && requestStatuses.length > 0"
      :alt-labels="$vuetify.breakpoint.mobile"
      class="elevation-0 mb-n2 mx-n15 mx-md-0"
      :value="stepper"
    >
      <v-stepper-header>
        <template v-for="(status, statusIndex) in requestStatuses">
          <v-stepper-step
            :key="`request-status-stepper-${statusIndex}`"
            :step="statusIndex + 1"
          >
            {{ status.label }}
          </v-stepper-step>
          <v-divider
            v-if="requestStatuses.length !== statusIndex + 1"
            :key="`request-status-stepper-divider-${statusIndex}`"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
    <v-alert v-if="showAlert" dense outlined type="error" class="ma-0">
      要請は完了していません。<strong>要請</strong>ボタンで要請してください。
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    originalData: { type: Object, default: () => ({}) },
    editMode: { type: Boolean, default: false },
    requestStatuses: { type: Array, default: () => [] },
  },

  computed: {
    stepper() {
      const status = this.originalData?.RequestStatus__c || '要請前';
      // 対応不能の場合は完了扱いにする
      const findTarget = status == '対応不能' ? '完了' : status;
      const stepper =
        this.requestStatuses.map((v) => v.label).indexOf(findTarget) + 1;
      return stepper;
    },

    showAlert() {
      const id = this.originalData?.Id;
      const status = this.originalData?.RequestStatus__c;
      return !this.editMode && id && status === '要請前';
    },
  },
};
</script>
