export const config = {
  GISURL: 'http://hamabosai.maps.arcgis.com/apps/webappviewer/index.html?',
  GISURL_kunren:
    'https://hamabosai.maps.arcgis.com/apps/webappviewer/index.html?id=c255c0b31cbb46feb085192f4c62a11f',
  selectMap: 'standardMap',
  mapItems: [
    {
      id: 'standardMap',
      name: '標準地図',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
      type: 'url',
    },
    {
      id: 'lightColorMap',
      name: '淡色地図',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
      type: 'url',
    },
    {
      id: 'orthoImage',
      name: '電子国土基本図（オルソ画像）',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/ort/{z}/{x}/{y}.jpg',
      type: 'url',
    },
    {
      id: 'colorElevationChart',
      name: '色別標高図',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png',
      type: 'url',
    },
    {
      id: 'streets-vector',
      name: 'ESRI streets',
      value: 'streets-vector',
      type: 'esrimap',
    },
    {
      id: 'satellite',
      name: 'ESRI satellite',
      value: 'satellite',
      type: 'esrimap',
    },
    {
      id: 'topo-vector',
      name: 'Topographic map',
      value: 'topo-vector',
      type: 'esrimap',
    },
    // { id:'osm',name: 'Open Street Map', value: 'osm' ,type:'esrimap'},
  ],
  latitude: 34.71085759913865,
  longitude: 137.72616729965483,
  x: 15331606.812706782,
  y: 4124656.9407469835,
  spatialReference: { wkid: 102100 },
  zoom: 13,
  styleModel: {
    pointSymbol: {
      type: 'simple-marker',
      path:
        'M16,3.5c-4.142,0-7.5,3.358-7.5,7.5c0,4.143,7.5,18.121,7.5,18.121S23.5,15.143,23.5,11C23.5,6.858,20.143,3.5,16,3.5z M16,14.584c-1.979,0-3.584-1.604-3.584-3.584S14.021,7.416,16,7.416S19.584,9.021,19.584,11S17.979,14.584,16,14.584z',
      color: '#FE642E',
      size: '32',
      xoffset: 0,
      yoffset: 11,
    },
    polylineSymbol: {
      type: 'simple-line',
      color: '#8A2BE2',
      width: '4',
      join: 'miter',
      style: 'solid',
    },
    polygonSymbol: {
      type: 'simple-fill',
      color: 'rgba(76, 159, 180, 0.7)',
      style: 'solid',
      outline: {
        color: 'white',
        width: 1,
      },
    },
  },
};
