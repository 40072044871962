var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"table-props":{
      defaultListOptions: _vm.defaultListOptions,
      viewComment: !_vm.le,
      inputFieldSetName: 'ListFieldSet',
      listFieldSetName: 'FixListFieldSet,ListFieldSet',
      defaultInputFieldSetName: 'DefaultListFieldSet',
      defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
      bulkFieldSetName: 'BulkFieldSet',
      csvFieldSetName: 'CSVFieldSet,ListFieldSet',
      canCreate: false,
      masterFieldName: 'CDS_M_JMAInfo__c',
    }}},'ListView',{
      objectName: 'CDS_T_JMAInfo__c',
      detailPageName: 'CDS_VF_JMAInfoDetail',
      isDisasterRelated: true,
      hasVersion: true,
      masterObjectName: 'CDS_M_JMAInfo__c',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }