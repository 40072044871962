<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <LifelineListView
      v-bind="{
        objectSettings,
        detailLayoutName: 'DetailLayout',
        isDisasterRelated: true,
        hasVersion: true,
      }"
      :table-props="{
        viewAttachment: true,
        viewComment: !le,
        inputFieldSetName: 'ListFieldSet',
        listFieldSetName: 'FixListFieldSet,ListFieldSet',
        defaultInputFieldSetName: 'ListFieldSet',
        defaultListFieldSetName: 'FixListFieldSet,ListFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
        csvFieldSetName: 'CSVFieldSet,ListFieldSet',
      }"
      :search-props="{
        applyErrataToCondition: true,
        showBaseDatetime: !le,
      }"
    >
    </LifelineListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import LifelineListView from './LifelineListView.vue';
import { objectSettings } from '@/assets/js/objects/CDS_T_Lifeline__c';

export default {
  components: {
    defaultLayout,
    PageHeader,
    LifelineListView,
  },

  data: () => ({
    objectSettings,
  }),

  computed: {
    le() {
      return this.$query.current().le === 'true';
    },
  },
};
</script>
