<template>
  <v-icon v-if="value && item.InfoType__c" @click.stop="openLink(item)">
    mdi-open-in-new
  </v-icon>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean, Number, null, undefined],
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    config: {
      type: Array,
      required: true,
    },
  },

  methods: {
    // リンク先を開く
    openLink(item) {
      // レコードIdか情報種別がなければ何もしない
      if (!item.RecordId__c || !item.InfoType__c) return;
      // configの取得
      const targetConfig = this.config.find((v) => v.Label == item.InfoType__c);
      if (!targetConfig) return;

      // ${aaa}の変数を置き換える
      const link = this.replaceVariable(targetConfig.LinkTo, item);

      this.$transition.to(link, null, {
        notApplyRetUrl: true,
        openNewTab: true,
      });
    },
    replaceVariable(sentence, dataMap) {
      if (sentence) {
        // nullなどを置換した安全なオブジェクト
        const safeObj = Object.entries(dataMap).reduce((prev, [key, value]) => {
          return {
            ...prev,
            [key]: this.escapeNull(value),
          };
        }, {});
        // functionを作成
        const replaceFunction = new Function('obj', `return \`${sentence}\`;`);
        // 置き換え実施
        const result = replaceFunction(safeObj);

        return result;
      } else {
        return sentence;
      }
    },
    escapeNull(v) {
      if (v === null || v === undefined) return '';
      return `${v}`;
    },
  },
};
</script>
