<template>
  <public-layout>
    <div v-if="!success">
      <h1>パスワード変更</h1>

      <v-form ref="form" v-model="valid" lazy-validation @submit="validate">
        <v-text-field
          v-model="value.oldPassword"
          label="旧パスワード"
          :rules="[rules.required]"
          required
          outlined
          dense
          type="password"
        ></v-text-field>

        <v-text-field
          v-model="value.newPassword"
          label="新パスワード"
          :rules="[rules.required]"
          required
          outlined
          dense
          type="password"
        ></v-text-field>

        <v-text-field
          v-model="value.newPasswordConfirm"
          label="新パスワード(再入力)"
          :rules="[rules.required]"
          required
          outlined
          dense
          type="password"
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          color="info"
          class="mr-4"
          depressed
          @click="validate"
        >
          パスワード変更
        </v-btn>
      </v-form>
    </div>
    <div v-else>
      <h3>パスワードを変更しました。</h3>
      <v-btn
        color="info"
        depressed
        class="mr-4"
        @click="$transition.fallback()"
      >
        トップへ
      </v-btn>
    </div>
    <div>
      <v-btn color="gray" depressed class="mr-4" @click="logout">
        logout
      </v-btn>
      <v-btn color="gray" depressed class="mr-4" @click="getLoggedIn">
        getLoggedIn
      </v-btn>
    </div>
  </public-layout>
</template>

<script>
import publicLayout from '@/components/layout/public';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    valid: true,
    value: {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
    },
    rules: {
      required: (v) => !!v || '必須項目です。',
    },
    success: false,
  }),

  methods: {
    /**
     * フォームのバリデート
     */
    async validate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.value.newPassword !== this.value.newPasswordConfirm) {
        this.openSnackBar({
          message: `新パスワードと新パスワード(再入力)が一致しません。`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 5000,
          },
          closable: true,
        });
        return;
      }
      await this.$store.dispatch('loading/register', this.changePassword());
    },

    /**
     * パスワード変更
     */
    async changePassword() {
      try {
        const result = await this.$cognito.changePassword(
          this.value.oldPassword,
          this.value.newPassword,
        );
        console.log('changepassword result:', result);
        this.success = true;
      } catch (error) {
        console.error(error);
        this.openSnackBar({
          message: `パスワード変更に失敗しました。${error.message}`,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        this.$refs.form.resetValidation();
      }
    },

    async logout() {
      await this.$cognito.logout();
    },

    async getLoggedIn() {
      try {
        const user = await this.$cognito.getLoggedInUser();
        console.log(user);
      } catch (error) {
        console.error(error);
      }
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
  components: {
    publicLayout,
  },
};
</script>
