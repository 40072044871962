<template>
  <detail
    object-name="CDS_T_Publicity__c"
    layout-name="DetailLayout"
    default-title="市民への情報提供履歴"
    saved-page="CDS_VF_PublicityDetail"
    detail-page-name="CDS_VF_PublicityDetail"
    list-page="CDS_VF_PublicityList"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
  >
  </detail>
</template>

<script>
import detail from '@/components/detail/';

export default {
  components: {
    detail,
  },
  computed: {
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
  },
};
</script>
