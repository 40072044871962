var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{scopedSlots:_vm._u([{key:"pageHeader",fn:function(){return [_c('page-header')]},proxy:true}])},[_c('ListView',_vm._b({attrs:{"table-props":{
      csvFieldSetName: 'CSVFieldSet',
      bulkFieldSetName: 'BulkFieldSet',
      canEdit: false,
      canCreate: _vm.isEditMode,
    },"search-props":{
      syncConditionWithUrl: true,
    }}},'ListView',{
      objectName: 'CDS_T_Publicity__c',
      detailLayoutName: 'DetailPreviewLayout',
      detailPageName: 'CDS_VF_PublicityDetail',
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }