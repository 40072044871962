import App from './App.vue';

export default {
  path: '/CDS_VF_FirstAidList',
  name: 'CDS_VF_FirstAidList',
  component: App,
  meta: {
    pageProperty: {
      title: '応急救護所一覧',
      controller: 'CDS_CTR_FirstAidList',
    },
  },
};
