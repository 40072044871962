import Vue from 'vue';

// encoded data
const configContext = window._appConfig;
// parsed data
const configData = JSON.parse(
  new TextDecoder().decode(
    Uint8Array.from(atob(configContext), (c) => c.charCodeAt(0)),
  ),
);

window.appConfig = configData;

const plugin = {
  install(Vue) {
    Vue.prototype.$config = configData;
    Vue.appConfig = configData;
  },
};

Vue.use(plugin);
export default plugin;
