/* eslint-disable no-extra-boolean-cast */
// 一括配信内容Validation(電話番号・メールアドレス・URL入力不可)
export const SEND_CONTENT_VALIDATION =
  /^^(?!(.|\s)*[\w\-._]+@[\w\-._]+\.[A-Za-z]+)^(?!(.|\s)*https?:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+)^(?!(.|\s)*0\d{2,3}-\d{1,4}-\d{4})(.|\s)*$/;
export const SEND_CONTENT_VALIDATION_MESSAGE =
  '電話番号・メールアドレス・URLを入力することはできません。';

// 災害情報のバリデート
export const validateDisaster = (value) => {
  const result = [];

  // 対応状況が対応中のとき、終息日時に値があればエラー
  if (value.DisasterStatus__c === '対応中' && !!value.CloseDate__c) {
    result.push('対応状況が「対応中」の場合は終息日時に値を入力できません。');
  }

  // 対応状況が終息のとき、終息日時が空であればエラー
  if (value.DisasterStatus__c === '終息' && !value.CloseDate__c) {
    result.push('対応状況が「終息」の場合は終息日時に入力してください。');
  }

  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 開設状況と開設日時閉鎖日時の共通バリデート
const openStatusValidate = (status, openDate, closeDate) => {
  const result = [];

  switch (status) {
    case '開設': {
      if (!openDate) {
        result.push('開設状況が「開設」の時は、開設日時を入力してください。');
      }
      if (!!closeDate) {
        result.push(
          '開設状況が「開設」の時は、閉鎖日時を入力しないでください。',
        );
      }
      break;
    }
    case '閉鎖': {
      if (!openDate) {
        result.push('開設状況が「閉鎖」の時は、開設日時を入力してください。');
      }
      if (!closeDate) {
        result.push('開設状況が「閉鎖」の時は、閉鎖日時を入力してください。');
      }
      break;
    }
    default: {
      if (!!openDate) {
        result.push(
          '開設状況が「開設」「閉鎖」以外の時は、開設日時を入力しないでください。',
        );
      }
      if (!!closeDate) {
        result.push(
          '開設状況が「開設」「閉鎖」以外の時は、閉鎖日時を入力しないでください。',
        );
      }
      break;
    }
  }
  return result;
};

// 避難所のバリデート
export const validateRefuge = (value) => {
  let result = [];

  // 開設状況のバリデート
  result = [
    ...result,
    ...openStatusValidate(
      value.OpenStatus__c,
      value.OpenDate__c,
      value.CloseDate__c,
    ),
  ];

  // 避難世帯数が避難者数を超えない
  // 世帯数、人数の両フィールドに値がある(0は値があるものと見る)ときだけ制限をかける
  const householdNum = Number(value.HouseholdNum__c);
  const peopleNum = Number(value.PeopleNum__c);
  if (
    (!!householdNum || householdNum === 0) &&
    (!!peopleNum || peopleNum === 0)
  ) {
    // 避難世帯数が避難者数を超えない
    if (householdNum > peopleNum) {
      result.push('避難世帯数より避難者数が大きくなるように入力してください。');
    }
  }

  // うち
  const voluntaryHouseholdNum = Number(value.VoluntaryHouseholdNum__c);
  const voluntaryPeopleNum = Number(value.VoluntaryPeopleNum__c);
  if (
    (!!householdNum || householdNum === 0) &&
    (!!voluntaryHouseholdNum || voluntaryHouseholdNum === 0)
  ) {
    if (voluntaryHouseholdNum > householdNum) {
      result.push('うち自主避難世帯数が避難世帯数を超えています。');
    }
  }
  if (
    (!!peopleNum || peopleNum === 0) &&
    (!!voluntaryPeopleNum || voluntaryPeopleNum === 0)
  ) {
    if (voluntaryPeopleNum > peopleNum) {
      result.push('うち自主避難者数が避難者数を超えています。');
    }
  }

  // 位置情報は必須にする
  if (!value.Location__c) {
    result.push('避難所位置を指定してください。');
  }

  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 応急救護所のバリデート
export const validateFirstAid = (value) => {
  let result = [];

  // 開設状況のバリデート
  result = [
    ...result,
    ...openStatusValidate(
      value.OpenStatus__c,
      value.OpenDate__c,
      value.CloseDate__c,
    ),
  ];

  // 位置情報は必須にする
  if (!value.Location__c) {
    result.push('応急救護所位置を指定してください。');
  }

  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// ヘリポートのバリデート
export const validateHeliport = (value) => {
  let result = [];

  // 開設状況のバリデート
  result = [
    ...result,
    ...openStatusValidate(
      value.OpenStatus__c,
      value.OpenDate__c,
      value.CloseDate__c,
    ),
  ];
  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 本部設置のバリデート
export const validateHeadquarter = (value) => {
  const result = [];
  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 本部設置モーダルのバリデート
export const validateHeadquarterModal = (value) => {
  const result = [];
  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 活動拠点のバリデート
export const validateActivityBase = (value) => {
  let result = [];

  // 開設状況のバリデート
  result = [
    ...result,
    ...openStatusValidate(
      value.OpenStatus__c,
      value.OpenDate__c,
      value.CloseDate__c,
    ),
  ];
  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 福祉避難所のバリデート
export const validateWelfareShelter = (value) => {
  let result = [];

  // 開設状況のバリデート
  result = [
    ...result,
    ...openStatusValidate(
      value.OpenStatus__c,
      value.OpenDate__c,
      value.CloseDate__c,
    ),
  ];
  return result.length === 0 ? null : { message: result.join('\n'), result };
};

// 事案報告のバリデート
export const validateDamageReport = (value) => {
  const result = [];
  const { SurvivorNum__c } = value;
  // 要救助者数の確認状況 によって 現時点の要救助者数 の必須を変える
  if (value.SurvivorCondition__c === '確定（入力時点の人数として）') {
    if (
      SurvivorNum__c === null ||
      SurvivorNum__c === undefined ||
      SurvivorNum__c === ''
    ) {
      result.push(
        '「要救助者数の確認状況」が"確定（入力時点の人数として）"の時は「現時点の要救助者数」は必須項目です。',
      );
    }
  } else {
    if (
      !(
        SurvivorNum__c === null ||
        SurvivorNum__c === undefined ||
        SurvivorNum__c === ''
      )
    ) {
      result.push(
        '「要救助者数の確認状況」が"確定（入力時点の人数として）"以外の時は「現時点の要救助者数」は入力しないでください。',
      );
    }
  }
  return result.length === 0 ? null : { message: result.join('\n'), result };
};
