import Vue from 'vue';

/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.$util = this;
    // Vue.util = this;
  },

  /**
   * レイアウト情報を取得
   * @param {String} objectName オブジェクト名
   * @param {String} layoutName レイアウト名
   *
   * @returns {Object | undefined} layout
   */
  async getLayout(objectName, layoutName) {
    this.layout = this.layout || {};
    if (!this.layout[objectName]) {
      const { layout } = await Vue.prototype.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getLayout',
        params: {
          objectName,
        },
      });
      this.layout[objectName] = layout;
    }
    const objectInfo = await this.getObjectInfo(objectName);
    return {
      layout: structuredClone(this.layout[objectName]?.[layoutName]),
      objectInfo,
    };
  },

  /**
   * 項目セットを取得
   * @param {String} objectName オブジェクト名
   * @param {String | Array<String>} fieldSetName  項目セット名
   *
   * @returns {Object | undefined} fieldSet
   */
  async getFieldSet(objectName, fieldSetName) {
    // 結果の項目セット
    let resultFieldSet;

    const objectInfo = await this.getObjectInfo(objectName);

    if (fieldSetName) {
      this.fieldSet = this.fieldSet || {};
      if (!this.fieldSet[objectName]) {
        const { fieldSet } = await Vue.prototype.$con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'getFieldSet',
          params: {
            objectName,
          },
        });
        this.fieldSet[objectName] = fieldSet;
      }

      // 対象のオブジェクトの項目セット
      const objectFieldSet = this.fieldSet[objectName];

      if (objectFieldSet) {
        // 項目セット名の配列
        const fieldSetNameArray = Array.isArray(fieldSetName)
          ? fieldSetName
          : fieldSetName.split(',');

        // 各項目セット名に対して項目セットを取得する
        resultFieldSet = fieldSetNameArray
          .map((name) => name && objectFieldSet[name])
          .filter((v) => !!v)
          .reduce((prev, next) => {
            const p = prev || {};
            const existFieldKeys = (p.fields || []).map(
              (f) => `${f.fieldPath}${f.key}`,
            );
            return {
              fullName: p.fullName || next.fullName,
              description: p.description || next.description,
              label: p.label || next.label,
              sobjecttype: p.sobjecttype || next.sobjecttype,
              name: p.name || next.name,
              fields: [
                ...(p.fields || []),
                // 現在存在する項目を除去して追加する
                ...(next.fields || []).filter(
                  (f) => !existFieldKeys.includes(`${f.fieldPath}${f.key}`),
                ),
              ],
            };
          }, undefined);
      }
    }

    return {
      fieldSet: structuredClone(resultFieldSet),
      objectInfo,
    };
  },

  /**
   * オブジェクト情報を取得
   * @param {String} objectName オブジェクト名
   *
   * @returns {Object} objectInfo
   */
  async getObjectInfo(objectName) {
    // キャッシュさせる
    this.objectInfo = this.objectInfo || {};
    if (!this.objectInfo[objectName]) {
      const { objectInfo } = await Vue.prototype.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getObjectInfo',
        params: {
          objectName,
        },
      });
      this.objectInfo[objectName] = objectInfo;
    }
    return structuredClone(this.objectInfo[objectName]);
  },

  /**
   * 設定情報を取得
   * @param {String} key 設定キー
   *
   * @returns {String} 設定値
   */
  async getConfigValue(key) {
    const res = await Vue.prototype.$con.invoke({
      controller: 'CDS_CTR_Common',
      method: 'getConfigValue',
      params: {
        key,
      },
    });
    return (res || {}).value;
  },

  /**
   * ページヘッダーに表示するアイテムを取得
   *
   * @returns {Object} menuItem
   */
  async getPageHeaderMenuItem() {
    // リクエストを投げるためのデータを生成
    // pathnameとsearch(URLパラメータ)
    const { pathname, search } = window.location;
    // pathを/で分けた最後の部分を取得(ページ名 CDS_VF_～)
    const splitPathname = pathname.split('/');
    const lastPath = splitPathname[splitPathname.length - 1];

    const url = `${lastPath}${search}`;
    const requestData = {
      url,
    };

    this.pageHeaderMenuItem = this.pageHeaderMenuItem || {};
    if (this.pageHeaderMenuItem[url]) {
      return this.pageHeaderMenuItem[url];
    }

    const res = await Vue.prototype.$con.invoke({
      controller: 'CDS_CTR_Common',
      method: 'getPageHeaderMenuItem',
      params: requestData,
    });

    this.pageHeaderMenuItem[url] = res;

    return res || null;
  },

  /**
   * 初期値のデフォルト値を取得する
   * @param {state} state storeのstate
   * @returns
   */
  getDefaultInitValue(state) {
    const {
      disaster: { disaster },
    } = state;
    const init = {
      CDS_T_Disaster__c: disaster.Id,
    };
    return init;
  },

  /**
   * 編集時のデフォルト値を取得する
   * @param {state} state storeのstate
   * @returns
   */
  getDefaultEveryOverwriteValue(state) {
    const {
      user: { organization, user },
    } = state;
    const init = {
      CDS_M_Organization__c: organization.Id,
      CDS_M_Organization__r: organization,
      Reporter__c: user.DefaultReporter__c,
      ReporterAffiliation__c: user.Department__c,
      ReporterTel__c: user.Phone,
    };
    return init;
  },
};

Vue.use(plugin);
export default plugin;
