<template>
  <div>
    <div>
      <v-slide-group
        v-model="tab"
        mandatory
        active-class="primary white--text"
        class="pa-2"
      >
        <v-slide-item
          v-for="(area, areaIndex) in content.info.areas"
          :key="areaIndex"
          v-slot="{ active, toggle }"
        >
          <v-btn
            class="mx-1"
            :input-value="active"
            depressed
            small
            @click="toggle"
          >
            {{ area }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          v-for="(area, areaIndex) in content.info.areas"
          :key="areaIndex"
        >
          <v-row no-gutters justify="space-around">
            <v-col
              v-for="(status, statusIndex) in content.info.statuses"
              :key="statusIndex"
              cols="auto"
              class="py-1 px-2"
            >
              <div :class="`${status.textClass} text-center`">
                <div class="text-sm-body-1 text-body-2">
                  {{ status.name }}
                </div>
                <div class="text-sm-h5 text-h6">
                  {{ _.get(dataMap, `${area}.${status.name}`, 0) }}
                  <template v-if="status.name == '計'">
                    / {{ _.get(dataMaster, area, '') }}
                  </template>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-around">
            <v-col
              v-for="(item, itemIndex) in content.info.numberItem"
              :key="itemIndex"
              cols="auto"
              class="py-1 px-2"
            >
              <div class="text-center">
                <div class="text-sm-body-1 text-body-2">
                  {{ _.get(item, 'label', '').replace(/^※/, '') }}
                </div>
                <div class="text-sm-h5 text-h6 red--text">
                  {{
                    getRoundedNumber(
                      _.get(dataMap, `${area}.${item.fieldPath}`, '-'),
                    )
                  }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="pb-3">
      <e-chart :option="content.chart" autoresize style="height: 220px;" />
    </div>
  </div>
</template>

<script>
import { OpenStatus__c } from '@/assets/js/objects/CDS_T_Refuge__c';

export default {
  props: {
    updateEvent: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    tab: null,

    objectInfo: {},
    // 下部に表示する項目
    numberFields: ['HouseholdNum__c', 'PeopleNum__c', 'AccommodatableNum__c'],

    // データ本体
    dataSource: [],
    // map形式のデータ
    dataMap: {},
    // マスタ件数
    dataMaster: {},
  }),
  computed: {
    // 区名
    wards() {
      return (
        this.objectInfo?.properties?.Ward__c?.picklistValues?.map(
          (v) => v.value,
        ) || []
      );
    },
    // 凡例 (未着手, 対応中, ...)
    legend() {
      return (
        this.objectInfo?.properties?.OpenStatus__c?.picklistValues?.map(
          (v) => v.value,
        ) || []
      );
    },
    // データ
    content() {
      return {
        // 数字
        info: {
          // エリア
          areas: ['市全体', ...this.wards],
          // 対応状況
          statuses: [...this.legend, '計'].map((l) => ({
            name: l,
            textClass: '',
            ...OpenStatus__c[l],
          })),
          // 世帯数などの凡例
          numberItem: this.numberFields.map((f) => ({
            ...this.objectInfo?.properties?.[f],
            fieldPath: f,
          })),
        },
        // グラフ
        chart: {
          color: this.legend.map((l) => OpenStatus__c[l].chartColor),
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            top: '0%',
            data: this.legend,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '48',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: this.wards,
            inverse: true,
          },
          dataset: {
            dimensions: ['Ward__c', ...this.legend],
            source: this.dataSource,
          },
          series: [...Array(this.legend.length)].map(() => ({
            type: 'bar',
            stack: 'stackname',
            label: {
              show: true,
              position: 'insideRight',
            },
            cursor: 'default',
          })),
          textStyle: {
            fontFamily: [
              'Hiragino Kaku Gothic ProN',
              'Hiragino Sans',
              'Roboto',
              'sans-serif',
            ],
          },
        },
      };
    },
  },
  watch: {
    updateEvent: {
      handler() {
        this.init();
      },
      deep: true,
    },
    loading(to) {
      this.$emit('loading', to);
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await Promise.all([
        this.getObjectInfo(),
        this.getStat(),
        this.getMasterCount(),
      ]);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // オブジェクト情報の取得
    async getObjectInfo() {
      if (!this.objectInfo || Object.keys(this.objectInfo).length === 0) {
        const objectInfo = await this.$util.getObjectInfo('CDS_T_Refuge__c');
        this.$set(this, 'objectInfo', objectInfo);
      }
    },
    // 情報の取得
    async getStat() {
      const { resultList = [], resultMap = {} } =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getRefugeStat',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};
      if (resultList && Array.isArray(resultList)) {
        this.dataSource = resultList;
      }
      if (_.isObject(resultMap) && !_.isEmpty(resultMap)) {
        this.$set(this, 'dataMap', resultMap);
      }
    },
    // マスタ件数の取得
    async getMasterCount() {
      const res =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getRefugeMasterCount',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};
      this.$set(this, 'dataMaster', res);
    },
    /**
     * 小数になる可能性があるので四捨五入する
     */
    getRoundedNumber(value) {
      if (!value) return value;
      if (Number(value) === value) {
        return Math.round(value);
      } else {
        return value;
      }
    },
  },
};
</script>
