<template>
  <v-card tile>
    <v-card-title>
      臨時拠点データ
    </v-card-title>

    <v-card-text>
      <FormulateForm
        v-model="extraForm.value"
        v-bind="extraForm.attrs"
        @submit-raw="handleOnSubmit"
      >
        <FormulateInput
          type="group"
          name="extra"
          repeatable
          remove-position="after"
          label="臨時拠点"
          class="extra-area"
        >
          <template #default="{index}">
            <v-row class="extra-row ma-0">
              <field
                v-for="f in displayFields"
                :key="f.fieldPath"
                v-bind="{
                  ...f,
                  fieldInfo: {
                    ...f.fieldInfo,
                    reverseGeocodingEventhandler: handleOnDecidedLocation(
                      index,
                    ),
                  },
                  // 名称変更は許可しない
                  readonly: recordHasId(index) && f.fieldPath === 'Name',
                }"
              >
              </field>
            </v-row>
          </template>
          <template #remove="{removeItem, index}">
            <!-- レコードがなければremove可能 -->
            <div class="d-flex px-2 align-center">
              <v-btn v-if="!getExtraRecord(index).Id" icon @click="removeItem">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <div v-else style="width: 36px; height: 36px;"></div>
            </div>
          </template>
          <template #addmore="{addMore}">
            <v-btn depressed color="blue-grey lighten-5" @click="addMore">
              <v-icon left>
                mdi-plus
              </v-icon>
              臨時拠点を追加する
            </v-btn>
          </template>
        </FormulateInput>
      </FormulateForm>
    </v-card-text>
    <v-card-actions>
      <Confirm
        message="「臨時拠点」は、一度作成すると名称変更および拠点の削除はできません。(拠点名称は正しいですか?)"
        @on-click-yes="handleClickExtraButton"
      >
        <template #default="{ on }">
          <v-btn depressed color="primary" v-on="on">
            臨時拠点データ保存
          </v-btn>
        </template>
      </Confirm>
    </v-card-actions>
  </v-card>
</template>

<script>
import Confirm from '@/components/common/Confirm';
import field from '@/components/detail/field/index.vue';

import { mapActions } from 'vuex';

export default {
  name: 'StaffReportCreateExtraForm',

  components: {
    Confirm,
    field,
  },

  data: () => ({
    objectInfo: {},
    objectInfoConvert: {
      label: {
        Name: '臨時拠点名',
        CDS_M_Organization__c: '入力組織名',
      },
      type: {
        Location__c: 'location-dialog',
      },
      other: {},
    },
    extraForm: {
      attrs: {
        name: 'CreateExtraStaffForm',
      },
      value: {
        extra: [],
      },
    },
  }),

  computed: {
    displayFields() {
      return [
        {
          fieldPath: 'Name',
          required: true,
        },
        {
          fieldPath: 'Location__c',
        },
        {
          fieldPath: 'Address__c',
        },
        {
          fieldPath: 'CDS_M_Organization__c',
        },
      ].map((field) => {
        const { fieldPath } = field;
        const fieldInfo = this.objectInfo?.properties?.[fieldPath];
        return {
          ...field,
          key: fieldPath,
          fieldInfo: {
            ...fieldInfo,
            label: this.objectInfoConvert.label[fieldPath] || fieldInfo?.label,
            type: this.objectInfoConvert.type[fieldPath] || fieldInfo?.type,
            ...(this.objectInfoConvert.other[fieldPath] || {}),
          },
          column: false,
          editMode: true,
        };
      });
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadExtraRecords(), this.loadObjectInfo()]),
      );
    },

    // 臨時レコードの読み込み
    async loadExtraRecords() {
      const result =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getExtraRecords',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};

      const { objects } = result;
      // フォームにセット
      this.extraForm.value.extra = objects;
    },

    // オブジェクト情報の読み込み
    async loadObjectInfo() {
      const result = await this.$util.getObjectInfo('CDS_T_Staff__c');
      if (result) {
        this.$set(this, 'objectInfo', result);
      }
    },

    // 臨時レコードの保存
    async saveExtraRecords() {
      try {
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller: this.$pageProperty.controller,
            method: 'createExtraStaff',
            params: {
              disasterId: this.$store.state.disaster.disaster.Id,
              extraRecords: this.extraForm.value.extra,
            },
          }),
        );
        this.init();
        this.saveComplete('臨時拠点データを保存しました');
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 臨時レコードを取得する
    getExtraRecord(index) {
      return this.extraForm.value?.extra?.[index] || {};
    },

    // レコードにIdが存在するか
    recordHasId(index) {
      return !!this.getExtraRecord(index).Id;
    },

    /******** ハンドラ ********/

    // 臨時ボタンのハンドラ
    handleClickExtraButton() {
      this.$formulate.submit(this.extraForm.attrs.name);
    },

    // フォームのvalidate
    async handleOnSubmit(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        // 独自のバリデーションがある場合はここで実施
        // const { extra } = this.extraForm.value;

        await this.saveExtraRecords();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /**
     * 位置情報を決めた時の住所等のハンドラ
     */
    handleOnDecidedLocation(index) {
      return ({ formatted_address }) => {
        // 念のためクローン
        const obj = structuredClone(this.extraForm.value);

        // 住所にセット
        _.set(obj, ['extra', index, 'Address__c'], formatted_address);

        // フォームにセット
        this.$formulate.setValues(this.extraForm.attrs.name, {
          ...obj,
        });
      };
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>

<style lang="scss">
.extra-area {
  .formulate-input-grouping {
    .formulate-input-group-repeatable {
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px solid gray;
      }
    }
  }
}
</style>
