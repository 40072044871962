import { covertLatLngToNumArray } from '@/googlemap/util';
export default (_Polyline) => {
  /**
   * 拡張多角線クラス
   */
  class Polyline extends _Polyline {
    /**
     * 多角線データをGeoJSON形式で出力する
     * @return {[type]} [description]
     */
    getGeoJSON() {
      const feature = {
        type: 'Feature',
        properties: {
          googleFeatureType: 'polyline'
        },
        geometry: {
          type: 'LineString',
          coordinates: this.getCoordinates()
        }
      };
      if (this.strokeColor) feature.properties.strokeColor = this.strokeColor;
      return feature;
    }
    /**
     * 多角線データの頂点データを配列で出力する
     * @return {[type]} [description]
     */
    getCoordinates() {
      return this.getPath().getArray().map(d => covertLatLngToNumArray(d));
    }
    /**
     * 多角線データを符号化JSON形式で出力する
     * @return {[type]} [description]
     */
    getJSON() {
      return {
        type: 'Feature',
        properties: {
          strokeColor: this.strokeColor,
        },
        geometry: {
          type: 'Polyline',
          coordinates: window.google.maps.geometry.encoding.encodePath(this.getPath())
        }
      };
    }
    /**
     * 多角線中心点出力
     * @return {[type]} [description]
     */
    getCenter() {
      return this.getPath().getAt(Math.floor(this.getPath().length / 2));
    }
    /**
     * 多角線の距離を取得
     * @return {[type]} [description]
     */
    getMeasureResult() {
      return window.google.maps.geometry.spherical.computeLength(this.getPath());
    }

    loadJSON(polygon) {
      const { properties, geometry } = polygon;
      this.setPath(google.maps.geometry.encoding.decodePath(geometry.coordinates));
      this.setOptions(properties);
    }
    /**
     * 多角形データをGeoJSON形式で出力する
     * @return {[type]} [description]
     */
    static getGeoJSON() {
      const feature = {
        type: 'Feature',
        properties: {
          googleFeatureType: 'polygon',
          geometry: {
            type: 'Polygon',
            coordinates: [this.getCoordinates()]
          }
        }
      };
      if (this.fillColor) feature.properties.fillColor = this.fillColor;
      return feature;
    }
  }
  return Polyline;
}

