<template>
  <detail
    object-name="CDS_T_Chronology__c"
    layout-name="ChronologyLayout"
    default-title="新規クロノロジー作成"
    saved-page="CDS_VF_ChronologyDetail"
    detail-page-name="CDS_VF_ChronologyDetail"
    list-page="CDS_VF_ChronologyList"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
  >
    <template #DetailMenus="{editMode, value}">
      <template v-if="!editMode && value.RecordId__c && value.InfoType__c">
        <ActionButton
          btn-value="情報元参照"
          depressed
          icon="fas fa-external-link-alt"
          @click="openLink(value)"
        />
      </template>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import ActionButton from '@/components/detail/ActionButton';

export default {
  components: {
    detail,
    ActionButton,
  },

  data: () => ({
    config: [],
  }),

  computed: {
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadConfig()]),
      );
    },
    // 設定の読み込み
    async loadConfig() {
      const config = await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'getConfig',
      });
      this.$set(this, 'config', config);
    },
    // リンク先を開く
    openLink(item) {
      // レコードIdか情報種別がなければ何もしない
      if (!item.RecordId__c || !item.InfoType__c) return;
      // configの取得
      const targetConfig = this.config.find((v) => v.Label == item.InfoType__c);
      if (!targetConfig) return;

      // ${aaa}の変数を置き換える
      const link = this.replaceVariable(targetConfig.LinkTo, item);

      this.$transition.to(link, null, {
        notApplyRetUrl: true,
        openNewTab: true,
      });
    },
    replaceVariable(sentence, dataMap) {
      if (sentence) {
        // nullなどを置換した安全なオブジェクト
        const safeObj = Object.entries(dataMap).reduce((prev, [key, value]) => {
          return {
            ...prev,
            [key]: this.escapeNull(value),
          };
        }, {});
        // functionを作成
        const replaceFunction = new Function('obj', `return \`${sentence}\`;`);
        // 置き換え実施
        const result = replaceFunction(safeObj);

        return result;
      } else {
        return sentence;
      }
    },
    escapeNull(v) {
      if (v === null || v === undefined) return '';
      return `${v}`;
    },
  },
};
</script>
