<template>
  <v-alert
    dense
    outlined
    :color="color"
    :icon="icon"
    :class="{ pointer: !!link }"
    @click="onClickAlert"
  >
    <template #prepend>
      <div class="h100 d-flex align-center mr-2">
        <div class="d-flex">
          <v-icon :color="color">
            {{ icon }}
          </v-icon>
        </div>
      </div>
    </template>
    <div v-if="title" class="text-h6 font-weight-bold" v-text="title" />
    <div v-if="content" style="white-space: pre-wrap;" v-text="content" />
  </v-alert>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'red',
    },
    icon: {
      type: String,
      default: 'mdi-alert-octagon-outline',
    },
    alertProps: {
      type: Object,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
  },

  methods: {
    onClickAlert() {
      if (this.link) {
        this.$transition.to(this.link, null, {
          openNewTab: !this.link.startsWith(location.origin),
          notApplyRetUrl: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
