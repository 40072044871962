export default (_Circle) => {
  /**
   * 拡張円クラス
   */
  class Circle extends _Circle {
    /**
     * 円データをGeoJSON形式で出力する
     * @return {[type]} [description]
     */
    getGeoJSON() {
      var feature = { type: 'Feature' };
      feature.properties = {};
      feature.properties.googleFeatureType = 'circle';
      if (this.fillColor) feature.properties.fillColor = this.fillColor;
      feature.properties.radius = this.radius;
      feature.geometry = {
        type: 'Polygon',
        // geojsonには円の定義は無いので計算
        coordinates: [this.getCoordinates()]
      };
      return feature;
    }
    /**
     * 円データの頂点データを配列で出力する
     * @return {[type]} [description]
     */
    getCoordinates() {
      var vertex = 36;	// 頂点の数
      var latRadians = Math.PI * this.center.lat() / 180;
      var TMP = 1 - 0.00669437999014132 * Math.pow(Math.sin(Math.PI * this.center.lat() / 180), 2);
      var arc_lat = 110574.2758215944444 / Math.pow(TMP, 3 / 2);
      var arc_lng = (111319.490793273333 * Math.cos(latRadians)) / Math.pow(TMP, 1 / 2);
      var R = this.radius;
      var coordinates = [];
      for (var i = vertex; i >= 0; i--) {
        var rad = (i / (vertex / 2)) * Math.PI;
        var lat = (R / arc_lat) * Math.sin(rad) + this.center.lat();
        var lng = (R / arc_lng) * Math.cos(rad) + this.center.lng();
        coordinates[i] = [lng, lat];
      }
      coordinates.push(coordinates[0]);
      return coordinates;
    }
    /**
     * 円データをJSON形式で出力する(符号化は行わない)
     * @return {[type]} [description]
     */
    getJSON() {
      var feature = { type: 'Feature' };
      feature.properties = {};
      feature.properties.fillColor = this.fillColor;
      feature.properties.strokeColor = this.strokeColor;
      feature.geometry = {
        type: 'Circle',
        radius: this.getRadius(),
        center: this.getCenter()
      };
      return feature;
    }
    loadJSON(polygon) {
      const { properties, geometry } = polygon;
      const { center, radius } = geometry
      this.setOptions({ center, radius, ...properties });
    }
  }
  return Circle;
}