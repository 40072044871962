import App from './App.vue';
import { user } from '@/beforeGuard/defaultRules';

export default {
  path: '/CDS_VF_DisasterDetail',
  name: 'CDS_VF_DisasterDetail',
  component: App,
  meta: {
    pageProperty: {
      title: '災害管理詳細',
      controller: 'CDS_CTR_DisasterDetail',
    },
    guardRules: [user],
  },
};
