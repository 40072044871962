import App from './App.vue';

export default {
  path: '/CDS_VF_ActivityBaseDetail',
  name: 'CDS_VF_ActivityBaseDetail',
  component: App,
  meta: {
    pageProperty: {
      title: '活動拠点詳細',
      controller: 'CDS_CTR_ActivityBaseDetail',
    },
  },
};
