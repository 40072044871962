<template functional>
  <div
    :class="`announce-type-area-cell d-flex align-center justify-center ${$options.computedAllColorClass(
      props,
    )}`"
  >
    <div class="announce-type-area-cell-inner text-center">
      {{ props.status }}
    </div>
  </div>
</template>

<script>
const colorClass = {
  発令: 'announce-declare',
  解除: 'announce-lift',
};

const oldColorClass = {
  発令: 'old-announce-declare',
  解除: 'old-announce-lift',
};

export default {
  name: 'AnnounceTypeAreaCell',
  props: {
    // 今回発令時のステータス
    status: {
      type: String,
      default: null,
    },
    // 前回発令時のステータス
    oldStatus: {
      type: String,
      default: null,
    },
  },

  computedAllColorClass(props) {
    const { status, oldStatus } = props;
    // クラスの配列
    const classArray = [];
    // 今回発令のクラスを追加
    if (!_.isEmpty(status) && colorClass[status]) {
      classArray.push(colorClass[status]);
    }
    // 前回発令のクラスを追加
    if (!_.isEmpty(oldStatus) && oldColorClass[oldStatus]) {
      classArray.push(oldColorClass[oldStatus]);
    }
    return classArray.join(' ');
  },
};
</script>

<style lang="scss" scoped>
$color-pack: false;

@import '~vuetify/src/styles/main.sass';

// 外側のセル
.announce-type-area-cell {
  height: 100%;
}
// 発令
.announce-declare {
  background: map-get($pink, lighten-5);
}
// 解除
.announce-lift {
  background: map-get($blue, lighten-5);
}
// 前回 発令
.old-announce-declare {
  border: 2px solid map-get($red, darken-4);
}
// 前回 解除
.old-announce-lift {
  border: 2px solid map-get($indigo, darken-4);
}
</style>
