import App from './App.vue';

export default {
  path: '/CDS_VF_StaffReportCreate',
  name: 'CDS_VF_StaffReportCreate',
  component: App,
  meta: {
    pageProperty: {
      title: '参集人員報告日時作成',
      controller: 'CDS_CTR_StaffReportCreate',
    },
  },
};
