<template>
  <div>
    <div class="weather ma-3 d-flex justify-center">
      <!-- 雨雲のうごき -->
      <RainCloud />
      <Landslide />
      <Flood />
    </div>
    <v-card class="control ma-3">
      <!-- 距離計測 -->
      <Measurement />
      <!-- 印刷 -->
      <Print />
      <!-- 設定 -->
      <Config />
    </v-card>
  </div>
</template>

<script>
import Measurement from './Measurement';
import Print from './Print';
import Config from './Config';
import RainCloud from './Weather/RainCloud.vue';
import Landslide from './Weather/Landslide.vue';
import Flood from './Weather/Flood.vue';

export default {
  components: {
    Measurement,
    Print,
    Config,
    RainCloud,
    Landslide,
    Flood,
  },
  data: () => ({}),
};
</script>

<style scoped>
.weather {
  position: absolute;
  top: 0px;
  width: 100%;
}
.control {
  position: absolute;
  top: 0px;
  right: 0;
}
@media print {
  .control {
    display: none !important;
  }
}
</style>
