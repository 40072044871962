import App from './App.vue';

export default {
  path: '/CDS_VF_WelfareShelterTotalList',
  name: 'CDS_VF_WelfareShelterTotalList',
  component: App,
  meta: {
    pageProperty: {
      title: '福祉避難所集計',
      controller: 'CDS_CTR_WelfareShelterTotalList',
    },
  },
};
