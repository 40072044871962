import App from './App.vue';
import { user } from '@/beforeGuard/defaultRules';

export default {
  path: '/CDS_VF_SBSConnectResultList',
  name: 'CDS_VF_SBSConnectResultList',
  component: App,
  meta: {
    pageProperty: {
      title: 'SBS連携ログ',
      controller: 'CDS_CTR_SBSConnectResultList',
    },
    guardRules: [user],
  },
};
