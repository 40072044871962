<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_Notice__c',
        detailPageName: 'CDS_VF_NoticeDetail',
        showImageTab: true,
        isDisasterRelated: true,
      }"
      :search-props="{
        syncConditionWithUrl: true,
        defaultSearchCondition,
        forceCondition,
        changeObjectInfo,
        changeLayout,
      }"
      :table-props="{
        viewAttachment: true,
        csvOutputConfig,
        paramsExtension: searchRequestAdditional,
        bulkFieldSetName: 'BulkFieldSet',
        canCreate: isEditMode,
        canEdit: isEditMode,
      }"
      class="notice-list-view"
    >
      <template #datatable.item.DestinationOrganization__c="props">
        <div class="notice-destination-organization">
          <div class="notice-destination-organization-inner">
            {{ orgDisplay(props.item) }}
          </div>
        </div>
      </template>
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },

  data: (vm) => ({
    // 初期条件
    defaultSearchCondition: {
      // 自組織を指定
      CDS_M_Organization__c: [vm.$store.state.user.organization.Id],
    },
    // リクエストの追加付与パラメータ
    searchRequestAdditional: {},
    // 既読未読の選択肢
    readStatusValues: [
      { label: '未読記事', value: false, defaultValue: false },
      { label: '既読記事', value: true, defaultValue: false },
    ],
  }),

  computed: {
    csvOutputConfig() {
      return {
        DestinationOrganization__c: (value, fieldInfo, record) => {
          return this.orgDisplay(record);
        },
      };
    },
    isEditMode() {
      return this.$query.current()?.e === 'true';
    },
  },

  methods: {
    orgDisplay(item) {
      const targetOrgNames = (
        item.NoticeManagerToNotice__r?.map(
          (n) => n.CDS_M_Organization__r?.Name,
        ) || []
      ).filter((v) => !!v);
      return targetOrgNames.join(',');
    },

    async forceCondition({ searchObject, targetCondition }) {
      let result = structuredClone(searchObject);

      // 一旦クリア
      this.$set(
        this.searchRequestAdditional,
        'targetOrganizationIds',
        undefined,
      );
      // 組織
      if (targetCondition.CDS_M_Organization__c) {
        /**
         * 組織の情報
         */
        // 組織だけ別処理にするため取り出し
        const organizationIds = [...targetCondition.CDS_M_Organization__c];
        if (!_.isEmpty(organizationIds)) {
          this.$set(
            this.searchRequestAdditional,
            'targetOrganizationIds',
            organizationIds,
          );
        }
        // デフォルト条件からは削除する
        let AND = result.AND?.filter(
          (r) => !Object.keys(r).includes('CDS_M_Organization__c'),
        );

        // 後処理
        if (AND?.length > 0) {
          result = {
            ...result,
            AND,
          };
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { AND, ...other } = result;
          result = other;
        }
      }

      /**
       * 掲載中
       */
      // 一旦クリア
      this.$set(this.searchRequestAdditional, 'isOpen', undefined);
      // 掲載中も別処理にする
      if (targetCondition.IsOpen__c !== undefined) {
        this.$set(
          this.searchRequestAdditional,
          'isOpen',
          targetCondition.IsOpen__c,
        );
      }
      result.AND = result.AND?.filter(
        (r) => !Object.keys(r).includes('IsOpen__c'),
      );

      /**
       * 既読未読
       */
      // 一旦クリア
      this.$set(this.searchRequestAdditional, 'isRead', undefined);
      // 制御
      if (Array.isArray(targetCondition.isRead__c)) {
        const selected = targetCondition.isRead__c
          .filter((v) => !!v)
          .map((v) => this.readStatusValues.find((x) => x.label === v)?.value)
          .filter((v) => v === true || v === false);

        // trueもfalseもあれば追加条件はなし
        if (
          selected.length > 0 &&
          (!selected.includes(true) || !selected.includes(false))
        ) {
          // 選択した配列をセット
          this.$set(this.searchRequestAdditional, 'isRead', selected);
        }
      }
      result.AND = result.AND?.filter(
        (r) => !Object.keys(r).includes('isRead__c'),
      );

      return result;
    },

    async changeObjectInfo({ objectInfo }) {
      return {
        ...objectInfo,
        properties: {
          ...objectInfo.properties,
          // 既読未読の選択肢を追加
          isRead__c: {
            enum: this.readStatusValues.map((v) => v.label),
            format: 'picklist',
            helpText: '',
            label: '既読/未読',
            picklistValues: this.readStatusValues.map((v) => ({
              ...v,
              value: v.label,
            })),
            // (なし)を表示しない
            noNullValue: true,
            type: 'picklist',
          },
        },
      };
    },

    async changeLayout({ layout }) {
      return {
        ...layout,
        sections: [
          ...layout.sections,
          // 既読未読用の項目を追加
          {
            column: '1',
            detailHeading: true,
            editHeading: true,
            id: 'custom-layout-section-01',
            items: [
              {
                type: 'field',
                fieldPath: 'isRead__c',
              },
            ],
            label: null,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.notice-destination-organization {
  max-width: 30em;
  &-inner {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
