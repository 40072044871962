<template>
  <detail
    use-version
    object-name="CDS_T_Request__c"
    layout-name="RequestLayout"
    default-title="支援要請詳細"
    subtitle="支援要請詳細"
    saved-page="CDS_VF_RequestDetail"
    detail-page-name="CDS_VF_RequestDetail"
    list-page="CDS_VF_RequestList?le=true"
    :list-view-props="{
      canSearch: false,
    }"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    use-correction
  >
    <template #layout="slotProps">
      <DetailLayout :slot-props="slotProps" />
    </template>
    <template #underHeader="{originalData, editMode}">
      <DetailStepper
        :original-data="originalData"
        :edit-mode="editMode"
        :request-statuses="computedRequestStatuses"
      />
    </template>
  </detail>
</template>

<script>
import { mapActions } from 'vuex';
import detail from '@/components/detail/';
import DetailStepper from './DetailStepper';
import RequestStatusLabel from '@/components/app/RequestStatusLabel';
import DetailLayout from './DetailLayout.vue';

export default {
  components: {
    DetailLayout,
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        /**
         * レイアウト読み出しをオーバラライド
         */
        _loadLayout: detail.methods.loadLayout,
        loadLayout: async function () {
          await this._loadLayout();
          // 緯度経度登録項目の入力タイプを図形入力に変更
          // DetailLayoutでも取得するが、保存時のfeaturesの判定はここで実施されるので変えておく
          this.objectInfo.properties.Location__c.type = 'features';
        },
      },
    },
    DetailStepper,
  },

  provide: {
    listItem: {
      // 履歴の一覧のスロットの指定
      detail_history: [
        {
          slotName: 'item.RequestStatus__c',
          component: RequestStatusLabel,
        },
      ],
    },
  },

  data: () => ({
    objectInfo: {},
  }),

  computed: {
    // 初期値の設定
    initValue() {
      const {
        user: { organization, user },
      } = this.$store.state;
      return {
        ...this.$util.getDefaultInitValue(this.$store.state),
        // 要請元組織
        CDS_M_Organization__c: organization.Id,
        RequestDate__c: new Date().toJSON(),
        Reporter__c: user.DefaultReporter__c,
        ReporterAffiliation__c: user.Department__c,
        ReporterTel__c: user.Phone,
        RequestStatus__c: '未対応',
      };
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      // 要請元の氏名等は上書きしない
      return {};
    },
    // ステータス選択肢
    computedRequestStatuses() {
      return (
        this.objectInfo?.properties?.RequestStatus__c?.picklistValues?.filter(
          (v) => v.label != '対応不可',
        ) || []
      );
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.loadObjectInfo()]),
      );
    },
    // オブジェクト情報の取得
    async loadObjectInfo() {
      try {
        const objectInfo = await this.$util.getObjectInfo('CDS_T_Request__c');
        if (objectInfo) {
          this.$set(this, 'objectInfo', objectInfo);
        }
      } catch (error) {
        this.openSnackBar({
          message: 'オブジェクト情報の取得に失敗しました。' + error.message,
          props: {
            color: 'red',
            bottom: true,
            timeout: 10000,
          },
          closable: true,
        });
        console.error(error);
      }
    },

    loadedData() {
      console.log('data loaded');
      this.loaded = true;
    },

    ...mapActions('snackbar', ['openSnackBar']),
  },
};
</script>
