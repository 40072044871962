import { config } from '@/arcGIS/esri';
import Map from '@arcgis/core/Map';
import Basemap from '@arcgis/core/Basemap';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapView from '@arcgis/core/views/MapView';
import Sketch from '@arcgis/core/widgets/Sketch';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { toGeoCoordinateString } from '@arcgis/core/rest/geometryService';
import Graphic from '@arcgis/core/Graphic';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';
import WebTileLayer from '@arcgis/core/layers/WebTileLayer';
import Search from '@arcgis/core/widgets/Search';
import { locationToAddress } from '@arcgis/core/rest/locator';
import Point from '@arcgis/core/geometry/Point';

import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';

import {
  xyToLngLat,
  lngLatToXY,
} from '@arcgis/core/geometry/support/webMercatorUtils';

import { loadApi } from '../googlemap/loader';

const arcgisApiUrls = {
  geometryServer:
    'https://utility.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer',
  geocodeServer:
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
};

export class EsriLoader {
  constructor(params) {
    this.drawGraphicsLayer = null;
    this.mapView = null;
    this.map = null;
    this.search = {};
    this.sketch = {};
    //背景地図（初期値）
    this.selectMap = config.selectMap;
    //背景地図切り替え用
    this.mapItems = config.mapItems;
    //緯度経度（初期値）
    this.latitude = config.latitude;
    this.longitude = config.longitude;
    this.resultUTM = null;
    //地図の拡大縮小（初期値）
    this.zoom = config.zoom;
    //描画図形のスタイル
    this.styleModel = config.styleModel;
    this.eventClick = null;
    //保存済み描画データ
    this.model = params.model;
    //図形の描画フラグ
    this.canDraw = params.canDraw;
    //編集中かどうか
    this.isEdit = params.isEdit;
    // arcgisのapiのurl
    this.apiUrlPromise = params.apiUrlPromise;
  }
  /**
   * ArcGISの地図を読み込む
   */
  async loadMap() {
    // 絵画用のレイヤー
    this.drawGraphicsLayer = new GraphicsLayer();

    // GoogleMapAPIを使用するために読み込む
    await loadApi();

    if (this.model) {
      //保存データを反映する
      let savedGraphics = this.canDraw
        ? this.conversionToArcGis(JSON.parse(this.model))
        : JSON.parse(this.model);
      if (this.canDraw) {
        savedGraphics.graphics.map((jsonItem) => {
          let geo;
          let sym;
          if (jsonItem.type == 'point') {
            geo = {
              type: jsonItem.type,
              latitude: jsonItem.latitude,
              longitude: jsonItem.longitude,
              x: jsonItem.x,
              y: jsonItem.y,
              spatialReference: config.spatialReference,
            };
            sym = this.styleModel.pointSymbol;
            //座標表示
            this.setCoordinate(geo);
            this.setUTM(this.longitude, this.latitude);
          } else if (jsonItem.type == 'polyline') {
            geo = {
              type: jsonItem.type,
              paths: jsonItem.data,
              spatialReference: config.spatialReference,
            };
            sym = this.styleModel.polylineSymbol;
          } else if (jsonItem.type == 'polygon') {
            geo = {
              type: jsonItem.type,
              rings: jsonItem.data,
              spatialReference: config.spatialReference,
            };
            sym = this.styleModel.polygonSymbol;
          }
          if (geo != null && sym != null) {
            let graph = new Graphic({
              geometry: geo,
              symbol: sym,
            });
            this.drawGraphicsLayer.graphics.add(graph);
          }
        });
        if (savedGraphics.zoom) this.zoom = savedGraphics.zoom;
      } else {
        let geo;
        geo = {
          type: 'point',
          latitude: savedGraphics.latitude,
          longitude: savedGraphics.longitude,
          spatialReference: config.spatialReference,
        };
        this.createPoint(geo);
        this.setCoordinate(geo);
        this.setUTM(savedGraphics.longitude, savedGraphics.latitude);
      }
    }
    if (this.drawGraphicsLayer.graphics.length == 0) {
      //初期ポイントの設定
      let geo;
      geo = {
        type: 'point',
        latitude: this.latitude,
        longitude: this.longitude,
        x: config.x,
        y: config.y,
        spatialReference: config.spatialReference,
      };
      this.createPoint(geo);
      this.setCoordinate(geo);
      this.setUTM(this.longitude, this.latitude);
    }

    //初期中心点の設定
    const searchCenter = new Point({
      type: 'point',
      latitude: this.latitude,
      longitude: this.longitude,
    });

    this.map = new Map({
      basemap: new Basemap({
        portalItem: {
          id: '96cff8b8e48d45548833f19e29f09943',
        },
      }),
      layers: [this.drawGraphicsLayer],
    });
    this.mapView = new MapView({
      container: 'mapDiv',
      map: this.map,
      center: searchCenter,
      zoom: this.zoom,
    });

    //ベースマップのsatelliteを表示させるために記述
    let source = config.mapItems
      .filter((x) => x.type != 'url')
      .map((v) => {
        return Basemap.fromId(v.value);
      });
    new BasemapGallery({
      view: this.mapView,
      source: source,
    });

    const sketchViewModel = new SketchViewModel({
      view: this.mapView,
      layer: this.drawGraphicsLayer,
      pointSymbol: this.styleModel.pointSymbol,
      polylineSymbol: this.styleModel.polylineSymbol,
      polygonSymbol: this.styleModel.polygonSymbol,
    });

    this.sketch = new Sketch({
      layer: this.drawGraphicsLayer,
      view: this.mapView,
      creationMode: 'update',
      viewModel: sketchViewModel,
      visibleElements: {
        settingsMenu: false,
      },
    });
    //図形の描画をしない時、対象のツールを非表示にする
    if (!this.canDraw) {
      this.sketch.visibleElements = {
        createTools: {
          polyline: false,
          polygon: false,
          rectangle: false,
          circle: false,
        },
        selectionTools: {
          'rectangle-selection': false,
          'lasso-selection': false,
        },
        settingsMenu: false,
      };
    }
    // 検索入力ウィジェット
    this.search = new Search({
      view: this.mapView,
      popupEnabled: false,
      resultGraphicEnabled: false,
      sources: [
        {
          name: '地番検索',
          placeholder: '地番を入力...',
          url: `${await this
            .apiUrlPromise}/arcgis/rest/services/HamamatsuChibanLoc/GeocodeServer/findAddressCandidates`,
          withinViewEnabled: false,
          maxResults: 3,
          zoomScale: 5000,
        },
      ],
    });

    this.mapView.ui.add(this.search, {
      position: 'top-left',
      index: 0,
    });
    //検索完了時イベント呼び出し
    this.search.on('search-complete', this.onSearchComplete);

    // ユーティリティ・制御系
    //マウス右ドラッグによる地図の回転イベントを止める
    this.mapView.constraints = {
      rotationEnabled: false,
    };
    //ホイールのスクロールでの地図の拡大・縮小イベントを止める
    this.mapView.on('mouse-wheel', function (evt) {
      evt.stopPropagation();
    });

    this.switchingFeature(this.isEdit);

    // 描画ツール関係
    this.mapView.when(() => {
      this.mapView.ui.add(this.sketch, 'bottom-left');
      //図形作成後イベント
      this.sketch.on('create', (event) => {
        if (event.state == 'complete') {
          if (event.graphic.geometry.type == 'point') {
            // 他の点グラフィックは削除する
            let graphics = this.drawGraphicsLayer.graphics;
            for (let i = 0; i < graphics.length - 1; i++) {
              if (graphics.getItemAt(i).geometry.type == 'point') {
                this.drawGraphicsLayer.remove(graphics.getItemAt(i));
              }
            }
            this.setCoordinate(event.graphic.geometry);
            this.resultUTM = this.setUTM(this.longitude, this.latitude);
            this.onPointUpdated(event.graphic.geometry, this.resultUTM);
          }
          // 図形の保存
          this.FeatureChangeEventHandler && this.FeatureChangeEventHandler();
        }
      });
      //図形編集後イベント
      this.sketch.on('update', (event) => {
        if (event.state == 'complete') {
          if (event.graphics[0].geometry.type == 'point') {
            this.resultUTM = this.setUTM(this.longitude, this.latitude);
            this.onPointUpdated(event.graphics[0].geometry, this.resultUTM);
          }
        }
        if (event.graphics[0].geometry.type == 'point') {
          this.setCoordinate(event.graphics[0].geometry);
        }
        this.FeatureChangeEventHandler && this.FeatureChangeEventHandler();
      });
      // 図形削除後イベント
      this.sketch.on('delete', (event) => {
        // 点グラフィックは消えないようにする
        if (event.graphics[0].geometry.type == 'point') {
          let hold = event.graphics[0].geometry;
          let sym = this.styleModel.pointSymbol;
          if (hold != null && sym != null) {
            this.createPoint(hold);
          }
        }
      });
      this.changeMap(this.selectMap);
    });
  }
  /**
   * 検索完了後の処理
   * @param {*} event
   */
  onSearchComplete = (event) => {
    const { results: searchResults } = event;

    const resultItems = searchResults?.reduce((prev, next) => {
      // 地番検索の結果があれば優先的に表示
      if (next.source?.name === '地番検索') {
        return [...next.results, ...prev];
      } else {
        return [...prev, ...next.results];
      }
    }, []);

    // 結果がなければ終わり
    if (resultItems.length === 0) return;

    let geo = resultItems[0].feature;
    this.createPoint(geo.geometry);
    this.setCoordinate(geo.geometry);
    this.resultUTM = this.setUTM(this.longitude, this.latitude);
    this.FeatureChangeEventHandler && this.FeatureChangeEventHandler();
  };
  /**
   * 点グラフィックの作成
   * @param {object} geo
   *
   */
  createPoint(geo) {
    if (geo != null) {
      let graph = new Graphic({
        geometry: geo,
        symbol: this.styleModel.pointSymbol,
      });
      let graphics = this.drawGraphicsLayer.graphics;
      for (let i = 0; i < graphics.length; i++) {
        if (graphics.getItemAt(i).geometry.type == 'point') {
          this.drawGraphicsLayer.remove(graphics.getItemAt(i));
        }
      }
      this.drawGraphicsLayer.graphics.add(graph);
    }
  }

  /**
   * 背景地図の切り替え
   * @param {string} val
   */
  async changeMap(val) {
    const mapInfo = config.mapItems.find((v) => v.id === val);
    if (!mapInfo) return;
    let layers = this.map.basemap.baseLayers;
    if (layers.items.length > 1) {
      this.map.basemap.baseLayers.items.splice(0, layers.items.length - 1);
    }
    if (mapInfo.type == 'url') {
      const tileLayer = new WebTileLayer({
        urlTemplate: mapInfo.value.replace(
          '{z}/{x}/{y}',
          '{level}/{col}/{row}',
        ),
        copyright: '国土地理院',
      });
      this.map.basemap.baseLayers.add(tileLayer);
    } else {
      // ベースマップが読み込まれるまで待つ
      const nextBasemap = await this.getBasemap(mapInfo.value).loadAll();
      // toggleが読み込まれるまで待つ
      const basemapToggle = await new Promise((resolve, reject) => {
        const basemapToggle = new BasemapToggle({
          view: this.mapView,
          nextBasemap,
        });
        basemapToggle.when(resolve, reject);
      });
      //地図切り替え
      await basemapToggle.toggle();
    }
    this.map.reorder(this.drawGraphicsLayer, 99);
  }
  /**
   *  ベースマップオブジェクトの生成
   */
  getBasemap(basemapId) {
    return Basemap.fromId(basemapId);
  }
  /**
   * UTM取得処理
   * @param {number} lon
   * @param {number} lat
   */
  async setUTM(lon, lat) {
    const params = {
      sr: '4326',
      coordinates: [[lon, lat]],
      conversionType: 'mgrs',
      conversionMode: 'mgrsNewWith180InZone01',
    };
    //UTM座標を取得・表示
    try {
      //UTM座標取得用サービス
      const res = await toGeoCoordinateString(
        arcgisApiUrls.geometryServer,
        params,
      );
      this.getUTM && this.getUTM(res[0]);
      return res[0];
    } catch (error) {
      //UTM座標取得時エラーが発生した場合の処理
      console.error(error);
    }
  }

  /**
   *  緯度経度を設定する
   * @param {object} graphic
   */
  setCoordinate(graphic) {
    this.latitude = graphic.latitude;
    this.longitude = graphic.longitude;
    this.getCoordinate && this.getCoordinate([this.latitude, this.longitude]);
  }

  /**
   *  住所を取得する
   * @param {object} geo
   * @param {object} utmPromise
   */
  onPointUpdated(geo, utmPromise) {
    let param = {
      location: [geo.longitude, geo.latitude],
      x: geo.x,
      y: geo.y,
    };

    //逆ジオ
    locationToAddress(arcgisApiUrls.geocodeServer, param).then((res) => {
      // ジオコーディングの結果の住所が「日本」かどうかで
      // 処理を振り分ける
      const address = res?.attributes?.LongLabel;
      if (address != '日本') {
        // 取得した住所をそのまま使用
        let address = res.attributes.LongLabel;
        if (address) {
          if (address.indexOf(', JPN') > 0) {
            address = address.replace(', JPN', '');
          }
          // 検索欄に取得した住所を入力
          this.search.searchTerm = address;
          this.chAddress &&
            this.chAddress({ ...this.conversionAddress(address), utmPromise });
        }
      } else {
        // 地番変換
        this.locationToParcelNumber(geo, utmPromise);
      }
    });
  }
  /**
   * 地番処理
   * @param {object} geo
   * @param {object} utmPromise
   *
   */
  async locationToParcelNumber(geo, utmPromise) {
    const maplayer_url = `${await this
      .apiUrlPromise}/arcgis/rest/services/hamamatsu_chaban_polygon/MapServer`;
    let xhr = new XMLHttpRequest();
    let query_url = maplayer_url + '/0/query';

    let query =
      'f=json&geometryType=esriGeometryPoint' +
      '&geometry=' +
      String(geo.longitude) +
      ',' +
      String(geo.latitude) +
      '&inSR=4326' +
      '&outFields=Address' +
      '&returnGeometry=false';

    xhr.open('GET', query_url + '?' + query);
    xhr.send();
    xhr.onload = () => {
      let features = JSON.parse(xhr.response).features;
      // features.lengthの有無で地番を取得できたか判定
      if (features.length > 0) {
        // 住所を取得
        let address = features[0].attributes.Address;
        if (address) {
          // 検索欄に取得した住所を入力
          this.search.searchTerm = address;
          this.chAddress &&
            this.chAddress({
              ...this.conversionAddress(address),
              utmPromise,
            });
        }
      }
      // 地番が取得できない=県外など
      else {
        this.search.searchTerm = '住所を取得できませんでした。';
      }
    };
  }
  /**
   * 区名を判断する
   * @param {string} address
   * @returns {string} 取得した区名を返す
   */
  getWard(address) {
    //県内かどうかを判定
    if (address.indexOf('静岡県') != -1) {
      //静岡県内の住所の場合は「静岡県」を省略する
      address = address.substring(address.indexOf('静岡県') + 3);
    }
    //浜松市内かどうかを判定
    if (address.indexOf('浜松市') != -1) {
      //浜松市内の場合は区名を取得する
      let ward = address.substring(
        address.indexOf('浜松市') + 3,
        address.indexOf('区') + 1,
      );
      return ward;
    }
    //静岡県外、浜松市外の場合は「浜松市外」を区名にする
    return '浜松市外';
  }

  /**
   * reverseGeocodingEventhandler()を使用するためにobjectを生成
   * @param {*} address 住所情報
   * @returns
   */
  conversionAddress(address) {
    let ward = this.getWard(address);
    let result = {
      formatted_address: address,
      address_components: [
        {
          long_name: ward,
          short_name: ward,
          types: ['political', 'sublocality', 'sublocality_level_1'],
        },
      ],
    };
    return result;
  }

  /**
   * 編集時以外は描画・検索をオフにする
   * 地図内のクリックも無効にする
   * @param {boolean} val
   */
  switchingFeature(val) {
    this.sketch.visible = val;
    this.search.visible = val;
    if (this.mapView && !val && !this.eventClick) {
      this.eventClick = this.mapView.on('immediate-click', function (evt) {
        evt.stopPropagation();
      });
    }
    if (val && this.eventClick) {
      this.eventClick.remove();
      this.eventClick = null;
    }
  }
  /**
   * 図形の保存
   * @returns {object}
   */
  getGraphData() {
    let graphics = this.drawGraphicsLayer.graphics;
    let json = {};
    let jsonGraphics = [];
    if (this.canDraw) {
      for (let i = 0; i < graphics.items.length; i++) {
        let geo = graphics.getItemAt(i).geometry;
        let jsonChild = {};
        jsonChild.type = geo.type;
        if (geo.type === 'polyline') {
          jsonChild.data = geo.paths[0];
          jsonChild.tool = 'polyline';
        } else if (geo.type === 'polygon') {
          // jsonChild.type = geo.type;
          jsonChild.data = geo.rings[0];
          jsonChild.tool = geo.tool;
        } else if (geo.type === 'point') {
          // jsonChild.type = geo.type;
          jsonChild.longitude = Number(geo.longitude.toFixed(10));
          jsonChild.latitude = Number(geo.latitude.toFixed(10));
          jsonChild.x = geo.x;
          jsonChild.y = geo.y;
          jsonChild.tool = 'point';
        }
        jsonGraphics.push(jsonChild);
      }
      json.graphics = jsonGraphics;
      json.zoom = this.mapView.zoom;
      return this.conversionToGoogleMap(json);
    } else {
      json.latitude = Number(this.latitude.toFixed(10));
      json.longitude = Number(this.longitude.toFixed(10));
      return json;
    }
  }
  /**
   * 図形データの変換（ArcGIS→GoogleMap）
   * @param {object} json
   * @returns {object}
   */
  conversionToGoogleMap(json) {
    let result = {};
    result.type = 'FeatureCollection';
    let features = [];
    json.graphics.forEach((val) => {
      let element = {};
      element.type = 'Feature';
      if (val.type == 'point') {
        element.properties = { strokeColor: '#FF0000' };
        element.geometry = {
          type: 'Marker',
          coordinates: { lat: val.latitude, lng: val.longitude },
        };
      } else {
        let data = val.data.map((x) => {
          return xyToLngLat(x[0], x[1]);
        });
        let path = data.map((y) => {
          return new google.maps.LatLng(y[1], y[0]);
        });

        if (val.type == 'polyline') {
          element.properties = { strokeColor: '#FF0000' };
          element.geometry = {
            type: 'Polyline',
            coordinates: google.maps.geometry.encoding.encodePath(path),
          };
        } else if (val.type == 'polygon') {
          element.properties = { fillColor: '#FF0000', strokeColor: '#FF0000' };
          element.geometry = {
            type: 'Polygon',
            coordinates: google.maps.geometry.encoding.encodePath(path),
          };
        }
      }
      features.push(element);
    });
    result.features = features;
    result.zoom = json.zoom;

    return result;
  }

  /**
   * 図形データ変換（GoogleMap→ArcGIS）
   * @param {object} json
   * @returns {object}
   */
  conversionToArcGis(json) {
    let result = {};
    let graphics = [];
    if (typeof json === 'string') {
      json = JSON.parse(json);
    }
    json.features.forEach((val) => {
      let element = {};
      if (val.geometry.type == 'Marker') {
        let xy = lngLatToXY(
          val.geometry.coordinates.lng,
          val.geometry.coordinates.lat,
        );
        element.type = 'point';
        element.longitude = val.geometry.coordinates.lng;
        element.latitude = val.geometry.coordinates.lat;
        element.x = xy[0];
        element.y = xy[1];
      } else {
        if (val.geometry.type == 'Polyline') {
          element.type = 'polyline';
        } else {
          element.type = 'polygon';
        }
        let path = google.maps.geometry.encoding.decodePath(
          val.geometry.coordinates,
        );
        let data = [];
        path.forEach((x) => {
          let coordinate = x.toJSON();
          data.push(lngLatToXY(coordinate.lng, coordinate.lat));
        });
        element.data = data;
      }
      graphics.push(element);
    });
    result.graphics = graphics;
    result.zoom = json.zoom;
    return result;
  }
}
