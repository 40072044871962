<template>
  <detail
    object-name="CDS_T_Notice__c"
    layout-name="NoticeLayout"
    default-title="新規お知らせ作成"
    saved-page="CDS_VF_NoticeDetail"
    detail-page-name="CDS_VF_NoticeDetail"
    detail-layout-name="CDS_T_Notice__c-検索レイアウト"
    search-layout-name="CDS_T_Notice__c-一覧表レイアウト"
    list-page="CDS_VF_NoticeList?le=true"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    :override="override"
    :disable-actions="disableActions"
    @loaded-data="detailDataLoaded"
  >
    <template #DetailMenus="{editMode}">
      <template v-if="!editMode && isTarget">
        <ActionButton
          v-if="isRead"
          btn-value="既読"
          icon="mdi-check-circle"
          color="green"
          outlined
          @click="unReadNotice()"
        />
        <ActionButton
          v-else
          btn-value="未読"
          icon="mdi-check-circle-outline"
          outlined
          @click="readNotice()"
        />
      </template>
    </template>
    <template #custom-tab="{editMode}">
      <v-tab v-if="!editMode">
        <v-icon left>
          mdi-check
        </v-icon>
        既読状況
      </v-tab>
    </template>
    <template #custom-tab-item="{editMode}">
      <v-tab-item v-if="!editMode">
        <v-data-table
          :items="readStatus"
          :headers="[
            { text: '#', value: 'CDS_M_Organization__r.Sort__c' },
            { text: '組織名', value: 'CDS_M_Organization__r.Name' },
            { text: '既読状況', value: 'isRead__c' },
            { text: '更新日時', value: 'LastModifiedDate' },
          ]"
          sort-by="CDS_M_Organization__r.Sort__c"
          class="elevation-0 cdsTable pt-3"
          :mobile-breakpoint="0"
          style="max-width: 800px;"
        >
          <template #item.isRead__c="{item}">
            <v-simple-checkbox v-model="item.isRead__c" disabled />
          </template>
          <template #item.LastModifiedDate="{item}">
            {{ item.LastModifiedDate | datetime }}
          </template>
        </v-data-table>
      </v-tab-item>
    </template>
  </detail>
</template>

<script>
import { mapActions } from 'vuex';
import detail from '@/components/detail/';
import ActionButton from '@/components/detail/ActionButton';

export default {
  components: {
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        /**
         * データ読み出しをオーバラライド
         */
        _loadData: detail.methods.loadData,
        loadData: async function () {
          const { controller } = this.$pageProperty;
          const query = this.$query.current();
          // 通常読み出しを実行
          await this._loadData();
          try {
            if (query.id) {
              // 既読管理オブジェクトの取得
              const readStatus = await this.$con.invoke({
                controller,
                method: 'getReadStatus',
                params: {
                  id: query.id,
                },
              });
              const Targets =
                readStatus.map((d) => d.CDS_M_Organization__c) || [];
              // オリジナルデータの保持
              this.original.object.Targets = Targets;
              this.value = { ...this.value, Targets };
            }
          } catch (error) {
            this.openErrorSnackBar({
              message: 'データの読み出しに失敗しました。' + error.message,
            });
            console.error(error);
          }
        },
        /**
         * レイアウト読み出しをオーバラライド
         */
        _loadLayout: detail.methods.loadLayout,
        loadLayout: async function () {
          await this._loadLayout();
          // 通知対象組織選択項目を手動で追加する
          this.layout.sections = [
            ...this.layout.sections,
            {
              label: '対象組織',
              detailHeading: true,
              editHeading: true,
              column: '1',
              items: [
                {
                  fieldPath: 'Targets',
                  readonly: false,
                  required: false,
                  type: 'field',
                },
              ],
            },
          ];
          this.objectInfo.properties.Targets = {
            label: '通知対象組織',
            type: 'reference',
            multiple: true,
            $ref: '#/definitions/CDS_M_Organization__c',
          };
        },
      },
    },
    ActionButton,
  },
  data: () => ({
    sections: [],
    objectInfo: {},
    readStatus: [],
    // 削除可能か
    canDelete: false,
    override: {
      // ※ここでのthisはdetailコンポーネントで束縛されている
      // 保存機能オーバライド
      async save() {
        const { objectName } = this;
        const { controller } = this.$pageProperty;
        const newData = {
          ...this.original.object,
          ...this.value,
          attributes: { type: objectName },
        };
        // 通知対象組織を取り除く
        const targets = newData.Targets;
        delete newData.Targets;
        // データ保存
        const res = await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller,
            method: 'saveData',
            params: {
              object: JSON.stringify(newData),
            },
          }),
        );
        // 添付ファイル保存
        await this.saveAttachment(res.obj.Id);
        // 既読オブジェクトを作成
        if (!newData.ShowInPortal__c && targets && targets.length > 0) {
          await this.$con.invoke({
            controller,
            method: 'createNewNotice',
            params: {
              id: res.obj.Id,
              organizationIdList: targets.join(';'),
            },
          });
        }
        return res;
      },
      async remove() {
        const { objectName } = this;
        const { controller } = this.$pageProperty;
        const deleteData = {
          attributes: { type: objectName },
          ...this.original.object,
          ...this.value,
        };
        delete deleteData.Targets;
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller,
            method: 'deleteData',
            params: {
              object: JSON.stringify(deleteData),
            },
          }),
        );
        this.$transition.to(this.listPage || this.savedPage, {
          ...this.$query.current(),
          sb: JSON.stringify({
            message: '削除しました',
            props: {
              color: 'green',
              bottom: true,
              timeout: 2000,
            },
            closable: true,
          }),
        });
      },
    },
  }),
  computed: {
    // 通知対象かどうか
    isTarget() {
      const status = _.find(this.readStatus, {
        CDS_M_Organization__c: this.$store.state.user.organization.Id,
      });
      return status ? true : false;
    },
    // 既読かどうか
    isRead() {
      const status =
        _.find(this.readStatus, {
          CDS_M_Organization__c: this.$store.state.user.organization.Id,
        }) || {};
      return status.isRead__c;
    },
    // 初期値の設定
    initValue() {
      return this.$util.getDefaultInitValue(this.$store.state);
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
    // 編集可能不可能の制御
    disableActions() {
      // 参照モードのときはボタンを表示しない
      return this.canDelete ? [] : ['remove'];
    },
  },
  async mounted() {
    await this.readNotice();
  },
  methods: {
    /**
     * 既読処理
     */
    async readNotice() {
      const query = this.$query.current();
      if (query.id) {
        await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'readNotice',
          params: {
            id: query.id,
            organizationId: this.$store.state.user.organization.Id,
          },
        });
      }
      await this.getReadStatus();
    },
    /**
     * 未読処理
     */
    async unReadNotice() {
      const query = this.$query.current();
      await this.$con.invoke({
        controller: this.$pageProperty.controller,
        method: 'unReadNotice',
        params: {
          id: query.id,
          organizationId: this.$store.state.user.organization.Id,
        },
      });
      await this.getReadStatus();
    },
    /**
     * 既読状況取得
     */
    async getReadStatus() {
      const query = this.$query.current();
      if (query.id) {
        this.readStatus = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getReadStatus',
          params: {
            id: query.id,
          },
        });
      }
    },
    /**
     * 詳細データが読み込まれた時
     */
    async detailDataLoaded(value) {
      // 自組織が作成したものであれば削除可能
      this.canDelete =
        value.CDS_M_Organization__c == this.$store.state.user.organization.Id;
    },
    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openErrorSnackBar',
    ]),
  },
};
</script>
<style></style>
